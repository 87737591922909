import React from "react";

import { Dropdown, DropdownList, DropdownTrigger } from "@devices/components/shared";

type Props = React.PropsWithChildren<{ disableClickAway?: boolean; displayLeft?: boolean }>;

const toogleEvent = "menu:toggle";

export default function MenuContainer({ children, disableClickAway = false, displayLeft = false }: Props): JSX.Element {
    const [editMenuOpen, setEditMenuOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        if (!editMenuOpen) {
            document.dispatchEvent(new Event(toogleEvent));
        }
        setEditMenuOpen(!editMenuOpen);
    };

    React.useEffect(() => {
        const handleMenuToggle = () => {
            if (editMenuOpen) {
                setEditMenuOpen(false);
            }
        };

        document.addEventListener(toogleEvent, handleMenuToggle);
        return () => document.removeEventListener(toogleEvent, handleMenuToggle);
    }, [editMenuOpen]);

    return (
        <Dropdown>
            <DropdownTrigger onClick={handleToggle} anchorRef={anchorRef} data-testid="menu-trigger">
                <i className="fas fa-ellipsis-v" />
            </DropdownTrigger>
            <DropdownList
                isOpen={editMenuOpen}
                onClickAway={disableClickAway ? () => null : () => setEditMenuOpen(false)}
                anchorRef={anchorRef}
                displayLeft={displayLeft}
            >
                {children}
            </DropdownList>
        </Dropdown>
    );
}
