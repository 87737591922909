import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { createUseStyles } from "react-jss";
import { Modal } from "src/ui/shared/Modal/Modal";

import { UploadProgressBar } from "@files/components/UploadProgressBar";
import { UploadProvider, useUploadContext } from "@files/context/UploadContext";
import { maxFileSize } from "@files/UploadPage";
import { Box } from "@mui/material";
import { UploadFileField } from "@shared/form/UploadFileField";

const useStyles = createUseStyles({
    dialogContent: {
        "&.MuiDialogContent-root": {
            paddingBottom: "20px",
        },
    },
    modalTitleBox: {
        display: "flex",
        justifyContent: "space-between",
        padding: 20,
        paddingBottom: 0,
    },
    pointer: {
        cursor: "pointer",
    },
    contentBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "flex-end",
    },
});

type FormValues = {
    files: File[];
};
type Props = {
    groupId: number;
    open: boolean;
    handleClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const FileUploadContent = (props: Props) => {
    const classes = useStyles();
    const { uploadStarted } = useUploadContext();
    const validate = (values: FormValues): ValidationErrors => {
        const errors: ValidationErrors = {};
        if (values.files) {
            for (const file of values.files) {
                if (file.size > maxFileSize) {
                    errors.files = `File ${file.name} exceeds the maximum size limit of 256 MB.`;
                    break;
                }
            }
        }
        return errors;
    };

    return (
        <Modal title="Upload File" open={props.open} onClose={() => props.handleClose(false)}>
            <Form<FormValues>
                validate={validate}
                onSubmit={() => undefined}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <UploadFileField name="files" disabled={uploadStarted} groupId={props.groupId} />
                        {values.files && values.files.length ? (
                            <Box className={classes.contentBox}>
                                <UploadProgressBar filesToUpload={values.files} />
                            </Box>
                        ) : null}
                    </form>
                )}
            />
        </Modal>
    );
};

export const FileUploadModal = (props: Props) => (
    <UploadProvider>
        <FileUploadContent {...props} />
    </UploadProvider>
);
