import React, { useState } from "react";
import { useDispatch, useSelector } from "src/store";

import ActionsMenu from "@dashboard/files/components/ActionsMenu/ActionsMenu";
import { useCommands } from "@devices/components/Commands/hooks";
import { ConfirmDialog } from "@devices/components/shared";
import { setPaneTab } from "@devices/store";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { Device } from "@devices/types";
import { getPresignedDownloadUrl } from "@files/api";
import { useDeleteFile } from "@files/hooks/entities";
import { FileInfo } from "@files/types";
import { hasUnsuccessfulAnalyzer } from "@files/utils";
import { Box, DialogContentText } from "@mui/material";
import { permissionService } from "@services/permissionService/permissionService";
import { SecondaryButton } from "@shared/CustomButton";

import { FileSettingsDialog } from "../../../FileSettingsDialog";
import { useStyles } from "./TableItem.style";
import { getApkFileStatus, getPrimaryButtonProps } from "./TableItem.utils";

type Props = {
    file: FileInfo;
    device: Device;
};
export const ActionsCell = (props: Props) => {
    const [isSettingsModalOpen, setIsSettingsModalOpen] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { removeFile } = useDeleteFile();
    const unsuccessfulAnalyzerExist = hasUnsuccessfulAnalyzer(props.file);
    const status = getApkFileStatus(props.file, props.device.lastState?.packages);

    const { deviceAbility, fileAbility } = permissionService();
    const mayInstallApp = deviceAbility(props.device).can("install", "App");
    const mayManageTags = fileAbility(props.file).can("manage", "Tag");

    const installedPackage = props.device.lastState?.packages?.find(
        (item) => item.package.toLowerCase() === props.file.metadata?.file?.apk?.manifest?.package.toLowerCase(),
    );
    const currentAppVersion = Number(installedPackage?.versionCode);
    const appVersionToInstall = Number(props.file.metadata?.file?.apk?.manifest?.versioncode);
    const appIsUpToDate = currentAppVersion >= appVersionToInstall;

    const { createMdmCommand } = useCommands();

    const isExpired = useSelector(selectSubscriptionExpired);

    const toggleModal = () => setIsModalOpen((prev) => !prev);

    const handleDelete = async () => {
        await removeFile(props.file.id);
    };

    const installPackageForDevice = async () => {
        const url = await getPresignedDownloadUrl(props.file.id);
        const commandObject = {
            url: url,
            source: "url",
            package: props.file.metadata?.file?.apk?.manifest?.package,
            version: props.file.metadata?.file?.apk?.manifest?.versioncode,
        };

        await createMdmCommand({
            deviceId: props.device.id,
            json: {
                "command": "installPackage",
                "caption": "Install package",
                ...commandObject,
            },
        }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };
    const getTagBtnTitle = () => {
        const result = { title: "Manage tags" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageTags) {
            result.title = "You are not allowed to manage tags in this group";
        }
        return result;
    };

    const installButtonProps = getPrimaryButtonProps(status, unsuccessfulAnalyzerExist, mayInstallApp);

    return (
        <>
            <Box className={classes.buttonBox}>
                {!appIsUpToDate ? (
                    <SecondaryButton
                        tooltipProps={{
                            title: isExpired ? "Subscription expired" : installButtonProps.tooltip,
                        }}
                        disabled={installButtonProps.disabled || isExpired}
                        onClick={installPackageForDevice}
                    >
                        <i className={installButtonProps.iconClass} />
                    </SecondaryButton>
                ) : null}
                <SecondaryButton
                    onClick={() => setIsSettingsModalOpen(true)}
                    tooltipProps={getTagBtnTitle()}
                    disabled={!mayManageTags}
                >
                    <i className="fa-solid fa-gear" />
                </SecondaryButton>
                <ActionsMenu file={props.file} />
            </Box>

            <ConfirmDialog
                title="Delete file"
                dangerButton
                primaryActionText="Delete"
                open={isModalOpen}
                onConfirm={handleDelete}
                onClose={toggleModal}
            >
                <DialogContentText>
                    Are you sure you want to delete <span style={{ fontWeight: "bold" }}>{props.file.filename}</span>?
                </DialogContentText>
            </ConfirmDialog>

            <FileSettingsDialog
                item={props.file}
                open={isSettingsModalOpen}
                onClose={() => setIsSettingsModalOpen(false)}
            />
        </>
    );
};
