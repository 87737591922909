import { FormApi } from "final-form";
import React from "react";

import { Group } from "@dashboard/devices/types";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Collapse, List, ListItem, ListItemButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { UncontrolledCheckboxField } from "@shared/form/CheckboxField";

import { GroupWithChildren } from "./GroupFilterHeader";

const useStyles = makeStyles(() => ({
    expandIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginLeft: 4,
    },
    itemContent: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
}));

type GroupTreeItemProps = {
    group: GroupWithChildren;
    expandedGroups: number[];
    form: FormApi<Record<string, boolean>>;
    allGroups: Group[];
    onExpand: (groupId: number) => void;
    depth?: number;
    filterWithParent?: boolean;
};

export const GroupFilterItem = ({ depth = 0, ...props }: GroupTreeItemProps) => {
    const classes = useStyles();
    const hasChildren = props.group.children.length > 0;
    const isExpanded = props.expandedGroups.includes(props.group.id);
    const isChecked = props.form.getState().values[props.group.name] || false;

    const onUncheckChildGroups = (group: GroupWithChildren) => {
        group.children.forEach((child) => {
            props.form.change(child.name, false);
            onUncheckChildGroups(child);
        });
    };
    const onCheckParentGroups = (group: GroupWithChildren) => {
        let currentGroup: Group | undefined = group;
        while (currentGroup?.parentId !== null) {
            const parentGroup = props.allGroups.find((g) => g.id === currentGroup?.parentId);
            if (parentGroup) {
                props.form.change(parentGroup.name, true);
            }
            currentGroup = parentGroup;
        }
    };
    const onCheckWithParent = () => {
        if (isChecked) {
            props.form.change(props.group.name, false);
            onUncheckChildGroups(props.group);
        } else {
            props.form.change(props.group.name, true);
            onCheckParentGroups(props.group);
        }
    };

    const onCheckboxChange = () => {
        if (props.filterWithParent) {
            onCheckWithParent();
        } else {
            props.form.change(props.group.name, !isChecked);
        }
    };
    const onExpand = () => {
        props.onExpand(props.group.id);
    };

    return (
        <React.Fragment>
            <ListItem style={{ paddingLeft: `${depth * 16}px` }} disablePadding>
                <ListItemButton>
                    <Box className={classes.itemContent}>
                        <UncontrolledCheckboxField
                            label={props.group.name}
                            checked={isChecked}
                            onChange={onCheckboxChange}
                        />
                        {hasChildren ? (
                            <Box component="span" className={classes.expandIcon} onClick={onExpand}>
                                {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                            </Box>
                        ) : null}
                    </Box>
                </ListItemButton>
            </ListItem>
            {hasChildren ? (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {props.group.children.map((child) => (
                            <GroupFilterItem
                                key={child.id}
                                group={child}
                                depth={depth + 1}
                                expandedGroups={props.expandedGroups}
                                onExpand={props.onExpand}
                                form={props.form}
                                allGroups={props.allGroups}
                                filterWithParent={props.filterWithParent}
                            />
                        ))}
                    </List>
                </Collapse>
            ) : null}
        </React.Fragment>
    );
};
