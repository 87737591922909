import React from "react";
import Highlighter from "react-highlight-words";
import { Tooltip } from "src/ui/shared/Tooltip";

import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CellContext } from "@tanstack/react-table";

import { Application } from "./AppTab";

const useStyles = makeStyles({
    box: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        cursor: "pointer",
        "& i": {
            padding: 8,
            width: 16,
            height: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    arrowBox: { padding: 8 },
    titleBox: { maxWidth: 250 },
    title: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", flex: 1 },
});

type Props = React.PropsWithChildren<CellContext<Application, unknown> & { searchWords: string[] }>;
export const NameCell = (props: Props) => {
    const classes = useStyles();

    return (
        <Box onClick={props.row.getToggleExpandedHandler()} className={classes.box}>
            <div className={classes.arrowBox}>
                {props.row.getIsExpanded() ? (
                    <i className="fa-solid fa-angle-down" />
                ) : (
                    <i className="fa-sm fa-solid fa-chevron-right" />
                )}
            </div>
            <Tooltip title={props.getValue<string>()}>
                <Box className={classes.titleBox}>
                    <Typography noWrap className={classes.title}>
                        <Highlighter
                            searchWords={props.searchWords}
                            textToHighlight={props.getValue<string>()}
                            autoEscape
                        />
                    </Typography>
                </Box>
            </Tooltip>
        </Box>
    );
};
