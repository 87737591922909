import { TypedUseSelectorHook, useDispatch as _useDispatch, useSelector as _useSelector } from "react-redux";
import { getEnv } from "src/services/configService";

import DevicesReducer from "@dashboard/devices/store";
import DownloadsReducer from "@dashboard/downloads/store";
import { FilesReducer } from "@dashboard/files/store";
import ProductsReducer from "@dashboard/products/store";
import ProvisioningsReducer from "@dashboard/provisioning/store";
import { WorkspacesReducer } from "@dashboard/workspaces/store";
import { combineReducers, configureStore, createListenerMiddleware } from "@reduxjs/toolkit";

import AccountReducer from "./account";
import AuthReducer from "./auth";
import BetaReducer from "./beta";
import { initDeviceListMiddleware, initDownloadsFiltersMiddleware, initUrlMiddleware } from "./helpers/middleware";

import type { PreloadedState } from "@reduxjs/toolkit";
const listenerMiddleware = createListenerMiddleware();
initDeviceListMiddleware(listenerMiddleware);
initUrlMiddleware(listenerMiddleware);
initDownloadsFiltersMiddleware(listenerMiddleware);

const rootReducer = combineReducers({
    auth: AuthReducer,
    account: AccountReducer,
    devices: DevicesReducer,
    downloads: DownloadsReducer,
    files: FilesReducer,
    products: ProductsReducer,
    beta: BetaReducer,
    provisionings: ProvisioningsReducer,
    workspaces: WorkspacesReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).prepend(listenerMiddleware.middleware),
    devTools: getEnv() !== "production",
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: [listenerMiddleware.middleware],
        preloadedState,
    });
};

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppStore = ReturnType<typeof setupStore>;

type AppDispatch = typeof store.dispatch;

export const useDispatch = (): AppDispatch => _useDispatch<AppDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;
