import { LimitType } from "src/types/limitType";

import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";

const useStyles = makeStyles((theme: Theme) => ({
    expiredSubscription: {
        backgroundColor: theme.palette.yellow[50],
    },
}));

const currentDate = new Date();

type Props = {
    workspace: API.Workspace;
};
const TableItem = ({ workspace }: Props) => {
    const classes = useStyles();

    const limit = workspace.subscription?.limits.find((item) => item.type === LimitType.LicenseLimit);
    const isExpired = workspace.subscription.expirationDate
        ? new Date(workspace.subscription.expirationDate) <= currentDate
        : false;

    return (
        <TableRow className={isExpired ? classes.expiredSubscription : ""}>
            <MinimalTableCell align="left">{workspace.subscription.id}</MinimalTableCell>
            <MinimalTableCell align="left">{workspace.organizationName}</MinimalTableCell>
            <MinimalTableCell align="left">{workspace.subscription.chargeId}</MinimalTableCell>
            <MinimalTableCell align="left">{limit?.value || "unlimited"}</MinimalTableCell>
            <MinimalTableCell align="left">
                {convertToLocalDate(workspace.subscription.creationDate, "ISO", "dateTime")}
            </MinimalTableCell>
            <MinimalTableCell align="left">
                {workspace.subscription.expirationDate
                    ? convertToLocalDate(workspace.subscription.expirationDate, "ISO", "dateTime")
                    : "-"}
            </MinimalTableCell>
        </TableRow>
    );
};

export default TableItem;
