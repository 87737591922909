import clsx from "clsx";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import UserSettingsService from "src/services/userSettingsService";

import {
    selectSubscription,
    selectSubscriptionExpiringWithinMonth,
} from "@dashboard/devices/store/selectors/subscriptions";
import { daysToDate } from "@dashboard/devices/utils/dates";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar, SnackbarProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";

const threeDays = 3;

const useStyles = makeStyles((theme: Theme) => ({
    orange: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.orange[50],
        },
    },
    black: {
        color: theme.palette.black[100],
    },
    closeIcon: {
        cursor: "pointer",
        marginLeft: 8,
    },
    detailsBtn: {
        marginLeft: 8,
    },
}));

const getMessage = (days: number) => {
    if (days === 0) {
        return "Your subscription expires today. Please renew your plan to maintain access to all features.";
    }
    return `Your subscription is expiring in ${days} days. Please renew your plan to keep access to all features.`;
};

export const SubscriptionExpirationBanner = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const subscription = useSelector(selectSubscription);
    const daysLeft = subscription?.expirationDate ? daysToDate(subscription.expirationDate) : null;
    const inReminderPeriod = useSelector(selectSubscriptionExpiringWithinMonth);
    const lastExpirationDate = UserSettingsService.getWorkspacePageLastExpirationBannerDate();

    const hideBanner = () => {
        if (subscription) {
            const nowInMilliseconds = DateTime.now().toMillis();
            UserSettingsService.setWorkspaceValue("workspacePage.lastExpirationBannerDate", nowInMilliseconds);
            setOpen(false);
        }
    };
    const handleClose: SnackbarProps["onClose"] = (_, reason) => {
        if (reason !== "clickaway") {
            hideBanner();
        }
    };
    const onRenewClick = () => {
        window.open("https://emteria.com/p/subscription", "_blank");
    };
    const onDetailsClick = () => {
        navigate("/account/workspaces");
    };

    useEffect(() => {
        if (!daysLeft || !inReminderPeriod || daysLeft <= 0) {
            setOpen(false);
            return;
        }

        if (!lastExpirationDate) {
            setOpen(true);
            return;
        }

        const lastShownDate = DateTime.fromMillis(+lastExpirationDate);
        const daysSinceLastShow = DateTime.now().diff(lastShownDate, "days").days;
        const shouldShow = daysSinceLastShow >= threeDays && daysLeft % threeDays === 0;

        setOpen(shouldShow);
    }, [subscription?.cancellationDate, daysLeft, inReminderPeriod, lastExpirationDate]);

    if (!daysLeft || !inReminderPeriod || daysLeft <= 0) {
        return null;
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
            className={classes.orange}
            message={<span className={classes.black}>{getMessage(daysLeft)}</span>}
            action={
                <>
                    <SecondaryButton size="small" onClick={onDetailsClick}>
                        See details
                    </SecondaryButton>
                    <PrimaryButton size="small" onClick={onRenewClick} className={classes.detailsBtn}>
                        Contact sales
                    </PrimaryButton>
                    <CloseIcon className={clsx(classes.closeIcon, classes.black)} onClick={hideBanner} />
                </>
            }
        />
    );
};
