import clsx from "clsx";
import React from "react";
import Highlighter from "react-highlight-words";
import { shallowEqual } from "react-redux";
import euporiaService from "src/services/euporiaService";
import UserSettingsService from "src/services/userSettingsService";
import { useDispatch, useSelector } from "src/store";

import { ManagementSection } from "@dashboard/devices/components/ManagementSection/ManagementSection";
import {
    closeBatchMode,
    closeDetails,
    selectBatchActionIsTypeMove,
    selectBatchActionSelectionTransformed,
    selectBatchState,
    selectDetailsState,
    selectSearchQuery,
    showDetails,
    updateSearchQuery,
} from "@dashboard/devices/store";
import { selectLicensesWithFilter } from "@dashboard/licenses/store/selectors";
import { TutorialSection } from "@dashboard/overview/components/TutorialSection";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { SidePanel } from "@dashboard/shared/components/SidePanel/SidePanel";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Paper, TableBody, TableContainer, TablePagination, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ExpiredWrapper } from "@shared/ExpiredWrapper";
import { MinimalTable, MinimalTableCell, MinimalTableHeader } from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import { fuzzyFilter } from "@shared/table/Tanstack.utils";
import {
    Cell,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    OnChangeFn,
    useReactTable,
} from "@tanstack/react-table";

import { GroupFilterHeader } from "../shared/components/GroupFilterHeader/GroupFilterHeader";
import { FilterWrapper } from "./components/Table/Filters";
import {
    DeviceTableData,
    escapeRegExp,
    getVisibleColumns,
    tableColumns,
    useDevicePageNameFilter,
    useTransformLicenseToTableData,
} from "./DevicesPage.utils";

const useStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
        overflowX: "auto",
    },
    tableRow: {
        "&:hover": {
            backgroundColor: theme.palette.blue[50],
        },
    },
    tableRowSelected: {
        backgroundColor: theme.palette.blue[50],
    },
}));

const DevicesPage = () => {
    const [disablePageReset, setDisablePageReset] = React.useState(false);
    const devicePageNameFilter = useDevicePageNameFilter();
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(devicePageNameFilter);
    const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null);
    const [visibleColumns, setVisibleColumns] = React.useState(getVisibleColumns());
    const dispatch = useDispatch();
    const classes = useStyles();

    const selectBatchActionSelected = useSelector(selectBatchActionSelectionTransformed);
    const licenses = useSelector(selectLicensesWithFilter, shallowEqual);
    const workspace = useSelector(selectWorkspace);
    const searchQuery = useSelector(selectSearchQuery);
    const batchState = useSelector(selectBatchState);
    const detailsState = useSelector(selectDetailsState);
    const selectIsBatchActionMove = useSelector(selectBatchActionIsTypeMove);
    const data = useTransformLicenseToTableData(licenses);
    const state = React.useMemo(
        () => ({
            globalFilter: searchQuery,
            columnVisibility: { ...visibleColumns, moveLicenseCheckbox: selectIsBatchActionMove },
            rowSelection: selectBatchActionSelected,
            columnFilters,
        }),
        [searchQuery, visibleColumns, selectIsBatchActionMove, selectBatchActionSelected, columnFilters],
    );

    const setSearchQuery: OnChangeFn<unknown> = (value) => {
        if (typeof value === "string") {
            dispatch(updateSearchQuery(value.toLowerCase()));
        }
    };
    const handleTableItemClick = (payload: { rowId: string; cell: Cell<DeviceTableData, unknown> }) => {
        if (batchState.entity !== "license" && payload.cell.column.columnDef.id === "groupName") {
            setSelectedRowId(payload.rowId);
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: payload.cell.row.original.groupId,
                }),
            );
        }
        if (batchState.entity !== "license" && payload.cell.column.columnDef.id !== "groupName") {
            setSelectedRowId(payload.rowId);
            dispatch(
                showDetails({
                    type: "license",
                    selectedId: payload.cell.row.original.id,
                }),
            );
        }

        euporiaService.createTransaction("side-panel", {
            trigger_column: payload.cell.column.columnDef.id,
            panel_type: payload.cell.column.columnDef.id === "groupName" ? "group" : "license",
        });
    };

    const onGroupFilter = (values: Record<string, boolean>) => {
        const selectedGroups = Object.entries(values)
            .filter(([_, checked]) => checked)
            .map(([groupName]) => groupName);

        if (selectedGroups.length) {
            setColumnFilters([{ id: "groupName", value: selectedGroups }]);
            UserSettingsService.setWorkspaceValue("devicePage.filter.groupName", selectedGroups);
        } else {
            setColumnFilters([]);
            UserSettingsService.setWorkspaceValue("devicePage.filter.groupName", []);
        }
    };

    const searchWords = searchQuery?.trim().length ? [escapeRegExp(searchQuery.toLowerCase())] : [];
    const table = useReactTable({
        data: data,
        columns: tableColumns(searchQuery),
        filterFns: { fuzzy: fuzzyFilter },
        state,
        initialState: {
            pagination: {
                pageSize: UserSettingsService.getDevicePageRowsPerPage(),
                pageIndex: 0,
            },
        },
        defaultColumn: { size: 0 },
        autoResetPageIndex: !disablePageReset,
        onColumnVisibilityChange: setVisibleColumns,
        onGlobalFilterChange: setSearchQuery,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    const tableRows = table.getPaginationRowModel().rows;
    const totalRows = table.getFilteredRowModel().rows.length;
    const currentPageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    // disables autoResetPageIndex to rerender the table when opening devices details pane
    React.useEffect(() => {
        if (!detailsState.isOpen) {
            setSelectedRowId("");
        }
        setDisablePageReset(true);
    }, [detailsState.isOpen]);

    React.useEffect(() => {
        return () => {
            dispatch(closeDetails());
            dispatch(closeBatchMode());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer>
            <PageTitleWithIcon title="Devices" iconName="fa-solid fa-laptop" />
            <SectionDescription>
                Effortlessly monitor and manage your devices with real-time insights and status updates.
            </SectionDescription>
            {!workspace ? (
                <TutorialSection />
            ) : (
                <ExpiredWrapper>
                    <React.Fragment>
                        <ManagementSection
                            setSearchQuery={setSearchQuery}
                            onClearSearchQuery={() => dispatch(updateSearchQuery(""))}
                            setVisibleColumns={setVisibleColumns}
                            searchQuery={searchQuery}
                            table={table}
                        />
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <MinimalTable>
                                <MinimalTableHeader>
                                    {table
                                        .getHeaderGroups()
                                        .slice(1)
                                        .map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => {
                                                    if (header.id === "groupName") {
                                                        return (
                                                            <GroupFilterHeader
                                                                key={header.id}
                                                                title="Filter Groups"
                                                                header={header}
                                                                table={table}
                                                                onSubmit={onGroupFilter}
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <Header key={header.id} {...header}>
                                                            <FilterWrapper column={header.column} />
                                                        </Header>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                </MinimalTableHeader>
                                <TableBody>
                                    {tableRows.length === 0 ||
                                    tableRows.every((row) => row.getVisibleCells().length === 0) ? (
                                        <TableRow>
                                            <MinimalTableCell colSpan={table.getAllLeafColumns().length} align="center">
                                                No devices to show
                                            </MinimalTableCell>
                                        </TableRow>
                                    ) : (
                                        tableRows.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                className={clsx(
                                                    classes.tableRow,
                                                    selectedRowId === row.id ? classes.tableRowSelected : "",
                                                )}
                                            >
                                                {row.getVisibleCells().map((cell) => {
                                                    const cellValue = cell.getValue();
                                                    const renderedValue = flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    );
                                                    const shouldHighlight =
                                                        typeof cellValue === "string" &&
                                                        cell.column.id !== "status" &&
                                                        //this part makes sure custom cells are displayed
                                                        !cell.column.columnDef.cell;
                                                    const cellContent = shouldHighlight ? (
                                                        <Highlighter
                                                            searchWords={searchWords}
                                                            textToHighlight={cellValue}
                                                            autoEscape
                                                        />
                                                    ) : (
                                                        renderedValue
                                                    );

                                                    return (
                                                        <MinimalTableCell
                                                            align="left"
                                                            key={cell.id}
                                                            onClick={() =>
                                                                handleTableItemClick({ rowId: row.id, cell: cell })
                                                            }
                                                        >
                                                            {cellContent}
                                                        </MinimalTableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </MinimalTable>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[10, 25, 50]}
                            count={totalRows}
                            rowsPerPage={pageSize}
                            page={currentPageIndex}
                            onPageChange={(_e, newPage) => {
                                table.setPageIndex(newPage);
                            }}
                            onRowsPerPageChange={(e) => {
                                const newSize = parseInt(e.target.value, 10);
                                table.setPageSize(newSize);
                                UserSettingsService.setWorkspaceValue("devicePage.rowsPerPage", newSize);
                            }}
                        />
                    </React.Fragment>
                </ExpiredWrapper>
            )}
            <SidePanel />
        </PageContainer>
    );
};

export default DevicesPage;
