import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import ActionsMenu from "@dashboard/files/components/ActionsMenu/ActionsMenu";
import { FileSettingsDialog } from "@dashboard/shared/components/FileSettingsDialog";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { useDeleteFile } from "@files/hooks/entities";
import { FileInfo } from "@files/types";
import { hasApkMetadata, hasUnsuccessfulAnalyzer, isApk } from "@files/utils";
import { selectGroupById } from "@groups/store/selectors";
import { Box, DialogContentText } from "@mui/material";
import { permissionService } from "@services/permissionService/permissionService";
import { SecondaryButton } from "@shared/CustomButton";
import { selectWorkspace } from "@workspaces/store/selectors";

import { useStyles } from "../FileTab.style";
import { PickInstallationTypeDialog } from "./PickInstallationTypeDialog";

type Props = {
    file: FileInfo;
    groupId: number;
};
export const ActionsCell = (props: Props) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = React.useState(false);
    const [isInstallationTypeModalOpen, setIsInstallationTypeModalOpen] = React.useState(false);

    const { removeFile } = useDeleteFile();
    const classes = useStyles();
    const group = useSelector((state: RootState) => selectGroupById(state, props.groupId));
    const isApkFile = isApk(props.file.filename);
    const unsuccessfulAnalyzerExist = hasUnsuccessfulAnalyzer(props.file);
    const apkMetadataExist = hasApkMetadata(props.file);

    const isExpired = useSelector(selectSubscriptionExpired);

    const workspace = useSelector(selectWorkspace);
    const { groupAbility, fileAbility } = permissionService();
    const mayManageTags = fileAbility(props.file).can("manage", "Tag");

    const handleDelete = async () => {
        await removeFile(props.file.id);
    };
    const getInstallButtonProps = () => {
        if (groupAbility(group || null, workspace).cannot("install", "App")) {
            return {
                tooltip: "You are not allowed to install applications in this group",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: true,
            };
        }
        if (isExpired) {
            return {
                tooltip: "Subscription expired",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: true,
            };
        }
        if (unsuccessfulAnalyzerExist) {
            return {
                tooltip: "Processing, please wait...",
                iconClass: "fas fa-spinner fa-spin",
                disabled: true,
            };
        }
        if (!apkMetadataExist) {
            return {
                tooltip: "Invalid package",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: true,
            };
        } else {
            return {
                tooltip: "Install to all devices in this group",
                iconClass: "fa-solid fa-arrow-right-to-bracket",
                disabled: false,
            };
        }
    };
    const getTagBtnTitle = () => {
        const result = { title: "Manage tags" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageTags) {
            result.title = "You are not allowed to manage tags in this group";
        }
        return result;
    };

    return (
        <React.Fragment>
            <Box className={classes.buttonBox}>
                {isApkFile && (
                    <SecondaryButton
                        onClick={() => setIsInstallationTypeModalOpen(true)}
                        tooltipProps={{ title: getInstallButtonProps().tooltip }}
                        disabled={getInstallButtonProps().disabled}
                    >
                        <i className={getInstallButtonProps().iconClass} />
                    </SecondaryButton>
                )}
                <SecondaryButton
                    onClick={() => setIsSettingsModalOpen(true)}
                    tooltipProps={getTagBtnTitle()}
                    disabled={!mayManageTags}
                >
                    <i className="fa-solid fa-gear" />
                </SecondaryButton>
                <ActionsMenu file={props.file} />
            </Box>

            <ConfirmDialog
                title="Delete file"
                dangerButton
                primaryActionText="Delete"
                open={isDeleteModalOpen}
                onConfirm={handleDelete}
                onClose={() => setIsDeleteModalOpen(false)}
            >
                <DialogContentText>
                    This will delete <b>{props.file.filename}</b>. Are you sure?
                </DialogContentText>
            </ConfirmDialog>

            <PickInstallationTypeDialog
                file={props.file}
                group={group}
                open={isInstallationTypeModalOpen}
                onClose={() => setIsInstallationTypeModalOpen(false)}
            />

            <FileSettingsDialog
                item={props.file}
                open={isSettingsModalOpen}
                onClose={() => setIsSettingsModalOpen(false)}
            />
        </React.Fragment>
    );
};
