import { AxiosResponse } from "axios";
import * as queryString from "query-string";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse, BasicApiResponse, ListResponse } from "src/types";

import { MdmCommand } from "@dashboard/devices/types/commands";

import { unpackError } from "./helpers";

type UpdateDevicePayload = {
    id: number;
    name: string | null;
    description: string | null;
    comment: string | null;
};
export const updateDevice = async ({ id, ...payload }: UpdateDevicePayload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
        if (value !== null) {
            formData.append(key, value);
        }
    });

    try {
        const endpoint = await RouteService.getDevicesRoute();
        await httpService.patch<FormData, ApiResponse<API.License>>(endpoint + String(id), formData);
    } catch (error) {
        throw unpackError(error);
    }
};

interface FetchDeviceMdmCommandsPayload {
    deviceId: number;
    pageNumber?: number;
    pageSize?: number;
}
export const fetchDeviceMdmCommands = async (payload: FetchDeviceMdmCommandsPayload) => {
    try {
        const endpoint = await RouteService.getDevicesRoute();
        const { data } = await httpService.get<FetchDeviceMdmCommandsPayload, AxiosResponse<ListResponse<MdmCommand>>>(
            endpoint + `${payload.deviceId}/commands`,
            {
                params: {
                    id: payload.deviceId,
                    pageNumber: payload.pageNumber || 1,
                    pageSize: payload.pageSize || 100,
                },
            },
        );
        return data.list;
    } catch (error) {
        throw unpackError(error);
    }
};
interface CreateDeviceMdmCommandPayload {
    deviceId: number;
    json: Record<string, unknown>;
    commandType?: string;
    commandParameter?: string;
}
export const createDeviceMdmCommand = async (payload: CreateDeviceMdmCommandPayload) => {
    try {
        const endpoint = await RouteService.getDevicesRoute();
        const { data } = await httpService.post<string, AxiosResponse<ListResponse<MdmCommand>>>(
            endpoint + `${payload.deviceId}/commands`,
            queryString.stringify({ id: payload.deviceId, payload: JSON.stringify(payload.json) }),
            {
                headers: { "content-type": "application/x-www-form-urlencoded" },
            },
        );
        return data.list;
    } catch (error) {
        throw unpackError(error);
    }
};

interface DeleteDeviceMdmCommandPayload {
    deviceId: number;
    commandId: number;
}
export const deleteDeviceMdmCommand = async (payload: DeleteDeviceMdmCommandPayload) => {
    try {
        const endpoint = await RouteService.getDevicesRoute();
        const response = await httpService.delete<string, AxiosResponse<BasicApiResponse>>(
            endpoint + `${payload.deviceId}/commands/${payload.commandId}`,
        );
        return response;
    } catch (error) {
        throw unpackError(error);
    }
};
