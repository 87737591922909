import { RefObject } from "react";
import { SecondaryButton } from "src/ui/shared/CustomButton";

import { ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
    onClick: () => void;
    anchorRef: RefObject<HTMLButtonElement>;
}

export function DropdownTrigger({ children, onClick, anchorRef, className = "", ...props }: Props): JSX.Element {
    const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick();
    };

    return (
        <SecondaryButton
            ref={anchorRef}
            aria-haspopup="true"
            onClick={(e) => handleToggle(e)}
            className={`${className}`}
            {...props}
        >
            {children}
        </SecondaryButton>
    );
}
