import { UseFieldConfig } from "react-final-form";

import { FormControlProps, TextFieldProps } from "@mui/material";

import { TextField } from "./TextField";

type Props = TextFieldProps & {
    name: string;
    config?: UseFieldConfig<string, string>;
    formControlProps?: FormControlProps;
};

export const TextareaField = (props: Props) => {
    return <TextField {...props} multiline />;
};
