import { toast, ToastOptions } from "react-toastify";
import { getEnv } from "src/services/configService";

type ToastHandler = (body: unknown, options?: ToastOptions) => void;

const showToast: ToastHandler = (body, options) => {
    const message = typeof body === "string" ? body : "An unknown error has occurred";

    toast(message, {
        autoClose: 6000,
        pauseOnHover: true,
        pauseOnFocusLoss: true,
        draggable: false,
        ...options,
    });
};

export const showSuccessToast: ToastHandler = (body, options) => {
    if (typeof body !== "string" && getEnv() === "development") {
        console.error("The error appeared in showSuccessToast", body);
    }

    showToast(body, {
        type: "success",
        ...options,
    });
};
export const showWarningToast: ToastHandler = (body, options) => {
    if (typeof body !== "string" && getEnv() === "development") {
        console.error("The error appeared in showWarningToast", body);
    }

    showToast(body, {
        type: "warning",
        ...options,
    });
};
export const showErrorToast: ToastHandler = (body, options) => {
    if (typeof body !== "string" && getEnv() === "development") {
        console.error("The error appeared in showErrorToast", body);
    }

    showToast(body, {
        type: "error",
        autoClose: 20000,
        ...options,
    });
};
