import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { TextField } from "src/ui/shared/form/TextField";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Alert, Typography } from "@mui/material";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

export const ProductBranding = () => {
    const { value, onChange } = useCollapsiblePersistence("provisioningPage.collapsedSections", "ProductBranding");
    const sharedClasses = useStyles();
    const provisionings = useSelector(selectProvisionings, shallowEqual);

    return (
        <CollapsibleProvider initialValue={value} onChange={onChange}>
            <CollapsibleSectionSummary>
                <Typography className={sharedClasses.categoryTitle}>Product branding</Typography>
            </CollapsibleSectionSummary>
            <CollapsibleSectionDetails>
                <SectionDescription>
                    Product-specific settings for wallpaper or animation shown during the Android boot process.
                </SectionDescription>
                <Row style={{ paddingBottom: 8 }}>
                    <TextField
                        name="settings.bootAnimationUrl"
                        label="Boot animation URL"
                        fullWidth
                        disabled={!provisionings.length}
                    />
                </Row>
                <Row style={{ paddingBottom: 8 }}>
                    <TextField
                        name="settings.wallpaperUrl"
                        label="Default wallpaper URL"
                        fullWidth
                        disabled={!provisionings.length}
                    />
                </Row>
                <Alert style={{ marginTop: 0, marginBottom: 20 }} severity="info">
                    Need help building custom branding package? Please don&apos;t hesitate to contact our sales team for
                    assistance.
                </Alert>
            </CollapsibleSectionDetails>
        </CollapsibleProvider>
    );
};
