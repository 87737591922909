import React from "react";
import UserSettingsService from "src/services/userSettingsService";

import { Product } from "@dashboard/products/types/index";

type ProductId = number | null;
type UseCurrentProduct = (products: Product[]) => [ProductId, (value: ProductId) => void];

export const useCurrentProduct: UseCurrentProduct = (products) => {
    const lastSelectedProduct = UserSettingsService.getProductPageLastSelectedProduct();
    const validProductId = products.some((product) => product.id === lastSelectedProduct)
        ? lastSelectedProduct
        : undefined;
    const [id, setId] = React.useState<ProductId>(validProductId || products[0]?.id);

    const setCurrentProductId = (value: number | null) => {
        setId(value);
        UserSettingsService.setWorkspaceValue("productPage.lastSelectedProduct", value);
    };

    React.useEffect(() => {
        if (id !== validProductId && id) {
            UserSettingsService.setWorkspaceValue("productPage.lastSelectedProduct", id);
        }
    }, [id, validProductId]);

    return [id, setCurrentProductId];
};
