import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { TextField } from "src/ui/shared/form/TextField";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Typography } from "@mui/material";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

export const ProductAdministration = () => {
    const { value, onChange } = useCollapsiblePersistence(
        "provisioningPage.collapsedSections",
        "ProductAdministration",
    );
    const sharedClasses = useStyles();
    const provisionings = useSelector(selectProvisionings, shallowEqual);

    return (
        <CollapsibleProvider initialValue={value} onChange={onChange}>
            <CollapsibleSectionSummary>
                <Typography className={sharedClasses.categoryTitle}>Product administration</Typography>
            </CollapsibleSectionSummary>
            <CollapsibleSectionDetails>
                <SectionDescription style={{ marginBottom: 5 }}>
                    Custom device administration and ownership settings.
                </SectionDescription>
                <Row>
                    <TextField
                        name="settings.deviceManager"
                        label="Device manager component (e.g. com.example.pkg/.DeviceAdmin)"
                        disabled={!provisionings.length}
                        fullWidth
                    />
                </Row>
            </CollapsibleSectionDetails>
        </CollapsibleProvider>
    );
};
