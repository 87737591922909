import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "src/store";

import { selectPaneEditEnabled, setPaneEditEnabled } from "@devices/store";
import { Box } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";

type Props = {
    loading: boolean;
    pristine?: boolean;
};

const useStyles = createUseStyles({
    stickyFooter: {
        position: "sticky",
        right: "1%",
        bottom: "5%",
        float: "right",
        display: "flex",
    },
});

export const EditPrimaryButtons = ({ loading, pristine }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editEnabled = useSelector(selectPaneEditEnabled);

    if (!editEnabled) {
        return null;
    }

    return (
        <Box className={classes.stickyFooter}>
            <SecondaryButton onClick={() => dispatch(setPaneEditEnabled(false))} loading={loading} disabled={loading}>
                Cancel
            </SecondaryButton>
            <PrimaryButton type="submit" loading={loading} disabled={loading || pristine} style={{ marginLeft: 5 }}>
                Save
            </PrimaryButton>
        </Box>
    );
};
