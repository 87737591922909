import { useState } from "react";
import { createUseStyles } from "react-jss";
import { permissionService } from "src/services/permissionService/permissionService";
import { useSelector } from "src/store";

import { invokeFileDownload } from "@dashboard/files/api/index";
import { useDeleteFile } from "@dashboard/files/hooks/entities";
import { FileInfo, FileStatus } from "@dashboard/files/types/index";
import { FileDetailsModal } from "@dashboard/shared/components/MoreInformationFileModal/FileDetailsModal";
import { ConfirmDialog } from "@devices/components/shared";
import { DialogContentText } from "@mui/material";
import MenuContainer from "@sidePanel/shared/MenuLists/MenuContainer";
import { DeleteItem, EditItem, MenuItemTooltip } from "@sidePanel/shared/MenuLists/MenuItems";

import { selectSubscriptionExpired } from "../../../devices/store/selectors/subscriptions";
import { selectGroups } from "../../../groups/store/selectors";
import { selectWorkspace } from "../../../workspaces/store/selectors";

const useStyles = createUseStyles({
    actionContainer: {
        display: "flex",
        zIndex: 100,
        flexDirection: "column",
    },
});

interface Props {
    file: FileInfo;
}

const ActionsMenu = (props: Props): JSX.Element => {
    const classes = useStyles();
    const { removeFile } = useDeleteFile();
    const [deleteFileDialogOpen, setDeleteFileDialogOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const isExpired = useSelector(selectSubscriptionExpired);
    const { groupAbility } = permissionService();
    const currentWorkspace = useSelector(selectWorkspace);
    const currentWorkspaceGroups = useSelector(selectGroups);
    const currentGroup = currentWorkspaceGroups.find((item) => item.id === props.file.groupId);
    const mayManageFile = groupAbility(currentGroup || null, currentWorkspace).can("manage", "File");

    const isUploading = props.file.status === FileStatus.Pending;
    const isDownloadDisabled = isUploading || isExpired;

    const deleteFile = async () => {
        await removeFile(props.file.id);
    };
    const downloadFile = async () => {
        await invokeFileDownload(props.file.id, props.file.filename);
    };
    const DeleteFileItem = () => {
        return (
            <MenuItemTooltip
                title={!mayManageFile ? "You role does not allow to delete files" : null}
                arrow
                placement="bottom"
            >
                <DeleteItem
                    label="Delete file"
                    onClick={() => setDeleteFileDialogOpen(true)}
                    disabled={!mayManageFile}
                />
            </MenuItemTooltip>
        );
    };

    const DownloadFileItem = () => {
        return (
            <MenuItemTooltip title={isExpired ? "Subscription expired" : null} arrow placement="bottom">
                <EditItem
                    label="Download file"
                    iconName="fa-solid fa-download"
                    onClick={downloadFile}
                    disabled={isDownloadDisabled}
                />
            </MenuItemTooltip>
        );
    };
    const ShowDetailsItem = () => {
        return (
            <EditItem
                label="Show details"
                iconName="fa-regular fa-file-lines"
                onClick={() => setDetailsModalOpen(true)}
            />
        );
    };

    return (
        <>
            <MenuContainer displayLeft>
                <div className={classes.actionContainer}>
                    <ShowDetailsItem key="0" />
                    <DownloadFileItem key="1" />
                    <DeleteFileItem key="2" />
                </div>
            </MenuContainer>
            <ConfirmDialog
                title="Delete file"
                dangerButton
                primaryActionText="Delete"
                open={deleteFileDialogOpen}
                onConfirm={deleteFile}
                onClose={() => setDeleteFileDialogOpen(false)}
            >
                <DialogContentText>
                    Are you sure you want to delete <span style={{ fontWeight: "bold" }}>{props.file.filename}</span>?
                </DialogContentText>
            </ConfirmDialog>
            <FileDetailsModal
                isOpen={detailsModalOpen}
                closeDialog={() => setDetailsModalOpen(false)}
                file={props.file}
            />
        </>
    );
};

export default ActionsMenu;
