import React, { useCallback } from "react";
import { UncontrolledTextField } from "src/ui/shared/form/TextField";
import { Modal } from "src/ui/shared/Modal/Modal";
import { showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { FileInfo } from "@dashboard/files/types";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondaryButton } from "@shared/CustomButton";

const useStyles = makeStyles(() => ({
    textContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 6,
    },
    category: {
        marginTop: 8,
    },
    copyButton: {
        marginLeft: 8,
    },
}));

interface Props {
    file: FileInfo | null;
    isOpen: boolean;
    closeDialog: () => void;
}

export const FileDetailsModal = ({ file, isOpen, closeDialog }: Props) => {
    const classes = useStyles();

    const copyToClipboard = useCallback((text: string | undefined) => {
        if (!text) {
            return;
        }
        navigator.clipboard
            .writeText(text)
            .then(() => {
                showSuccessToast("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text to clipboard", err);
            });
    }, []);

    return (
        <Modal title="File information" open={isOpen} onClose={closeDialog}>
            <Typography variant="h6" className={classes.category}>
                General
            </Typography>

            <UncontrolledTextField label="Name" value={file?.filename} disabled fullWidth />
            <UncontrolledTextField label="Uploader" value={file?.uploader} disabled fullWidth />

            {file?.createdDate && (
                <UncontrolledTextField
                    label="Updated"
                    value={convertToLocalDate(file.createdDate, "ISO", "dateTime")}
                    disabled
                    fullWidth
                />
            )}
            {file?.metadata?.file?.apk?.manifest?.versioncode && (
                <div className={classes.textContainer}>
                    <UncontrolledTextField
                        label="Version code"
                        value={file.metadata.file.apk.manifest.versioncode}
                        disabled
                        fullWidth
                    />
                    <SecondaryButton
                        className={classes.copyButton}
                        onClick={() => copyToClipboard(file?.metadata?.file?.apk?.manifest?.versioncode)}
                        tooltipProps={{ title: "Copy" }}
                    >
                        <i className="fa-solid fa-copy" />
                    </SecondaryButton>
                </div>
            )}
            {file?.metadata?.file?.apk?.manifest?.versionname && (
                <div className={classes.textContainer}>
                    <UncontrolledTextField
                        label="Version name"
                        value={file.metadata.file.apk.manifest.versionname}
                        disabled
                        fullWidth
                    />
                    <SecondaryButton
                        className={classes.copyButton}
                        onClick={() => copyToClipboard(file?.metadata?.file?.apk?.manifest?.versionname)}
                        tooltipProps={{ title: "Copy" }}
                    >
                        <i className="fa-solid fa-copy" />
                    </SecondaryButton>
                </div>
            )}
            {file?.metadata?.file?.apk?.signers?.map((signer, index) => (
                <React.Fragment key={index}>
                    <Typography variant="h6" className={classes.category}>
                        Signer #{index + 1}
                    </Typography>
                    <div className={classes.textContainer}>
                        <UncontrolledTextField
                            label="SHA256 digest"
                            value={signer.digests.find((d) => d.type === "sha-256")?.certificate}
                            disabled
                            fullWidth
                        />
                        <SecondaryButton
                            className={classes.copyButton}
                            tooltipProps={{ title: "Copy" }}
                            onClick={() =>
                                copyToClipboard(signer.digests.find((d) => d.type === "sha-256")?.certificate)
                            }
                        >
                            <i className="fa-solid fa-copy" />
                        </SecondaryButton>
                    </div>
                    <div className={classes.textContainer}>
                        <UncontrolledTextField
                            label="DN"
                            value={Object.entries(signer.distinguishedName)
                                .filter(([_key, value]) => value !== "unknown")
                                .map(([key, value]) => `${key}=${value}`)
                                .join(", ")}
                            disabled
                            fullWidth
                        />
                        <SecondaryButton
                            className={classes.copyButton}
                            onClick={() =>
                                copyToClipboard(
                                    Object.entries(signer.distinguishedName)
                                        .filter(([_key, value]) => value !== "unknown")
                                        .map(([key, value]) => `${key}=${value}`)
                                        .join(", "),
                                )
                            }
                            tooltipProps={{ title: "Copy" }}
                        >
                            <i className="fa-solid fa-copy" />
                        </SecondaryButton>
                    </div>
                </React.Fragment>
            ))}
        </Modal>
    );
};
