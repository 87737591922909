import React from "react";
import { createRoot } from "react-dom/client";
import { unregister } from "./registerServiceWorker";

// Importing symbol-observable fixes a redux warning.
// More details: https://github.com/reduxjs/redux-toolkit/issues/2008
import "symbol-observable";

import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";

const root = createRoot(document.getElementById("root"));

root.render(<App />);
unregister();
