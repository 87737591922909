import React from "react";
import { Form } from "react-final-form";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { FileSelectField } from "src/ui/shared/form/FileSelectField";
import { Modal } from "src/ui/shared/Modal/Modal";

import { useCommands } from "@dashboard/devices/components/Commands/hooks";
import { MdmPolicyCommand } from "@devices/types";
import { Box } from "@mui/material";
import { TextField } from "@shared/form/TextField";
import { showErrorToast } from "@shared/toasts/Toasts";

interface Props {
    open: boolean;
    groupId: number;
    policyCommands: MdmPolicyCommand[];
    onClose: () => void;
}

type FormValues = {
    fileInput: string;
    files: FileList;
};

export const ImportGroupPolicyDialog = ({ open = false, groupId, policyCommands, onClose }: Props) => {
    const ref = React.useRef<HTMLInputElement>(null);
    const { updatePolicyCommands } = useCommands();

    const handleFileUpload = async (file: File): Promise<string | null> => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onload = (event) => {
                try {
                    resolve(event.target?.result?.toString() ?? null);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsText(file);
        });
    };

    const onSubmit = async (values: FormValues) => {
        const file = values.files[0];

        let fileContents = null;

        try {
            fileContents = await handleFileUpload(file);
        } catch (error: unknown) {
            if (error instanceof Error) {
                showErrorToast(error.message);
            }
        }

        if (fileContents) {
            let parsedCommands: MdmPolicyCommand[] = [];

            try {
                parsedCommands = JSON.parse(fileContents) as MdmPolicyCommand[];
            } catch (error) {
                showErrorToast("Failed to parse a file");
                return;
            }

            if (!Array.isArray(parsedCommands)) {
                showErrorToast("File contents are invalid");
                return;
            }

            const combinedCommands = [...policyCommands, ...parsedCommands];
            await updatePolicyCommands({ groupId, policy: JSON.stringify(combinedCommands) });
            onClose();
        } else {
            showErrorToast("Please try again");
        }
    };
    const onValidate = (values: FormValues) => {
        if (!values.files?.length) {
            return { fileInput: "Please upload the file first" };
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={onValidate}
            render={({ handleSubmit, form, submitting }) => (
                <form onSubmit={handleSubmit} id="editCommand">
                    <Modal
                        title="Import group policy"
                        open={open}
                        onClose={onClose}
                        description="This dialog may be used to import multiple commands at once. It can be useful when
                                synchronizing policies between different groups to for restoring a policy backup.
                                Warning: Importing policy commands from external file may introduce command
                                duplicates. Please validate your policy after the import process finishes."
                        endButton={
                            <PrimaryButton onClick={form.submit} disabled={submitting} loading={submitting}>
                                Import
                            </PrimaryButton>
                        }
                    >
                        <Box style={{ display: "flex", gap: 6 }}>
                            <TextField
                                name="fileInput"
                                label="File"
                                value={form.getFieldState("files")?.value?.[0].name ?? "Select file"}
                                fullWidth
                                disabled
                            />

                            <SecondaryButton onClick={() => ref.current?.click()}>
                                <FileSelectField name="files" ref={ref} />
                                <i className="fas fa-upload" />
                            </SecondaryButton>
                        </Box>
                    </Modal>
                </form>
            )}
        />
    );
};
