import _ from "lodash";

import { Row } from "@tanstack/react-table";

import { Build } from "../products/types";

export const createRolloutTag = (rollout: number | undefined): API.Tag | null => {
    return rollout !== undefined && rollout !== 100
        ? {
              key: "rollout",
              value: String(rollout) + "%",
              type: "scoped",
          }
        : null;
};
export const createBuildVariantTag = (build: Build): API.Tag | null => {
    let variant = null;

    if (build.configOverride && "buildVariant" in build.configOverride) {
        variant = build.configOverride.buildVariant as string;
    } else if (build.configSnapshot && build.configSnapshot?.buildVariant) {
        variant = build.configSnapshot.buildVariant;
    }

    if (variant) {
        return {
            key: "variant",
            value: variant,
            type: "scoped",
        };
    }
    return null;
};
export const filterByTags = <T extends API.File>(row: Row<T>, _columnId: string, filterValue: unknown) => {
    if (!filterValue || (Array.isArray(filterValue) && !filterValue.length)) {
        return true;
    }

    const removableTags = row.original.metadata?.user?.tags || [];
    const rolloutTag = createRolloutTag(row.original.rolloutPercentage);
    const tags = [rolloutTag, ...removableTags].filter(Boolean);
    const tagsKeys = tags.map((tag) => tag?.key);

    return _.some(filterValue, (i) => _.includes(tagsKeys, i));
};
