import _ from "lodash";
import { RootState } from "src/store";

import { DeviceFilterType } from "@dashboard/devices/components/Filtering/Filters.utils";
import { localState } from "@dashboard/devices/store";
import { createSelector } from "@reduxjs/toolkit";

export type FilterState = {
    isOpen: boolean;
    isActive: boolean;
    properties: Record<DeviceFilterType, string[]>;
};

const defaultFilter: FilterState = {
    isOpen: false,
    isActive: true,
    properties: {
        "license_activationStatus": [],
        "device_status": [],
        "policy_status": [],
        "group_name": [],
        "codename": [],
        "system_version": [],
    },
};

const devicesPageFilterState = (state: RootState): FilterState => localState(state).filters;
const selectFilterProperties = (state: RootState): Record<DeviceFilterType, string[]> =>
    localState(state).filters?.properties;
const selectMergedFiltersByProperty = <T extends DeviceFilterType>() =>
    createSelector(
        [selectFilterProperties, (_state: RootState, property: T, options: string[]) => ({ property, options })],
        (properties, { property, options }) => {
            const currentFilterProperties = properties[property] ?? [];
            return _.fromPairs(options.map((key) => [key, currentFilterProperties.includes(key)]));
        },
    );

export { defaultFilter, devicesPageFilterState, selectFilterProperties, selectMergedFiltersByProperty };
