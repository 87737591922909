import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-interceptor";
import * as queryString from "query-string";

import authService, { AuthGrantType } from "./authService";

type CustomAxiosRequestConfig = InternalAxiosRequestConfig & {
    _retry?: boolean;
};

const authPrefix = "Bearer";
const authTpl = (authToken: string) => `${authPrefix} ${authToken}`;

const cachedAxios = setupCache(axios.create(), {
    ttl: 1000 * 60 * 5,
});

cachedAxios.interceptors.request.use(
    (config: CustomAxiosRequestConfig) => {
        const token = authService.getSession()?.access_token;
        if (token) {
            config.headers = config.headers ?? {};
            config.headers.Authorization = config.headers.Authorization ?? authTpl(token);
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    },
);

type AuthError = {
    error: string;
    error_description: string;
    error_uri: string;
};

let isRefreshing = false;
const axiosInstance = axios.create();

const resetAuth = async () => {
    await authService.logout();
    window.location.pathname = "/login";
};

const refreshAccessToken = async (config: CustomAxiosRequestConfig) => {
    isRefreshing = true;
    const token = await authService.refreshSession();
    isRefreshing = false;

    if (token && token.access_token) {
        config._retry = true;
        config.headers = config.headers ?? {};
        config.headers.Authorization = authTpl(token.access_token);
    }

    return axiosInstance(config);
};

axiosInstance.interceptors.request.use(
    (config: CustomAxiosRequestConfig) => {
        const token = authService.getSession();
        if (token && token.access_token) {
            config.headers = config.headers ?? {};
            config.headers.Authorization = config.headers.Authorization ?? authTpl(token.access_token);
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (result) => {
        return result;
    },
    async (error: AxiosError<AuthError>) => {
        const originalRequestConfig = error.config as CustomAxiosRequestConfig;
        const originalRequestConfigData = queryString.parse(originalRequestConfig.data as string);
        const isUnauthorized = error?.response?.status === 401;
        const isRefreshTokenInvalid =
            error?.response?.status === 400 && error?.response?.data?.error === "invalid_grant";
        const refreshTokenApiError =
            error?.response?.status === 500 && originalRequestConfigData?.grant_type === AuthGrantType.refresh_token;

        if (isUnauthorized) {
            // if the process of token refresh is already running, postpone this call
            if (isRefreshing) {
                return new Promise((resolve) => {
                    setTimeout(resolve, 1200);
                }).then(() => {
                    return axiosInstance(originalRequestConfig);
                });
                // if the process of token refresh is not running yet, start it now
            } else if (!originalRequestConfig._retry) {
                originalRequestConfig._retry = true;
                return refreshAccessToken(originalRequestConfig);
            }
        }

        if (isRefreshTokenInvalid || refreshTokenApiError) {
            await resetAuth();
        }

        return Promise.reject(error);
    },
);

const httpService = {
    get: axiosInstance.get.bind(null),
    getWithCache: cachedAxios.get.bind(null),
    post: axiosInstance.post.bind(null),
    patch: axiosInstance.patch.bind(null),
    put: axiosInstance.put.bind(null),
    delete: axiosInstance.delete.bind(null),
    axiosInstance,
};

export default httpService;
