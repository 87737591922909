import { LocalStorage } from "src/services/localStorageService";
import UserSettingsService from "src/services/userSettingsService";
import { RootState } from "src/store";

import { addSubscriptions, ListStatus } from "@dashboard/devices/store";
import { normalizeSubscriptions, subscriptionsAdapter } from "@dashboard/devices/store/helpers";
import { getDefaultSubscription } from "@dashboard/devices/utils/subscription";
import {
    fetchWorkspaces as apiFetchWorkspaces,
    FetchWorkspacesParams,
    MemberUpdatePayload,
    updateWorkspace as apiUpdateWorkspace,
    updateWorkspaceMembers as apiUpdateWorkspaceMembers,
} from "@dashboard/workspaces/api/index";
import { createAsyncThunk, createSlice, EntityState, SliceCaseReducers } from "@reduxjs/toolkit";

import { Workspace } from "../types";
import { normalizeWorkspaces } from "../utils";
import workspacesAdapter, { selectIsPartOfWorkspace } from "./selectors";

interface WorkspaceSlice {
    status: ListStatus | null;
    currentWorkspaceId: number;
    items: EntityState<Workspace>;
}

export const fetchWorkspaces = createAsyncThunk<API.Workspace[], FetchWorkspacesParams | undefined>(
    "workspaces/fetch",
    async (arg, { dispatch }) => {
        const includeTerminated = arg?.includeTerminated || UserSettingsService.getIncludeTerminatedWorkspaces();
        let data = await apiFetchWorkspaces({ includeTerminated });

        if (!data.length) {
            data = await apiFetchWorkspaces({ includeTerminated: true });
        }

        const subscriptions = data.map((item) => item.subscription);
        const normalizedSubscriptions = normalizeSubscriptions(subscriptions);
        dispatch(addSubscriptions(normalizedSubscriptions));
        return data;
    },
);

type UpdateWorkspaceMembersResponse =
    | { isWorkspaceMember: true; workspace: API.Workspace }
    | { isWorkspaceMember: false; defaultWorkspace: Workspace | undefined };
export const updateWorkspaceMembers = createAsyncThunk<UpdateWorkspaceMembersResponse, MemberUpdatePayload>(
    "workspaces/update/members",
    async (arg, { getState }) => {
        const state = getState() as RootState;
        const response = await apiUpdateWorkspaceMembers(arg);

        if (selectIsPartOfWorkspace(state, response)) {
            return { isWorkspaceMember: true, workspace: response };
        } else {
            const workspace = workspacesAdapter.getSelectors().selectById(state.workspaces.items, arg.workspaceId);
            const subscriptions = subscriptionsAdapter.getSelectors().selectAll(state.devices.list.subscriptions.items);
            const filteredSubscriptions = subscriptions.filter((item) => item.id !== workspace?.subscription);
            const defaultSubscription = getDefaultSubscription(filteredSubscriptions);
            const workspaces = Object.values(state.workspaces.items.entities);
            const defaultWorkspace = workspaces.find((item) => item?.subscription === defaultSubscription?.id);
            return { isWorkspaceMember: false, defaultWorkspace };
        }
    },
);
export const updateWorkspace = createAsyncThunk("workspaces/update", apiUpdateWorkspace);

const workspacesSlice = createSlice<WorkspaceSlice, SliceCaseReducers<WorkspaceSlice>, "workspaces">({
    name: "workspaces",
    initialState: {
        status: null,
        currentWorkspaceId: Number(LocalStorage.getItem("currentWorkspace")),
        items: workspacesAdapter.getInitialState(),
    },
    reducers: {
        setCurrentWorkspaceId: (state, action) => {
            LocalStorage.setItem("currentWorkspace", action.payload);
            state.currentWorkspaceId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaces.pending, (state) => {
                state.status = "pending";
            })
            .addCase(fetchWorkspaces.rejected, (state) => {
                state.status = "rejected";
            })
            .addCase(fetchWorkspaces.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";

                const sortedWorkspaces = [...payload].sort((a, b) => a.name.localeCompare(b.name));
                const workspaces = normalizeWorkspaces(sortedWorkspaces);
                workspacesAdapter.setAll(state.items, workspaces);

                const currentWorkspaceExists = sortedWorkspaces.some(
                    (workspace) => workspace.id === state.currentWorkspaceId,
                );

                if (!currentWorkspaceExists) {
                    const subscriptions = sortedWorkspaces.map((item) => item.subscription);
                    const defaultSubscription = getDefaultSubscription(subscriptions);
                    const defaultWorkspace = sortedWorkspaces.find(
                        (item) => item.subscription.id === defaultSubscription.id,
                    );
                    if (defaultWorkspace) {
                        state.currentWorkspaceId = defaultWorkspace.id;
                        LocalStorage.setItem("currentWorkspace", defaultWorkspace.id);
                    }
                }
            })
            .addCase(updateWorkspace.fulfilled, (state, { payload }) => {
                const { id, ...changes } = payload;
                workspacesAdapter.updateOne(state.items, { id, changes });
            })
            .addCase(updateWorkspaceMembers.fulfilled, (state, { meta, payload }) => {
                if (payload.isWorkspaceMember) {
                    workspacesAdapter.updateOne(state.items, {
                        id: payload.workspace.id,
                        changes: { members: payload.workspace.members },
                    });
                }

                if (!payload.isWorkspaceMember) {
                    const updatedWorkspaceId = meta.arg.workspaceId;
                    workspacesAdapter.removeOne(state.items, updatedWorkspaceId);

                    if (payload.defaultWorkspace) {
                        const defaultWorkspaceId = payload.defaultWorkspace.id;
                        state.currentWorkspaceId = defaultWorkspaceId;
                        LocalStorage.setItem("currentWorkspace", defaultWorkspaceId);
                    }
                }
            });
    },
});

export const WorkspacesReducer = workspacesSlice.reducer;

export const { setCurrentWorkspaceId } = workspacesSlice.actions;
