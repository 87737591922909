import { FormApi } from "final-form";
import { Form, useForm, useFormState } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { MinimalTableCell, MinimalTableHeaderCell } from "src/ui/shared/table/MinimalTableComponents";
import { TableHeader } from "src/ui/shared/table/TableHeader";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Provisioning, SystemProperty } from "@dashboard/provisioning/types";
import { alternatingBg, Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Paper, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

const initialValues = { property: undefined, value: undefined, comment: null };

type FormValues = SystemProperty;

export const SystemProperties = () => {
    const { value, onChange } = useCollapsiblePersistence("provisioningPage.collapsedSections", "SystemProperties");
    const parentForm = useForm();
    const sharedClasses = useStyles();

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const { values: parentFormValues } = useFormState<Partial<Provisioning>>();
    const properties = parentFormValues.settings?.systemProperties || [];

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        const newValue = { ...values, comment: values.comment || null };
        if (properties.length) {
            parentForm.change("settings.systemProperties", [...properties, newValue]);
        }
        if (!properties.length) {
            parentForm.change("settings.systemProperties", [newValue]);
        }
        form.restart(initialValues);
    };
    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        if (!values.property) {
            errors.property = "Property is not allowed to be empty";
        }
        if (!values.value) {
            errors.value = "Value is not allowed to be empty";
        }
        if (properties.includes(values)) {
            errors.property = "This property has already been added";
        }
        return errors;
    };
    const handleDelete = (index: number) => {
        parentForm.change(
            "settings.systemProperties",
            properties.filter((_, i) => i !== index),
        );
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ form }) => (
                <CollapsibleProvider initialValue={value} onChange={onChange}>
                    <CollapsibleSectionSummary>
                        <Typography className={sharedClasses.categoryTitle}>System properties</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <SectionDescription>Custom values for standard system properties.</SectionDescription>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell align="left">Property</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Value</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Comment</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {properties.length ? (
                                        properties.map((item, index) => (
                                            <TableRow key={item.property + item.value} style={alternatingBg(index)}>
                                                <MinimalTableCell component="th" scope="row" align="left">
                                                    {item.property}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="left">{item.value}</MinimalTableCell>
                                                <MinimalTableCell align="left">{item.comment}</MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDelete(index)}
                                                        tooltipProps={{ title: "Remove" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell align="center" colSpan={4}>
                                                No system properties to show
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row style={{ paddingBottom: 12, marginTop: 15 }}>
                            <TextField
                                name="property"
                                label="Property name"
                                disabled={!provisionings.length}
                                fullWidth
                            />
                            <TextField name="value" label="Property value" disabled={!provisionings.length} fullWidth />
                        </Row>
                        <Row>
                            <TextField name="comment" label="Comment" disabled={!provisionings.length} fullWidth />
                            <PrimaryOutlinedButton onClick={form.submit} disabled={!provisionings.length}>
                                Add
                            </PrimaryOutlinedButton>
                        </Row>
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            )}
        />
    );
};
