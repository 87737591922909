import { diff } from "deep-diff";
import { FormApi } from "final-form";
import _ from "lodash";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import semver from "semver";
import { RootState, useDispatch } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";
import { TextareaField } from "src/ui/shared/form/TextareaField";
import { TextField } from "src/ui/shared/form/TextField";
import { Modal } from "src/ui/shared/Modal/Modal";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import {
    setBuildChangelog,
    updateBuildCompatibility,
    updateBuildMetadata,
    updateBuildRollout,
} from "@dashboard/products/store";
import { selectProductChangelogsById } from "@dashboard/products/store/selectors/products";
import { Build } from "@dashboard/products/types";
import { TagManagementSection } from "@dashboard/shared/components/TagManagementSection";
import { Box, DialogProps, InputAdornment, Typography } from "@mui/material";

import { buildVariantOptions } from "../ProductBuilder/components/Recipes";
import { useStyles } from "./BuildSettingsDialog.style";
import { BuildVariantLabel } from "./shared";

type FormValues = {
    compatibilitySection: string;
    tagSection: API.Tag[];
    changeLogSection: string;
    rolloutSection: number;
    buildVariant: string;
};

const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};
    if (values.compatibilitySection) {
        if (!semver.validRange(values.compatibilitySection)) {
            errors.compatibilitySection = "Semver range format required (e.g. >=1.0.0 or =1.2.3)";
        }
    }
    if (values.rolloutSection < 0 || values.rolloutSection > 100) {
        errors.rolloutSection = "Rollout must be in the range from 0 to 100";
    }
    return errors;
};
const getFormDiff = (form: FormApi<FormValues>) => {
    const rolloutChanged = form.getFieldState("rolloutSection")?.dirty;
    const compatibilityChanged = form.getFieldState("compatibilitySection")?.dirty;
    const changeLogChanged = form.getFieldState("changeLogSection")?.dirty;
    const tagsChanged = diff(
        _.keyBy(form.getState().initialValues.tagSection, "key"),
        _.keyBy(form.getState().values.tagSection, "key"),
    );
    const pristine = !compatibilityChanged && !changeLogChanged && !tagsChanged && !rolloutChanged;

    return { compatibilityChanged, changeLogChanged, tagsChanged, rolloutChanged, pristine };
};
const getBuildVariant = (build: Build): string => {
    if (build.configOverride && "buildVariant" in build.configOverride) {
        return build.configOverride.buildVariant as string;
    }

    if (build.configSnapshot && build.configSnapshot?.buildVariant) {
        return build.configSnapshot.buildVariant;
    }

    return "-";
};

type Props = Omit<DialogProps, "onClose"> & {
    build: Build;
    onClose: (event?: object) => void;
};

export const BuildSettingsDialog = ({ build, ...props }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const changeLog = useSelector((state: RootState) => selectProductChangelogsById(state, build.productId));
    const fullBuildFile = build.files.find((item) => item.metadata?.updateType === "full");
    const fullBuildFileCompatibility = fullBuildFile?.metadata?.compatibility;
    const compatibility = build.compatibility || fullBuildFileCompatibility;
    const initialValues: FormValues = {
        compatibilitySection: compatibility || "",
        changeLogSection: changeLog?.[build.version]?.join("\n") || "",
        tagSection: build.metadata?.user?.tags || [],
        rolloutSection: build.rolloutPercentage,
        buildVariant: getBuildVariant(build),
    };

    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        const { submitting, active } = form.getState();
        const { pristine } = getFormDiff(form);

        if (e.key === "Enter" && !pristine && !submitting && !active) {
            await form.submit();
        }
    };
    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        const { compatibilityChanged, changeLogChanged, tagsChanged, rolloutChanged } = getFormDiff(form);

        try {
            if (compatibilityChanged) {
                await dispatch(
                    updateBuildCompatibility({
                        productId: build.productId,
                        buildId: build.buildId,
                        compatibility: values.compatibilitySection,
                    }),
                ).unwrap();
            }
            if (changeLogChanged) {
                await dispatch(
                    setBuildChangelog({
                        productId: build.productId,
                        version: build.version,
                        changes: values.changeLogSection.split("\n").filter((item) => item.trim() !== ""),
                    }),
                ).unwrap();
            }
            if (tagsChanged) {
                await dispatch(
                    updateBuildMetadata({
                        productId: build.productId,
                        buildId: build.buildId,
                        metadata: { ...build.metadata?.user, tags: values.tagSection },
                    }),
                ).unwrap();
            }
            if (rolloutChanged) {
                await dispatch(
                    updateBuildRollout({
                        productId: build.productId,
                        buildId: build.buildId,
                        rolloutPercentage: +values.rolloutSection,
                    }),
                ).unwrap();
            }

            showSuccessToast("Build settings updated successfully");
            props.onClose();
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong during updating settings");
        }
    };

    return (
        <Form<FormValues>
            {...{ initialValues, validate, onSubmit }}
            render={({ handleSubmit, submitting, valid, form, values }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        {...props}
                        title="Build settings"
                        onKeyDown={(e) => onKeyDown(e, form)}
                        endButton={
                            <PrimaryButton
                                onClick={form.submit}
                                disabled={submitting || !valid || getFormDiff(form).pristine}
                                loading={submitting}
                                tooltipProps={{ title: !valid ? "You have validation issues" : "" }}
                            >
                                Save
                            </PrimaryButton>
                        }
                    >
                        <TagManagementSection
                            tags={values.tagSection}
                            onChange={(data) => form.change("tagSection", data)}
                        />
                        <TextField
                            label="Compatibility"
                            name="compatibilitySection"
                            placeholder="Compatibility version (e.g., >=1.0.0, >2.0, =1.2.3)"
                            fullWidth
                        />
                        <TextareaField
                            name="changeLogSection"
                            label="Changes"
                            minRows={4}
                            placeholder={
                                "- Fixed bug in network module\n- Improved battery performance\n- Added support for new hardware"
                            }
                            fullWidth
                        />
                        <TextField
                            name="rolloutSection"
                            label="Rollout"
                            type="number"
                            fullWidth
                            required
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />

                        <Box className={classes.variantBox}>
                            <Box className={classes.variantSelectBox}>
                                <SelectField
                                    fullWidth
                                    label="Build variant"
                                    disabled={true}
                                    name="buildVariant"
                                    options={buildVariantOptions}
                                    style={{ marginRight: 6 }}
                                />
                                <BuildVariantLabel />
                            </Box>
                            <Typography className={classes.hintText}>
                                Build variant cannot be changed once the build has been created.
                            </Typography>
                        </Box>
                    </Modal>
                </form>
            )}
        />
    );
};
