import clsx from "clsx";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import authService from "src/services/authService";
import { getEnv, getMvcLegacyUrl, getSettings } from "src/services/configService";
import UserSettingsService from "src/services/userSettingsService";
import { selectProfile } from "src/store/account";
import ConfirmRegistrationPage from "src/ui/containers/account/ConfirmRegistrationPage/ConfirmRegistrationPage";

import { selectSubscriptionFdroidRepos } from "@dashboard/devices/store/selectors/subscriptions";
import { selectProducts } from "@dashboard/products/store/selectors/products";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import { DrawerToogler } from "./components/DrawerToogler";
import { ListWithSubHeader } from "./components/ListWithSubHeader";
import {
    CollapsibleLink,
    ExternalLink,
    LegacyLink,
    PopupMenuLink,
    RouterLink,
} from "./components/NavigationLinks/NavigationLinks";
import { useStyles } from "./Navigation.style";

const getLegacyUrl = (path: string) => getMvcLegacyUrl() + path;

const useWithVisibleNavigation = () => {
    const { pathname } = useLocation();

    const included = ["/account/", "/dashboard/", "/admin/"];
    const excluded = ["/account/confirm", "/account/recover", "/account/reset"];

    const isExcluded = excluded.some((path) => pathname.startsWith(path));
    if (isExcluded) {
        return false;
    }
    return included.some((path) => pathname.startsWith(path));
};

type DrawerContextType = { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> };

export const DrawerContext = React.createContext<DrawerContextType>({
    open: false,
    setOpen: () => null,
});

export const Navigation = (props: React.PropsWithChildren<object>) => {
    const openInLocalStorage = UserSettingsService.getDrawerOpen();
    const [open, setOpen] = React.useState(openInLocalStorage === null ? true : openInLocalStorage);
    const classes = useStyles();
    const navigate = useNavigate();
    const env = getEnv();
    const { pathname } = useLocation();
    const { logo, features } = getSettings();

    const repositories = useSelector(selectSubscriptionFdroidRepos);
    const currentWorkspace = useSelector(selectWorkspace);
    const workspaceProducts = useSelector(selectProducts, shallowEqual);

    const value = React.useMemo(() => ({ open, setOpen }), [open, setOpen]);

    const hasUserRole = authService.isUserInAnyAccessRole();
    const isAdmin = authService.isUserAdmin();
    const isMerchant = authService.isUserMerchant();
    const isDeveloper = authService.isUserDeveloper();
    const isSupporter = authService.isUserSupporter();
    const isAccountant = authService.isUserAccountant();
    const hasProducts = !!workspaceProducts.length;

    const hasVisibleNavigation = useWithVisibleNavigation();
    const profile = useSelector(selectProfile);
    const hasUnfinishedOnboarding = profile && !profile.profileCompleted;

    const canViewAppRepositories = features.appRepositoriesEnabled && (isAdmin || !!repositories.length);
    const canViewProducts = hasUserRole || hasProducts;

    if (hasUnfinishedOnboarding && pathname !== "/account/confirm") {
        return <ConfirmRegistrationPage />;
    }

    if (!hasVisibleNavigation) {
        return <div className={classes.content}>{props.children}</div>;
    }

    return (
        <DrawerContext.Provider {...{ value }}>
            <div className={classes.root}>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <div className={classes.logoBox}>
                        <a
                            href="/dashboard/overview"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/dashboard/overview");
                            }}
                        >
                            <img
                                src={open ? logo.fullLogo : logo.smallLogo}
                                height={32}
                                className="d-inline-block align-top"
                                alt={logo.alt}
                            />
                        </a>
                        {features.enviromentLabelEnabled && open ? <div className={classes.envLabel}>{env}</div> : null}
                        <DrawerToogler />
                    </div>
                    <Divider />
                    <div className={classes.navigationBox}>
                        <ListWithSubHeader title={currentWorkspace?.name || ""} open={open}>
                            <RouterLink title="Dashboard" startIcon="fa-solid fa-chart-line" to="/dashboard/overview" />
                            <RouterLink title="Devices" startIcon="fa-solid fa-laptop" to="/dashboard/devices" />
                            <RouterLink title="Groups" startIcon="fa-solid fa-folder-tree" to="/dashboard/groups" />
                            <RouterLink
                                title="Provisioning"
                                startIcon="fa-solid fa-sliders"
                                to="/dashboard/provisioning"
                            />
                            <RouterLink
                                title="Downloads"
                                startIcon="fa-regular fa-floppy-disk"
                                to="/dashboard/downloads"
                                condition={features.downloadsEnabled}
                            />
                            <RouterLink
                                title="Products"
                                startIcon="fa-solid fa-microchip"
                                to="/dashboard/products"
                                condition={canViewProducts}
                            />
                            <RouterLink title="Files" startIcon="fa-regular fa-file" to="/dashboard/files" />
                            <RouterLink
                                title="App repositories"
                                startIcon="fa-solid fa-cubes"
                                to="/dashboard/repositories"
                                condition={canViewAppRepositories}
                            />
                            <RouterLink
                                title="Universal licenses"
                                startIcon="fa-solid fa-ticket"
                                to="/dashboard/licenses"
                                condition={features.universalLicensesEnabled}
                            />
                        </ListWithSubHeader>
                        <Divider />
                        <ListWithSubHeader title="Account" open={open}>
                            <CollapsibleLink
                                title="Admin"
                                startIcon="fa-regular fa-user"
                                condition={isAdmin || isMerchant || isDeveloper || isSupporter || isAccountant}
                            >
                                <RouterLink title="Builds" to="/admin/builds/queue" condition={isAdmin} />
                                <LegacyLink
                                    title="Certificate"
                                    href={getLegacyUrl("/Admin/Certificate")}
                                    condition={isAdmin}
                                />
                                <LegacyLink
                                    title="Customers"
                                    href={getLegacyUrl("/Admin/Customers")}
                                    condition={isMerchant}
                                />
                                <LegacyLink title="Email" href={getLegacyUrl("/Admin/Email")} condition={isAdmin} />
                                <RouterLink
                                    title="Expirations"
                                    to="/admin/expirations"
                                    condition={isMerchant || isAccountant}
                                />
                                <LegacyLink
                                    title="F-Droid"
                                    href={getLegacyUrl("/Admin/Fdroid")}
                                    condition={isDeveloper}
                                />
                                <RouterLink title="Files" to="/admin/files" condition={isAdmin} />
                                <LegacyLink
                                    title="Groups"
                                    href={getLegacyUrl("/Admin/Groups")}
                                    condition={isDeveloper}
                                />
                                <LegacyLink
                                    title="Invoices"
                                    href={getLegacyUrl("/Admin/Invoices")}
                                    condition={isMerchant}
                                />
                                <LegacyLink
                                    title="Licenses"
                                    href={getLegacyUrl("/Admin/Licenses")}
                                    condition={isMerchant}
                                />
                                <LegacyLink title="Logs" href={getLegacyUrl("/Admin/Logs")} condition={isDeveloper} />
                                <LegacyLink
                                    title="Maintenance"
                                    href={getLegacyUrl("/Admin/Maintenance")}
                                    condition={isDeveloper}
                                />
                                <RouterLink title="Memberships" to="/admin/members" condition={isAdmin} />
                                <LegacyLink
                                    title="Newsletter"
                                    href={getLegacyUrl("/Admin/Newsletter")}
                                    condition={isMerchant}
                                />
                                <RouterLink title="Products" to="/admin/products" condition={isDeveloper} />
                                <LegacyLink
                                    title="Provisioning"
                                    href={getLegacyUrl("/Admin/Provisioning")}
                                    condition={isAdmin}
                                />
                                <LegacyLink
                                    title="Releases"
                                    href={getLegacyUrl("/Admin/Releases")}
                                    condition={isDeveloper}
                                />
                                <LegacyLink title="Roles" href={getLegacyUrl("/Admin/Roles")} condition={isDeveloper} />
                                <LegacyLink
                                    title="Security"
                                    href={getLegacyUrl("/Admin/Security")}
                                    condition={isSupporter}
                                />
                                <LegacyLink
                                    title="Subscriptions"
                                    href={getLegacyUrl("/Admin/Subscriptions")}
                                    condition={isMerchant}
                                />
                                <LegacyLink
                                    title="Vouchers"
                                    href={getLegacyUrl("/Admin/Vouchers")}
                                    condition={isMerchant}
                                />
                            </CollapsibleLink>
                            <RouterLink title="Workspace" startIcon="fa-solid fa-briefcase" to="/account/workspaces" />
                            <RouterLink title="Settings" startIcon="fa-solid fa-gears" to="/account/profile" />
                            <RouterLink title="Logout" startIcon="fa-solid fa-arrow-right-from-bracket" to="/logout" />
                        </ListWithSubHeader>
                    </div>
                    <Divider />
                    <ListWithSubHeader title="Helpdesk" open={open}>
                        <PopupMenuLink title="Help" startIcon="fa-regular fa-circle-question">
                            <ExternalLink title="Discussion forum" url="https://forum.emteria.com" />
                            <ExternalLink title="Knowledge base" url="https://emteria.com/kb" />
                            <ExternalLink title="Privacy policy" url="https://emteria.com/legal/privacy-policy" />
                            <ExternalLink title="Terms of use" url="https://emteria.com/legal/terms-of-use" />
                            <ExternalLink title="Legal notice" url="https://emteria.com/legal/legal-notice" />
                        </PopupMenuLink>
                    </ListWithSubHeader>
                </Drawer>
                <div className={classes.content}>
                    <div className={classes.main}>{props.children}</div>
                </div>
            </div>
        </DrawerContext.Provider>
    );
};
