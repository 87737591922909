import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteApiKeys, getAccount, getApiKeys, getBillingCard, recreateApiKeys } from "src/services/accountService";
import paymentService from "src/services/paymentService";
import UserSettingsService from "src/services/userSettingsService";
import ApmLink from "src/ui/shared/ApmLink";
import { DangerOutlinedButton, PrimaryOutlinedButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { Tooltip } from "src/ui/shared/Tooltip";

import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { Box, Card, CardHeader, Divider, List, ListItem, Switch, SwitchProps } from "@mui/material";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import packageJson from "../../../../../package.json";
import EditStripeCard from "./EditStripeCard";
import { useStyles } from "./ProfilePage.style";

type FeatureSwitchProps = SwitchProps & {
    description: string;
};
const FeatureSwitch = ({ description, ...props }: FeatureSwitchProps) => {
    const classes = useStyles();
    return (
        <ListItem>
            <Box className={classes.switchBox}>
                <Switch {...props} inputProps={{ "aria-label": "primary checkbox" }} color="primary" />
                <Box className={classes.switchContent}>
                    <span className={classes.switchLabel}>{props.title}</span>
                    <div>{description}</div>
                </Box>
            </Box>
        </ListItem>
    );
};

const ProfilePage = () => {
    const [profile, setProfile] = useState<API.Profile>();
    const [billingAddress, setBillingAddress] = useState<API.BillingAddress | undefined>();
    const [billingCard, setBillingCard] = useState<API.BillingCard | null>(null);

    const [apiKeys, setApiKeys] = useState<string | undefined>();
    const [isRemovingApiKey, setIsRemovingApiKey] = useState(false);
    const [isRenewApiKey, setIsRenewApiKey] = useState(false);
    const [isModalShown, setIsModalShown] = useState(false);
    const [stripePromise, setStripePromise] = useState<PromiseLike<Stripe | null> | Stripe | null>(null);

    const classes = useStyles();

    const copyToClipboard = useCallback((text: string | undefined) => {
        if (!text) {
            return;
        }
        const sanitizedText = text.trim();
        navigator.clipboard
            .writeText(sanitizedText)
            .then(() => {
                showSuccessToast("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text to clipboard", err);
            });
    }, []);

    const handleApiKeysRenew = async () => {
        setIsRenewApiKey(true);

        try {
            const { model, message } = await recreateApiKeys();
            setApiKeys(model);
            showSuccessToast(message);
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setIsRenewApiKey(false);
        }
    };
    const handleApiKeysDeletion = async () => {
        setIsRemovingApiKey(true);

        try {
            const { message } = await deleteApiKeys();
            showSuccessToast(message);
            setApiKeys("");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setIsRemovingApiKey(false);
        }
    };

    const handleFetchPublicProductsToggle: FeatureSwitchProps["onChange"] = (_, checked) => {
        UserSettingsService.setCommonValue("fetchPublicProducts", checked);
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const { data } = await getAccount();
                setProfile(data.profile);
                setBillingAddress(data?.billingAddress);
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            }

            try {
                const _billingCard = await getBillingCard();
                setBillingCard(_billingCard);
                if (_billingCard !== null) {
                    const promiseData = await paymentService.getStripePromise(loadStripe);
                    setStripePromise(promiseData as PromiseLike<Stripe | null> | Stripe | null);
                }
            } catch (error) {
                showErrorToast("Failed retrieving billing card");
            }

            try {
                const apiKeysData = await getApiKeys();
                setApiKeys(apiKeysData);
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            }
        };

        void loadData();
    }, []);

    if (!profile) {
        return null;
    }

    return (
        <>
            <PageContainer className={classes.container}>
                <Box>
                    <PageTitleWithIcon title="Settings" iconName="fa-solid fa-gears" />
                    <SectionDescription>
                        Here you can manage your account, update your profile information, payment details, and more.
                    </SectionDescription>
                </Box>

                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={<span style={{ fontSize: 18 }}>Portal settings</span>}
                    />
                    <List className={classes.list}>
                        <FeatureSwitch
                            defaultChecked={UserSettingsService.getFetchPublicProducts()}
                            title="Show public products"
                            description="Show releases of public third-party products in the download page."
                            onChange={handleFetchPublicProductsToggle}
                        />
                    </List>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Box className={classes.headerBox}>
                                <span style={{ fontSize: 18 }}>Profile data</span>
                                <div className={classes.headerButtonBox}>
                                    <Link to="/account/edit">
                                        <PrimaryOutlinedButton
                                            size="small"
                                            startIcon={<i className="fas fa-fw fa-pencil-alt" />}
                                        >
                                            Edit
                                        </PrimaryOutlinedButton>
                                    </Link>
                                    <ApmLink parent="Account profile page" title="Delete account" to="/account/delete">
                                        <DangerOutlinedButton
                                            size="small"
                                            startIcon={<i className="fas fa-fw fa-trash-alt" />}
                                        >
                                            Delete
                                        </DangerOutlinedButton>
                                    </ApmLink>
                                </div>
                            </Box>
                        }
                    />

                    <List>
                        <ListItem>
                            <span className={classes.headerColumn}>Email</span>
                            <span className={classes.valueColumn}>{profile?.email}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Authentication</span>
                            <span className={classes.valueColumn}>
                                {profile?.twoFactorEnabled ? <span>Two-factor</span> : <span>Password</span>}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Name</span>
                            <span className={classes.valueColumn}>{profile?.name}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Phone</span>
                            <span className={classes.valueColumn}>
                                {profile?.phoneCode}
                                {profile?.phoneNumber}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Address</span>
                            <span className={classes.valueColumn}>{profile?.address}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>City</span>
                            <span className={clsx(classes.valueColumn, classes.capitalizedColumn)}>
                                {profile?.city}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Zip code</span>
                            <span className={classes.valueColumn}>{profile?.zipCode}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Country</span>
                            <span className={classes.valueColumn}>{profile?.country}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Notifications</span>
                            <span className={classes.valueColumn}>
                                {profile?.newsletterChecked ? <span>Extended</span> : <span>Standard</span>}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Usage</span>
                            <span className={classes.valueColumn}>
                                {profile?.typeOfUsage === "b" ? (
                                    <span>Business use</span>
                                ) : (
                                    <span>Non-business use</span>
                                )}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Company</span>
                            <span className={classes.valueColumn}>{profile?.companyName}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>VAT ID</span>
                            <span className={classes.valueColumn}>{profile?.companyVAT}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Area</span>
                            <span className={classes.valueColumn}>{profile?.fieldOfApplication}</span>
                        </ListItem>
                    </List>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Box className={classes.headerBox}>
                                <span style={{ fontSize: 18 }}>Billing address</span>
                                <Link to="/account/billing/edit">
                                    <PrimaryOutlinedButton
                                        size="small"
                                        startIcon={<i className="fas fa-fw fa-pencil-alt" />}
                                    >
                                        Edit
                                    </PrimaryOutlinedButton>
                                </Link>
                            </Box>
                        }
                    />

                    <List>
                        <ListItem>
                            <span className={classes.headerColumn}>Name</span>
                            <span className={classes.valueColumn}>{billingAddress?.name}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Address</span>
                            <span className={classes.valueColumn}>{billingAddress?.address}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>City</span>
                            <span className={classes.valueColumn}>{billingAddress?.city}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Zip code</span>
                            <span className={classes.valueColumn}>{billingAddress?.zipCode}</span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Country</span>
                            <span className={classes.valueColumn}>{billingAddress?.country}</span>
                        </ListItem>
                    </List>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Box className={classes.headerBox}>
                                <span style={{ fontSize: 18 }}>Payment method</span>
                                <Tooltip placement="top" title="Please contact support">
                                    <PrimaryOutlinedButton
                                        startIcon={<i className="fas fa-fw fa-pencil-alt" />}
                                        onClick={() => setIsModalShown(true)}
                                        size="small"
                                        disabled
                                    >
                                        Edit
                                    </PrimaryOutlinedButton>
                                </Tooltip>
                            </Box>
                        }
                    />
                    <List>
                        <ListItem>
                            <span className={classes.headerColumn}>Card type</span>
                            <span className={clsx(classes.valueColumn, classes.capitalizedColumn)}>
                                {billingCard?.brand ?? "No card set"}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Card number</span>
                            <span className={classes.valueColumn}>
                                {billingCard?.lastFourDigits
                                    ? `**** **** **** ${billingCard?.lastFourDigits}`
                                    : "No card number"}
                            </span>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <span className={classes.headerColumn}>Expiration date</span>
                            <span className={classes.valueColumn}>
                                {billingCard?.expMonth ?? "MM"} / {billingCard?.expYear ?? "YY"}
                            </span>
                        </ListItem>
                    </List>
                </Card>
                {stripePromise ? (
                    // https://github.com/stripe/react-stripe-js/issues/273#issuecomment-1102947196
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Elements stripe={stripePromise}>
                        <EditStripeCard open={isModalShown} onClose={() => setIsModalShown(false)} />
                    </Elements>
                ) : null}
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Box className={classes.headerBox}>
                                <span style={{ fontSize: 18 }}>API key</span>
                            </Box>
                        }
                    />
                    <List>
                        <ListItem>
                            <div className={classes.apiKeyContainer}>
                                {apiKeys ? (
                                    <>
                                        <span>{apiKeys}</span>
                                        <div className={classes.apiKeyActions}>
                                            <SecondaryButton
                                                className={classes.copyButton}
                                                startIcon={<i className="fa-solid fa-copy" />}
                                                onClick={() => copyToClipboard(apiKeys)}
                                            >
                                                Copy
                                            </SecondaryButton>
                                            <SecondaryButton
                                                startIcon={<i className="fa-solid fa-arrow-rotate-left" />}
                                                onClick={() => handleApiKeysRenew()}
                                                loading={isRenewApiKey}
                                                disabled={isRenewApiKey || isRemovingApiKey}
                                                size="small"
                                            >
                                                Renew
                                            </SecondaryButton>
                                            <DangerOutlinedButton
                                                disabled={!apiKeys || isRemovingApiKey || isRenewApiKey}
                                                size="small"
                                                startIcon={<i className="fas fa-fw fa-trash-alt" />}
                                                onClick={() => handleApiKeysDeletion()}
                                                loading={isRemovingApiKey}
                                            >
                                                Delete
                                            </DangerOutlinedButton>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span>No API key has been generated yet</span>
                                        <PrimaryOutlinedButton
                                            style={{ marginLeft: 12 }}
                                            startIcon={<i className="fa-solid fa-key" />}
                                            onClick={() => handleApiKeysRenew()}
                                            loading={isRenewApiKey}
                                            disabled={isRenewApiKey || isRemovingApiKey}
                                            size="small"
                                        >
                                            Generate
                                        </PrimaryOutlinedButton>
                                    </>
                                )}
                            </div>
                        </ListItem>
                    </List>
                </Card>
                <Box className={classes.versionDisplay}>Version: {packageJson.version}</Box>
            </PageContainer>
        </>
    );
};

export default ProfilePage;
