import { FormApi } from "final-form";
import React from "react";
import { Form, useField } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { selectFilesWithApkFilter, selectFilesWithBinFilter } from "src/ui/containers/dashboard/files/store/selectors";
import { isApk } from "src/ui/containers/dashboard/files/utils";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";
import { TextField } from "src/ui/shared/form/TextField";
import { Modal } from "src/ui/shared/Modal/Modal";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Tooltip } from "src/ui/shared/Tooltip";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { AndroidModule } from "@dashboard/products/types";
import AddIcon from "@mui/icons-material/Add";
import { Box, Chip, TableBody, TableContainer, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
    actionOptions,
    certificateOptions,
    moduleValidationSchema,
    partitionOptions,
    privilegedOptions,
    typeOptions,
} from "./ModuleSection.utils";

const useStyles = makeStyles({
    dialog: { width: 700 },
    inputBox: { marginTop: 12, display: "flex", flexDirection: "column", gap: 8 },
    buttonBox: { marginBottom: 12, display: "flex", justifyContent: "flex-end" },
});

type FormValues = AndroidModule & { fileId?: string };

export const ModuleSection = () => {
    const [open, setOpen] = React.useState(false);
    const { input: modulesInput } = useField<AndroidModule[]>("customBuildConfig.modules");

    const classes = useStyles();
    const validate = useValidationSchema(moduleValidationSchema);

    const executables = useSelector(selectFilesWithBinFilter, shallowEqual);
    const applications = useSelector(selectFilesWithApkFilter, shallowEqual);
    const moduleFiles = [...executables, ...applications];
    const fileIds = modulesInput.value.map((item) => {
        if ("source" in item) {
            return item.source.fileId;
        }
    });

    const onRemove = (module: AndroidModule) => {
        const newModules = modulesInput.value.filter((item) => item.name !== module.name);
        modulesInput.onChange(newModules);
    };
    const onSubmit = ({ fileId, ...values }: FormValues, form: FormApi<FormValues>) => {
        if (values.action === "remove") {
            modulesInput.onChange([...modulesInput.value, values]);
            form.restart();
            return;
        }

        const file = moduleFiles.find((item) => item.id === fileId);
        if (values.action === "add" && file) {
            modulesInput.onChange([
                ...modulesInput.value,
                {
                    ...values,
                    name: file.filename,
                    type: isApk(file.filename) ? "prebuilt_app" : "prebuilt_executable",
                    source: { type: "storage", fileId },
                },
            ]);
            form.restart();
        }
    };

    return (
        <React.Fragment>
            <Tooltip title="Manage Android modules">
                <CustomBadge badgeContent={modulesInput.value.length}>
                    <Chip label="Modules" onClick={() => setOpen(true)} icon={<AddIcon />} />
                </CustomBadge>
            </Tooltip>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                title="Modules"
                description="Manage Android modules by adding or removing APKs and executable files for your
                custom build configuration. Use the table below to review all configured modules and
                remove any that are no longer needed."
            >
                <Form<FormValues>
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{
                        action: "add",
                        partition: "product",
                        privileged: "regular",
                        certificate: "presigned",
                        type: "prebuilt_executable",
                    }}
                    render={({ handleSubmit, values, form }) => (
                        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                            <SelectField
                                label="Action"
                                name="action"
                                options={actionOptions}
                                fullWidth
                                style={{ marginTop: 12 }}
                            />

                            {values.action === "remove" ? (
                                <TextField
                                    name="name"
                                    label="Name"
                                    placeholder="Module name (e.g., Email)"
                                    fullWidth
                                    style={{ marginTop: 4 }}
                                />
                            ) : null}

                            {values.action === "add" ? (
                                <React.Fragment>
                                    <SelectField label="Type" name="type" options={typeOptions} fullWidth />
                                    <SelectField
                                        label="File"
                                        name="fileId"
                                        options={[...(values.type === "prebuilt_app" ? applications : executables)].map(
                                            (item) => ({
                                                label: item.filename,
                                                value: item.id,
                                                disabled: fileIds.includes(item.id),
                                            }),
                                        )}
                                        fullWidth
                                    />
                                    <SelectField
                                        label="Partition"
                                        name="partition"
                                        options={partitionOptions}
                                        fullWidth
                                    />
                                </React.Fragment>
                            ) : null}

                            {values.action === "add" && values.type === "prebuilt_app" ? (
                                <React.Fragment>
                                    <SelectField
                                        label="Permission level"
                                        name="privileged"
                                        options={privilegedOptions}
                                        fullWidth
                                    />
                                    <SelectField
                                        label="Signature"
                                        name="certificate"
                                        options={certificateOptions}
                                        fullWidth
                                    />
                                </React.Fragment>
                            ) : null}

                            <Box className={classes.buttonBox}>
                                <PrimaryOutlinedButton onClick={form.submit}>
                                    {values.action === "add" ? "Add" : "Remove"}
                                </PrimaryOutlinedButton>
                            </Box>

                            <TableContainer>
                                <MinimalTable>
                                    <MinimalTableHeader>
                                        <TableRow>
                                            <MinimalTableHeaderCell>Name</MinimalTableHeaderCell>
                                            <MinimalTableHeaderCell>Type</MinimalTableHeaderCell>
                                            <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                        </TableRow>
                                    </MinimalTableHeader>
                                    <TableBody>
                                        {!modulesInput.value.length ? (
                                            <TableRow>
                                                <MinimalTableCell colSpan={3} align="center">
                                                    No items available
                                                </MinimalTableCell>
                                            </TableRow>
                                        ) : null}

                                        {modulesInput.value.map((item) => (
                                            <TableRow key={item.name}>
                                                <MinimalTableCell align="left">{item.name}</MinimalTableCell>
                                                <MinimalTableCell align="left">
                                                    {item.action === "add" ? "Added" : "Removed"}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => onRemove(item)}
                                                        tooltipProps={{ title: "Delete" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </MinimalTable>
                            </TableContainer>
                        </form>
                    )}
                />
            </Modal>
        </React.Fragment>
    );
};
