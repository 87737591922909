import * as yup from "yup";

export const moduleValidationSchema = yup.object({
    action: yup.string().oneOf(["add", "remove"], "Invalid action value").required().label("Action"),
    name: yup
        .string()
        .when("action", {
            is: "remove",
            then: (schema) => schema.required(),
        })
        .matches(/^[a-zA-Z0-9_-]+$/, "Name cannot contain special characters or spaces")
        .label("Name"),
    fileId: yup
        .string()
        .when("action", {
            is: "add",
            then: (schema) => schema.required(),
        })
        .label("File"),
    type: yup
        .string()
        .oneOf(["prebuilt_app", "prebuilt_executable"], "Invalid type value")
        .when("action", {
            is: "add",
            then: (schema) => schema.required(),
        })
        .label("Type"),
    partition: yup
        .string()
        .oneOf(["odm", "product", "system", "system_ext", "vendor"], "Invalid partition value")
        .when("action", {
            is: "add",
            then: (schema) => schema.required(),
        })
        .label("Partition"),
    privileged: yup
        .string()
        .oneOf(["regular", "privileged"], "Invalid type value")
        .when(["action", "type"], {
            is: (action: string, type: string) => action === "add" && type === "prebuilt_app",
            then: (schema) => schema.required(),
        })
        .label("Privileged"),
    certificate: yup
        .string()
        .oneOf(["presigned", "platform"], "Invalid certificate value")
        .when(["action", "type"], {
            is: (action: string, type: string) => action === "add" && type === "prebuilt_app",
            then: (schema) => schema.required(),
        })
        .label("Certificate"),
});

export const actionOptions = [
    { value: "add", label: "Add new module" },
    { value: "remove", label: "Remove existing module" },
];
export const partitionOptions = [
    { value: "odm", label: "/odm" },
    { value: "product", label: "/product" },
    { value: "system", label: "/system" },
    { value: "system_ext", label: "/system_ext" },
    { value: "vendor", label: "/vendor" },
];
export const privilegedOptions = [
    { value: "regular", label: "Regular" },
    { value: "privileged", label: "Privileged" },
];
export const typeOptions = [
    { value: "prebuilt_app", label: "Application" },
    { value: "prebuilt_executable", label: "Executable" },
];
export const certificateOptions = [
    { value: "platform", label: "Platform (resign with platform certificate)" },
    { value: "presigned", label: "Presigned (leave signature as-is)" },
];
