import _ from "lodash";
import authService from "src/services/authService";
import { RootState } from "src/store";
import { LimitType } from "src/types/limitType";

import {
    selectSubscription,
    selectSubscriptionsFdroidReposById,
} from "@dashboard/devices/store/selectors/subscriptions";
import { GroupRoles } from "@dashboard/devices/types";
import { selectFiles } from "@dashboard/files/store/selectors";
import { selectGroupsByIds } from "@dashboard/groups/store/selectors";
import { selectProducts } from "@dashboard/products/store/selectors/products";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { Workspace } from "../types";

const noLimit = 2147483647;

const isUnlimited = (limit: number | undefined): boolean => {
    return limit === noLimit;
};

const workspacesAdapter = createEntityAdapter<Workspace>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

const defaultSelectors = workspacesAdapter.getSelectors<RootState>((state) => state.workspaces.items);

const selectWorkspaces = defaultSelectors.selectAll;
const selectWorkspaceById = defaultSelectors.selectById;

const selectWorkspaceMemberRoleByEmail = (
    state: RootState,
    workspaceId: number | undefined,
    email: string | null,
): GroupRoles | null => {
    if (!workspaceId) {
        return null;
    }
    const workspace = selectWorkspaceById(state, workspaceId);
    const workspaceMember = workspace?.members?.find((member) => member.email === email);
    if (!workspaceMember) {
        return null;
    }

    return workspaceMember.role;
};
const selectWorkspaceId = (state: RootState) => {
    return state.workspaces.currentWorkspaceId || null;
};
const selectWorkspace = createSelector([selectWorkspaces, selectWorkspaceId], (workspaces, currentWorkspaceId) => {
    return workspaces.find((workspace) => workspace.id === currentWorkspaceId) || null;
});
const selectWorkspaceGroupsIds = createSelector(
    [selectWorkspace, (state: RootState) => state.devices.list.subscriptions.items.entities],
    (workspace, subscriptions) => {
        const subscription = Object.values(subscriptions).find((item) => item?.id === workspace?.subscription);
        return subscription?.groupIds || [];
    },
);
const selectWorkspaceMemberRole = (state: RootState): GroupRoles | null => {
    const workspace = selectWorkspace(state);
    const currentLoggedUserEmail = authService.getCurrentUser()?.email;

    if (!currentLoggedUserEmail) {
        return null;
    }

    const workspaceMemberRole = selectWorkspaceMemberRoleByEmail(state, workspace?.id, currentLoggedUserEmail);

    return workspaceMemberRole;
};

const selectWorkspaceProvisioningLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.ProvisioningLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};
const selectWorkspaceProductLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.ProductLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};
const selectWorkspaceFileLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.FileCountLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};
const selectGroupNestingLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.GroupNestingLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};
const selectWorkspaceLicenseLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.LicenseLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};
const selectWorkspaceFdroidRepoLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.FDroidRepoLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};
const selectWorkspaceStreamLimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.StreamSessionLimit);

    return isUnlimited(limit?.value) ? Infinity : limit?.value || 0;
};

const selectIsWorkspaceProvisioningOverlimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    const provisionings = selectProvisionings(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.ProvisioningLimit);

    if (limit?.value === undefined) {
        return true;
    }

    if (isUnlimited(limit.value)) {
        return false;
    }
    return limit.value <= provisionings.length;
};
const selectIsWorkspaceProductOverlimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    if (!subscription) {
        return true;
    }
    const products = selectProducts(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.ProductLimit);

    if (limit?.value === undefined) {
        return true;
    }

    if (isUnlimited(limit.value)) {
        return false;
    }
    return limit.value <= products.length;
};
const selectIsWorkspaceFilesOverlimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    if (!subscription) {
        return true;
    }
    const files = selectFiles(state);
    const limit = subscription?.limits.find((item) => item.type === LimitType.FileCountLimit);

    if (limit?.value === undefined) {
        return true;
    }

    if (isUnlimited(limit.value)) {
        return false;
    }
    return limit.value <= files.length;
};
const selectIsWorkspaceFDroidReposOverlimit = (state: RootState) => {
    const subscription = selectSubscription(state);
    if (!subscription) {
        return true;
    }
    const repos = selectSubscriptionsFdroidReposById(state, subscription.id);
    const limit = subscription?.limits.find((item) => item.type === LimitType.FDroidRepoLimit);
    if (limit?.value === undefined) {
        return true;
    }

    if (isUnlimited(limit.value)) {
        return false;
    }
    return limit.value <= repos.length;
};
const selectIsWorkspaceLoading = (state: RootState): boolean => {
    return state.workspaces.status === "pending" || state.workspaces.status === null;
};
const selectIsPartOfWorkspace = (state: RootState, workspace: API.Workspace) => {
    const user = authService.getCurrentUser();
    const groups = selectGroupsByIds(state, workspace.subscription.groupIds);
    const groupsMembers = _.uniqBy(
        _.flatMap(groups, (item) => item.members),
        "email",
    );

    const isWorkspaceMember = workspace?.members.some((item) => item.email === user?.email);
    if (isWorkspaceMember) {
        return true;
    }
    const isGroupMember = groupsMembers.some((item) => item.email === user?.email);
    if (isGroupMember) {
        return true;
    }
    return false;
};

export default workspacesAdapter;

export {
    selectIsPartOfWorkspace,
    selectIsWorkspaceFDroidReposOverlimit,
    selectIsWorkspaceFilesOverlimit,
    selectIsWorkspaceLoading,
    selectIsWorkspaceProductOverlimit,
    selectIsWorkspaceProvisioningOverlimit,
    selectWorkspace,
    selectWorkspaceById,
    selectWorkspaceFdroidRepoLimit,
    selectWorkspaceFileLimit,
    selectWorkspaceGroupsIds,
    selectWorkspaceId,
    selectWorkspaceLicenseLimit,
    selectWorkspaceMemberRole,
    selectWorkspaceMemberRoleByEmail,
    selectGroupNestingLimit,
    selectWorkspaceProductLimit,
    selectWorkspaceProvisioningLimit,
    selectWorkspaceStreamLimit,
    selectWorkspaces,
};
