import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const drawerOpenWidth = 240;
export const drawerCloseWidth = 66;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            maxHeight: "100vh",
            height: "100vh",
        },
        content: {
            flexGrow: 1,
            position: "relative",
            zIndex: 1,
            display: "flex",
        },
        main: {
            minHeight: "100vh",
            margin: "0 auto",
            flexGrow: 1,
        },
        drawer: {
            width: drawerOpenWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
            zIndex: 10,
        },
        drawerOpen: {
            overflow: "hidden",
            width: drawerOpenWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            overflow: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up("sm")]: {
                width: drawerCloseWidth,
            },
        },
        logoBox: {
            padding: "18px 18px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
        },
        navigationBox: {
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
        workspaceTitle: { position: "absolute", bottom: 2, left: 12, fontSize: 10, fontStyle: "italic" },
        envLabel: {
            position: "absolute",
            textTransform: "uppercase",
            color: theme.palette.red[50],
            bottom: 4,
            right: 6,
            fontSize: 14,
            fontWeight: 700,
            zIndex: 2,
        },
    }),
);
