import * as queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { handleInvitation } from "src/services/accountService";
import { getSettings } from "src/services/configService";
import { useDispatch } from "src/store";
import { LoadingIcon } from "src/ui/shared/Loading";

import { IconSize } from "@dashboard/devices/components/shared/index";
import { showDetails } from "@dashboard/devices/store/index";
import { selectSubscriptions } from "@dashboard/devices/store/selectors/subscriptions";
import { fetchWorkspaces, setCurrentWorkspaceId } from "@dashboard/workspaces/store/index";
import { Box, Container, Typography } from "@mui/material";

import { selectWorkspaces } from "../workspaces/store/selectors";
import { useStyles } from "./InvitationPage.style";

const InvitationPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState(10);
    const [error, setError] = useState({ message: "", reason: "" });
    const { logo } = getSettings();
    const [confirmationUrl, setConfirmationUrl] = useState<string | null>(null);

    const workspaces = useSelector(selectWorkspaces);

    const { query } = queryString.parseUrl(window.location.href);

    const subscriptions = useSelector(selectSubscriptions);
    const subscriptionId =
        query.id && query.type === "group"
            ? subscriptions.find((subscription) => subscription.groupIds.includes(Number(query.id)))?.id
            : null;
    const invitedGroupWorkspace = workspaces.find((workspace) => workspace.subscription === subscriptionId);

    const redirectToGroup = useCallback(() => {
        if (query.id) {
            dispatch(setCurrentWorkspaceId(invitedGroupWorkspace?.id));

            navigate("/dashboard/devices");
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: Number(query.id),
                }),
            );
        }
    }, [dispatch, query.id, navigate, invitedGroupWorkspace?.id]);

    const redirectToWorkspace = useCallback(() => {
        void dispatch(fetchWorkspaces()).then(() => {
            dispatch(setCurrentWorkspaceId(Number(query.id)));
            navigate("/account/workspaces");
        });
    }, [dispatch, query.id, navigate]);

    const handleRedirect = useCallback(() => {
        if (confirmationUrl) {
            window.location.href = confirmationUrl;
        } else if (query.type === "group") {
            redirectToGroup();
        } else if (query.type === "workspace") {
            redirectToWorkspace();
        }
    }, [confirmationUrl, query.type, redirectToGroup, redirectToWorkspace]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (countdown === 0) {
            handleRedirect();
            return;
        }
        if (!loading) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown, handleRedirect, loading]);

    useEffect(() => {
        const processInvitation = async () => {
            if (
                typeof query.email === "string" &&
                typeof query.token === "string" &&
                typeof query.type === "string" &&
                typeof query.id === "string"
            ) {
                try {
                    const response = await handleInvitation({
                        email: query.email,
                        token: query.token,
                        type: query.type,
                        id: query.id,
                    });
                    if (response.status === 200) {
                        const {
                            needEmailConfirmation,
                            id: confirmationId,
                            token: confirmationToken,
                        } = response.data.model;
                        if (needEmailConfirmation) {
                            const url =
                                "/account/confirm?id=" +
                                encodeURIComponent(confirmationId) +
                                "&token=" +
                                encodeURIComponent(confirmationToken) +
                                "&email=" +
                                encodeURIComponent(query.email);
                            setConfirmationUrl(url);
                        } else {
                            await dispatch(fetchWorkspaces());
                        }
                    } else if (response.status === 201) {
                        window.location.href = response.headers.location;
                    }
                } catch (e) {
                    const err = e as Error;
                    setError({
                        message:
                            "Failed to accept the invitation. Please try again. If the issue persists, contact our team at support@emteria.com",
                        reason: `Reason: ${err.message}`,
                    });
                } finally {
                    setLoading(false);
                }
            }
        };

        void processInvitation();
    }, [dispatch, query.email, query.id, query.token, query.type]);

    return (
        <Container className={classes.root}>
            <Box className={classes.box}>
                <img src={logo.fullLogo} alt={logo.alt} className={classes.logo} />
                {loading ? (
                    <Box className={classes.loadingBox}>
                        <LoadingIcon size={IconSize.large} />
                    </Box>
                ) : (
                    <>
                        {error.message ? (
                            <>
                                <Typography variant="h6">{error.message}</Typography>
                                <Typography variant="h6" className={classes.topSpacing}>
                                    {error.reason}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="h6">Your invitation from the email has been accepted.</Typography>
                                <Typography variant="body1" className={classes.topSpacing}>
                                    You are added to {query.type === "group" ? "group: " : "workspace: "}
                                    <span className={classes.inviationTarget}>{query.name}</span>.
                                </Typography>
                            </>
                        )}
                        <Typography variant="body1" className={classes.topSpacing}>
                            You will be redirected to your dashboard shortly.
                        </Typography>
                        <Typography variant="body1" className={classes.countdown}>
                            Redirecting in {countdown}...
                        </Typography>
                        <Typography variant="body2" className={classes.link} onClick={handleRedirect}>
                            Move now
                        </Typography>
                    </>
                )}
            </Box>
        </Container>
    );
};

export default InvitationPage;
