import React from "react";
import { useField, useForm } from "react-final-form";
import { shallowEqual } from "react-redux";
import { androidVersionOptions } from "src/data/products";
import { useSelector } from "src/store";
import { selectGroupById } from "src/ui/containers/dashboard/groups/store/selectors";
import { useVisibilityOptions } from "src/ui/containers/dashboard/shared/useVisibilityOptions";
import { selectWorkspace, selectWorkspaceById } from "src/ui/containers/dashboard/workspaces/store/selectors";
import { Tooltip } from "src/ui/shared/Tooltip";

import { selectFilesWithZipFilter } from "@dashboard/files/store/selectors";
import { Product, ProductBootAnimation } from "@dashboard/products/types";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Box, InputAdornment, Theme, Typography } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import makeStyles from "@mui/styles/makeStyles";
import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
} from "@provisioning/components/CollapsibleSection";
import { EntityId } from "@reduxjs/toolkit";
import { CategoriesSelectNoForm, SelectField, UncontrolledSelectField } from "@shared/form/SelectField";
import { TextField, UncontrolledTextField } from "@shared/form/TextField";

import { BuildVariantLabel } from "../../Builds/shared";
import { HardwareInput } from "./HardwareInput";
import { ModuleSection } from "./ModuleSection/ModuleSection";
import { ProjectSection } from "./ProjectSection/ProjectSection";
import { VariableSection } from "./VariableSection";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        flex: 1,
    },
    section: { display: "flex", gap: 20, flexDirection: "column" },
    collapsibleSection: { display: "flex", gap: 20, flexDirection: "column", paddingBottom: 20 },
    hintSection: { display: "flex", gap: 20, alignItems: "center" },
    visibilitySelect: { backgroundColor: theme.palette.white[50] },
    buttonSection: { display: "flex", gap: 12, marginTop: 20 },
}));

const generateVersionOptions = (value: string | undefined) => {
    const versionNames = Object.keys(androidVersionOptions);
    const versions =
        value && versionNames.includes(value) ? androidVersionOptions[value] : ["15", "14", "13", "12", "11"];
    return versions.map((version) => ({
        label: version,
        value: version,
    }));
};

export const buildVariantOptions = [
    { label: "User ", value: "user" },
    { label: "Debug", value: "userdebug" },
    { label: "Engineering ", value: "eng" },
];

export const defaultBuildVariantOption = "user";

type Props = {
    product: Product | undefined;
};
export const Recipes = (props: Props) => {
    const classes = useStyles();
    const form = useForm<Product>();
    const formErrors = form.getState().errors || {};
    const { input: provisioningIdInput } = useField<number | null>("customBuildConfig.provisioningId");
    const { input: bootAnimationInput } = useField<ProductBootAnimation | undefined>("customBuildConfig.bootanimation");
    const { input: rootDeviceInput } = useField<string | undefined>("customBuildConfig.rootDevice");
    const { input: compatibilityDeviceInput } = useField<string | undefined>("customBuildConfig.compatibility");
    const { input: groupIdField } = useField<number | undefined>("groupId");
    const { input: codenameField } = useField("codename");
    const { input: androidVersionField } = useField("androidVersion");

    const workspace = useSelector(selectWorkspace);
    const visibilityId = (groupIdField.value || workspace?.id) as EntityId;
    const visibilityWorkspace = useSelector((state) => selectWorkspaceById(state, visibilityId));
    const visibilityGroup = useSelector((state) => selectGroupById(state, visibilityId));
    const visibilityOptions = useVisibilityOptions();
    const isEditing = !!props.product;

    const workspaceZipFiles = useSelector(selectFilesWithZipFilter, shallowEqual);
    const provisionings = useSelector(selectProvisionings);

    const bootAnimationFiles = workspaceZipFiles.map((item) => ({
        label: item.filename,
        value: item.id,
    }));
    const provisioningFiles = provisionings.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    const onProvisioningIdChange: SelectInputProps["onChange"] = (e) => {
        if (!e.target.value) {
            provisioningIdInput.onChange(null);
        }
        if (e.target.value) {
            provisioningIdInput.onChange(+e.target.value);
        }
    };
    const onBootAnimationChange: SelectInputProps["onChange"] = (e) => {
        if (e.target.value === undefined) {
            bootAnimationInput.onChange(undefined);
            return;
        }

        if (e.target.value) {
            bootAnimationInput.onChange({
                source: {
                    type: "storage",
                    fileId: e.target.value,
                },
            });
        }
    };
    const onVersionChange: SelectInputProps["onChange"] = (e) => {
        const value = e.target.value;
        if (typeof value === "string") {
            form.batch(() => {
                androidVersionField.onChange(value);
                compatibilityDeviceInput.onChange(`>=${value}`);
            });
        }
    };
    const onVisibilityChange: SelectInputProps["onChange"] = (e) => {
        const value = e.target.value;

        if (value === workspace?.id) {
            groupIdField.onChange(null);
        }
        if (value !== workspace?.id) {
            groupIdField.onChange(value);
        }
    };
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (codenameField.value === "") {
            codenameField.onChange(e.target.value.toLowerCase().replace(/ /g, "_"));
        }
    };

    return (
        <Box className={classes.container}>
            <TextField
                name="customBuildConfig.productName"
                label="Product name"
                placeholder="Android Box 4001"
                onBlur={onBlur}
                fullWidth
                required
            />

            <Box className={classes.hintSection}>
                <TextField
                    name="codename"
                    label="Codename"
                    placeholder="android_box_4001"
                    fullWidth
                    required
                    disabled={isEditing}
                    style={{ flex: 1 }}
                    InputProps={
                        !isEditing
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          <span>{workspace?.guid ? `${workspace.guid}_` : ""}</span>
                                      </InputAdornment>
                                  ),
                              }
                            : {}
                    }
                />

                <Tooltip title="All codenames will have your workspace GUID as prefix. Codenames must be alphanumerical, they may contain underscores. Codename is very important - they cannot be changed once a device is flashed.">
                    <i className="fas fa-info-circle" />
                </Tooltip>
            </Box>

            <Box>
                <CollapsibleProvider initialValue={false}>
                    <CollapsibleSectionSummary>
                        <Typography>OS configuration</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <Box className={classes.collapsibleSection} style={{ marginTop: 8 }}>
                            <HardwareInput />

                            <UncontrolledSelectField
                                label="Android version"
                                value={androidVersionField.value}
                                options={generateVersionOptions(rootDeviceInput.value)}
                                errorMessage={formErrors["androidVersion"]}
                                onChange={onVersionChange}
                                fullWidth
                            />

                            <UncontrolledSelectField
                                label="Provisioning settings"
                                clearLabel="No provisioning"
                                value={provisioningIdInput.value || undefined}
                                options={provisioningFiles}
                                errorMessage={formErrors["customBuildConfig.provisioningId"]}
                                onChange={onProvisioningIdChange}
                                disabled={!provisioningFiles.length}
                                fullWidth
                            />

                            <UncontrolledSelectField
                                label="Boot animation"
                                clearLabel="No boot animation"
                                value={bootAnimationInput.value?.source?.fileId}
                                options={bootAnimationFiles}
                                errorMessage={formErrors["customBuildConfig.bootanimation"]}
                                onChange={onBootAnimationChange}
                                disabled={!bootAnimationFiles.length}
                                placeholder="Select boot animation file"
                                fullWidth
                            />
                            <SelectField
                                name="customBuildConfig.uiSettings.headlessMode"
                                label="Headless mode"
                                options={[
                                    { label: "Product has a display", value: "no" },
                                    { label: "Product has no display", value: "yes" },
                                ]}
                                fullWidth
                            />
                            <SelectField
                                name="customBuildConfig.uiSettings.kioskMode"
                                label="Kiosk mode"
                                options={[
                                    { label: "UI is not restricted", value: "no" },
                                    { label: "Hide status and navigation bars", value: "yes" },
                                ]}
                                fullWidth
                            />

                            <Box className={classes.buttonSection}>
                                <ProjectSection />
                                <ModuleSection />
                            </Box>
                        </Box>
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>

                <CollapsibleProvider initialValue={false}>
                    <CollapsibleSectionSummary>
                        <Typography>Build configuration</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <Box className={classes.collapsibleSection} style={{ marginTop: 8 }}>
                            <Box className={classes.hintSection}>
                                {isEditing ? (
                                    <UncontrolledTextField
                                        name="groupId"
                                        label="Visibility"
                                        errorMessage={formErrors["groupId"]}
                                        value={visibilityWorkspace?.name || visibilityGroup?.name}
                                        disabled
                                        fullWidth
                                    />
                                ) : (
                                    <CategoriesSelectNoForm
                                        name="groupId"
                                        label="Visibility"
                                        options={visibilityOptions}
                                        defaultValue={visibilityId}
                                        errorMessage={formErrors["groupId"]}
                                        onChange={onVisibilityChange}
                                        className={classes.visibilitySelect}
                                        fullWidth
                                    />
                                )}

                                <Tooltip title="Products cannot be moved once they are created. Only devices in the same group will see updates for this product.">
                                    <i className="fas fa-info-circle" />
                                </Tooltip>
                            </Box>
                            <Box className={classes.hintSection}>
                                <SelectField
                                    fullWidth
                                    label="Build variant"
                                    name="customBuildConfig.buildVariant"
                                    options={buildVariantOptions}
                                    defaultValue={defaultBuildVariantOption}
                                />
                                <BuildVariantLabel />
                            </Box>
                            <Box className={classes.section}>
                                <UncontrolledTextField
                                    label="Compatibility"
                                    value={compatibilityDeviceInput.value}
                                    onChange={compatibilityDeviceInput.onChange}
                                    errorMessage={formErrors["customBuildConfig.compatibility"]}
                                    required
                                    fullWidth
                                />
                                <TextField
                                    label="Nightly period"
                                    name="nightlyPeriod"
                                    placeholder="e.g. 7 (nightly build triggered once a week)"
                                    fullWidth
                                />
                            </Box>
                        </Box>

                        <Box className={classes.buttonSection}>
                            {props.product ? <VariableSection productId={props.product.id} /> : null}
                        </Box>
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            </Box>
        </Box>
    );
};
