import { styled, Typography } from "@mui/material";

export const CustomTileContainer = styled("div")(({ theme }) => ({
    minHeight: 104,
    border: `1px solid ${theme.palette.shadow[150]}`,
    borderRadius: 8,
    backgroundColor: theme.palette.white[50],
    padding: "8px 16px",
}));
export const CustomTileTitle = styled(Typography)(({ theme }) => ({
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.black[100],
    marginBottom: 4,
}));
export const CustomTileText = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.shadow[350],
}));
export const CustomTileLink = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    cursor: "pointer",
    marginBottom: 5,
    color: theme.palette.blue[150],
    "&:hover": {
        color: theme.palette.yellow[150],
    },
}));
