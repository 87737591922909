import { RootState } from "src/store";
import { selectGroupsEntities } from "src/ui/containers/dashboard/groups/store/selectors";
import { selectLicensesEntities } from "src/ui/containers/dashboard/licenses/store/selectors";

import { localState } from "@dashboard/devices/store";
import { createSelector } from "@reduxjs/toolkit";

type DetailsTypes = "subscription" | "group" | "license" | "device";
export type DetailsState = {
    isOpen: boolean;
    type: DetailsTypes | null;
    selectedId: number | null;
};

export const selectDetailsState = (state: RootState): DetailsState => localState(state).details;

const selectDetailsType = (state: RootState): DetailsState["type"] => selectDetailsState(state)?.type;

const selectDetailsSelectedId = (state: RootState): number | null => selectDetailsState(state)?.selectedId;

export const selectDetailsIsGroup = (state: RootState): boolean => selectDetailsType(state) === "group";
export const selectDetailsIsLicense = (state: RootState): boolean => selectDetailsType(state) === "license";

export const selectDetailsSelectedData = createSelector(
    selectDetailsType,
    selectDetailsSelectedId,
    selectGroupsEntities,
    selectLicensesEntities,
    (detailsType, selectedId, groups, licenses) => {
        const group = selectedId ? groups[selectedId] : null;
        const license = selectedId ? licenses[selectedId] : null;

        if (detailsType === "group" && group) {
            return { group, license: null };
        }
        if (detailsType === "license" && license) {
            return { group: null, license };
        }
        return { group: null, license: null };
    },
);
