import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Props } from "./Tag";

const getColor = (props: Props, theme: Theme) => {
    switch (props.theme) {
        case "light":
            return [theme.palette.yellow[150], theme.palette.blue[250], theme.palette.yellow[150]];
        default:
            return [theme.palette.blue[250], theme.palette.white[50], theme.palette.black[100]];
    }
};

export default makeStyles((theme: Theme) => ({
    container: (props: Props) => ({
        borderRadius: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: getColor(props, theme)[0],
        padding: "0 2px 0 10px",
    }),
    scopedContainer: {
        padding: 2,
    },
    simpleContainer: {
        padding: "2px 8px",
        maxWidth: 150,
    },
    label: {
        fontSize: 12,
    },
    keyLabel: (props: Props) => ({
        color: getColor(props, theme)[1],
        padding: "2px",
    }),
    simpleKeyLabel: {
        maxWidth: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    valueLabel: {
        marginRight: 6,
        maxWidth: 150,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: theme.palette.blue[250],
        textTransform: "lowercase",
        fontSize: 12,
    },
    iconDark: (props: Props) => ({
        color: getColor(props, theme)[2],
        fontSize: 9,
        "&:hover": {
            color: "white",
        },
    }),
    iconLight: {
        color: theme.palette.white[50],
        fontSize: 9,
    },
    valueBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.shadow[50],
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
        padding: "0px 6px",
        textAlign: "center",
    },
    keyBox: {
        marginRight: "5px",
        padding: "0px 4px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: 13,
        height: 13,
        padding: 0,
        marginLeft: 3,
        borderRadius: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        border: "none",
        "&:hover": {
            "& $iconDark": {
                color: theme.palette.white[50],
            },
        },
    },
    key: { paddingRight: 10 },
}));
