import * as yup from "yup";

export const projectValidationSchema = yup.object({
    action: yup.string().oneOf(["add", "remove"], "Invalid action value").required().label("Action"),
    name: yup
        .string()
        .matches(/^[a-zA-Z0-9_/-]+$/, "Module name cannot contain special characters or spaces except '/'")
        .required()
        .label("Name"),
    path: yup
        .string()
        .when("action", {
            is: "add",
            then: (schema) => schema.required(),
        })
        .label("Path"),
    remote: yup.string().label("Remote"),
    revision: yup.string().label("Revision"),
});
export const actionOptions = [
    { value: "add", label: "Add new project" },
    { value: "remove", label: "Remove existing project" },
];
