import exportFromJSON from "export-from-json";
import { SecondaryButton } from "src/ui/shared/CustomButton";
import { Tooltip } from "src/ui/shared/Tooltip";

import { Product } from "@products/types";

type Props = { product: Product };

export const ExportSection = (props: Props) => {
    const handleExportConfig = () => {
        exportFromJSON({
            data: props.product,
            fileName: `config_${props.product.codename}`,
            exportType: exportFromJSON.types.json,
        });
    };

    return (
        <Tooltip title="Export configuration" placement="right-end">
            <SecondaryButton onClick={handleExportConfig} size="small">
                <i className="fa-solid fa-file-arrow-down" style={{ fontSize: 18 }} />
            </SecondaryButton>
        </Tooltip>
    );
};
