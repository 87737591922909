import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    content: {
        minHeight: 300,
        margin: "12px 0px",
        background: theme.palette.shadow[50],
        borderRadius: "6px",
    },
    container: {
        padding: 20,
        minHeight: 400,
        maxHeight: 550,
    },
    dialogTitleBox: {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        fontSize: 20,
    },
    informationText: {
        fontWeight: 600,
    },
    closeBtn: {
        cursor: "pointer",
        color: "black",
        fontSize: 20,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 16,
        padding: "14px 0px",
        maxHeight: 300,
        overflow: "auto",
        overflowX: "hidden",
        position: "relative",
    },
    selectManyContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
    },
    title: {
        marginLeft: 10,
    },
    filterBadge: {
        cursor: "pointer",
        marginLeft: 5,
        paddingRight: 5,
        paddingBottom: 3,
        display: "inline",
    },
    tagIcon: {
        display: "inline",
        color: theme.palette.black[100],
    },
    sortIcon: {
        marginLeft: "8px",
        fontSize: "12px",
        cursor: "pointer",
    },
    sortButtonContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    sortText: {
        marginRight: "4px",
    },
    empty: { height: 150, display: "flex", justifyContent: "center", alignItems: "center" },
}));
