import _ from "lodash";
import React from "react";
import { Form } from "react-final-form";
import UserSettingsService from "src/services/userSettingsService";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { CheckboxField } from "src/ui/shared/form/CheckboxField";
import { Modal } from "src/ui/shared/Modal/Modal";

import { Checkbox, FormControlLabel, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Header } from "@shared/table/TableHeader";
import { Header as TanstackHeader, Table } from "@tanstack/react-table";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        minHeight: 300,
        margin: "12px 0px",
        background: theme.palette.shadow[50],
        borderRadius: "6px",
    },
    informationText: {
        fontWeight: 600,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 16,
        padding: "14px 0px",
        maxHeight: 300,
        overflow: "auto",
        overflowX: "hidden",
        position: "relative",
    },
    selectManyContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    sortIcon: {
        marginLeft: "8px",
        fontSize: "12px",
        cursor: "pointer",
    },
    sortButtonContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    sortText: {
        marginRight: "4px",
    },
    filterBadge: {
        cursor: "pointer",
        marginLeft: 5,
        paddingRight: 5,
        paddingBottom: 3,
        display: "inline",
    },
}));

const changeAllValuesTo = (arr: { key: string; label: string }[], value: boolean) => {
    return arr.reduce((acc, item) => ({ ...acc, [item.key]: value }), {});
};
const getColumnFilters = <T,>(table: Table<T>) => {
    const columnFilters = table.getState().columnFilters.find((item) => item.id === "system")?.value;

    if (Array.isArray(columnFilters)) {
        return columnFilters;
    }
    return [];
};
const getInitialFilters = <T,>(table: Table<T>) => {
    const columnFilters = getColumnFilters(table);

    return {
        system: columnFilters.includes("system"),
        application: columnFilters.includes("application"),
    };
};

type Props<T> = {
    table: Table<T>;
    deviceId: number;
    options: { key: string; label: string }[];
    header: TanstackHeader<T, unknown>;
};

export const FilterHeader = <T,>(props: Props<T>) => {
    const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("asc");
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    const columnFilters = getColumnFilters(props.table);
    const truthyFilters = changeAllValuesTo(props.options, true);
    const falsyFilters = changeAllValuesTo(props.options, false);

    const onSubmit = (values: Record<string, boolean>) => {
        const value = [];

        if (values.application && values.system) {
            value.push("system", "application");
        } else if (values.application && !values.system) {
            value.push("application");
        } else if (!values.application && values.system) {
            value.push("system");
        }

        props.table.setColumnFilters([{ id: "system", value }]);
        UserSettingsService.setWorkspaceValue("licenseSidePanel.appsTab.systemFilter", value);
        setOpen(false);
    };

    const toggleSortOrder = () => {
        setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    };
    const getSortedFilters = (arr: { key: string; label: string }[]) => {
        return _.orderBy(arr, (item) => item.key, sortOrder);
    };

    return (
        <Header {...props.header}>
            <Form<Record<string, boolean>>
                onSubmit={onSubmit}
                initialValues={getInitialFilters(props.table)}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <CustomBadge
                                badgeContent={columnFilters.length}
                                className={classes.filterBadge}
                                style={{ backgroundColor: "transparent" }}
                                onClick={() => setOpen(true)}
                                overlap="rectangular"
                            >
                                <i className="fa-solid fa-filter" />
                            </CustomBadge>
                            <Modal
                                title="Filter applications"
                                open={open}
                                onClose={() => setOpen(false)}
                                endButton={<PrimaryButton onClick={form.submit}>Save</PrimaryButton>}
                            >
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.sortButtonContainer} onClick={toggleSortOrder}>
                                        <Typography className={classes.sortText}>Sort</Typography>
                                        <i
                                            className={`fa-solid ${
                                                sortOrder === "asc" ? "fa-arrow-up" : "fa-arrow-down"
                                            } ${classes.sortIcon}`}
                                        />
                                    </div>

                                    <div className={classes.content}>
                                        <div className={classes.checkboxContainer}>
                                            {getSortedFilters(props.options).map((item) => (
                                                <CheckboxField
                                                    key={item.key}
                                                    name={`["${item.key}"]`}
                                                    label={item.label}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.selectManyContainer}>
                                        {Object.values(values).length ? (
                                            <FormControlLabel
                                                label={<span className={classes.informationText}>Select all</span>}
                                                control={
                                                    <Checkbox
                                                        checked={Object.values(values).every((item) => item)}
                                                        indeterminate={false}
                                                        onChange={(
                                                            _e: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean,
                                                        ) => {
                                                            form.reset(checked ? truthyFilters : falsyFilters);
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </form>
                            </Modal>
                        </form>
                    );
                }}
            />
        </Header>
    );
};
