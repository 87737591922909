import React from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import UserSettingsService from "src/services/userSettingsService";
import { useDispatch, useSelector } from "src/store";

import { Search } from "@dashboard/devices/components/Header/Search";
import { closeBatchMode, closeDetails, showDetails } from "@dashboard/devices/store/index";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import { SecondaryButton } from "@shared/CustomButton";
import { ExpiredWrapper } from "@shared/ExpiredWrapper";
import { HoverRow } from "@shared/table/HoverRow";
import { MinimalTable, MinimalTableCell, MinimalTableHeader } from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import { Tooltip } from "@shared/Tooltip";
import {
    Cell,
    ColumnDef,
    ColumnFiltersState,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { LinkCell } from "../devices/components/Table/Cells";
import { TutorialSection } from "../overview/components/TutorialSection";
import { GroupFilterHeader } from "../shared/components/GroupFilterHeader/GroupFilterHeader";
import { SidePanel } from "../shared/components/SidePanel/SidePanel";
import CreateGroupDialog from "./components/CreateGroupDialog";
import { ActionCell } from "./components/table/ActionCell";
import { ComplianceStatusIcon } from "./components/table/ComplianceStatus";
import { ExpandedCell, ExpandedHeader } from "./components/table/Expander";
import { OnlineStatusIcon } from "./components/table/OnlineStatus";
import TableCell from "./components/table/TableCell";
import { customGlobalFilterFn, useGroupPageNameFilter } from "./GroupPage.utils";
import { useStyles } from "./GroupsPage.style";
import { selectGroupsOverviewData } from "./store/selectors";

export interface GroupOverviewData {
    name: string;
    description: string;
    onlineDevices: string;
    compliantDevices: string;
    numberOfFiles: string;
    numberOfDevices: string;
    id: number;
    isDefault: boolean;
    parentId: number | null;
    children: GroupOverviewData[];
}
const GroupsPage = () => {
    const initialColumnFilters = useGroupPageNameFilter();
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(initialColumnFilters);
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();
    const { groupAbility } = permissionService();
    const tableData = useSelector(selectGroupsOverviewData);
    const workspace = useSelector(selectWorkspace);

    const isExpired = useSelector(selectSubscriptionExpired);

    const tableColumns: ColumnDef<GroupOverviewData, unknown>[] = React.useMemo(
        () => [
            {
                header: (info) => <ExpandedHeader title="Name" {...info} />,
                accessorKey: "name",
                enableSorting: true,
                cell: (info) => (
                    <ExpandedCell {...info}>
                        <TableCell {...info} globalFilter={globalFilter} />
                    </ExpandedCell>
                ),
                filterFn: (row, columnId, filterValue: string[]) => {
                    return filterValue.includes(row.getValue(columnId));
                },
            },
            {
                header: "Description",
                accessorKey: "description",
                cell: (info) => (
                    <LinkCell value={info.getValue<string>()} title="Show group details" searchQuery={globalFilter} />
                ),
                enableSorting: true,
            },
            {
                header: "Devices",
                accessorKey: "numberOfDevices",
                cell: (info) => <TableCell {...info} globalFilter={globalFilter} />,
                enableSorting: true,
                sortingFn: "alphanumeric",
            },
            {
                header: "Online",
                accessorKey: "onlineDevices",
                cell: (info) => (
                    <TableCell {...info} globalFilter={globalFilter}>
                        <OnlineStatusIcon {...info} />
                    </TableCell>
                ),
                enableSorting: true,
                sortingFn: "alphanumeric",
            },
            {
                header: "Compliance",
                accessorKey: "compliantDevices",
                cell: (info) => (
                    <TableCell {...info} globalFilter={globalFilter}>
                        <ComplianceStatusIcon {...info} />
                    </TableCell>
                ),
                enableSorting: true,
            },
            {
                header: "Action",
                accessorKey: "action",
                cell: (info) => <ActionCell {...info} />,
                enableSorting: true,
            },
        ],
        [globalFilter],
    );
    const state = React.useMemo(
        () => ({ globalFilter, expanded, columnFilters }),
        [expanded, globalFilter, columnFilters],
    );

    const table = useReactTable<GroupOverviewData>({
        data: tableData,
        columns: tableColumns,
        state,
        globalFilterFn: customGlobalFilterFn,
        getSubRows: (row) => row.children,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onExpandedChange: setExpanded,
        getExpandedRowModel: getExpandedRowModel(),
    });

    const handleCellClick = (cell: Cell<GroupOverviewData, unknown>) => {
        if (cell.row.original.id && cell.column.id !== "name" && cell.column.id !== "action") {
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: cell.row.original.id,
                }),
            );
        }
    };
    const onGroupFilter = (values: Record<string, boolean>) => {
        const selectedGroups = Object.entries(values)
            .filter(([_, checked]) => checked)
            .map(([groupName]) => groupName);

        if (selectedGroups.length) {
            setColumnFilters([{ id: "name", value: selectedGroups }]);
            UserSettingsService.setWorkspaceValue("groupPage.filter.name", selectedGroups);
        } else {
            setColumnFilters([]);
            UserSettingsService.setWorkspaceValue("groupPage.filter.name", []);
        }
    };
    const getTooltipTitle = () => {
        if (isExpired) {
            return "Subscription expired";
        }
        if (groupAbility(null, workspace).cannot("create", "Group")) {
            return "Your role does not allow you to create groups";
        }
        return "";
    };

    React.useEffect(() => {
        return () => {
            dispatch(closeDetails());
            dispatch(closeBatchMode());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer className={classes.modalContainer}>
            <SidePanel />
            <PageTitleWithIcon title="Groups" iconName="fa-solid fa-folder-tree" />
            <SectionDescription>
                Get detailed insights into the current status of your device groups.
            </SectionDescription>
            {workspace ? (
                <ExpiredWrapper>
                    <React.Fragment>
                        <div className={classes.searchContainer}>
                            <Search
                                defaultValue={globalFilter}
                                handleChange={(value) => setGlobalFilter(String(value))}
                                handleClear={() => setGlobalFilter("")}
                                placeholder="Enter a keyword to search..."
                                style={{ maxWidth: 600 }}
                            />
                            <Tooltip
                                title={getTooltipTitle()}
                                hide={groupAbility(null, workspace).can("create", "Group")}
                            >
                                <SecondaryButton
                                    onClick={() => setCreateDialogOpen(true)}
                                    disabled={groupAbility(null, workspace).cannot("create", "Group")}
                                    startIcon={<i className="fa-solid fa-plus" />}
                                    size="small"
                                >
                                    Add group
                                </SecondaryButton>
                            </Tooltip>
                        </div>
                        <TableContainer component={Paper}>
                            <MinimalTable>
                                <MinimalTableHeader>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <TableRow key={headerGroup.id}>
                                            {headerGroup.headers.map((header) => {
                                                if (header.id === "name") {
                                                    return (
                                                        <GroupFilterHeader
                                                            key={header.id}
                                                            title="Filter Groups"
                                                            header={header}
                                                            table={table}
                                                            onSubmit={onGroupFilter}
                                                            filterWithParent
                                                        />
                                                    );
                                                }
                                                return <Header key={header.id} {...header} />;
                                            })}
                                        </TableRow>
                                    ))}
                                </MinimalTableHeader>
                                <TableBody>
                                    {table.getRowModel().rows.length === 0 ? (
                                        <TableRow>
                                            <MinimalTableCell
                                                colSpan={tableColumns.length}
                                                className={classes.noResultsRow}
                                            >
                                                This workspace has no groups. You can create a new group by using the
                                                button above.
                                            </MinimalTableCell>
                                        </TableRow>
                                    ) : (
                                        table.getRowModel().rows.map((row) => (
                                            <HoverRow key={row.id} {...row}>
                                                {row.getVisibleCells().map((cell) => (
                                                    <MinimalTableCell
                                                        key={cell.id}
                                                        onClick={() => handleCellClick(cell)}
                                                    >
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </MinimalTableCell>
                                                ))}
                                            </HoverRow>
                                        ))
                                    )}
                                </TableBody>
                            </MinimalTable>
                        </TableContainer>
                        <CreateGroupDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
                    </React.Fragment>
                </ExpiredWrapper>
            ) : (
                <TutorialSection />
            )}
        </PageContainer>
    );
};

export default GroupsPage;
