import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { SwitchField } from "src/ui/shared/form/SwitchField";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { SectionDescription } from "@dashboard/shared/styles";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

const useStyles = makeStyles((theme: Theme) => ({
    columnContainer: {
        paddingTop: 24,
        paddingBottom: 8,
        paddingLeft: 15,
        display: "flex",
        justifyContent: "space-between",
    },
    column: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    categoryTitle: {
        fontSize: 22,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

export const Connectivity = () => {
    const { value, onChange } = useCollapsiblePersistence("provisioningPage.collapsedSections", "Connectivity");
    const styles = useStyles();

    const provisionings = useSelector(selectProvisionings, shallowEqual);

    return (
        <CollapsibleProvider initialValue={value} onChange={onChange}>
            <CollapsibleSectionSummary>
                <Typography className={styles.categoryTitle}>Connectivity options</Typography>
            </CollapsibleSectionSummary>
            <CollapsibleSectionDetails>
                <SectionDescription>
                    Configuration options for built-in connectivity components like VNC server or MDM client.
                </SectionDescription>
                <Box className={styles.columnContainer}>
                    <div className={styles.column}>
                        <SwitchField
                            label="Enable SSH Server"
                            name="settings.enableSshServer"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                        <SwitchField
                            label="Enable MDM client"
                            name="settings.enableMdmClient"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                        <SwitchField
                            label="Enable SFTP Server"
                            name="settings.enableSftpServer"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                    </div>

                    <div className={styles.column}>
                        <SwitchField
                            label="Enable Bluetooth"
                            name="settings.enableBluetooth"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                        <SwitchField
                            label="Enable Wifi"
                            name="settings.enableWifi"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                    </div>

                    <div className={styles.column}>
                        <SwitchField
                            label="Enable ADB"
                            name="settings.enableAdbServer"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                        <SwitchField
                            label="Enable VNC"
                            name="settings.enableVncServer"
                            labelPlacement="end"
                            disabled={!provisionings.length}
                        />
                    </div>
                </Box>
            </CollapsibleSectionDetails>
        </CollapsibleProvider>
    );
};
