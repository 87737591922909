import _ from "lodash";
import React from "react";
import { shallowEqual } from "react-redux";
import UserSettingsService from "src/services/userSettingsService";
import { RootState, useDispatch, useSelector } from "src/store";
import { TagFilterHeader } from "src/ui/containers/dashboard/shared/components/TagFilterHeader";
import { HoverRow } from "src/ui/shared/table/HoverRow";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Header } from "src/ui/shared/table/TableHeader";

import { FilterHeader } from "@dashboard/shared/components/SidePanel/GroupPanel/FileTab/FilterHeader";
import { getBadgeContent, getColumnFilters } from "@dashboard/shared/components/SidePanel/shared/FileTab.utils";
import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { createRolloutTag, filterByTags } from "@dashboard/shared/tags";
import useFetchInterval from "@devices/hooks/useFetchInterval";
import { Device } from "@devices/types";
import { fetchFiles } from "@files/store";
import { selectFiles, selectFilesByGroupIdWithShared, selectFilesListStatus } from "@files/store/selectors";
import { createVersionCodeTag, hasApkMetadata, hasUnsuccessfulAnalyzer, isApk, normalizeFiles } from "@files/utils";
import { Box, Paper, TableBody, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getEnv } from "@services/configService";
import {
    ColumnDef,
    ColumnFilter,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { ActionsCell } from "./ActionsCell";

const useStyles = makeStyles({
    content: { maxHeight: "100%", marginBottom: 60 },
    tagsBox: {
        overflowY: "auto",
        maxWidth: 400,
        maxHeight: 70,
        display: "flex",
        flexWrap: "wrap",
        gap: 4,
        padding: 4,
    },
});

const useInitialFilters = () => {
    const filters: ColumnFilter[] = [];
    const tagsFilter = UserSettingsService.getLicenseSidePanelFileTabTagsFilter();
    const typeFilter = UserSettingsService.getLicenseSidePanelFileTabTypeFilter();

    if (tagsFilter.length) {
        filters.push({ id: "tags", value: tagsFilter });
    }
    if (typeFilter.length) {
        filters.push({ id: "ownerId", value: typeFilter });
    }
    return filters;
};

interface Props {
    device: Device;
    groupId: number;
}

const FileTab = (props: Props) => {
    const initialFilters = useInitialFilters();
    const [columnFilters, setColumnFilters] = React.useState<ColumnFilter[]>(initialFilters);
    const classes = useStyles();
    const dispatch = useDispatch();

    const status = useSelector(selectFilesListStatus);

    const accessibleFiles = useSelector(
        (state: RootState) => selectFilesByGroupIdWithShared(state, props.groupId),
        shallowEqual,
    );
    const normalizedApkFiles = React.useMemo(
        () => normalizeFiles(accessibleFiles.filter((item) => isApk(item.filename))),
        [accessibleFiles],
    );

    const allFiles = useSelector(selectFiles, shallowEqual);
    const normalizedDeviceFiles = React.useMemo(
        () => normalizeFiles(allFiles.filter((item) => item.ownerId === String(props.device.id))),
        [allFiles, props.device.id],
    );

    const data = React.useMemo(
        () => normalizeFiles([...normalizedDeviceFiles, ...normalizedApkFiles]),
        [normalizedDeviceFiles, normalizedApkFiles],
    );

    const tagsArray = _.compact(_.flatMap(data, (file) => file.metadata?.user?.tags));

    const columns: ColumnDef<API.File, unknown>[] = React.useMemo(
        () => [
            {
                header: "Package name",
                enableSorting: true,
                accessorKey: "filename",
                cell: (info) => {
                    const title = hasApkMetadata(info.row.original)
                        ? info.row.original.metadata?.file?.apk?.manifest?.package
                        : info.row.original.filename;
                    return (
                        <Box>
                            <Typography>{title}</Typography>
                        </Box>
                    );
                },
            },
            {
                header: "Uploader",
                accessorKey: "ownerId",
                enableSorting: true,
                filterFn: (row, _columnId, filterValue: ("user" | "device")[]) => {
                    const isUser = filterValue.includes("user");
                    const isDevice = filterValue.includes("device");
                    const isUploadedByDevice = row.original.ownerId === String(props.device.id);

                    if (!filterValue.length) {
                        return true;
                    }

                    if (isUser && isDevice) {
                        return true;
                    }

                    if (isUser && !isUploadedByDevice) {
                        return true;
                    }

                    if (isDevice && isUploadedByDevice) {
                        return true;
                    }

                    return false;
                },
                cell: (info) => {
                    return (
                        <Box>
                            {info.row.original.ownerId === String(props.device.id) ? (
                                <Tooltip title="Device">
                                    <i className="fa-solid fa-microchip" />
                                </Tooltip>
                            ) : (
                                <Tooltip title="User">
                                    <i className="fa-solid fa-user" />
                                </Tooltip>
                            )}
                        </Box>
                    );
                },
            },
            {
                header: "Tags",
                accessorKey: "tags",
                enableSorting: false,
                meta: { filterVariant: "filesTags" },
                filterFn: filterByTags,
                cell: (info) => {
                    const versionCodeTag = createVersionCodeTag(info.row.original);
                    const rolloutTag = createRolloutTag(info.row.original.rolloutPercentage);
                    const removableTags = info.row.original.metadata?.user?.tags;

                    return (
                        <div className={classes.tagsBox}>
                            {removableTags?.length
                                ? removableTags.map((item) => <Tag key={item.key} item={item} />)
                                : null}
                            {versionCodeTag ? (
                                <Tag key={versionCodeTag.key} item={versionCodeTag} theme="light" />
                            ) : null}
                            {rolloutTag ? <Tag key={rolloutTag.key} item={rolloutTag} theme="light" /> : null}
                        </div>
                    );
                },
            },
            {
                header: "Action",
                accessorKey: "action",
                enableSorting: false,
                cell: (info) => <ActionsCell file={info.row.original} device={props.device} />,
            },
        ],
        [props.device],
    );

    const table = useReactTable<API.File>({
        data,
        columns,
        state: { columnFilters },
        initialState: { sorting: [{ id: "filename", desc: true }] },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
    });

    const reFetchFiles = async () => {
        try {
            await dispatch(fetchFiles());
        } catch (err) {
            if (getEnv() === "development") {
                console.error("[Files] reFetchFiles failed: ", err);
            }
        }
    };
    const setTagFilters: React.Dispatch<React.SetStateAction<Record<string, boolean>>> = (values) => {
        setColumnFilters((prev) => {
            const prevFiltersWithoutTags = prev.filter((filter) => filter.id !== "tags");
            const value = Object.keys(_.pickBy(values, (item) => item));
            UserSettingsService.setWorkspaceValue("licenseSidePanel.fileTab.tagsFilter", value);
            return [{ id: "tags", value }, ...prevFiltersWithoutTags];
        });
    };
    const setTypeFilters = (values: Record<string, boolean>) => {
        setColumnFilters((prev) => {
            const prevFiltersWithoutTags = prev.filter((filter) => filter.id !== "ownerId");
            const value = Object.keys(_.pickBy(values, (item) => item));
            UserSettingsService.setWorkspaceValue("licenseSidePanel.fileTab.typeFilter", value);
            return [{ id: "ownerId", value }, ...prevFiltersWithoutTags];
        });
    };
    const getInitialFilters = () => {
        const filters = getColumnFilters("ownerId", table);

        return {
            user: filters.includes("user"),
            device: filters.includes("device"),
        };
    };

    useFetchInterval(reFetchFiles, {
        condition: hasUnsuccessfulAnalyzer([...normalizedDeviceFiles, ...normalizedApkFiles]),
    });

    if (status === "pending" && !data.length) {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.content}>
                <PaneElement.Header>Available files</PaneElement.Header>
                <TableContainer component={Paper}>
                    <MinimalTable>
                        <MinimalTableHeader>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        if (header.id === "action") {
                                            return (
                                                <MinimalTableHeaderCell key={header.id} align="right">
                                                    Actions
                                                </MinimalTableHeaderCell>
                                            );
                                        }
                                        if (header.id === "tags") {
                                            return (
                                                <TagFilterHeader
                                                    key={header.id}
                                                    header={header}
                                                    tags={tagsArray}
                                                    columnFilters={columnFilters}
                                                    onSetFilters={setTagFilters}
                                                />
                                            );
                                        }
                                        if (header.id === "ownerId") {
                                            return (
                                                <FilterHeader<API.File>
                                                    key={header.id}
                                                    header={header}
                                                    badgeContent={getBadgeContent("ownerId", columnFilters)}
                                                    form={{
                                                        onSubmit: setTypeFilters,
                                                        initialValues: getInitialFilters(),
                                                    }}
                                                    options={[
                                                        { key: "user", label: "User files" },
                                                        { key: "device", label: "Device files" },
                                                    ]}
                                                />
                                            );
                                        }
                                        return <Header key={header.id} {...header} />;
                                    })}
                                </TableRow>
                            ))}
                        </MinimalTableHeader>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <React.Fragment key={row.id}>
                                    <HoverRow {...row}>
                                        {row.getVisibleCells().map((cell) => (
                                            <MinimalTableCell
                                                key={cell.id}
                                                onClick={() => null}
                                                style={{ overflow: "visible" }}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </MinimalTableCell>
                                        ))}
                                    </HoverRow>
                                </React.Fragment>
                            ))}
                            {!table.getRowModel().rows.length ? (
                                <TableRow>
                                    <MinimalTableCell colSpan={columns.length + 1} align="center">
                                        No files
                                    </MinimalTableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </MinimalTable>
                </TableContainer>
            </Box>
        </PaneElement.Container>
    );
};

export default FileTab;
