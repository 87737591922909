import { FormApi } from "final-form";
import { Form, useForm } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";
import { Modal } from "src/ui/shared/Modal/Modal";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { PrimaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { createProvisioning } from "../store";
import { Provisioning } from "../types";
import { defaultProvisioning } from "../utils";

type FormValues = Provisioning;

type Props = {
    open: boolean;
    onClose: () => void;
};

const CreateProvisioningDialog = (props: Props) => {
    const dispatch = useDispatch();
    const currentWorkspace = useSelector(selectWorkspace);
    const parentForm = useForm();

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const provisioningNames = provisionings.map((item) => item.name);

    const onValidate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.name) {
            errors.name = "Settings name is not allowed to be empty";
        }
        if (provisioningNames.includes(values.name)) {
            errors.name = "Settings name must be unique";
        }

        return errors;
    };
    const onSubmit = async (values: FormValues) => {
        if (currentWorkspace?.id) {
            const { name, settings } = values;
            await dispatch(createProvisioning({ name, workspaceId: currentWorkspace.id, settings }))
                .unwrap()
                .then((newProvisioning) => {
                    showSuccessToast("Created new provisioning settings");
                    parentForm.initialize(newProvisioning);
                })
                .catch(({ message = "Update failed" }) => showErrorToast(message))
                .finally(() => props.onClose());
        }
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            void form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={onValidate}
            initialValues={defaultProvisioning}
            render={({ handleSubmit, submitting, valid, form }) => (
                <Modal
                    title="Create new provisioning settings"
                    onKeyDown={(e) => onKeyDown(e, form)}
                    {...props}
                    endButton={
                        <PrimaryButton onClick={form.submit} disabled={submitting || !valid} loading={submitting}>
                            Create
                        </PrimaryButton>
                    }
                >
                    <form onSubmit={handleSubmit}>
                        <TextField label="Settings name" fullWidth name="name" />
                    </form>
                </Modal>
            )}
        />
    );
};

export default CreateProvisioningDialog;
