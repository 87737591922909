import _ from "lodash";
import { RootState } from "src/store";

import { ReleaseFilterType } from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import { localState } from "@dashboard/downloads/store";
import { createSelector } from "@reduxjs/toolkit";

export type FilterState = {
    properties: Record<ReleaseFilterType, string[]>;
};

const defaultFilter: FilterState = {
    properties: {
        "releasesCodename": [],
        "releasesProduct": [],
        "channel": ["Live", "Beta"],
    },
};

const selectFilterProperties = (state: RootState): Record<ReleaseFilterType, string[]> =>
    localState(state).filters?.properties ?? {};

const selectMergedFiltersByProperty = () => {
    return createSelector(
        [
            selectFilterProperties,
            (_state: RootState, property: ReleaseFilterType) => property,
            (_state: RootState, _property: ReleaseFilterType, options: string[]) => options,
        ],
        (properties, property, options) => {
            const currentFilterProperties = properties[property] ?? [];
            return _.fromPairs(options.map((key) => [key, currentFilterProperties.includes(key)]));
        },
    );
};

export { defaultFilter, selectMergedFiltersByProperty };
