import { diff } from "deep-diff";
import { FormApi } from "final-form";
import _ from "lodash";
import { Form } from "react-final-form";
import { RootState, useDispatch, useSelector } from "src/store";
import { Modal } from "src/ui/shared/Modal/Modal";

import { updateFileMetadata, updateFileRollout } from "@files/store";
import { DialogProps, InputAdornment } from "@mui/material";
import { PrimaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { TagManagementSection } from "./TagManagementSection";

type FormValues = {
    rolloutPercentage: number;
    tags: API.Tag[];
};

type Props = Omit<DialogProps, "onClose"> & {
    item?: API.File;
    onClose: (event?: object) => void;
};

const getFormDiff = (form: FormApi<FormValues>) => {
    const rolloutPercentageChanged = form.getFieldState("rolloutPercentage")?.dirty;
    const tagsChanged = diff(
        _.keyBy(form.getState().initialValues.tags, "key"),
        _.keyBy(form.getState().values.tags, "key"),
    );
    const pristine = !rolloutPercentageChanged && !tagsChanged;

    return { pristine, rolloutPercentageChanged, tagsChanged };
};
const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};
    if (typeof values.rolloutPercentage !== "number") {
        errors.rolloutPercentage = "Rollout must be a number";
    }
    if (values.rolloutPercentage < 0 || values.rolloutPercentage > 100) {
        errors.rolloutPercentage = "Rollout must be in the range from 0 to 100";
    }
    return errors;
};

export const FileSettingsDialog = (props: Props) => {
    const dispatch = useDispatch();
    const status = useSelector((state: RootState) => state.files.status);

    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        if (!props.item) {
            return;
        }

        const { rolloutPercentageChanged, tagsChanged } = getFormDiff(form);

        try {
            if (rolloutPercentageChanged) {
                await dispatch(
                    updateFileRollout({ id: props.item.id, rolloutPercentage: +values.rolloutPercentage }),
                ).unwrap();
            }
            if (tagsChanged) {
                await dispatch(
                    updateFileMetadata({
                        fileId: props.item.id,
                        metadata: { ...props.item.metadata?.user, tags: values.tags },
                    }),
                ).unwrap();
            }
            props.onClose();
            showSuccessToast("File settings updated successfully");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong during updating settings");
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !getFormDiff(form).pristine && status !== "pending") {
            await form.submit();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
                rolloutPercentage: props.item?.rolloutPercentage,
                tags: props.item?.metadata?.user?.tags || [],
            }}
            render={({ handleSubmit, form, values }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        title="File settings"
                        {...props}
                        onKeyDown={(e) => onKeyDown(e, form)}
                        endButton={
                            <PrimaryButton
                                onClick={form.submit}
                                disabled={status === "pending" || getFormDiff(form).pristine}
                                loading={status === "pending"}
                            >
                                Save
                            </PrimaryButton>
                        }
                    >
                        <TagManagementSection tags={values.tags} onChange={(data) => form.change("tags", data)} />
                        <TextField
                            name="rolloutPercentage"
                            type="number"
                            label="Rollout"
                            fullWidth
                            required
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            onChange={(e) => form.change("rolloutPercentage", Number(e.target.value))}
                        />
                    </Modal>
                </form>
            )}
        />
    );
};
