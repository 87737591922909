import React, { ForwardedRef, InputHTMLAttributes } from "react";
import { useField, UseFieldConfig } from "react-final-form";

type Value = string | number | readonly string[] | undefined;
interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    config?: UseFieldConfig<Value, Value>;
}

export const FileSelectField = React.forwardRef(
    ({ name, config, ...rest }: Props, ref?: ForwardedRef<HTMLInputElement>) => {
        const { input } = useField(name, config);

        return (
            <input
                ref={ref}
                type="file"
                onChange={({ target }) => input.onChange(target.files)}
                {...rest}
                style={{ display: "none", ...rest.style }}
            />
        );
    },
);

FileSelectField.displayName = "FileSelectField";
