import { useState } from "react";
import { DangerButton, PrimaryButton } from "src/ui/shared/CustomButton";
import { Modal } from "src/ui/shared/Modal/Modal";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";

interface Props {
    title?: string;
    open?: boolean;
    onConfirmDisabled?: boolean;
    dangerButton?: boolean;
    primaryActionText?: string;
    onConfirm: () => Promise<void> | void;
    onClose: () => void;
}

export function ConfirmDialog({
    title = "Confirmation",
    open = false,
    dangerButton = false,
    onConfirmDisabled,
    primaryActionText = "Confirm",
    onConfirm,
    onClose,
    children,
}: React.PropsWithChildren<Props>): JSX.Element {
    const [loading, setLoading] = useState(false);

    const handleConfirm = async () => {
        try {
            setLoading(true);
            await onConfirm();
            onClose();
        } catch (error) {
            if (error instanceof Error) {
                showErrorToast(error.message || "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !onConfirmDisabled && !loading) {
            await handleConfirm();
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onKeyDown={onKeyDown}
            title={title}
            endButton={
                dangerButton ? (
                    <DangerButton onClick={handleConfirm} disabled={onConfirmDisabled || loading} loading={loading}>
                        {primaryActionText}
                    </DangerButton>
                ) : (
                    <PrimaryButton onClick={handleConfirm} disabled={onConfirmDisabled || loading} loading={loading}>
                        {primaryActionText}
                    </PrimaryButton>
                )
            }
        >
            {children}
        </Modal>
    );
}
