import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { Modal } from "src/ui/shared/Modal/Modal";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { Device, Group } from "@dashboard/devices/types";
import TabContext from "@mui/lab/TabContext";
import MuiTabPanel from "@mui/lab/TabPanel";
import { Box, styled, Typography } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiTab from "@mui/material/Tab";
import MuiTabs, { TabsProps } from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";

import { useCommands } from "./hooks";
import { ComplexCommands } from "./Tabs/ComplexCommands";
import { Examples } from "./Tabs/Examples";
import { JsonEditor } from "./Tabs/JsonEditor";

const AppBar = styled(MuiAppBar)({
    boxShadow: "none",
});
const Tabs = styled(MuiTabs)(({ theme }) => ({
    minHeight: "36px",
    "& .MuiTabs-indicator": {
        backgroundColor: theme.palette.blue[150],
    },
}));
const Tab = styled(MuiTab)(({ theme }) => ({
    minWidth: "200px",
    textTransform: "capitalize",
    fontWeight: 600,
    color: theme.palette.blue[150],
    minHeight: "36px",
}));
const TabPanel = styled(MuiTabPanel)({
    padding: 0,
});

const useStyles = makeStyles({
    scrollBtn: {
        width: "20px",
        transition: "width 1s",
        "&.Mui-disabled": {
            width: 0,
        },
    },
    tabsContent: {
        maxHeight: "calc(100vh - 300px)",
        maxWidth: "calc(100vh - 300px)",
        overflowY: "auto",
    },
});

type Props = {
    title: string;
    device?: Device;
    group?: Group;
    isOpen: boolean;
    toggleDialog: VoidFunction;
    isPolicy?: boolean;
};

export type FormValues = {
    commandType: string;
};

export const NewCommandDialog = (props: Props) => {
    const classes = useStyles();

    const submitRef = useRef<HTMLButtonElement>(null);
    const [tabValue, setTabValue] = useState(0);
    const {
        loadingState: { createLoading, updatePolicyLoading },
    } = useCommands();
    const loading = createLoading || updatePolicyLoading;

    const editorRef = React.useRef<HTMLDivElement>(null);

    const { title, toggleDialog, isOpen } = props;

    const handleChange: TabsProps["onChange"] = (_event, value) => {
        if (typeof value === "number") {
            setTabValue(value);
        }
    };

    const a11yProps = (index: number) => {
        return {
            id: `details-tab-${index}`,
            "aria-controls": `details-tabpanel-${index}`,
        };
    };

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                submitRef?.current?.click();
            }
        };

        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <Modal
            open
            title={title}
            onClose={toggleDialog}
            endButton={
                <PrimaryButton
                    onClick={() => submitRef?.current?.click()}
                    type="submit"
                    loading={loading}
                    disabled={loading}
                >
                    Create
                </PrimaryButton>
            }
        >
            <TabContext value={String(tabValue)}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="Details"
                        scrollButtons="auto"
                        TabScrollButtonProps={{ classes: { root: classes.scrollBtn } }}
                    >
                        <Tab
                            label={
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Box marginRight={0.3}>
                                        <Icon name="fas fa-table-cells-large" color="primary" size={IconSize.xsmall} />
                                    </Box>
                                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>Examples</Typography>
                                </Box>
                            }
                            key={0}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Box marginRight={0.3}>
                                        <Icon
                                            name="fas fa-wand-magic-sparkles"
                                            color="primary"
                                            size={IconSize.xsmall}
                                        />
                                    </Box>
                                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>Commands</Typography>
                                </Box>
                            }
                            key={1}
                            {...a11yProps(1)}
                        />
                        <Tab
                            label={
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Box marginRight={0.3}>
                                        <Icon name="fas fa-pencil" color="primary" size={IconSize.xsmall} />
                                    </Box>
                                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>Editor</Typography>
                                </Box>
                            }
                            key={2}
                            {...a11yProps(2)}
                        />
                    </Tabs>
                </AppBar>
                <Box className={classes.tabsContent} style={{ padding: 15 }}>
                    <TabPanel value="0" key={0}>
                        <Examples {...props} editorRef={editorRef} submitRef={submitRef} toggleDialog={toggleDialog} />
                    </TabPanel>
                    <TabPanel value="1" key={1}>
                        <ComplexCommands
                            {...props}
                            editorRef={editorRef}
                            submitRef={submitRef}
                            toggleDialog={toggleDialog}
                        />
                    </TabPanel>
                    <TabPanel value="2" key={2}>
                        <JsonEditor
                            {...props}
                            editorRef={editorRef}
                            submitRef={submitRef}
                            toggleDialog={toggleDialog}
                        />
                    </TabPanel>
                </Box>
            </TabContext>
        </Modal>
    );
};
