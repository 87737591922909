import _ from "lodash";
import { useReducer, useState } from "react";
import { createUseStyles } from "react-jss";
import { RootState, useDispatch, useSelector } from "src/store";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { AddMemberDialog } from "@dashboard/shared/components/AddMemberDialog";
import { MemberListItem } from "@dashboard/shared/components/MemberListItem";
import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { closeDetails, removeGroupLocally, updateGroup } from "@devices/store";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { Group, GroupRoles } from "@devices/types";
import { selectGroupMemberRoleByEmail } from "@groups/store/selectors";
import { Box, Switch, Table, TableBody, TableCell, TableRow } from "@mui/material";
import authService from "@services/authService";
import { permissionService } from "@services/permissionService/permissionService";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { TableHeader } from "@shared/table/TableHeader";
import { selectWorkspace } from "@workspaces/store/selectors";

import { WorkspaceMemberListItem } from "./WorkspaceMemberListItem";

const useStyles = createUseStyles({
    headerBox: { display: "flex", justifyContent: "space-between", paddingBottom: 10 },
    btnBox: { display: "flex", gap: 5 },
    twoFactorContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 4,
    },
});

function MemberTab(props: Group): JSX.Element {
    const [loading, setLoading] = useState(false);
    const [removedMembers, setRemovedMembers] = useState<string[]>([]);
    const [changedMembers, setChangedMembers] = useState<Record<string, GroupRoles>>({} as Record<string, GroupRoles>);
    const [dialogOpen, setDialogOpen] = useReducer((state) => !state, false);

    const classes = useStyles();
    const dispatch = useDispatch();
    const displaySaveBtn = removedMembers.length || Object.keys(changedMembers).length;
    const currentUser = authService.getCurrentUser();

    const isExpired = useSelector(selectSubscriptionExpired);

    const workspace = useSelector(selectWorkspace);

    const userIsWorkspaceMember = workspace?.members.some((member) => member.userId === currentUser?.sub);
    const { groupAbility } = permissionService();

    const mayManageMember = groupAbility(props, workspace).can("manage", "Member");

    const groupRole = useSelector((state: RootState) =>
        selectGroupMemberRoleByEmail(state, props.id, currentUser?.email || null),
    );

    const allMembers = _.unionBy(props.members, workspace?.members, "email").map((member) => {
        const isGroupMember = props.members.some((groupMember) => groupMember.email === member.email);
        return { ...member, isWorkspaceOnly: !isGroupMember };
    });

    const getAddBtnTitle = () => {
        const result = { title: "Add member" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageMember) {
            result.title = "Your group role does not allow you to add members";
        }
        return result;
    };

    const onRoleChange = (role: GroupRoles, email: string) => {
        const newChangedMembers = Object.assign({}, changedMembers);
        newChangedMembers[email] = role;
        setChangedMembers(newChangedMembers);
    };

    const onRemove = (checked: boolean, email: string) => {
        if (checked) {
            setRemovedMembers([...removedMembers, email]);
        } else {
            const membersWithout = removedMembers.filter((member) => member !== email);
            setRemovedMembers(membersWithout);
        }
    };

    const onSave = () => {
        setLoading(true);
        dispatch(updateGroup({ id: props.id, removedMembers, changedMembers }))
            .unwrap()
            .then((data) => {
                showSuccessToast("Member updated");
                const userIsGroupMember = data[0]?.members.some((item) => item.userId === currentUser?.sub);
                if (!userIsGroupMember && !userIsWorkspaceMember) {
                    dispatch(closeDetails());
                    dispatch(removeGroupLocally(props.id));
                }
            })
            .catch(({ message }) => showErrorToast(message))
            .finally(() => {
                setRemovedMembers([]);
                setLoading(false);
            });
    };

    const onCancel = () => {
        setRemovedMembers([]);
        setChangedMembers({});
    };

    return (
        <PaneElement.Container>
            <PaneElement.Section>
                <PaneElement.Header>Access requirements</PaneElement.Header>
                <div className={classes.twoFactorContainer}>
                    <PaneElement.Text>Enforce two-factor authentication</PaneElement.Text>
                    <Switch checked={props.requiresTotp} disabled color="primary" size="small" />
                </div>
            </PaneElement.Section>
            <Box className={classes.headerBox}>
                <PaneElement.Header>Group memberships</PaneElement.Header>
                <Box className={classes.btnBox}>
                    {displaySaveBtn ? (
                        <>
                            <SecondaryButton onClick={onCancel} disabled={loading}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton onClick={onSave} disabled={loading} loading={loading}>
                                Save
                            </PrimaryButton>
                        </>
                    ) : null}
                    <SecondaryButton
                        onClick={setDialogOpen}
                        tooltipProps={getAddBtnTitle()}
                        style={{ marginLeft: 15 }}
                        disabled={!mayManageMember}
                    >
                        <i className="fas fa-solid fa-user-plus" />
                    </SecondaryButton>
                </Box>
            </Box>
            {allMembers.length ? (
                <PaneElement.TableContainer>
                    <Table stickyHeader>
                        <TableHeader>
                            <TableRow>
                                <TableCell align="left" size="small">
                                    E-mail
                                </TableCell>
                                <TableCell align="left" size="small">
                                    Role
                                </TableCell>
                                <TableCell align="right" size="small">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {_.sortBy(allMembers, "email").map((item, index) =>
                                item.isWorkspaceOnly ? (
                                    <WorkspaceMemberListItem index={index} key={item.id} member={item} />
                                ) : (
                                    <MemberListItem
                                        index={index}
                                        key={item.id}
                                        member={item}
                                        loading={loading}
                                        handleRole={onRoleChange}
                                        handleRemove={onRemove}
                                        groupId={props.id}
                                        removeInProgress={removedMembers.includes(item.email)}
                                    />
                                ),
                            )}
                        </TableBody>
                    </Table>
                </PaneElement.TableContainer>
            ) : (
                <PaneElement.NoData message="No members" />
            )}
            <AddMemberDialog name="group" open={dialogOpen} onClose={setDialogOpen} id={props.id} ownRole={groupRole} />
        </PaneElement.Container>
    );
}

export default MemberTab;
