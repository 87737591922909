import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        height: 50,
    },
    collapseBtn: {
        "&:focus": {
            outline: "none",
        },
    },
    collapsePropertyTitle: {
        fontWeight: "bold",
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});
