import exportFromJSON from "export-from-json";
import { useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { selectLicenses } from "src/ui/containers/dashboard/licenses/store/selectors";

import { DropdownItem } from "@dashboard/devices/components/shared/Dropdown/DropdownItem";
import { Icon, IconSize } from "@dashboard/devices/components/shared/Icon";
import { selectFilterProperties } from "@dashboard/devices/store";
import { filterLicensesByProperties } from "@dashboard/devices/store/helpers/index";
import { selectDevicesByLicenseIds } from "@dashboard/devices/store/selectors/devices";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { License } from "@devices/types";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type LicenseData = Omit<License, "device"> & {
    "device": number | null;
};
type DeviceData = {
    "device id": number;
    "IP address": string;
    "SSID": string;
    "name": string;
    "description": string;
    "product": string;
    "serial": string;
};

const useStyles = makeStyles((theme: Theme) => ({
    csvButton: {
        height: "1.85rem",
        "&.MuiTypography-colorPrimary": {
            color: theme.palette.black[100],
        },
    },
    btnIcon: {
        marginRight: "6px",
    },
    menuItem: {
        height: "1.85rem",
    },
}));

const ExportCSV = () => {
    const classes = useStyles();
    const filtersproperties = useSelector(selectFilterProperties);
    const allLicenses = useSelector(selectLicenses);
    const groups = useSelector(selectGroups);
    const isExpired = useSelector(selectSubscriptionExpired);

    const filteredLicenses = filterLicensesByProperties({
        licenses: allLicenses,
        filters: filtersproperties,
        groups: groups,
    });
    const licenseIds = filteredLicenses.map((license) => license.id);
    const devices = useSelector((state) => selectDevicesByLicenseIds(state, licenseIds));

    const licensesData = filteredLicenses
        .map((license) => ({
            ...license,
            device: license?.device?.id ?? null,
        }))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ id, groupId, ...restProps }) => restProps)
        .map((license) =>
            Object.entries(license).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: value === null ? "" : value,
                }),
                {} as LicenseData,
            ),
        );

    const devicesData = devices
        .map((device) => ({
            "device id": device?.id,
            "IP address": device?.lastState?.ethernet?.ip ?? "",
            "SSID": device?.lastState?.wifi?.ssid ?? "",
            "name": device?.name,
            "description": device?.description,
            "product": device?.product,
            "serial": device?.serial,
        }))
        .map((device) =>
            Object.entries(device).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: value === null ? "" : value,
                }),
                {} as DeviceData,
            ),
        );

    const combinedData = licensesData.map((license) => ({
        ...license,
        ...devicesData.find((device) => device["device id"] === license.device),
    }));

    const handleClick = () => {
        if (licensesData.length && (!devicesData.length || !devicesData[0])) {
            exportFromJSON({
                data: licensesData,
                fields: Object.keys(licensesData[0]),
                fileName: "licenses",
                exportType: exportFromJSON.types.csv,
            });
        } else if (licensesData[0] && devicesData[0]) {
            exportFromJSON({
                data: combinedData,
                fields: [...Object.keys(licensesData[0]), ...Object.keys(devicesData[0])],
                fileName: "licenses_with_devices",
                exportType: exportFromJSON.types.csv,
            });
        }
    };

    return (
        <DropdownItem
            className={classes.menuItem}
            onClick={handleClick}
            disabled={!filteredLicenses.length || isExpired}
            tooltipProps={{ title: "Subscription expired", hide: !isExpired }}
        >
            <Icon name="fas fa-file-csv" size={IconSize.small} className={classes.btnIcon} />
            Export licenses
        </DropdownItem>
    );
};

export default ExportCSV;
