import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { RootState, useDispatch } from "src/store";
import { SecondaryButton } from "src/ui/shared/CustomButton";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";
import { Tooltip } from "src/ui/shared/Tooltip";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { fetchProductChangelog } from "@products/store";
import { selectBuilds } from "@products/store/selectors/builds";
import { selectProductChangelogsById } from "@products/store/selectors/products";
import { Product } from "@products/types";

import { ChangesDialog } from "./ChangesDialog";

type Props = { product: Product };

export const ChangeSection = (props: Props) => {
    const [isChangesOpen, setIsChangesOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();

    const productChanges = useSelector((state: RootState) => selectProductChangelogsById(state, props.product.id));
    const builds = useSelector(selectBuilds)?.filter((build) => build.productId === props.product.id);
    const buildsVersions = _.uniq(builds?.map((build) => build.version));
    const versionsObject = _.zipObject(
        buildsVersions,
        buildsVersions.map(() => []),
    );

    React.useEffect(() => {
        const fetchProductChanges = async () => {
            setIsLoading(true);
            try {
                await dispatch(fetchProductChangelog({ id: props.product.id })).unwrap();
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message || "Something went wrong while fetching files");
            } finally {
                setIsLoading(false);
            }
        };

        void fetchProductChanges();
    }, [dispatch, props.product.id]);

    return (
        <React.Fragment>
            <Tooltip title="Show release notes">
                <SecondaryButton
                    data-testid="changes-button"
                    onClick={() => setIsChangesOpen(true)}
                    size="small"
                    disabled={isLoading}
                >
                    <FormatListBulletedIcon />
                </SecondaryButton>
            </Tooltip>
            <ChangesDialog
                open={isChangesOpen}
                onClose={() => setIsChangesOpen(false)}
                title="Product releases"
                changes={{ ...versionsObject, ...productChanges }}
                productId={props.product.id}
            />
        </React.Fragment>
    );
};
