import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FilterDialog } from "src/ui/shared/filter/FilterDialog";

import { selectGroupsNames } from "@dashboard/groups/store/selectors";
import { selectLicenses } from "@dashboard/licenses/store/selectors";
import { DeviceTableData } from "@devices/DevicesPage.utils";
import { Column } from "@tanstack/react-table";

import {
    deviceStatusFilterOptionsArray,
    licenseStatusFilterOptionsArray,
    policyStatusFilterOptionsArray,
} from "../Filtering/Filters.utils";

export const FilterWrapper = ({ column }: { column: Column<DeviceTableData, unknown> }) => {
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const groupNames = useSelector(selectGroupsNames);
    const licenses = useSelector(selectLicenses);

    const codenames = _.uniq(_.compact(licenses.map((license) => license.device?.product?.trim()))).filter(
        (codename) => codename !== "",
    );

    const versions = _.uniq(
        _.compact(licenses.map((license) => license.device?.lastState?.version.emteriaVersion || "")),
    );

    const columnFilterType = column.columnDef.meta?.filterVariant;

    switch (columnFilterType) {
        case "license":
            return (
                <FilterDialog
                    title="License status"
                    options={licenseStatusFilterOptionsArray}
                    property="license_activationStatus"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "deviceOnline":
            return (
                <FilterDialog
                    title="Device status"
                    options={deviceStatusFilterOptionsArray}
                    property="device_status"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "policyStatus":
            return (
                <FilterDialog
                    title="Policy status"
                    options={policyStatusFilterOptionsArray}
                    property="policy_status"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "groupName":
            return (
                <FilterDialog
                    title="Group name"
                    options={groupNames}
                    property="group_name"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "codename":
            return (
                <FilterDialog
                    title="Codename"
                    options={codenames}
                    property="codename"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "systemVersion":
            return (
                <FilterDialog
                    title="Version"
                    sortType="version"
                    initialSorting="desc"
                    property="system_version"
                    options={versions}
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        default:
            return null;
    }
};
