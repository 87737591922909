import { ColumnFilter, Table } from "@tanstack/react-table";

export const getColumnFilters = <T>(key: string, table: Table<T>) => {
    const columnFilters = table.getState().columnFilters.find((item) => item.id === key)?.value;

    if (Array.isArray(columnFilters)) {
        return columnFilters;
    }
    return [];
};
export const getBadgeContent = (key: string, columnFilters: ColumnFilter[]) => {
    const filters = columnFilters.filter((filter) => filter.id === key)[0]?.value;

    if (Array.isArray(filters)) {
        return filters.length;
    }

    return 0;
};
