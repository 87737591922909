import fileSize from "filesize";
import _ from "lodash";
import React from "react";
import { getEnv } from "src/services/configService";
import UserSettingsService from "src/services/userSettingsService";
import { useDispatch, useSelector } from "src/store";
import { TagFilterHeader } from "src/ui/containers/dashboard/shared/components/TagFilterHeader";
import { LoadingIcon } from "src/ui/shared/Loading";
import { Header } from "src/ui/shared/table/TableHeader";

import { IconSize } from "@dashboard/devices/components/shared";
import useFetchInterval from "@dashboard/devices/hooks/useFetchInterval";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { fetchFiles } from "@dashboard/files/store";
import { selectFiles, selectFilesListStatus } from "@dashboard/files/store/selectors";
import { hasUnsuccessfulAnalyzer } from "@dashboard/files/utils";
import { FileSettingsDialog } from "@dashboard/shared/components/FileSettingsDialog";
import { filterByTags } from "@dashboard/shared/tags";
import { Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@mui/styles";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableCellWithTooltip,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import {
    ColumnFilter,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";

import FileIcon from "../FileIcon";
import { TableItem, TagsCell, VisibilityChangeCell } from "../TableItem/TableItem";
import { getLastTableRowStyle } from "../TableItem/TableItem.style";
import { FileExtendedTableItem, getAnalyzedFiles, useTransformFilesToTableData } from "./UploadTable.utils";

const columnHelper = createColumnHelper<FileExtendedTableItem>();

export const tableColumns = [
    columnHelper.accessor("contentType", {
        header: "Type",
        cell: (info) => <FileIcon row={info.row} />,
        enableSorting: false,
    }),
    columnHelper.accessor("filename", {
        header: "Name",
        cell: (info) => (
            <MinimalTableCellWithTooltip
                maxLength={10}
                style={{ maxWidth: 200 }}
                title={info.getValue()}
                testid="file-name-cell"
            >
                {info.getValue()}
            </MinimalTableCellWithTooltip>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor((row) => fileSize(row.size || 0) ?? "-", {
        id: "size",
        header: "Size",
        cell: (info) => (
            <MinimalTableCellWithTooltip
                maxLength={10}
                title={info.getValue()}
                testid="file-size-cell"
                style={{ minWidth: 100 }}
            >
                {info.getValue()}
            </MinimalTableCellWithTooltip>
        ),
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
            if (!rowA.original.size || !rowB.original.size) {
                return 0;
            }
            const sizeA = rowA.original.size;
            const sizeB = rowB.original.size;
            return sizeA - sizeB;
        },
    }),
    columnHelper.accessor(
        (row) => {
            if (row.formattedCreatedDate) {
                return row.formattedCreatedDate;
            }
            return { displayValue: "", tooltipTitle: "" };
        },
        {
            header: "Created",
            enableSorting: true,
            cell: (info) => (
                <MinimalTableCellWithTooltip
                    maxLength={20}
                    title={info.getValue().tooltipTitle}
                    testid="file-created-cell"
                >
                    {info.getValue().displayValue}
                </MinimalTableCellWithTooltip>
            ),
            sortingFn: (rowA, rowB) => {
                const dateA = rowA.original.formattedCreatedDate?.tooltipTitle || "";
                const dateB = rowB.original.formattedCreatedDate?.tooltipTitle || "";
                return dateA.localeCompare(dateB);
            },
        },
    ),
    columnHelper.accessor("visibility", {
        header: "Source",
        enableSorting: true,
        cell: (info) => <VisibilityChangeCell {...info} />,
    }),
    columnHelper.display({
        id: "tags",
        header: "Tags",
        cell: (info) => <TagsCell row={info.row} />,
        meta: { filterVariant: "filesTags" },
        filterFn: filterByTags,
    }),
];

const useStyles = makeStyles({
    container: {
        marginBottom: 5,
    },
    expiredContainer: {
        marginTop: 140,
    },
    loadingContainer: {
        height: "50px",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});

const UploadTable = () => {
    const [selectedFileId, setSelectedFileId] = React.useState<string | null>(null);
    const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFilter[]>([]);
    const [sorting, setSorting] = React.useState<SortingState>(UserSettingsService.getFilePageSorting());
    const isExpired = useSelector(selectSubscriptionExpired);

    const classes = useStyles();
    const dispatch = useDispatch();

    const files = useSelector(selectFiles);
    const data = useTransformFilesToTableData(files);
    const status = useSelector(selectFilesListStatus);

    const tagsArray = _.compact(_.flatMap(files, (file) => file.metadata?.user?.tags));
    const analyzedFiles = React.useMemo(() => getAnalyzedFiles(data), [data]);

    const table = useReactTable<FileExtendedTableItem>({
        data: analyzedFiles,
        columns: tableColumns,
        state: { columnFilters, sorting },
        initialState: { pagination: { pageIndex: 0, pageSize: 50 } },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
    });

    const totalRows = table.getFilteredRowModel().rows.length;
    const currentPageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    const reFetchFiles = async () => {
        try {
            await dispatch(fetchFiles());
        } catch (err) {
            if (getEnv() === "development") {
                console.error("[Files] reFetchFiles failed: ", err);
            }
        }
    };
    const setFilters: React.Dispatch<React.SetStateAction<Record<string, boolean>>> = (values) => {
        setColumnFilters([
            {
                id: "tags",
                value: Object.keys(_.pickBy(values, (value) => value)),
            },
        ]);
    };

    useFetchInterval(reFetchFiles, { condition: hasUnsuccessfulAnalyzer(files) });

    React.useEffect(() => {
        UserSettingsService.setWorkspaceValue("filePage.sorting", sorting);
    }, [sorting]);

    return (
        <React.Fragment>
            {(!isExpired || table.getRowModel().rows.length > 0) && (
                <TableContainer className={isExpired ? classes.expiredContainer : classes.container} component={Paper}>
                    <MinimalTable>
                        <MinimalTableHeader>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        if (header.column.columnDef.meta?.filterVariant === "filesTags") {
                                            return (
                                                <TagFilterHeader
                                                    key={header.id}
                                                    header={header}
                                                    tags={tagsArray}
                                                    columnFilters={columnFilters}
                                                    onSetFilters={setFilters}
                                                />
                                            );
                                        } else {
                                            return <Header key={header.id} {...header} />;
                                        }
                                    })}
                                    <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                                </TableRow>
                            ))}
                        </MinimalTableHeader>
                        <TableBody>
                            {status === "pending" && !table.getRowModel().rows.length ? (
                                <TableRow>
                                    <MinimalTableCell align="center" colSpan={tableColumns.length}>
                                        <div className={classes.loadingContainer}>
                                            <LoadingIcon size={IconSize.large} />
                                        </div>
                                    </MinimalTableCell>
                                </TableRow>
                            ) : null}
                            {status !== "pending" && !table.getRowModel().rows.length ? (
                                <TableRow>
                                    <MinimalTableCell align="center" colSpan={tableColumns.length}>
                                        There are no files yet.
                                    </MinimalTableCell>
                                </TableRow>
                            ) : null}
                            {table.getRowModel().rows.map((row, index) => (
                                <TableItem
                                    key={row.id}
                                    row={row}
                                    setSelectedFileId={setSelectedFileId}
                                    setSelectedRowId={setSelectedRowId}
                                    selectedRowId={selectedRowId}
                                    style={getLastTableRowStyle(index, table.getRowModel().rows.length)}
                                />
                            ))}
                        </TableBody>
                    </MinimalTable>
                </TableContainer>
            )}
            {(!isExpired || table.getRowModel().rows.length > 0) && (
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 20, 50]}
                    count={totalRows}
                    rowsPerPage={pageSize}
                    page={currentPageIndex}
                    onPageChange={(_event, page) => table.setPageIndex(page)}
                    onRowsPerPageChange={(e) => {
                        const size = e.target.value ? Number(e.target.value) : 10;
                        table.setPagination({ pageIndex: 0, pageSize: size });
                    }}
                />
            )}
            {selectedFileId ? (
                <FileSettingsDialog
                    open
                    onClose={() => setSelectedFileId(null)}
                    item={data.filter((item) => item.id === selectedFileId)[0]}
                />
            ) : null}
        </React.Fragment>
    );
};

export default UploadTable;
