import { FormApi } from "final-form";
import _ from "lodash";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Form } from "react-final-form";
import semver from "semver";
import { useDispatch, useSelector } from "src/store";

import { DeviceFilterType } from "@dashboard/devices/components/Filtering/Filters.utils";
import { Icon, IconSize } from "@dashboard/devices/components/shared";
import {
    selectMergedFiltersByProperty as selectDevicesMergedFiltersByProperty,
    updateFilterProperty as updateDevicesFilterProperty,
} from "@dashboard/devices/store";
import {
    ReleaseFilterType,
    ReleaseFilterTypes,
} from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import { updateFilterProperty as updateDownloadsFilterProperty } from "@dashboard/downloads/store";
import { selectMergedFiltersByProperty as selectDownloadsMergedFiltersByProperty } from "@dashboard/downloads/store/selectors/filters";
import { Checkbox, DialogProps, FormControlLabel, Typography } from "@mui/material";
import { CustomBadge } from "@shared/CustomBadge";
import { PrimaryButton } from "@shared/CustomButton";
import { CheckboxField } from "@shared/form/CheckboxField";

import { Modal } from "../Modal/Modal";
import { useStyles } from "./FilterDialog.style";

interface FormValues {
    [x: string]: boolean;
}
type Props = DialogProps & {
    title: string;
    options: string[];
    setOpenFilterDialog: Dispatch<SetStateAction<boolean>>;
    property: DeviceFilterType | ReleaseFilterType;
    sortType?: "text" | "version";
    initialSorting?: "asc" | "desc";
};

const changeAllValuesTo = (obj: Record<string, boolean>, value: boolean) => {
    return Object.fromEntries(Object.keys(obj).map((key) => [key, value]));
};

export const FilterDialog = ({
    title,
    options,
    property,
    setOpenFilterDialog,
    sortType = "text",
    initialSorting = "asc",
    ...props
}: Props) => {
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">(initialSorting);

    const classes = useStyles();
    const dispatch = useDispatch();

    const selectDevicesMergedFilters = useMemo(() => selectDevicesMergedFiltersByProperty(), []);
    const selectDownloadsMergedFilters = useMemo(() => selectDownloadsMergedFiltersByProperty(), []);

    const isReleaseFilterType = (filterProperty: string): filterProperty is ReleaseFilterType => {
        return ReleaseFilterTypes.includes(filterProperty as ReleaseFilterType);
    };

    const filters = useSelector((state) =>
        isReleaseFilterType(property)
            ? selectDownloadsMergedFilters(state, property, options)
            : selectDevicesMergedFilters(state, property, options),
    );

    const truthyFilters = changeAllValuesTo(filters, true);
    const falsyFilters = changeAllValuesTo(filters, false);

    const getSortedFilters = (arr: string[]) => {
        if (sortType === "version") {
            const versions = arr.sort(semver.compare);
            return sortOrder === "asc" ? versions : versions.reverse();
        }
        return _.orderBy(arr, (item) => item, sortOrder);
    };

    const onSubmit = (values: FormValues) => {
        const newValues = Object.keys(values).filter((key) => values[key]);
        if (isReleaseFilterType(property)) {
            dispatch(
                updateDownloadsFilterProperty({
                    property,
                    values: newValues,
                }),
            );
        } else {
            dispatch(
                updateDevicesFilterProperty({
                    property,
                    values: newValues,
                }),
            );
        }
        setOpenFilterDialog(false);
    };

    const handleCloseButton = (form: FormApi<FormValues, Partial<FormValues>>) => {
        form.reset(filters);
        setOpenFilterDialog(false);
    };

    const toggleSortOrder = () => {
        setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    };

    return (
        <>
            <CustomBadge
                className={classes.filterBadge}
                badgeContent={Object.values(filters).filter((item) => item).length}
                onClick={() => setOpenFilterDialog(true)}
                overlap="rectangular"
            >
                <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
            </CustomBadge>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={filters}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                {...props}
                                title={title}
                                onClose={() => handleCloseButton(form)}
                                endButton={<PrimaryButton onClick={form.submit}>Save</PrimaryButton>}
                            >
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.sortButtonContainer} onClick={toggleSortOrder}>
                                        <Typography className={classes.sortText}>Sort</Typography>
                                        <i
                                            className={`fa-solid ${
                                                sortOrder === "asc" ? "fa-arrow-up" : "fa-arrow-down"
                                            } ${classes.sortIcon}`}
                                        />
                                    </div>

                                    <div className={classes.content}>
                                        <div className={classes.checkboxContainer}>
                                            {getSortedFilters(Object.keys(filters)).map((item) => (
                                                <CheckboxField key={item} name={`["${item}"]`} label={item} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.selectManyContainer}>
                                        {Object.values(values).length ? (
                                            <FormControlLabel
                                                label={<span className={classes.informationText}>Select all</span>}
                                                control={
                                                    <Checkbox
                                                        checked={Object.values(values).every((item) => item)}
                                                        indeterminate={false}
                                                        onChange={(
                                                            _e: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean,
                                                        ) => {
                                                            form.reset(checked ? truthyFilters : falsyFilters);
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </form>
                            </Modal>
                        </form>
                    );
                }}
            />
        </>
    );
};
