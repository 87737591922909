export enum LimitType {
    LicenseLimit = 0,
    ProductLimit = 1,
    FDroidRepoLimit = 2,
    FileCountLimit = 3,
    ProvisioningLimit = 4,
    StreamSessionLimit = 5,
    BuildLimit = 6,
    GroupLimit = 7,
    GroupNestingLimit = 8,
    UserLimit = 9,
    FileSizeLimit = 10,
    CommandLimit = 11,
    PolicyLimit = 12,
}
