import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "src/store";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { Modal } from "src/ui/shared/Modal/Modal";
import { Header } from "src/ui/shared/table/TableHeader";

import { Group } from "@dashboard/devices/types";
import { Box, Checkbox, FormControlLabel, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Header as TanstackHeader, Table } from "@tanstack/react-table";

import { selectGroups } from "../../../groups/store/selectors";
import { buildGroupTree, getAllGroupIds, getInitialFilters } from "./GroupFilterHeader.utils";
import { GroupFilterItem } from "./GroupFilterItem";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        margin: "12px 0px",
        background: theme.palette.shadow[50],
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    filterBadge: {
        cursor: "pointer",
        marginLeft: 5,
        paddingRight: 5,
        paddingBottom: 3,
        display: "inline",
    },
    informationText: {
        fontWeight: 600,
    },
}));

export type GroupWithChildren = Group & {
    children: GroupWithChildren[];
};
type FormValues = {
    [key: string]: boolean;
};
type Props<T> = {
    title: string;
    table: Table<T>;
    header: TanstackHeader<T, unknown>;
    onSubmit: (values: FormValues) => void;
    filterWithParent?: boolean;
};

export const GroupFilterHeader = <T,>(props: Props<T>) => {
    const [expandedGroups, setExpandedGroups] = React.useState<number[]>([]);
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    const allGroups = useSelector(selectGroups);

    const groupTree = React.useMemo(() => buildGroupTree(allGroups), [allGroups]);

    const truthyFilters = allGroups.reduce((acc, group) => ({ ...acc, [group.name]: true }), {});
    const falsyFilters = allGroups.reduce((acc, group) => ({ ...acc, [group.name]: false }), {});

    const onExpand = (groupId: number) => {
        setExpandedGroups((prev) =>
            prev.includes(groupId) ? prev.filter((id) => id !== groupId) : [...prev, groupId],
        );
    };
    const onSubmit = (values: FormValues) => {
        props.onSubmit(values);
        setOpen(false);
    };
    const onSelectAll = (checked: boolean, form: FormApi<FormValues>) => {
        form.reset(checked ? truthyFilters : falsyFilters);
    };
    const onCollapseAllChange = () => {
        const allExpanded = groupTree.every((group) => expandedGroups.includes(group.id));
        if (allExpanded) {
            setExpandedGroups([]);
        } else {
            const allGroupIds = getAllGroupIds(groupTree);
            setExpandedGroups(allGroupIds);
        }
    };

    return (
        <Header {...props.header}>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={getInitialFilters(props.table, allGroups, props.header.id)}
                render={({ form, handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <CustomBadge
                            badgeContent={Object.values(values).filter((item) => item).length}
                            className={classes.filterBadge}
                            onClick={() => setOpen(true)}
                        >
                            <i className="fa-solid fa-filter" />
                        </CustomBadge>
                        <Modal
                            open={open}
                            onClose={() => setOpen(false)}
                            title={props.title}
                            endButton={<PrimaryButton onClick={form.submit}>Confirm</PrimaryButton>}
                            startButton={
                                <SecondaryButton onClick={onCollapseAllChange}>
                                    {expandedGroups.length ? "Collapse all" : "Expand all"}
                                </SecondaryButton>
                            }
                        >
                            <Box className={classes.content}>
                                {groupTree.map((group) => (
                                    <GroupFilterItem
                                        key={group.id}
                                        group={group}
                                        expandedGroups={expandedGroups}
                                        onExpand={onExpand}
                                        form={form}
                                        allGroups={allGroups}
                                        filterWithParent={props.filterWithParent}
                                    />
                                ))}
                            </Box>

                            {Object.values(values).length ? (
                                <FormControlLabel
                                    label={<span className={classes.informationText}>Select all</span>}
                                    control={
                                        <Checkbox
                                            checked={Object.values(values).every((item) => item)}
                                            indeterminate={false}
                                            onChange={(_e, checked) => onSelectAll(checked, form)}
                                        />
                                    }
                                />
                            ) : null}
                        </Modal>
                    </form>
                )}
            />
        </Header>
    );
};
