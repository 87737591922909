import React from "react";
import authService from "src/services/authService";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import { DangerOutlinedButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { updateGroup } from "@dashboard/devices/store/index";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { ConfirmDialog } from "@devices/components/shared";
import { GroupOverviewData } from "@groups/GroupsPage";
import { selectGroupById, selectGroups } from "@groups/store/selectors";
import { DialogContentText } from "@mui/material";
import { CellContext } from "@tanstack/react-table";

import DeleteGroupDialog from "../DeleteGroupDialog";
import EditGroupDialog from "../EditGroupDialog";

export const ActionCell = (props: CellContext<GroupOverviewData, unknown>) => {
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [leaveDialogOpen, setLeaveDialogOpen] = React.useState(false);

    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace);
    const group = useSelector((state) => selectGroupById(state, props.row.original.id));

    const groups = useSelector(selectGroups);
    const hasChildrenGroups = groups.some((item) => item.parentId === props.row.original.id);
    const isDefaultGroup = workspace?.defaultGroupId === props.row.original.id;
    const { groupAbility } = permissionService();
    const isDeleteDisabled =
        hasChildrenGroups || isDefaultGroup || groupAbility(group || null, workspace).cannot("delete", "Group");

    const currentUser = authService.getCurrentUser();
    const isGroupMember = group?.members.some((item) => item.email === currentUser?.email);

    const handleLeaveGroup = async () => {
        if (currentUser) {
            await dispatch(updateGroup({ id: props.row.original.id, removedMembers: [currentUser?.email] }))
                .unwrap()
                .then(() => showSuccessToast("Successfully left the group"))
                .catch(({ message }) => showErrorToast(message));
        }
    };
    let deleteTooltip = "";
    if (isDefaultGroup) {
        deleteTooltip = "You can't delete the default group";
    } else if (groupAbility(group || null, workspace).cannot("delete", "Group")) {
        deleteTooltip = "You do not have permission to delete this group";
    } else if (hasChildrenGroups) {
        deleteTooltip = "You can't delete group with nested groups";
    } else {
        deleteTooltip = "Delete group";
    }

    return (
        <>
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    display: "flex",
                    gap: 8,
                    justifyContent: "flex-end",
                }}
            >
                <SecondaryButton tooltipProps={{ title: "Edit group" }} onClick={() => setEditDialogOpen(true)}>
                    <i className="fa-solid fa-pen-to-square" />
                </SecondaryButton>
                <DangerOutlinedButton
                    onClick={() => setLeaveDialogOpen(true)}
                    disabled={!isGroupMember}
                    tooltipProps={{
                        title: !isGroupMember ? "You are not a member of this group" : "Leave group",
                    }}
                >
                    <i className="fa-solid fa-right-from-bracket" />
                </DangerOutlinedButton>
                <DangerOutlinedButton
                    onClick={() => setDeleteDialogOpen(true)}
                    disabled={isDeleteDisabled}
                    tooltipProps={{ title: deleteTooltip }}
                >
                    <i className="fa-solid fa-trash-alt" />
                </DangerOutlinedButton>
            </div>

            <EditGroupDialog
                group={props.row.original}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
            />
            {group ? (
                <DeleteGroupDialog group={group} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
            ) : null}
            <ConfirmDialog
                dangerButton
                title="Leave group"
                open={leaveDialogOpen}
                onConfirm={handleLeaveGroup}
                onClose={() => setLeaveDialogOpen(false)}
            >
                <DialogContentText>Are you sure?</DialogContentText>
            </ConfirmDialog>
        </>
    );
};
