import clsx from "clsx";
import React from "react";
import UserSettingsService from "src/services/userSettingsService";

import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { DrawerContext } from "../Navigation";
import { drawerCloseWidth, drawerOpenWidth } from "../Navigation.style";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toggleButton: {
            backgroundColor: theme.palette.info.dark,
            height: 20,
            width: 20,
            maxWidth: 20,
            position: "fixed",
            top: 23,
            left: -10,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            transition: theme.transitions.create("transform", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            zIndex: 20,
        },
        toggleIcon: { color: theme.palette.white[50], fontSize: 12 },
        hide: { transform: `translateX(${drawerCloseWidth}px)` },
        show: { transform: `translateX(${drawerOpenWidth}px)` },
    }),
);

export const DrawerToogler = () => {
    const classes = useStyles();
    const drawer = React.useContext(DrawerContext);

    if (!drawer) {
        throw new Error("BaseLink need to be used under DrawerContext provider.");
    }

    const onClick = () =>
        drawer.setOpen((prev) => {
            UserSettingsService.setCommonValue("drawerOpen", !prev);
            return !prev;
        });

    return (
        <button className={clsx(classes.toggleButton, drawer.open ? classes.show : classes.hide)} onClick={onClick}>
            <i className={clsx(`fa-solid fa-angle-${drawer.open ? "left" : "right"}`, classes.toggleIcon)} />
        </button>
    );
};
