import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { shallowEqual } from "react-redux";
import { RootState, useDispatch, useSelector } from "src/store";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";
import * as yup from "yup";

import { ConfirmDialog } from "@devices/components/shared";
import { generateLicenses } from "@devices/store";
import { Group, License } from "@devices/types";
import { selectGroupById, selectGroups } from "@groups/store/selectors";
import { selectLicenses } from "@licenses/store/selectors";
import { Alert, Box, DialogContentText, Paper, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { permissionService } from "@services/permissionService/permissionService";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { withExpiredWrapper } from "@shared/ExpiredWrapper";
import { TextField } from "@shared/form/TextField";
import { MinimalTable, MinimalTableCell, MinimalTableHeader } from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";
import { PaneElement } from "@sidePanel/shared/PaneElement";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { updateWorkspace } from "@workspaces/store";
import { selectWorkspace, selectWorkspaceLicenseLimit } from "@workspaces/store/selectors";

import { ActionCell } from "./ActionCell";
import GenerateQRCodeDialog from "./qrCodeDialog/GenerateQRCodeDialog";

export const enrollmentLink = "https://emteria.com/kb/device-enrollment-using-qr-code";

const useStyles = makeStyles({
    defaultGroupContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: 16,
    },
    bold: { fontWeight: "bold" },
    alert: { marginTop: 8 },
    form: { display: "flex", gap: 8, marginTop: 12, justifyContent: "center" },
    table: { tableLayout: "fixed", width: "100%" },
});

type TableData = {
    activationCode: string;
    groupId: string;
    universalGroupId: string;
    actions: License;
};

const columnHelper = createColumnHelper<TableData>();

const tableColumns = [
    columnHelper.accessor("activationCode", {
        id: "activationCode",
        header: "License",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
    }),
    columnHelper.accessor("groupId", {
        id: "groupId",
        header: "Location",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
    }),
    columnHelper.accessor("universalGroupId", {
        id: "universalGroupId",
        header: "Target",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: false,
    }),
    columnHelper.accessor("actions", {
        id: "actions",
        header: "Actions",
        cell: (info) => <ActionCell {...info.getValue()} />,
        enableSorting: false,
    }),
];

const getCombinedUniversalLicenses = (groupId: number, licenses: License[]): License[] => {
    const universalLicenses = licenses.filter((item) => item.universalGroupId);
    const universalLicensesInThisGroup = universalLicenses.filter((item) => item.groupId === groupId);
    const universalLicensesTargetingThisGroup = universalLicenses.filter((item) => item.universalGroupId === groupId);

    const combined = [...universalLicensesInThisGroup, ...universalLicensesTargetingThisGroup];
    const uniqueCombined = Array.from(new Map(combined.map((item) => [item.id, item])).values());

    return uniqueCombined;
};
const getTableData = (licenses: License[], groups: Group[]): TableData[] => {
    return licenses.map((item) => ({
        activationCode: item.activationCode,
        groupId: groups.find((group) => group.id === item.groupId)?.name || "Unknown",
        universalGroupId: groups.find((group) => group.id === item.universalGroupId)?.name || "Unknown",
        actions: item,
    }));
};

type FormValues = {
    grantCount: number;
};

const EnrollmentTab = (props: Group) => {
    const [isGenerating, setIsGenerating] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace, shallowEqual);
    const workspaceLicenses = useSelector(selectLicenses);

    const permission = permissionService();
    const canCreateLicense = permission.workspaceAbility(workspace).can("create", "License");

    const groups = useSelector(selectGroups, shallowEqual);
    const currentGroup = useSelector((state: RootState) => selectGroupById(state, props.id));
    const isDefaultGroup = props.id === workspace?.defaultGroupId;
    const defaultGroup = useSelector((state: RootState) =>
        workspace ? selectGroupById(state, workspace.defaultGroupId) : null,
    );

    const licenses = useSelector(selectLicenses, shallowEqual);
    const licenseLimit = useSelector(selectWorkspaceLicenseLimit);
    const usedLicenses = workspaceLicenses.filter((item) => !item.revocationDate).length;
    const freeLicenseSlots = licenseLimit - usedLicenses;
    const combinedUniversalLicenses = React.useMemo(
        () => getCombinedUniversalLicenses(props.id, licenses),
        [licenses, props.id],
    );

    const tableData = React.useMemo(
        () => getTableData(combinedUniversalLicenses, groups),
        [combinedUniversalLicenses, groups],
    );
    const table = useReactTable({
        data: tableData,
        columns: tableColumns,
        autoResetPageIndex: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const tableRows = table.getRowModel().rows;

    const validate = useValidationSchema(
        yup.object({
            grantCount: yup
                .number()
                .required()
                .integer("Licenses count must be an integer")
                .min(1, "Licenses count must be greater than 0")
                .max(freeLicenseSlots, `Licenses count must be greater than ${freeLicenseSlots}`)
                .label("Licenses count"),
        }),
    );
    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        try {
            setIsGenerating(true);
            await dispatch(generateLicenses({ groupId: props.id, grantCount: values.grantCount })).unwrap();
            showSuccessToast(`New license has been generated in group: "${props.name}"`);
            form.restart({});
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
            return;
        } finally {
            setIsGenerating(false);
        }
    };
    const onConfirm = async () => {
        if (workspace) {
            try {
                await dispatch(updateWorkspace({ ...workspace, defaultGroupId: props.id })).unwrap();
                showSuccessToast("Default group changed successfully");
            } catch (error) {
                const { message = " An error occurred while changing the default group" } = error as Error;
                showErrorToast(message);
            } finally {
                setOpen(false);
            }
        }
    };

    if (!currentGroup || !workspace) {
        return null;
    }

    return (
        <PaneElement.Container>
            <PaneElement.Section>
                <PaneElement.Header>Zero-touch provisioning</PaneElement.Header>
                <PaneElement.Text>
                    Generate group enrollment QR code for devices with support for six tap provisioning. After scanning
                    the QR code, the device will automatically download the required settings, install the MDM client
                    and let the new device appear in this group.
                </PaneElement.Text>

                <Box style={{ display: "flex", justifyContent: "flex-end", gap: 10, marginTop: 20 }}>
                    <SecondaryButton onClick={() => window.open(enrollmentLink, "_blank")}>
                        More details
                    </SecondaryButton>
                    <PrimaryButton onClick={() => setIsModalOpen(true)}>Start enrollment</PrimaryButton>
                </Box>

                <GenerateQRCodeDialog groupId={props.id} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            </PaneElement.Section>

            <PaneElement.Section>
                <PaneElement.Header>Default enrollment group</PaneElement.Header>
                <div className={classes.defaultGroupContainer}>
                    <PaneElement.Text>
                        You can choose one of your groups as the default enrollment group. When registering new devices
                        manually using your account credentials, a license from this group will be used automatically.
                        If no unused licenses are available in this group and your workspace license limit has not been
                        reached, a new license will be generated automatically in this group for device registration.
                    </PaneElement.Text>
                    <PrimaryButton
                        onClick={() => setOpen(true)}
                        disabled={isDefaultGroup}
                        tooltipProps={{
                            title: "This group is already the default group",
                            hide: !isDefaultGroup,
                        }}
                    >
                        Set as default
                    </PrimaryButton>
                </div>
            </PaneElement.Section>

            <PaneElement.Section>
                <PaneElement.Header>Generate new licenses</PaneElement.Header>
                <Typography>
                    You are able to generate new unused licenses in this group, as long as the workspace limit is not
                    reached.
                </Typography>
                {freeLicenseSlots < 1 ? (
                    <Alert severity="info" className={classes.alert}>
                        You have used all your licenses. If you need additional licenses beyond the limit, you can
                        request them
                        <a href="https://emteria.com/p/subscription">here</a>.
                    </Alert>
                ) : (
                    <Form<FormValues>
                        {...{ onSubmit, validate }}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Typography>
                                    You have <u>{freeLicenseSlots}</u> licenses remaining before you reach the license
                                    limit.
                                </Typography>
                                <Box className={classes.form}>
                                    <TextField
                                        name="grantCount"
                                        type="number"
                                        placeholder="Number of licenses to generate (e.g. 5)"
                                        fullWidth
                                    />
                                    <Tooltip
                                        hide={canCreateLicense}
                                        title="You must be member of the workspace to generate new licenses"
                                    >
                                        <PrimaryButton
                                            type="submit"
                                            loading={isGenerating}
                                            disabled={!canCreateLicense || isGenerating}
                                        >
                                            Generate
                                        </PrimaryButton>
                                    </Tooltip>
                                </Box>
                            </form>
                        )}
                    />
                )}
            </PaneElement.Section>

            <PaneElement.Section withoutBorder>
                <PaneElement.Header>Universal licenses</PaneElement.Header>
                {combinedUniversalLicenses.length ? (
                    <TableContainer component={Paper}>
                        <MinimalTable className={classes.table}>
                            <MinimalTableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Header key={header.id} {...header} />
                                        ))}
                                    </TableRow>
                                ))}
                            </MinimalTableHeader>
                            <TableBody>
                                {tableRows.length ? (
                                    tableRows.map((row) => (
                                        <TableRow key={row.id}>
                                            {row.getVisibleCells().map((cell) =>
                                                flexRender(cell.column.columnDef.cell, {
                                                    ...cell.getContext(),
                                                    key: cell.id,
                                                }),
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <MinimalTableCell colSpan={table.getAllLeafColumns().length} align="center">
                                            No data matching your query
                                        </MinimalTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </MinimalTable>
                    </TableContainer>
                ) : (
                    <PaneElement.NoData message="No universal licenses" />
                )}
            </PaneElement.Section>

            <ConfirmDialog
                open={open}
                onConfirm={onConfirm}
                onClose={() => setOpen(false)}
                title="Change default group"
            >
                {defaultGroup ? (
                    <DialogContentText>
                        Do you want <span className={classes.bold}>{currentGroup.name}</span> to replace{" "}
                        <span className={classes.bold}> {defaultGroup.name}</span> , and become the default group for
                        this workspace?
                    </DialogContentText>
                ) : (
                    <DialogContentText>
                        Do you want <span className={classes.bold}>{currentGroup.name}</span> to become the default
                        group for this workspace?
                    </DialogContentText>
                )}
            </ConfirmDialog>
        </PaneElement.Container>
    );
};

export default withExpiredWrapper(EnrollmentTab);
