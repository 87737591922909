import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { RadioField, RadioFieldGorup } from "src/ui/shared/form/RadioField";
import { Modal } from "src/ui/shared/Modal/Modal";

import { useCommands } from "@devices/components/Commands/hooks";
import { setPaneTab } from "@devices/store";
import { selectDevicesByGroupId } from "@devices/store/selectors/devices";
import { Group } from "@devices/types";
import { getPresignedDownloadUrl } from "@files/api";
import { FileInfo } from "@files/types";
import { DialogProps } from "@mui/material";

type FormValues = {
    action: "installImmediately" | "addToPolicy";
};
type Props = Omit<DialogProps, "onClose"> & {
    group: Group | undefined;
    file: FileInfo;
    onClose: () => void;
};
export const PickInstallationTypeDialog = (props: Props) => {
    const dispatch = useDispatch();
    const devices = useSelector((state) => selectDevicesByGroupId(state, props.group?.id));

    const { updatePolicyCommands, createMdmCommand } = useCommands();

    const installImmediately = async () => {
        if (props.group) {
            const url = await getPresignedDownloadUrl(props.file.id);
            await createMdmCommand({
                groupId: props.group.id,
                json: {
                    command: "installPackage",
                    source: "url",
                    url: url,
                    package: props.file.metadata?.file?.apk?.manifest?.package,
                    version: props.file.metadata?.file?.apk?.manifest?.versioncode,
                },
            });
        }
    };
    const addToPolicy = async () => {
        if (props.group) {
            const policy = {
                command: "installPackage",
                source: "storage",
                file: props.file.id,
                package: props.file.metadata?.file?.apk?.manifest?.package,
                version: props.file.metadata?.file?.apk?.manifest?.versioncode,
            };
            const json = JSON.stringify(props.group.policy ? [...props.group.policy, policy] : [policy]);
            await updatePolicyCommands({ groupId: props.group.id, policy: json });
        }
    };
    const onSubmit = async (values: FormValues) => {
        switch (values.action) {
            case "installImmediately":
                await installImmediately();
                dispatch(setPaneTab("COMMANDS"));
                break;
            case "addToPolicy":
                await addToPolicy();
                dispatch(setPaneTab("POLICY"));
                break;
            default:
                break;
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            initialValues={{ action: "installImmediately" }}
            render={({ handleSubmit, submitting, form }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        title="Install application"
                        endButton={
                            <PrimaryButton onClick={form.submit} disabled={submitting} loading={submitting}>
                                Install
                            </PrimaryButton>
                        }
                        {...props}
                    >
                        <RadioFieldGorup name="action">
                            <RadioField
                                label="Install immediately"
                                value="installImmediately"
                                helperText="This application will be immediately installed on all devices in this group, but not on devices which may be added later."
                                style={{ marginBottom: 20 }}
                            />

                            <RadioField
                                label="Add to group policy"
                                value="addToPolicy"
                                helperText="This application will be added to a group policy and will be installed automatically on all devices in this group, even if they will be added in future."
                                style={{ marginBottom: 15 }}
                                disabled={!devices.length}
                                tooltip={!devices.length ? "No devices in group" : ""}
                            />
                        </RadioFieldGorup>
                    </Modal>
                </form>
            )}
        />
    );
};
