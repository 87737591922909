import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    dialogContent: {
        height: "calc(80vh - 24px)",
        background: "black",
        color: "white",
        overflow: "auto",
        padding: 20,
        flexGrow: 1,
    },
    modalTitleBox: {
        display: "flex",
        justifyContent: "space-between",
        color: theme.palette.white[50],
        backgroundColor: theme.palette.black[100],
        padding: 20,
    },
    modalBg: {
        backgroundColor: "black",
        color: "white",
    },
    titleLeftContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 20,
    },
    downloadBtn: {
        height: 30,
        width: 30,
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        color: theme.palette.white[50],

        "&:disabled": {
            cursor: "default",
            opacity: 0.5,
        },

        "&.fa-spin": {
            color: "white",
            backgroundColor: "black",
        },
    },
    logs: {
        color: theme.palette.white[50],
        overflow: "visible",
        fontFamily: "monospace",
    },
}));
