import React from "react";
import { theme } from "src/providers/ThemeProvider";

import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Modal } from "./Modal/Modal";

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: theme.palette.grey[100],
        padding: 24,
        borderRadius: 1,
        overflow: "auto",
    },
}));

interface ConfigDialogProps {
    open: boolean;
    configData: object | null;
    onClose: () => void;
}

const ConfigDialog: React.FC<ConfigDialogProps> = ({ open, configData, onClose }) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose} title="Custom Build Config">
            {configData ? (
                <Box component="pre" className={classes.container}>
                    {JSON.stringify(configData, null, 2)}
                </Box>
            ) : (
                <Typography variant="body2">No custom build config available.</Typography>
            )}
        </Modal>
    );
};

export default ConfigDialog;
