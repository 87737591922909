import _, { isArray, mergeWith } from "lodash";
import { RootState } from "src/store";
import { persistentStore } from "src/store/persistentStore";
import { PartialDeep } from "type-fest";

import { ReleaseFilterType } from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { defaultFilter, FilterState } from "./selectors/filters";

type LocalState = {
    filters: FilterState;
};

export const localState = (state: RootState): LocalState => state?.downloads;

const lastStore = persistentStore.get();
const lastDownloadsStore = lastStore?.downloads as LocalState;

const initialState: LocalState = {
    filters: _.cloneDeep(defaultFilter),
};

const initialWithLastPersistedState: LocalState = mergeWith(
    initialState,
    lastDownloadsStore,
    {
        // override anything from "initial-state" & "last-persisted-state" here
    },
    (obj: PartialDeep<LocalState>, src: PartialDeep<LocalState>) => {
        if (isArray(obj)) {
            return src;
        }
    },
);

const downloadsSlice = createSlice({
    name: "downloads",
    initialState: initialWithLastPersistedState,
    reducers: {
        updateFilterProperty(state, action: PayloadAction<{ property: ReleaseFilterType; values: string[] }>) {
            const { property, values } = action.payload;
            state.filters.properties[property] = values;
        },

        updateFilterProperties(state, action: PayloadAction<Partial<FilterState["properties"]>>) {
            state.filters.properties = { ...state.filters.properties, ...action.payload };
        },
    },
});

export default downloadsSlice.reducer;

export const { updateFilterProperty, updateFilterProperties } = downloadsSlice.actions;
