import * as queryString from "query-string";
import React, { useEffect, useState } from "react";
import { confirmRegistration } from "src/services/accountService";
import authService, { buildAuthCodeEndpoint } from "src/services/authService";
import { getHomepageUrl } from "src/services/configService";
import { LocalStorage } from "src/services/localStorageService";
import { PrimaryButton } from "src/ui/shared/CustomButton";

import { PageContainer } from "@dashboard/shared/styles";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast } from "@shared/toasts/Toasts";

import { ConfirmRegistrationForm } from "./ConfirmRegistrationForm";

const useStyles = makeStyles({
    container: {
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        marginTop: 12,
    },
    dataStaleText: {
        maxWidth: 600,
        textAlign: "center",
    },
});

type ConfirmationFlow = {
    email: string;
    codeVerifier: string;
    codeChallenge: string;
};

type Status = "pending" | "success" | "failed" | "data_stale";

const ConfirmRegistrationPage = () => {
    const [status, setStatus] = useState<Status | null>(null);
    const [email, setEmail] = useState("");

    const classes = useStyles();

    const { id, token, code } = queryString.parse(window.location.search, {
        decode: true,
    });

    const onGoHome = () => {
        LocalStorage.clear();
        window.location.href = getHomepageUrl();
    };

    useEffect(() => {
        // first visit, so no auth-code available yet
        if (!code && id && token) {
            void (async () => {
                try {
                    // get the cookie for auth-code API
                    const _email = await confirmRegistration({
                        id: id as string,
                        token: token as string,
                    });
                    // generate verifier and challenge for PKCE auth-flow
                    const codeVerifier = authService.helpers.generateCodeVerifier();
                    const codeChallenge = await authService.helpers.generateCodeChallenge(codeVerifier);
                    // save for later, when app comes back with "code" param
                    LocalStorage.setItem(
                        "lastConfirmationFlow",
                        {
                            codeChallenge,
                            codeVerifier,
                            email: _email,
                        },
                        180000,
                    );
                    // build the needed auth-endpoint
                    const authEndpoint = await buildAuthCodeEndpoint({ codeChallenge });
                    // GET the auth-endpoint which should redirect back with "code"-param
                    window.location.href = authEndpoint;
                } catch (err) {
                    setStatus("failed");
                }
            })();
        } else if (code) {
            // "code" delivered
            const { email: _email, codeVerifier } =
                LocalStorage.getItem<ConfirmationFlow>("lastConfirmationFlow") || {};

            if (!_email || !codeVerifier) {
                setStatus("data_stale");
                return;
            }

            void (async () => {
                try {
                    // get the token
                    await authService.initSessionViaAuthCode({
                        code: code as string,
                        codeVerifier,
                    });
                    setEmail(_email);
                    LocalStorage.removeItem("lastConfirmationFlow");
                    setStatus("success");
                } catch (err) {
                    showErrorToast(err);
                    setStatus("failed");
                }
            })();
        } else {
            setStatus("failed");
        }
    }, [code, id, token]);

    return (
        <PageContainer>
            {status === "pending" ? <div>Loading...</div> : null}
            {status === "failed" ? (
                <Box className={classes.container}>
                    <Typography>Validation token is invalid.</Typography>
                    <Typography>Please check that your email program is not modifying the link.</Typography>
                    <Typography>Try to copy &amp; paste manually.</Typography>
                    <PrimaryButton className={classes.button} onClick={onGoHome}>
                        Go to home page
                    </PrimaryButton>
                </Box>
            ) : null}
            {status === "data_stale" ? (
                <Box className={classes.container}>
                    <Typography className={classes.dataStaleText}>
                        This page may not be reloaded. If you see this message, please close this window and open the
                        confirmation link from your registration email again or retry registering with the same email
                        address on the emteria portal.
                    </Typography>
                </Box>
            ) : null}
            {status === "success" && <ConfirmRegistrationForm email={email} />}
        </PageContainer>
    );
};

export default ConfirmRegistrationPage;
