import _, { flatten } from "lodash";
import { RootState } from "src/store";

import { devicesAdapter } from "@dashboard/devices/store/helpers";
import { selectGroupsIdsBySubscriptionId } from "@dashboard/groups/store/selectors";
import { selectLicensesByGroupId } from "@dashboard/licenses/store/selectors";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

const defaultSelectors = devicesAdapter.getSelectors<RootState>((state) => state.devices.list.devices.items);

const selectDevicesByGroupIds = createSelector(
    (state: RootState) => state,
    (_state: RootState, groupIds: number[] | undefined) => groupIds,
    (state, groupIds) => {
        if (Array.isArray(groupIds) && !groupIds.length) {
            return [];
        }

        const licenses = groupIds?.map((groupId) => selectLicensesByGroupId(state, groupId));

        const devices = flatten(licenses)
            .map((license) => license.device)
            .filter((device) => device);
        return _.compact(devices);
    },
);
const selectDevicesByLicenseIds = (state: RootState, licenseIds: number[]) => {
    const devices = defaultSelectors.selectAll(state);
    return devices.filter((device) => licenseIds.includes(device.licenseId));
};

const selectDevices = (state: RootState) => {
    const currentWorkspace = selectWorkspace(state);
    if (!currentWorkspace) {
        return [];
    }
    const groupIds = selectGroupsIdsBySubscriptionId(state, currentWorkspace.subscription);
    return selectDevicesByGroupIds(state, groupIds) || [];
};
const selectDevicesByGroupId = createSelector(
    (state: RootState) => state,
    (_state: RootState, groupId: number | undefined) => groupId,
    (state, groupId) => {
        if (!groupId) {
            return [];
        }

        const licenses = selectLicensesByGroupId(state, groupId);
        const devices = flatten(licenses)
            .map((license) => license.device)
            .filter((device) => device);
        return devices ?? [];
    },
);
const selectDeviceCommands = createSelector(
    (state: RootState) => state,
    (_state: RootState, deviceId: number) => deviceId,
    (state, deviceId) => {
        const device = defaultSelectors.selectById(state, deviceId);
        return device?.commands ?? [];
    },
);
const selectDevicesAppsByGroupIds = createSelector(
    (state: RootState) => state,
    (_state: RootState, groupIds: number[] | undefined) => groupIds,
    (state, groupIds) => {
        if (Array.isArray(groupIds) && !groupIds.length) {
            return [];
        }

        const filteredDevices = selectDevicesByGroupIds(state, groupIds);
        const apps = filteredDevices.flatMap((device) => device?.lastState?.packages ?? []);

        return apps.filter((app) => !app?.system);
    },
);

export {
    selectDeviceCommands,
    selectDevices,
    selectDevicesAppsByGroupIds,
    selectDevicesByGroupId,
    selectDevicesByGroupIds,
    selectDevicesByLicenseIds,
};
