import { FormApi } from "final-form";
import { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import { deleteUniversalLicense, updateUniversalLicense } from "@dashboard/devices/store";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { License } from "@dashboard/devices/types";
import { updateProvisioning } from "@dashboard/provisioning/store/index";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Provisioning } from "@dashboard/provisioning/types";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { Alert, DialogContentText, Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton } from "@shared/CustomButton";
import { ExpiredWrapper } from "@shared/ExpiredWrapper";
import { TextField } from "@shared/form/TextField";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";

import { TutorialSection } from "../overview/components/TutorialSection";
import { selectWorkspace } from "../workspaces/store/selectors";
import { LicenseGroup } from "./components/LicenseGroup";
import { selectLicenses, selectUniversalLicenses } from "./store/selectors";

const useStyles = makeStyles({
    infoAlertText: {
        marginTop: 10,
    },
    errorAlertText: {
        marginTop: 5,
        marginBottom: 10,
    },
    inputBox: {
        marginTop: 15,
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
    },
    input: {
        width: 300,
    },
    targetGroup: {
        fontsize: 16,
        fontWeight: 600,
    },
    tableBody: {
        "& > tr:last-child td": {
            paddingBottom: "12px !important",
        },
    },
});

type FormValues = { activationCode: string };
export const UniversalLicensesPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace);
    const isSubscriptionExpired = useSelector(selectSubscriptionExpired);
    const universalLicenses = useSelector(selectUniversalLicenses);
    const licenses = useSelector(selectLicenses);
    const provisioningSettings = useSelector(selectProvisionings);
    const [isDeleting, setIsDeleting] = useState(false);
    const [licenseToDelete, setLicenseToDelete] = useState<License | null>(null);

    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        const license = licenses.find((l) => l.activationCode == values.activationCode);

        if (license?.id) {
            await dispatch(updateUniversalLicense({ id: license.id, groupId: license.groupId }))
                .unwrap()
                .then(() => showSuccessToast("Target group changed successfully"))
                .catch(({ message = "Target group change failed" }) => showErrorToast(message))
                .finally(() => form.restart());
        }
    };

    const validate = (values: FormValues) => {
        const licenseExists = licenses.some((l) => l.activationCode === values.activationCode);
        const activationCodeExists = universalLicenses.some((l) => l.activationCode === values.activationCode);
        const idNotMatchActivationCode = !licenses.find((l) => l.activationCode === values.activationCode)?.id;

        if (!values.activationCode) {
            return { activationCode: "Property is not allowed to be empty" };
        }
        if (!licenseExists) {
            return { activationCode: "This license does not exist" };
        }
        if (activationCodeExists) {
            return { activationCode: "This activation code has already been added" };
        }
        if (idNotMatchActivationCode) {
            return { activationCode: "Could not find the id for this activation code" };
        }
    };

    const handleDelete = (license: License) => {
        setIsDeleting(true);

        const updatedProvisionings: Provisioning[] = [];

        provisioningSettings.forEach((item) => {
            if (item.settings?.universalLicenses.includes(license.activationCode)) {
                const updatedUniversalLicenses = item.settings.universalLicenses.filter(
                    (code) => code !== license.activationCode,
                );

                const updatedItem = {
                    ...item,
                    settings: {
                        ...item.settings,
                        universalLicenses: updatedUniversalLicenses,
                    },
                };

                updatedProvisionings.push(updatedItem);
            }
        });

        Promise.all(updatedProvisionings.map((provisioning) => dispatch(updateProvisioning(provisioning)).unwrap()))
            .then(() => dispatch(deleteUniversalLicense(license.id)).unwrap())
            .then(() => showSuccessToast("License is not universal anymore"))
            .catch(({ message = "Failed to remove the universal license. Please try again" }) =>
                showErrorToast(message),
            )
            .finally(() => setIsDeleting(false));
    };

    const handleDeleteClick = (license: License) => {
        const isInProvisioning = provisioningSettings.some((provisioning) =>
            provisioning.settings?.universalLicenses.includes(license.activationCode),
        );

        if (isInProvisioning) {
            setLicenseToDelete(license);
        } else {
            handleDelete(license);
        }
    };

    return (
        <PageContainer>
            <PageTitleWithIcon title="Universal licenses" iconName="fa-solid fa-ticket" />
            <SectionDescription>
                Universal licenses can be used in provisioning settings to allow automatic registration and activation
                of your devices. Already existing devices will be registered by reusing their original license. New
                devices will be automatically registered by using a new license and added to the specified target group.
            </SectionDescription>
            {workspace ? (
                <ExpiredWrapper>
                    <>
                        <Alert className={classes.infoAlertText} severity="info">
                            Activation codes from this list will grant access to all unused licenses in the chosen
                            license group. This is useful for automatic activation purposes during the setup wizard or
                            in License Manager application. If a device was already activated in the past, emteria will
                            automatically use the same license again. Otherwise, one of the unused licenses from the
                            chosen license group will be used for the activation of the new device.
                        </Alert>
                        <Alert className={classes.errorAlertText} severity="error">
                            Make sure to keep universal licenses secret, as they can be misused by third-parties to
                            activate their devices.
                        </Alert>
                        <TableContainer component={Paper}>
                            <MinimalTable>
                                <MinimalTableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell align="left">Activation code</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">
                                            <Tooltip
                                                placement="top"
                                                title="Devices activated with this universal license will automatically join the group"
                                            >
                                                <span className={classes.targetGroup}>Target group</span>
                                            </Tooltip>
                                        </MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </MinimalTableHeader>
                                <TableBody className={classes.tableBody}>
                                    {universalLicenses.length ? (
                                        universalLicenses.map((item) => (
                                            <LicenseGroup
                                                key={item.id}
                                                license={item}
                                                isLoading={isDeleting}
                                                handleDelete={handleDeleteClick}
                                            />
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell
                                                align="center"
                                                colSpan={universalLicenses.length > 0 ? 4 : 3}
                                            >
                                                No universal licenses were added yet
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </MinimalTable>
                        </TableContainer>
                        <Form<FormValues>
                            onSubmit={onSubmit}
                            validate={validate}
                            initialValues={{ activationCode: undefined }}
                            render={({ handleSubmit, form, submitting }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.inputBox}>
                                        <TextField name="activationCode" label="Activation Code" fullWidth />
                                        <PrimaryButton
                                            onClick={form.submit}
                                            loading={submitting}
                                            disabled={isSubscriptionExpired}
                                            tooltipProps={{
                                                title: "Subscription expired",
                                                hide: !isSubscriptionExpired,
                                            }}
                                        >
                                            Add
                                        </PrimaryButton>
                                    </div>
                                </form>
                            )}
                        />
                    </>
                </ExpiredWrapper>
            ) : (
                <TutorialSection />
            )}
            {licenseToDelete && (
                <ConfirmDialog
                    title="Delete universal license"
                    dangerButton
                    primaryActionText="Delete"
                    open={!!licenseToDelete}
                    onClose={() => setLicenseToDelete(null)}
                    onConfirm={() => {
                        handleDelete(licenseToDelete);
                        setLicenseToDelete(null);
                    }}
                >
                    <DialogContentText>
                        Are you sure you want to delete{" "}
                        <span style={{ fontWeight: "bold" }}>{licenseToDelete.activationCode}</span> universal license?
                        This universal license will be automatically removed from all provisioning settings. This action
                        cannot be undone.
                    </DialogContentText>
                </ConfirmDialog>
            )}
        </PageContainer>
    );
};
