import _ from "lodash";
import React from "react";

import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryOutlinedButton } from "@shared/CustomButton";
import { UncontrolledTextField } from "@shared/form/TextField";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        gap: 6,
        marginBottom: 16,
    },
    tagsArea: {
        display: "flex",
        flexWrap: "wrap",
        alignContent: "flex-start",
        minHeight: 88,
        width: "100%",
        border: "1px solid" + theme.palette.grey[400],
        borderRadius: 4,
        boxSizing: "border-box",
        padding: 10,
        gap: 4,
    },
    placeholder: {
        fontSize: 16,
        color: theme.palette.text.disabled,
    },
    tagInput: {
        display: "flex",
        gap: 6,
    },
}));

type Props = {
    tags: API.Tag[];
    onChange: (tags: API.Tag[]) => void;
};
type ProcessedProps = {
    tags: Record<string, API.Tag>;
    onChange: (tags: API.Tag[]) => void;
};

const withProcessedProps = (Component: React.FC<ProcessedProps>) => {
    const WrappedComponent: React.FC<Props> = (props: Props) => {
        return <Component tags={_.keyBy(props.tags, "key")} onChange={props.onChange} />;
    };

    WrappedComponent.displayName = `withProcessedProps(${
        Component.displayName || Component.name || "TagManagementSection"
    })`;

    return WrappedComponent;
};
export const TagManagementSection = withProcessedProps((props: ProcessedProps) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const classes = useStyles();
    const tagsArr = Object.values(props.tags);

    const onRemove = (tag: API.Tag) => {
        const tagsCopy = { ...props.tags };
        delete tagsCopy[tag.key];
        props.onChange(Object.values(tagsCopy));
    };
    const onAdd = () => {
        if (inputRef.current?.value) {
            const [key, value] = inputRef.current.value.split("::");
            const type = value ? "scoped" : "simple";
            const tag: API.Tag = { key, type, value };
            const tagsCopy = { ...props.tags, [key]: tag };
            props.onChange(Object.values(tagsCopy));
            inputRef.current.value = "";
        }
    };

    return (
        <Box className={classes.content}>
            <Box className={classes.tagInput}>
                <UncontrolledTextField
                    label="Tags"
                    ref={inputRef}
                    placeholder="Use :: to create a scoped label set (e.g. priority::1)"
                    fullWidth
                />
                <PrimaryOutlinedButton onClick={onAdd}>Add</PrimaryOutlinedButton>
            </Box>
            <Box className={classes.tagsArea}>
                {tagsArr.length ? (
                    tagsArr.map((item) => <Tag key={item.key} item={item} onClick={onRemove} />)
                ) : (
                    <Typography className={classes.placeholder}>No tags</Typography>
                )}
            </Box>
        </Box>
    );
});
