import { useState } from "react";

import { Device } from "@dashboard/devices/types";
import { MdmCommands } from "@dashboard/devices/types/commands";
import { Table, TableBody, TableCell, TablePagination, TableRow } from "@mui/material";
import { TableHeader } from "@shared/table/TableHeader";

import { PaneElement } from "../PaneElement";
import DeviceCommandItem from "./DeviceCommandItem";

const rowsPerPage = 25;
const HEADERS = [
    { id: "collapse", label: "", align: "left" },
    { id: "date", label: "Date", align: "left" },
    { id: "status", label: "Status", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "command", label: "Command", align: "left" },
    { id: "actions", label: "Actions", align: "right" },
] as const;
interface DeviceCommandsTableProps {
    commands: MdmCommands;
    deviceId: Device["id"];
}
const DeviceCommandsTable = ({ commands, deviceId }: DeviceCommandsTableProps) => {
    const [pageIndex, setPageIndex] = useState(0);
    const paginatedFiles = commands.slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage);

    return (
        <>
            <PaneElement.TableContainer style={{ maxHeight: "77%", marginBottom: 10 }}>
                <Table stickyHeader aria-label="simple table">
                    <TableHeader>
                        <TableRow>
                            {HEADERS.map((item) => (
                                <TableCell key={item.id} align={item.align}>
                                    {item.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {paginatedFiles.map((command, index) => (
                            <DeviceCommandItem index={index} command={command} key={command?.id} deviceId={deviceId} />
                        ))}
                    </TableBody>
                </Table>
            </PaneElement.TableContainer>
            <TablePagination
                style={{ marginBottom: 10 }}
                component="div"
                count={commands.length}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                page={pageIndex}
                onPageChange={(_, page) => {
                    setPageIndex(page);
                }}
            />
        </>
    );
};

export default DeviceCommandsTable;
