import _ from "lodash";
import React from "react";
import { EmteriaRoles } from "src/data/roles";
import { LimitType } from "src/types/limitType";
import { fetchWorkspacesAll } from "src/ui/containers/admin/api";
import { withRequiredRole } from "src/ui/navigation/utils/withRequiredRole";

import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { ContentContainer, PageContainer } from "@dashboard/shared/styles";
import { TableBody, TableRow } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { LoadingBox } from "@shared/Loading";
import { MinimalTable, MinimalTableHeader, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast } from "@shared/toasts/Toasts";

import TableItem from "./TableItem";

const expirationRowsPerPage = 50;

const expirationHeaders = [
    { id: "id", label: "Id" },
    { id: "organizationName", label: "Organization" },
    { id: "charge", label: "Charge" },
    { id: "limit", label: "Limit" },
    { id: "createdDate", label: "Created Date" },
    { id: "expirationDate", label: "Expiration Date" },
] as const;

type WorkspaceState = {
    data: API.Workspace[];
    loading: boolean;
};
const DefaultState = {
    data: [],
    loading: false,
};

const AdminExpirationsPage = () => {
    const [workspaces, setWorkspaces] = React.useState<WorkspaceState>(DefaultState);
    const [pageIndex, setPageIndex] = React.useState(0);

    React.useLayoutEffect(() => {
        const fromExpirationDate = new Date();
        fromExpirationDate.setMonth(fromExpirationDate.getMonth() - 6);
        const toExpirationDate = new Date();
        toExpirationDate.setMonth(toExpirationDate.getMonth() + 3);

        setWorkspaces((prev) => ({ ...prev, loading: true }));
        void fetchWorkspacesAll({
            fromExpirationDate: fromExpirationDate.toISOString(),
            toExpirationDate: toExpirationDate.toISOString(),
            includeTerminated: false,
            billingType: "manual",
        })
            .then((data) => setWorkspaces({ data, loading: false }))
            .catch((error) => {
                const err = error as Error;
                showErrorToast(err.message);
                setWorkspaces({ data: [], loading: false });
            });
    }, []);

    if (workspaces.loading) {
        return <LoadingBox />;
    }

    const filteredWorkspaces = workspaces.data.filter((workspace) => {
        const subscription = workspace.subscription;
        if (!subscription?.expirationDate || subscription.cancellationDate) {
            return false;
        }

        const licenseLimit = subscription.limits.find((limit) => limit.type === LimitType.LicenseLimit);
        const limit = licenseLimit?.value ?? 0;
        if (limit > 0 && limit < 5) {
            return false;
        }

        return true;
    });

    const displayedWorkspaces = _.orderBy(
        filteredWorkspaces,
        (workspace) => workspace.subscription.expirationDate,
    ).slice(pageIndex * expirationRowsPerPage, (pageIndex + 1) * expirationRowsPerPage);

    return (
        <PageContainer>
            <PageTitleWithIcon title="Subscription expiration overview" iconName="fa-regular fa-calendar-times" />
            <ContentContainer>
                <MinimalTable>
                    <MinimalTableHeader>
                        <TableRow>
                            {expirationHeaders.map((item) => (
                                <MinimalTableHeaderCell key={item.id}>{item.label}</MinimalTableHeaderCell>
                            ))}
                        </TableRow>
                    </MinimalTableHeader>
                    <TableBody>
                        {displayedWorkspaces.map((workspace) => (
                            <TableItem key={workspace.id} workspace={workspace} />
                        ))}
                    </TableBody>
                </MinimalTable>
                <TablePagination
                    component="div"
                    count={displayedWorkspaces.length}
                    rowsPerPageOptions={[]}
                    rowsPerPage={expirationRowsPerPage}
                    page={pageIndex}
                    onPageChange={(_e, page) => setPageIndex(page)}
                />
            </ContentContainer>
        </PageContainer>
    );
};

export default withRequiredRole(AdminExpirationsPage, [
    EmteriaRoles.EmteriaSalesRole,
    EmteriaRoles.EmteriaAccountantRole,
]);
