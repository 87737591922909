import { FormApi } from "final-form";
import { Form, useForm, useFormState } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { isValidApkUrl } from "src/ui/utils/validityChecker";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { HostedApk, Provisioning } from "@dashboard/provisioning/types";
import { alternatingBg, Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Paper, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { MinimalTableCell, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { TableHeader } from "@shared/table/TableHeader";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

const initialValues = { name: undefined, url: undefined, comment: null };

type FormValues = HostedApk;

export const HostedApks = () => {
    const { value, onChange } = useCollapsiblePersistence("provisioningPage.collapsedSections", "HostedApks");
    const parentForm = useForm();
    const sharedClasses = useStyles();

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const { values: parentFormValues } = useFormState<Partial<Provisioning>>();
    const apps = parentFormValues.settings?.hostedApks || [];

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        const newValue = { ...values, comment: values.comment || null };
        if (apps.length) {
            parentForm.change("settings.hostedApks", [...apps, newValue]);
        }
        if (!apps.length) {
            parentForm.change("settings.hostedApks", [newValue]);
        }
        form.restart(initialValues);
    };
    const validate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};
        if (!values.name) {
            errors.name = "Name is not allowed to be empty";
        }
        if (!values.url) {
            errors.url = "Url is not allowed to be empty";
        }
        if (apps.includes(values)) {
            errors.name = "This app has already been added";
        }
        if (!isValidApkUrl(values.url)) {
            errors.url = "Invalid APK URL";
        }
        return errors;
    };
    const handleDelete = (index: number) => {
        parentForm.change(
            "settings.hostedApks",
            apps.filter((_, i) => i !== index),
        );
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ form }) => (
                <CollapsibleProvider initialValue={value} onChange={onChange}>
                    <CollapsibleSectionSummary>
                        <Typography className={sharedClasses.categoryTitle}>Preinstalled applications</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <SectionDescription>
                            A list of additional third-party applications to download and install.
                        </SectionDescription>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell align="left">Name</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">URL</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Comment</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {apps.length ? (
                                        apps.map((app, index) => (
                                            <TableRow key={app.name} style={alternatingBg(index)}>
                                                <MinimalTableCell component="th" scope="row" align="left">
                                                    {app.name}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="left">{app.url}</MinimalTableCell>
                                                <MinimalTableCell align="left">{app.comment}</MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDelete(index)}
                                                        tooltipProps={{ title: "Remove" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell align="center" colSpan={4}>
                                                No preinstalled applications to show
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row style={{ marginTop: 15, paddingBottom: 12 }}>
                            <TextField name="name" label="Name" disabled={!provisionings.length} fullWidth />
                            <TextField name="url" label="URL" disabled={!provisionings.length} fullWidth />
                        </Row>
                        <Row>
                            <TextField name="comment" label="Comment" disabled={!provisionings.length} fullWidth />
                            <PrimaryOutlinedButton onClick={form.submit} disabled={!provisionings.length}>
                                Add
                            </PrimaryOutlinedButton>
                        </Row>
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            )}
        />
    );
};
