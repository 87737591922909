import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    placeholder: {
        fontSize: 16,
        color: theme.palette.text.disabled,
    },
    label: {
        width: 150,
        textAlign: "right",
        marginRight: 16,
    },
    hintText: {
        fontSize: 12,
    },
    variantBox: { display: "flex", flexDirection: "column", width: "100%" },
    variantSelectBox: { display: "flex", gap: 6 },
}));
