import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";
import { Modal } from "src/ui/shared/Modal/Modal";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { createFdroidRepo } from "@dashboard/devices/store";
import { CreateFdroidRepoPayload } from "@dashboard/provisioning/api";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { InputAdornment } from "@mui/material";
import { PrimaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { useStyles } from "../RepositoriesPage.style";
import { validationSchema } from "./CreateFdroidRepoDialog.validation";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

type FormValues = Omit<CreateFdroidRepoPayload, "id" | "subscriptionId">;

export const CreateFdroidRepoDialog = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentWorkspace = useSelector(selectWorkspace);
    const validate = useValidationSchema(validationSchema);

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        if (currentWorkspace?.subscription) {
            dispatch(createFdroidRepo({ subscriptionId: currentWorkspace?.subscription, ...values }))
                .unwrap()
                .then(() => {
                    form.restart();
                    showSuccessToast("Successfully created a repository");
                })
                .catch(({ message = "Failed to create a private repository. Please try again" }) => {
                    showErrorToast(message);
                })
                .finally(() => props.onClose());
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            await form.submit();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        title="Create F-Droid repository"
                        description="Create your own private F-Droid repository and use it to distribute custom Android applications."
                        open={props.isOpen}
                        onClose={props.onClose}
                        onKeyDown={(e) => onKeyDown(e, form)}
                        disableEnforceFocus
                        endButton={<PrimaryButton onClick={form.submit}>Create</PrimaryButton>}
                    >
                        <TextField
                            name="name"
                            label="Name"
                            fullWidth
                            className={classes.adornment}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">https://apps.emteria.com/fdroid/</InputAdornment>
                                ),
                            }}
                            required
                        />
                        <TextField name="password" label="Password" placeholder="Password" type="password" fullWidth />
                        <TextField name="title" label="Title" placeholder="Title" fullWidth />
                        <TextField
                            multiline
                            name="description"
                            label="Description"
                            placeholder="Description"
                            fullWidth
                        />
                    </Modal>
                </form>
            )}
        />
    );
};
