/**
 *  All possible timezone constants.
 */

const AllTimezones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Timbuktu",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/ComodRivadavia",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Atka",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Buenos_Aires",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Catamarca",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Coral_Harbour",
    "America/Cordoba",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Ensenada",
    "America/Fort_Nelson",
    "America/Fort_Wayne",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Godthab",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indianapolis",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Jujuy",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Knox_IN",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Louisville",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Mendoza",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montreal",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port_of_Spain",
    "America/Port-au-Prince",
    "America/Porto_Acre",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Rosario",
    "America/Santa_Isabel",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Shiprock",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Virgin",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/South_Pole",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Ashkhabad",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Calcutta",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Chongqing",
    "Asia/Chungking",
    "Asia/Colombo",
    "Asia/Dacca",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Harbin",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Istanbul",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kashgar",
    "Asia/Kathmandu",
    "Asia/Katmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macao",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qyzylorda",
    "Asia/Rangoon",
    "Asia/Riyadh",
    "Asia/Saigon",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Tel_Aviv",
    "Asia/Thimbu",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ujung_Pandang",
    "Asia/Ulaanbaatar",
    "Asia/Ulan_Bator",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faeroe",
    "Atlantic/Faroe",
    "Atlantic/Jan_Mayen",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/ACT",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Canberra",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/LHI",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/North",
    "Australia/NSW",
    "Australia/Perth",
    "Australia/Queensland",
    "Australia/South",
    "Australia/Sydney",
    "Australia/Tasmania",
    "Australia/Victoria",
    "Australia/West",
    "Australia/Yancowinna",
    "Brazil/Acre",
    "Brazil/DeNoronha",
    "Brazil/East",
    "Brazil/West",
    "Canada/Atlantic",
    "Canada/Central",
    "Canada/Eastern",
    "Canada/Mountain",
    "Canada/Newfoundland",
    "Canada/Pacific",
    "Canada/Saskatchewan",
    "Canada/Yukon",
    "Chile/Continental",
    "Chile/EasterIsland",
    "Cuba",
    "Egypt",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belfast",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Tiraspol",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Iceland",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Iran",
    "Israel",
    "Jamaica",
    "Japan",
    "Kwajalein",
    "Libya",
    "Mexico/BajaNorte",
    "Mexico/BajaSur",
    "Mexico/General",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Johnston",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Ponape",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Samoa",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Truk",
    "Pacific/Wake",
    "Pacific/Wallis",
    "Pacific/Yap",
    "Poland",
    "Portugal",
    "Singapore",
    "Turkey",
    "US/Alaska",
    "US/Aleutian",
    "US/Arizona",
    "US/Central",
    "US/East-Indiana",
    "US/Eastern",
    "US/Hawaii",
    "US/Indiana-Starke",
    "US/Michigan",
    "US/Mountain",
    "US/Pacific",
    "US/Samoa",
];

/**
 *  Global definitions for package management.
 */

export const PackageTypes = {
    AVAILABLE: 0,
    USER: 1,
    SYSTEM: 2,
    LAUNCHABLE: 3,
    ALL: 4,
};

/**
 *  Frequently used command parameters. Their schemas are reused.
 */
const parameterSchemas = {
    mode: {
        title: "Target file mode",
        type: "string",
        pattern: "^[0-7]{3}$",
        options: {
            inputAttributes: {
                placeholder: "Example: 644",
            },
        },
    },
    version: {
        title: "Version code",
        type: "string",
        pattern: "^[0-9]+$",
        options: {
            inputAttributes: {
                placeholder: "Example: 134156",
            },
        },
    },
    type: {
        title: "Value type",
        type: "string",
        enum: ["int", "long", "float", "bool", "string", "set"],
        options: {
            enum_titles: ["Integer", "Long", "Float", "Boolean", "String", "Set"],
        },
        default: "int",
    },
    intent: {
        title: "Intent",
        type: "object",
    },
};

export type CommandPayload<T extends Record<string, unknown | unknown[]> = Record<string, unknown>> = {
    command: string;
    caption: string;
} & T;

type JsonEditorCommand = {
    name: string;
    title?: string;
    description?: string;
    type?: string;
    options?: {
        compact?: string;
        disable_properties?: string;
        hidden?: boolean;
    };
    template?: CommandPayload;
    schema: {
        title: string;
        description: string;
        type: string;
        options: Record<string, unknown>;
        properties?: {
            [key: string]: Record<string, unknown>;
        };
        required?: string[];
    };
};

/**
 * Schemas and predefined command parameter values for simple commands.
 */
export const simpleCommands: JsonEditorCommand[] = [
    {
        name: "changePasswordVisibility",
        template: {
            command: "changeSetting",
            caption: "Change password visibility",
            section: "System",
            type: "int",
            key: "show_password",
        },
        schema: {
            title: "Change password visibility",
            description: "Show the last character of a password as you type.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                value: {
                    title: "Desired password visibility",
                    type: "string",
                    enum: ["1", "0"],
                    options: {
                        enum_titles: ["Show passwords", "Hide passwords"],
                    },
                    default: "1",
                },
            },
            required: ["value"],
        },
    },
    {
        name: "changeScreenRotation",
        template: {
            command: "changeSetting",
            caption: "Change screen rotation",
            section: "System",
            type: "int",
            key: "user_rotation",
        },
        schema: {
            title: "Change screen rotation",
            description: "Rotate the screen of the device to the specified angle",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                value: {
                    title: "Rotation angle",
                    type: "string",
                    enum: ["0", "1", "2", "3"],
                    options: {
                        enum_titles: ["0°", "90°", "180°", "270°"],
                    },
                },
            },
            required: ["value"],
        },
    },
    {
        name: "changeTimezone",
        template: {
            command: "changeProperty",
            caption: "Change time zone",
            property: "persist.sys.timezone",
        },
        schema: {
            title: "Change time zone",
            description: "Change the time zone used on the device.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                value: {
                    title: "Time zone",
                    type: "string",
                    enum: AllTimezones,
                },
            },
            required: ["value"],
        },
    },
    {
        name: "rebootDevice",
        template: {
            command: "rebootDevice",
            caption: "Reboot device",
        },
        schema: {
            title: "Reboot device",
            description: "This immediately reboots the device. It can be used to enter the Recovery mode.",
            type: "object",
            options: {
                compact: true,
            },
            properties: {
                reason: {
                    title: "Reason",
                    type: "string",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: recovery",
                        },
                    },
                },
            },
            required: ["reason"],
        },
    },
    {
        name: "changeAutostartMode",
        template: {
            command: "processSubcommands",
            caption: "Setup autostart mode",
            success: "single",
        },
        schema: {
            title: "Setup autostart mode",
            description:
                "Start one or multiple applications automatically after the system is booted. May be useful in combination with Kiosk mode.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                enabled: {
                    title: "Desired action",
                    type: "string",
                    enum: ["true", "false"],
                    options: {
                        enum_titles: ["Enable autostart mode", "Disable autostart mode"],
                    },
                    default: "true",
                },
                subcommands: {
                    title: "List of subcommands",
                    type: "array",
                    options: {
                        hidden: true,
                    },
                    items: {
                        type: "object",
                        properties: {
                            command: {
                                type: "string",
                                default: "changePreference",
                            },
                            key: {
                                type: "string",
                                default: "autostart_enabled",
                            },
                            type: {
                                type: "string",
                                default: "bool",
                            },
                            value: {
                                type: "string",
                                template: "{{reference}}",
                                watch: {
                                    "reference": "enabled",
                                },
                            },
                        },
                    },
                    default: [
                        {
                            package: "com.emteria.settings",
                            category: "com.emteria.settings_preferences",
                        },
                        {
                            package: "com.emteria.kiosk",
                            category: "com.emteria.kiosk_preferences",
                        },
                    ],
                },
            },
            required: ["enabled"],
        },
    },
    {
        name: "changeAutostartApps",
        template: {
            command: "processSubcommands",
            caption: "Setup autostart apps",
            success: "single",
        },
        schema: {
            title: "Setup autostart apps",
            description:
                "Prepare list of applications to be started after system boot. Please note that this value must contain comma-separated autostart entries of form <em>n;package</em> without spaces.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                list: {
                    title: "Application list",
                    type: "string",
                    minLength: 8,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: 0;com.example.package,1;com.foo.bar",
                        },
                    },
                },
                subcommands: {
                    title: "List of subcommands",
                    type: "array",
                    options: {
                        hidden: true,
                    },
                    items: {
                        type: "object",
                        properties: {
                            command: {
                                type: "string",
                                default: "changePreference",
                            },
                            key: {
                                type: "string",
                                default: "autostart_apps",
                            },
                            type: {
                                type: "string",
                                default: "set",
                            },
                            value: {
                                type: "string",
                                template: "{{reference}}",
                                watch: {
                                    reference: "list",
                                },
                            },
                        },
                    },
                    default: [
                        {
                            package: "com.emteria.settings",
                            category: "com.emteria.settings_preferences",
                        },
                        {
                            package: "com.emteria.kiosk",
                            category: "com.emteria.kiosk_preferences",
                        },
                    ],
                },
            },
            required: ["list"],
        },
    },
    {
        name: "changeKioskMode",
        template: {
            command: "sendBroadcast",
            caption: "Setup kiosk mode",
        },
        schema: {
            title: "Setup kiosk mode",
            description:
                "Enabled Kiosk limits user navigation options and access to other applications. The new mode will be applied immediately. <b>Warning:</b> emteria.OS v1.2 or newer is required.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: true,
            },
            properties: {
                intent: {
                    title: "Immediate Kiosk action",
                    type: "object",
                    properties: {
                        component: {
                            title: "Kiosk component",
                            type: "string",
                            default: "com.emteria.kiosk/.receivers.EnabledReceiver",
                            options: {
                                hidden: true,
                            },
                        },
                        action: {
                            title: "Desired action",
                            type: "string",
                            enum: [
                                "emteria.intent.action.SET_KIOSK_ENABLED",
                                "emteria.intent.action.SET_KIOSK_DISABLED",
                            ],
                            options: {
                                enum_titles: ["Enable kiosk mode", "Disable kiosk mode"],
                            },
                        },
                    },
                    required: ["action"],
                },
            },
        },
    },
    {
        name: "changeKioskSetting",
        template: {
            command: "changePreference",
            caption: "Setup kiosk setting",
            package: "com.emteria.kiosk",
            category: "com.emteria.kiosk_preferences",
            key: "prefs_kiosk_enabled_key",
            type: "bool",
        },
        schema: {
            title: "Setup kiosk setting",
            description:
                "Enabled Kiosk limits user navigation options and access to other applications. The new mode will be applied after reboot.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                value: {
                    title: "Desired action",
                    type: "string",
                    enum: ["true", "false"],
                    options: {
                        enum_titles: ["Enable kiosk setting", "Disable kiosk setting"],
                    },
                    default: "true",
                },
            },
            required: ["value"],
        },
    },
    {
        name: "changeKioskPassword",
        template: {
            command: "changePreference",
            caption: "Setup kiosk password",
            package: "com.emteria.kiosk",
            category: "com.emteria.kiosk_preferences",
            key: "prefs_password_key",
            type: "string",
        },
        schema: {
            title: "Setup kiosk password",
            description:
                "Protect the kiosk mode by defining a password. The user needs to know this password to exit the kiosk mode. Empty password will disable protection.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                value: {
                    title: "Kiosk password",
                    type: "string",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: secret. Leave empty to remove.",
                        },
                    },
                },
            },
            required: ["value"],
        },
    },
    {
        name: "changeKioskApps",
        template: {
            command: "changePreference",
            caption: "Setup kiosk apps",
            package: "com.emteria.kiosk",
            category: "com.emteria.kiosk_preferences",
            key: "prefs_allowed_apps",
            type: "set",
        },
        schema: {
            title: "Setup kiosk apps",
            description:
                "Prepare list of applications visible to the user when kiosk mode is active. Please note that this value must contain comma-separated package names without spaces.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                value: {
                    title: "Application list",
                    type: "string",
                    minLength: 8,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: com.package.camera,de.other.logger",
                        },
                    },
                },
            },
            required: ["value"],
        },
    },
    {
        name: "startOsUpdate",
        template: {
            command: "startActivity",
            caption: "Start automatic OS update",
            intent: {
                component: "com.emteria.update/.ui.UpdateActivity",
                extras: [{ "key": "autoupdate", "type": "bool", "value": "true" }],
            },
        },
        schema: {
            title: "Start automatic OS update",
            description:
                "This command allows updating the OS. If no version is entered, the latest available version will be used. It is also possible to pre-download the update without installing it immediately.",
            type: "object",
            options: {
                compact: "true",
            },
            properties: {
                action: {
                    title: "Desired action",
                    type: "string",
                    enum: ["install", "download"],
                    options: {
                        enum_titles: ["Install update", "Download only"],
                    },
                    default: "install",
                },
                version: {
                    title: "Version",
                    type: "string",
                    options: {
                        inputAttributes: {
                            placeholder: "e.g., 11.0.0 (optional)",
                        },
                    },
                },
            },
            required: ["action"],
        },
    },
    {
        name: "submitBugreport",
        template: {
            command: "processSubcommands",
            caption: "Submit new bugreport",
            success: "single",
            subcommands: [
                {
                    command: "changeProperty",
                    property: "ctl.start",
                    value: "sendreport",
                },
                {
                    command: "sendBroadcast",
                    intent: {
                        component: "com.emteria.bugreport/.RequestReceiver",
                        action: "emteria.intent.action.BUGREPORT_REQUESTED",
                    },
                },
            ],
        },
        schema: {
            title: "Submit new bugreport",
            description:
                "This commands generates and provides current system logs to emteria for analysis and debugging.",
            type: "object",
            options: {
                compact: "true",
            },
        },
    },
];

/**
 *  Schema templates for complex commands.
 */
export const complexCommands: JsonEditorCommand[] = [
    {
        name: "changePreference",
        schema: {
            title: "Change system preference",
            description:
                "Changes preferences of system applications to override the default device configuration (e.g. screen orientation or update settings). <b>Warning:</b> Device reboot is required to apply the changes.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                package: {
                    packageType: PackageTypes.SYSTEM,
                    title: "Application package",
                    type: "string",
                    enum: [],
                    default: "",
                },
                category: {
                    title: "Category name",
                    type: "string",
                    minLength: 4,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: com.emteria.settings_preferences",
                        },
                    },
                },
                type: parameterSchemas.type,
                key: {
                    title: "Preference key name",
                    type: "string",
                    minLength: 4,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: screen_orientation_value",
                        },
                    },
                },
                value: {
                    title: "New value",
                    type: "string",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: 180",
                        },
                    },
                },
            },
            required: ["package", "category", "type", "key", "value"],
        },
    },
    {
        name: "changeProperty",
        schema: {
            title: "Change system property",
            description:
                "Creates or modifies global system properties. Can be used to adapt system behavior or start builtin services like SSH or VNC.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                property: {
                    type: "string",
                    title: "Property name",
                    minLength: 4,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: persist.sys.sshd.enabled",
                        },
                    },
                },
                value: {
                    type: "string",
                    title: "New value",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: 1",
                        },
                    },
                },
            },
            required: ["property", "value"],
        },
    },
    {
        name: "changeSetting",
        schema: {
            title: "Change system setting",
            description:
                "Changes global system settings like ADB, sound effects or showing password characters. Please refer to the Android documentation to get the full list of possible settings keys and corresponding value types.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                section: {
                    title: "Section name",
                    type: "string",
                    enum: ["System", "Global", "Secure"],
                    default: "System",
                },
                type: parameterSchemas.type,
                key: {
                    type: "string",
                    title: "Settings key name",
                    minLength: 4,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: show_password",
                        },
                    },
                },
                value: {
                    title: "New value",
                    type: "string",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: 0",
                        },
                    },
                },
            },
            required: ["section", "type", "key", "value"],
        },
    },
    {
        name: "copyFile",
        schema: {
            title: "Copy local file",
            description:
                "Copies an existing local file to another location by create a copy of this file and applying the desired file mode.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                source: {
                    title: "Source path",
                    type: "string",
                    minLength: 2,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: /sdcard/android-file.pdf",
                        },
                    },
                },
                destination: {
                    title: "Destination file path",
                    type: "string",
                    minLength: 2,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: /sdcard/sample.pdf",
                        },
                    },
                },
                mode: parameterSchemas.mode,
            },
            required: ["source", "destination", "mode"],
        },
    },
    {
        name: "deletePath",
        schema: {
            title: "Delete local path",
            description:
                "Erases a file or a folder with all its contents from the device storage. Succeeds if the target file of folder doesn't exist.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                path: {
                    title: "Path to a file or folder",
                    type: "string",
                    minLength: 3,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: /sdcard/myfile.zip",
                        },
                    },
                },
            },
            required: ["path"],
        },
    },
    {
        name: "downloadFile",
        schema: {
            title: "Download file",
            description: "Download a file from the Internet to the device.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                url: {
                    title: "Download URL",
                    type: "string",
                    minLength: 8,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: https://site.com/myfile.zip",
                        },
                    },
                },
                path: {
                    title: "Destination filepath",
                    type: "string",
                    minLength: 2,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: /sdcard/myfile.zip",
                        },
                    },
                },
                mode: parameterSchemas.mode,
            },
            required: ["url", "path", "mode"],
        },
    },
    {
        name: "enablePackage",
        schema: {
            title: "Enable package",
            description:
                "Enable or disable an application. A disabled application doesn't do anything, as if it was not installed.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                package: {
                    packageType: PackageTypes.AVAILABLE,
                    title: "Application package",
                    type: "string",
                    enum: [],
                    default: "",
                },
                state: {
                    title: "Package state",
                    type: "string",
                    enum: ["1", "0"],
                    options: {
                        enum_titles: ["Enable", "Disable"],
                    },
                },
            },
            required: ["package", "state"],
        },
    },
    {
        name: "enableRestriction",
        schema: {
            title: "Enable restriction",
            description:
                "Enables or disables Android restriction (e.g. disable external storage or disallow uninstalling existing applications). Please refer to the official <a href=\"https://developer.android.com/reference/android/os/UserManager.html\">Android documentation</a> for all available constants.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                key: {
                    type: "string",
                    title: "Restriction name",
                    minLength: 4,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: no_uninstall_apps",
                        },
                    },
                },
                state: {
                    type: "string",
                    title: "Desired action",
                    enum: ["1", "0"],
                    options: {
                        enum_titles: ["Enable restriction", "Disable restriction"],
                    },
                },
            },
            required: ["key", "state"],
        },
    },
    {
        name: "changePermission",
        schema: {
            title: "Grant package permission",
            description:
                "Grants or revokes a runtime Android permission (e.g. Bluetooth, Internet, Storage) to/from an <i>already installed</i> application package. Fails if no such application exists.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                package: {
                    packageType: PackageTypes.USER,
                    title: "Application package",
                    type: "string",
                    enum: [],
                    default: "",
                },
                permission: {
                    title: "Permission name",
                    type: "string",
                    minLength: 4,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: android.permission.BLUETOOTH",
                        },
                    },
                },
                action: {
                    title: "Desired action",
                    type: "string",
                    enum: ["grant", "revoke"],
                    options: {
                        enum_titles: ["Grant permission", "Revoke permission"],
                    },
                },
            },
            required: ["package", "permission", "action"],
        },
    },
    {
        name: "installCertificate",
        schema: {
            title: "Install certificate",
            description: "Install an SSL certificate from local storage to trust a custom Internet host.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                path: {
                    type: "string",
                    title: "Certificate path",
                    minLength: 2,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: /sdcard/rootcert.crt",
                        },
                    },
                },
            },
            required: ["path"],
        },
    },
    {
        name: "installPackage",
        schema: {
            title: "Install package",
            description: "Install an application from the Internet.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                url: {
                    type: "string",
                    title: "Download URL",
                    minLength: 8,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: http://site.com/sample.apk",
                        },
                    },
                },
                package: {
                    title: "Application package",
                    type: "string",
                    minLength: 8,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: com.company.appname",
                        },
                    },
                },
                version: {
                    title: "Version code",
                    type: "string",
                    pattern: "^[0-9]+$",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: 10144",
                        },
                    },
                },
                source: {
                    title: "source",
                    type: "string",
                    enum: ["url", "storage"],
                    options: {
                        enum_titles: ["Url", "Storage"],
                        hidden: true,
                    },
                    default: "url",
                },
            },
            required: ["url", "package", "version"],
        },
    },
    {
        name: "processSubcommands",
        schema: {
            title: "Process subcommands",
            description: "Send a list of commands to execute.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "false",
            },
            properties: {
                success: {
                    title: "Success criterium",
                    type: "string",
                    enum: ["all", "single"],
                    options: {
                        enum_titles: ["All subcommands succeed", "One subcommand succeeds"],
                    },
                },
                subcommands: {
                    title: "List of subcommands",
                    type: "array",
                },
            },
        },
    },
    {
        name: "rebootDevice",
        schema: {
            title: "Reboot device",
            description: "This immediately reboots the device. It can be used to enter the Recovery mode.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                reason: {
                    title: "Reason",
                    type: "string",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: recovery",
                        },
                    },
                },
            },
            required: ["reason"],
        },
    },
    {
        name: "sendBroadcast",
        schema: {
            title: "Send broadcast",
            description: "This sends an Android broadcast.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                intent: parameterSchemas.intent,
            },
            required: ["intent"],
        },
    },
    {
        name: "setupEthernetMode",
        schema: {
            title: "Setup Ethernet mode",
            description:
                "Change the main configuration of the Ethernet interface. <b>Warning:</b> Setting this might lead to a loss of the Internet connection and then you will not be able to interact with the device from here anymore.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                mode: {
                    title: "Mode",
                    type: "string",
                    enum: ["dhcp", "static"],
                    options: {
                        enum_titles: ["DHCP", "Static"],
                    },
                },
                ip: {
                    title: "IP address",
                    type: "string",
                    pattern: "[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                        inputAttributes: {
                            placeholder: "Example: 192.168.178.13",
                        },
                    },
                },
                gateway: {
                    title: "Gateway",
                    type: "string",
                    pattern: "[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                        inputAttributes: {
                            placeholder: "Example: 192.168.178.1",
                        },
                    },
                },
                netmask: {
                    title: "Netmask",
                    type: "string",
                    pattern: "[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                        inputAttributes: {
                            placeholder: "Example: 255.255.255.255",
                        },
                    },
                },
                dns: {
                    title: "DNS",
                    type: "string",
                    pattern: "[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}",
                    options: {
                        "dependencies": {
                            mode: "static",
                        },
                        inputAttributes: {
                            placeholder: "Example: 8.8.8.8",
                        },
                    },
                },
            },
            required: ["mode", "ip", "gateway", "netmask", "dns"],
        },
    },
    {
        name: "setupEthernetProxy",
        schema: {
            title: "Setup Ethernet proxy",
            description:
                "Change the configuration of the default proxy for all Ethernet connections. <b>Warning:</b> Setting this might lead to a loss of the Internet connection and then you will not be able to interact with the device from here anymore.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                mode: {
                    type: "string",
                    title: "Mode",
                    enum: ["none", "static", "pac"],
                    options: {
                        enum_titles: ["None", "Static", "PAC"],
                    },
                },
                host: {
                    title: "Host",
                    type: "string",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                    },
                },
                port: {
                    title: "Port",
                    type: "string",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                    },
                },
                pac: {
                    title: "PAC URL",
                    type: "string",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                    },
                },
                exclusions: {
                    title: "Exclusions",
                    type: "string",
                    options: {
                        dependencies: {
                            mode: "static",
                        },
                    },
                },
            },
            required: ["mode", "host", "port", "pac", "exclusions"],
        },
    },
    {
        name: "startActivity",
        schema: {
            title: "Start activity",
            description: "This starts a chosen Android activity.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "false",
            },
            properties: {
                intent: parameterSchemas.intent,
            },
            required: ["intent"],
        },
    },
    {
        name: "startExecutable",
        schema: {
            title: "Start executable",
            description: "This executes a shell command using existing binary file.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                executable: {
                    type: "string",
                    title: "Full shell command",
                    minLength: 2,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: am start -n com.name.app/.MyActivity",
                        },
                    },
                },
            },
            required: ["executable"],
        },
    },
    {
        name: "startService",
        schema: {
            title: "Start service",
            description: "This starts a chosen Android service.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "false",
            },
            properties: {
                intent: parameterSchemas.intent,
            },
            required: ["intent"],
        },
    },
    {
        name: "startPackage",
        schema: {
            title: "Start package",
            description: "This starts a preinstalled application.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                package: {
                    packageType: PackageTypes.LAUNCHABLE,
                    title: "Application package",
                    type: "string",
                    enum: [],
                    default: "",
                },
            },
            required: ["package"],
        },
    },
    {
        name: "stopPackage",
        schema: {
            title: "Stop package",
            description: "Terminates a running application.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                package: {
                    packageType: PackageTypes.LAUNCHABLE,
                    title: "Application package",
                    type: "string",
                    enum: [],
                    default: "",
                },
            },
            required: ["package"],
        },
    },
    {
        name: "uninstallPackage",
        schema: {
            title: "Uninstall package",
            description: "Uninstalls an existing application.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                package: {
                    packageType: PackageTypes.USER,
                    title: "Application package",
                    type: "string",
                    enum: [],
                    default: "",
                },
                version: parameterSchemas.version,
            },
            required: ["package", "version"],
        },
    },
    {
        name: "wipeUserdata",
        schema: {
            title: "Wipe userdata",
            description: "This command clear all data from the device.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
        },
    },
    {
        name: "writeFile",
        schema: {
            title: "Write to file",
            description: "Writes custom string to a file.",
            type: "object",
            options: {
                compact: "true",
                disable_properties: "true",
            },
            properties: {
                path: {
                    title: "File path",
                    type: "string",
                    minLength: 2,
                    options: {
                        inputAttributes: {
                            placeholder: "Example: /sdcard/myfile.txt",
                        },
                    },
                },
                content: {
                    type: "string",
                    title: "File content",
                    options: {
                        inputAttributes: {
                            placeholder: "Example: Hello World!",
                        },
                    },
                },
            },
            required: ["path", "content"],
        },
    },
];

/**
 *  Basic schema for the raw JSON editor.
 */
export const rawSchema = {
    title: "JSON Editor",
    type: "object",
    default: {
        command: "command name",
        caption: "caption name",
        parameter: "example",
    },
};
