import { useCallback } from "react";
import { SecondaryButton } from "src/ui/shared/CustomButton";
import { UncontrolledTextField } from "src/ui/shared/form/TextField";
import { Modal } from "src/ui/shared/Modal/Modal";
import { showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    textContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: 8,
    },
    label: {
        minWidth: 120,
        maxWidth: 120,
    },
    copyButton: {
        marginLeft: 8,
    },
});

interface Props {
    file: API.ReleaseFile;
    isOpen: boolean;
    toggleDialog: VoidFunction;
}

export const InformationModal = ({ file, isOpen, toggleDialog }: Props) => {
    const classes = useStyles();

    const copyToClipboard = useCallback((text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                showSuccessToast("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text to clipboard", err);
            });
    }, []);

    if (!isOpen) {
        return null;
    }

    return (
        <Modal open={isOpen} onClose={toggleDialog} title="Release details">
            <div className={classes.textContainer}>
                <Typography className={classes.label}>SHA256 hash:</Typography>

                <UncontrolledTextField value={file.hashSha256} disabled fullWidth />
                <SecondaryButton
                    className={classes.copyButton}
                    onClick={() => copyToClipboard(file.hashSha256)}
                    tooltipProps={{ title: "Copy" }}
                >
                    <i className="fa-solid fa-copy" />
                </SecondaryButton>
            </div>
            <div className={classes.textContainer}>
                <Typography className={classes.label}>MD5 hash:</Typography>

                <UncontrolledTextField value={file.hashMd5} disabled fullWidth />
                <SecondaryButton
                    className={classes.copyButton}
                    onClick={() => copyToClipboard(file.hashMd5)}
                    tooltipProps={{ title: "Copy" }}
                >
                    <i className="fa-solid fa-copy" />
                </SecondaryButton>
            </div>
        </Modal>
    );
};
