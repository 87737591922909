import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SecondaryButton } from "../CustomButton";

const useStyles = makeStyles((_theme: Theme) => ({
    description: { marginBottom: 16 },
    actionBox: { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" },
    startButtonBox: { display: "flex", gap: 6, flex: 1 },
    endButtonBox: { display: "flex", gap: 6 },
    childrenBox: { display: "flex", flexDirection: "column", gap: 8 },
    contentBox: { minWidth: 500 },
}));

type Props = Omit<DialogProps, "onClose"> & {
    onClose: (event?: object, reason?: "backdropClick" | "escapeKeyDown") => void;
    description?: string | JSX.Element;
    startButton?: React.ReactNode;
    endButton?: React.ReactNode;
};

export const Modal = ({ title, description, startButton, endButton, ...props }: React.PropsWithChildren<Props>) => {
    const classes = useStyles();

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };

    return (
        <Dialog {...props} onKeyDown={onKeyDown}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.contentBox} dividers>
                {description ? (
                    <DialogContentText className={classes.description}>{description}</DialogContentText>
                ) : null}
                <Box className={classes.childrenBox}>{props.children}</Box>
            </DialogContent>

            <DialogActions>
                <Box className={classes.actionBox}>
                    <Box className={classes.startButtonBox}>{startButton}</Box>

                    <Box className={classes.endButtonBox}>
                        <SecondaryButton onClick={props.onClose}>Close</SecondaryButton>
                        {endButton}
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
};
