import clsx from "clsx";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { DrawerContext } from "src/ui/navigation/Navigation";

import { ListItemButton, ListItemButtonProps, Typography, TypographyProps } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";

import { useStyles } from "./NavigationLinks.style";

interface BaseLinkProps extends Omit<ListItemButtonProps<"div">, "title"> {
    title: string;
    condition?: boolean;
    textProps?: TypographyProps;
    startIcon?: string;
    endIcon?: string;
    notCollapsible?: boolean;
    href?: string;
}
const BaseLink = (props: BaseLinkProps) => {
    const { condition = true, href, textProps = {}, startIcon, endIcon, className, ...rest } = props;
    const classes = useStyles();
    const drawer = React.useContext(DrawerContext);

    if (!drawer) {
        throw new Error("BaseLink needs to be used under DrawerContext provider.");
    }

    if (!condition) {
        return null;
    }

    if (!drawer.open && !props.notCollapsible) {
        return (
            <a style={{ textDecoration: "none", color: "inherit" }} href={href} onClick={(e) => e.preventDefault()}>
                <div className={classes.listItemBox}>
                    <ListItemButton {...rest} className={clsx(classes.listItem, className)} LinkComponent={"div"}>
                        <ListItemIcon className={classes.startIcon}>
                            <i className={startIcon} />
                        </ListItemIcon>
                    </ListItemButton>
                </div>
            </a>
        );
    }

    return (
        <div className={classes.listItemBox}>
            <a style={{ textDecoration: "none", color: "inherit" }} href={href} onClick={(e) => e.preventDefault()}>
                <ListItemButton {...rest} className={clsx(classes.listItem, className)} LinkComponent={"div"}>
                    {startIcon ? (
                        <ListItemIcon className={classes.startIcon}>
                            <i className={startIcon} />
                        </ListItemIcon>
                    ) : null}
                    <Typography className={classes.text} {...textProps}>
                        {props.title}
                    </Typography>
                    {endIcon ? (
                        <ListItemIcon className={classes.endIcon}>
                            <i className={endIcon} />
                        </ListItemIcon>
                    ) : null}
                </ListItemButton>
            </a>
        </div>
    );
};

interface LegacyLinkProps extends BaseLinkProps {
    href: string;
}
export const LegacyLink = (props: LegacyLinkProps) => {
    const location = useLocation();
    const onClick = () => (window.location.href = props.href);
    return <BaseLink {...props} onClick={onClick} selected={location.pathname === props.href} />;
};

interface RouterLinkProps extends BaseLinkProps {
    to: string;
}
export const RouterLink = (props: RouterLinkProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <BaseLink
            {...props}
            onClick={() => navigate(props.to)}
            selected={location.pathname === props.to}
            href={props.to}
        />
    );
};

interface ExternalLinkProps extends BaseLinkProps {
    url: string;
}
export const ExternalLink = (props: ExternalLinkProps) => {
    return <BaseLink {...props} href={props.url} onClick={() => window.open(props.url, "_blank")} />;
};

export const CollapsibleLink = ({ condition = true, ...props }: React.PropsWithChildren<BaseLinkProps>) => {
    const [open, setOpen] = React.useState(false);
    const drawer = React.useContext(DrawerContext);

    if (!drawer) {
        throw new Error("BaseLink need to be used under DrawerContext provider.");
    }
    if (!drawer.open && open) {
        setOpen(false);
    }
    if (!condition) {
        return null;
    }

    return (
        <div>
            <BaseLink
                {...props}
                endIcon={open ? "fa-solid fa-angle-down" : "fa-solid fa-angle-right"}
                onClick={() => {
                    setOpen((prev) => {
                        if (!drawer.open) {
                            drawer.setOpen(true);
                        }
                        return !prev;
                    });
                }}
            />
            <Collapse in={open} timeout="auto" unmountOnExit style={{ paddingLeft: 10 }}>
                {props.children}
            </Collapse>
        </div>
    );
};

export const PopupMenuLink = (props: React.PropsWithChildren<BaseLinkProps>) => {
    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <BaseLink {...props} {...bindTrigger(popupState)} />
                    <Menu {...bindMenu(popupState)} style={{ top: -30 }}>
                        {React.Children.map(props.children, (child) => {
                            if (React.isValidElement<BaseLinkProps>(child)) {
                                return (
                                    <div onClick={popupState.close}>
                                        {React.cloneElement(child, { notCollapsible: true })}
                                    </div>
                                );
                            }
                            return child;
                        })}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
};
