import UserSettingsService from "src/services/userSettingsService";

import { FilterFnOption } from "@tanstack/react-table";

import { GroupOverviewData } from "./GroupsPage";

export const customGlobalFilterFn: FilterFnOption<GroupOverviewData> = (row, _columnId, filterValue) => {
    if (!filterValue) {
        return true;
    }
    const normalizedFilterValue = String(filterValue).toLowerCase();

    const checkRowMatch = (rowData: GroupOverviewData): boolean => {
        const rowMatches = Object.values(rowData).some((value) =>
            typeof value === "string" || typeof value === "number"
                ? String(value).toLowerCase().includes(normalizedFilterValue)
                : false,
        );

        if (rowData.children && rowData.children.length > 0) {
            return rowMatches || rowData.children.some(checkRowMatch);
        }

        return rowMatches;
    };

    return checkRowMatch(row.original);
};
export const useGroupPageNameFilter = () => {
    const filter = UserSettingsService.getGroupPageNameFilter();

    if (filter.length) {
        return [{ id: "name", value: filter }];
    }

    return [];
};
