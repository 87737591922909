import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "src/store";

import { selectGroupChildrenIds, selectGroups } from "@dashboard/groups/store/selectors";
import { validateGroupForm } from "@dashboard/groups/utils";
import { selectGroupNestingLimit } from "@dashboard/workspaces/store/selectors";
import { selectPaneEditEnabled, setPaneEditEnabled, updateGroup, updateGroupParent } from "@devices/store";
import { selectDevicesByGroupId } from "@devices/store/selectors/devices";
import { Group } from "@devices/types";
import { selectLicensesByGroupId } from "@licenses/store/selectors";
import { Box } from "@mui/material";
import { SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { EditPrimaryButtons } from "@sidePanel/shared/EditActionButtons";
import { PaneElement } from "@sidePanel/shared/PaneElement";
import { PropertyKey, PropertyRow, PropertyValue } from "@sidePanel/shared/Property";

type FormValues = Pick<Group, "id" | "name" | "parentId"> & {
    description: string;
};

const useStyles = createUseStyles({
    editBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: 10,
        gap: 10,
    },
});

const SummaryTab = (props: Group) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editPropertiesEnabled = useSelector(selectPaneEditEnabled);

    const totalDevices = useSelector((state) => selectDevicesByGroupId(state, props.id));
    const onlineDevices = totalDevices.filter((device) => device?.isOnline);
    const onlineDeviceCount = onlineDevices.length;
    const offlineDeviceCount = totalDevices.length - onlineDeviceCount;

    const totalLicenses = useSelector((state) => selectLicensesByGroupId(state, props.id));

    const unusedLicenses = totalLicenses.filter((license) => !license.device);
    const unusedLicenseCount = unusedLicenses.length;
    const activeLicenseCount = totalLicenses.length - unusedLicenseCount;

    const groups = useSelector(selectGroups);
    const groupNestingLimit = useSelector(selectGroupNestingLimit);
    const validate = (values: FormValues) => validateGroupForm(values, groups, groupNestingLimit, props.id);
    const parentGroup = groups.find((group) => group.id === props.parentId);
    const childrenGroupIds = useSelector((state) => selectGroupChildrenIds(state, props.id));
    const avaibleGroups = groups.filter((group) => ![...childrenGroupIds, props.id].includes(group.id));
    const options = [
        { label: "-", value: undefined },
        ...avaibleGroups.map((item) => ({ label: item.name, value: item.id })),
    ].sort();

    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        try {
            const initialValues = form.getState().initialValues;
            const parentIdChanged = values.parentId !== initialValues.parentId;
            if (parentIdChanged) {
                await dispatch(updateGroupParent({ id: props.id, parentId: values.parentId })).unwrap();
            }

            const nameChanged = values.name !== initialValues.name;
            const descriptionChanged = values.description !== initialValues.description;
            if (nameChanged || descriptionChanged) {
                await dispatch(updateGroup({ ...values, description: values.description || "" })).unwrap();
            }

            showSuccessToast("Successfully updated group");
            dispatch(setPaneEditEnabled(false));
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Failed to update group");
        }
    };

    return (
        <PaneElement.Container>
            <PaneElement.Header>General</PaneElement.Header>
            <PropertyRow>
                <PropertyKey>Id</PropertyKey>
                <PropertyValue>{props.id}</PropertyValue>
            </PropertyRow>

            <Form<FormValues>
                onSubmit={onSubmit}
                validate={validate}
                initialValues={{
                    id: props.id,
                    name: props.name,
                    description: props.description || "",
                    parentId: props.parentId || null,
                }}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <PaneElement.Section>
                            {editPropertiesEnabled ? (
                                <Box className={classes.editBox}>
                                    <PropertyRow>
                                        <PropertyKey>Name</PropertyKey>
                                        <PropertyValue>
                                            <TextField name="name" disabled={submitting} required />
                                        </PropertyValue>
                                    </PropertyRow>
                                    <PropertyRow>
                                        <PropertyKey>Description</PropertyKey>
                                        <PropertyValue>
                                            <TextField name="description" disabled={submitting} />
                                        </PropertyValue>
                                    </PropertyRow>
                                    <PropertyRow>
                                        <PropertyKey>Parent group</PropertyKey>
                                        <PropertyValue>
                                            <SelectField
                                                name="parentId"
                                                style={{ width: 213 }}
                                                disabled={submitting}
                                                options={options}
                                            />
                                        </PropertyValue>
                                    </PropertyRow>
                                </Box>
                            ) : (
                                <Box>
                                    <PropertyRow>
                                        <PropertyKey>Name</PropertyKey>
                                        <PropertyValue>{props.name}</PropertyValue>
                                    </PropertyRow>
                                    <PropertyRow>
                                        <PropertyKey>Description</PropertyKey>
                                        <PropertyValue>{props.description || "-"}</PropertyValue>
                                    </PropertyRow>
                                    <PropertyRow>
                                        <PropertyKey>Parent group</PropertyKey>
                                        <PropertyValue>{parentGroup?.name || "-"}</PropertyValue>
                                    </PropertyRow>
                                </Box>
                            )}
                        </PaneElement.Section>

                        <PaneElement.Section>
                            <PaneElement.Header>Devices</PaneElement.Header>
                            <PropertyRow>
                                <PropertyKey>Online</PropertyKey>
                                <PropertyValue>{onlineDeviceCount}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Offline</PropertyKey>
                                <PropertyValue>{offlineDeviceCount}</PropertyValue>
                            </PropertyRow>
                        </PaneElement.Section>

                        <PaneElement.Section withoutBorder>
                            <PaneElement.Header>Licenses</PaneElement.Header>
                            <PropertyRow>
                                <PropertyKey>Active</PropertyKey>
                                <PropertyValue>{activeLicenseCount}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Unused</PropertyKey>
                                <PropertyValue>{unusedLicenseCount}</PropertyValue>
                            </PropertyRow>
                        </PaneElement.Section>

                        <EditPrimaryButtons loading={submitting} />
                    </form>
                )}
            />
        </PaneElement.Container>
    );
};

export default SummaryTab;
