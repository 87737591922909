import clsx from "clsx";
import { useState } from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { updateFile } from "@dashboard/files/store";
import { FileStatus, FileTableItem } from "@dashboard/files/types/index";
import { createVersionCodeTag } from "@dashboard/files/utils";
import { selectGroups } from "@dashboard/groups/store/selectors";
import { FileDetailsModal } from "@dashboard/shared/components/MoreInformationFileModal/FileDetailsModal";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { createRolloutTag } from "@dashboard/shared/tags";
import { useVisibilityOptions } from "@dashboard/shared/useVisibilityOptions";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { SelectChangeEvent, TableRow, Typography } from "@mui/material";
import { SecondaryButton } from "@shared/CustomButton";
import { CategoriesSelectNoForm } from "@shared/form/SelectField";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";
import { flexRender, Row } from "@tanstack/react-table";

import ActionsMenu from "../ActionsMenu/ActionsMenu";
import { useStyles } from "./TableItem.style";

interface FileUploadTableItemProps {
    row: Row<FileTableItem>;
    setSelectedFileId: React.Dispatch<React.SetStateAction<string | null>>;
    selectedRowId: string | null;
    setSelectedRowId: React.Dispatch<React.SetStateAction<string | null>>;
    style?: React.CSSProperties;
}

const TableItem = ({ row, setSelectedFileId, style, setSelectedRowId, selectedRowId }: FileUploadTableItemProps) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const item = row.original;
    const isUploading = item.status === FileStatus.Pending;

    const isExpired = useSelector(selectSubscriptionExpired);
    const currentWorkspace = useSelector(selectWorkspace);
    const { fileAbility } = permissionService();

    const mayManageTag = fileAbility(item).can("manage", "Tag");

    const handleRowClick = () => {
        setSelectedRowId((prevSelectedId) => (prevSelectedId === item.id ? null : item.id));
    };

    const getTagBtnTooltip = () => {
        const result = { title: "Manage tags" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageTag) {
            result.title = "Your group or workspace role does not allow you to manage tags";
        }
        return result;
    };

    if (!currentWorkspace) {
        return null;
    }

    return (
        <>
            <TableRow
                className={clsx(
                    classes.tableRow,
                    isUploading && classes.isUploading,
                    selectedRowId === item.id && "selected",
                )}
                onClick={handleRowClick}
            >
                {row.getVisibleCells().map((cell) =>
                    flexRender(cell.column.columnDef.cell, {
                        ...cell.getContext(),
                        style,
                        key: cell.id,
                    }),
                )}
                <MinimalTableCell align="right" style={{ overflow: "visible" }}>
                    <div className={classes.buttonBox}>
                        <SecondaryButton
                            tooltipProps={getTagBtnTooltip()}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedFileId(item.id);
                            }}
                            disabled={isUploading || !mayManageTag}
                        >
                            <i className="fa-solid fa-gear" />
                        </SecondaryButton>
                        <ActionsMenu file={item} />
                    </div>
                </MinimalTableCell>
            </TableRow>
            <FileDetailsModal isOpen={modalOpen} closeDialog={() => setModalOpen(false)} file={item} />
        </>
    );
};

type Props = {
    row: Row<FileTableItem>;
    style?: React.CSSProperties;
};
const VisibilityChangeCell = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isExpired = useSelector(selectSubscriptionExpired);
    const currentWorkspace = useSelector(selectWorkspace);
    const currentWorkspaceGroups = useSelector(selectGroups);
    const { groupAbility } = permissionService();

    const fileItem = props.row.original;
    const currentGroup = currentWorkspaceGroups.find((item) => item.id === fileItem.groupId);
    const mayManageFile = groupAbility(currentGroup || null, currentWorkspace).can("manage", "File");

    const getSelectTooltip = () => {
        const result = { title: "Change visibility" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageFile) {
            result.title = "You are not allowed to change visibility in this group";
        }
        return result;
    };

    const handleAccessibilityChange = async (event: SelectChangeEvent<unknown>) => {
        const value = event.target.value;

        const isGroupId = value !== currentWorkspace?.id;

        if (typeof value === "number" && currentWorkspace) {
            try {
                await dispatch(
                    updateFile({
                        id: fileItem.id,
                        groupId: isGroupId ? value : null,
                        workspaceId: isGroupId ? null : value,
                    }),
                ).unwrap();
                showSuccessToast("File visibility updated successfully");
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message);
            }
        }
    };

    if (!currentWorkspace) {
        return null;
    }
    return (
        <MinimalTableCell style={props.style}>
            <Tooltip {...getSelectTooltip()}>
                <CategoriesSelectNoForm
                    name="target"
                    value={currentGroup?.id || currentWorkspace.id}
                    options={useVisibilityOptions()}
                    className={classes.visibilitySelect}
                    fullWidth
                    onChange={handleAccessibilityChange}
                    disabled={!mayManageFile || fileItem.status === FileStatus.Pending}
                />
            </Tooltip>
        </MinimalTableCell>
    );
};
const TagsCell = ({ row }: Props) => {
    const classes = useStyles();

    const item = row.original;
    const removableTags = item.metadata?.user?.tags;
    const versionCodeTag = createVersionCodeTag(item);
    const rolloutTag = createRolloutTag(item.rolloutPercentage);
    const hasTags = removableTags?.length || versionCodeTag || rolloutTag;

    return (
        <MinimalTableCell>
            <div className={classes.tagsBox}>
                {removableTags?.length ? removableTags.map((t) => <Tag key={t.key} item={t} />) : null}
                {versionCodeTag ? <Tag key={versionCodeTag.key} item={versionCodeTag} theme="light" /> : null}
                {rolloutTag ? <Tag key={rolloutTag.key} item={rolloutTag} theme="light" /> : null}
                {!hasTags ? <Typography>-</Typography> : null}
            </div>
        </MinimalTableCell>
    );
};

export { TableItem, TagsCell, VisibilityChangeCell };
