import React, { useEffect, useState } from "react";

import { ProductAdmin } from "@dashboard/products/types";
import ConfigDialog from "@shared/ConfigDialog";

interface BuildConfigDialogProps {
    open: boolean;
    product: ProductAdmin | null;
    onClose: () => void;
}

const BuildConfigDialog: React.FC<BuildConfigDialogProps> = ({ open, onClose, product }) => {
    const [currentProduct, setCurrentProduct] = useState<ProductAdmin | null>(null);

    useEffect(() => {
        if (open && product) {
            setCurrentProduct(product);
        }
    }, [open, product]);

    return <ConfigDialog open={open} configData={currentProduct?.customBuildConfig ?? null} onClose={onClose} />;
};

export default BuildConfigDialog;
