import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "src/ui/shared/CustomButton";
import { TextareaField } from "src/ui/shared/form/TextareaField";
import { TextField } from "src/ui/shared/form/TextField";
import { LoadingIcon } from "src/ui/shared/Loading";
import { Modal } from "src/ui/shared/Modal/Modal";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";
import * as yup from "yup";

import { addProductVariable, deleteProductVariable, fetchProductVariables } from "@dashboard/products/api/index";
import { ProductVariable } from "@dashboard/products/types/index";
import { IconSize } from "@devices/components/shared";
import AddIcon from "@mui/icons-material/Add";
import { Chip, TableBody, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles(() => ({
    addButtonBox: { display: "flex", justifyContent: "flex-end", marginBottom: 12 },
    loadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 8,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: 6,
    },
}));

const maxSize = 10240;
const validationSchema = yup.object({
    key: yup.string().required("Key is required"),
    value: yup.string().required("Value is required").max(maxSize, "These can be up to 10KiB in size"),
    description: yup.string().optional(),
});

type Props = {
    productId: number;
};

type Secret = Omit<ProductVariable, "id">;
type FormValues = Secret;

export const VariableSection = ({ productId }: Props) => {
    const [productVariables, setProductVariables] = React.useState<Secret[]>([]);
    const [open, setOpen] = React.useState(false);
    const [deletingKey, setDeletingKey] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const classes = useStyles();
    const validate = useValidationSchema(validationSchema);

    const handleAddVariable = async (payload: Secret, form: FormApi<FormValues>) => {
        try {
            await addProductVariable({
                productId,
                variableData: payload,
            });
            setProductVariables([
                ...productVariables,
                { key: payload.key, value: payload.value, description: payload.description },
            ]);
            showSuccessToast("Successfully added product variable");
            form.restart();
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Failed to add variable. Please try again.");
        }
    };

    const handleDeleteVariable = async (keyToDelete: string) => {
        setDeletingKey(keyToDelete);
        try {
            await deleteProductVariable(productId, keyToDelete);
            setProductVariables(productVariables.filter((variable) => variable.key !== keyToDelete));
            showSuccessToast("Successfully deleted product variable");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Failed to delete variable. Please try again.");
        } finally {
            setDeletingKey(null);
        }
    };

    React.useEffect(() => {
        const fetchVariables = async () => {
            try {
                setIsLoading(true);
                const data = await fetchProductVariables(productId);
                setProductVariables(data);
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message);
                setProductVariables([]);
            } finally {
                setIsLoading(false);
            }
        };

        void fetchVariables();
    }, [productId]);

    return (
        <React.Fragment>
            <CustomBadge badgeContent={productVariables.length}>
                <Chip label="Variables" onClick={() => setOpen(true)} icon={<AddIcon />} />
            </CustomBadge>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                title="Product variable"
                description="Manage product variables by adding, editing, or removing key-value pairs used in your
                projects configuration. You can store up to 10KiB of data per variable. Use the table below
                to review the list of existing variables and remove any unnecessary entries."
            >
                <Form<FormValues>
                    validate={validate}
                    onSubmit={handleAddVariable}
                    render={({ handleSubmit, submitting, form }) => (
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <TextField name="key" label="Key" placeholder="Key" variant="outlined" fullWidth />

                            <TextareaField
                                name="value"
                                label="Value"
                                id="value"
                                placeholder="Value"
                                minRows={3}
                                maxRows={5}
                            />

                            <TextField
                                name="description"
                                label="Description"
                                placeholder="Description"
                                id="description"
                            />

                            <div className={classes.addButtonBox}>
                                <PrimaryOutlinedButton onClick={form.submit} disabled={submitting} loading={submitting}>
                                    Add
                                </PrimaryOutlinedButton>
                            </div>

                            <MinimalTable>
                                <MinimalTableHeader>
                                    <MinimalTableHeaderCell>Key</MinimalTableHeaderCell>
                                    <MinimalTableHeaderCell>Description</MinimalTableHeaderCell>
                                    <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                </MinimalTableHeader>
                                <TableBody>
                                    {isLoading ? (
                                        <TableRow>
                                            <MinimalTableCell colSpan={3}>
                                                <div className={classes.loadingBox}>
                                                    <LoadingIcon size={IconSize.large} />
                                                </div>
                                            </MinimalTableCell>
                                        </TableRow>
                                    ) : productVariables.length === 0 ? (
                                        <TableRow>
                                            <MinimalTableCell colSpan={3} align="center">
                                                No items available
                                            </MinimalTableCell>
                                        </TableRow>
                                    ) : (
                                        productVariables.map((variable) => (
                                            <TableRow key={variable.key}>
                                                <MinimalTableCell>{variable.key}</MinimalTableCell>
                                                <MinimalTableCell>{variable.description || "-"}</MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDeleteVariable(variable.key)}
                                                        loading={deletingKey === variable.key}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </MinimalTable>
                        </form>
                    )}
                />
            </Modal>
        </React.Fragment>
    );
};
