import _ from "lodash";
import React from "react";
import { shallowEqual } from "react-redux";
import { permissionService } from "src/services/permissionService/permissionService";
import UserSettingsService from "src/services/userSettingsService";
import { RootState, useSelector } from "src/store";
import { TagFilterHeader } from "src/ui/containers/dashboard/shared/components/TagFilterHeader";
import { HoverRow } from "src/ui/shared/table/HoverRow";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Header } from "src/ui/shared/table/TableHeader";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectFilesByGroupId, selectFilesListStatus, selectFilesShared } from "@dashboard/files/store/selectors";
import { createVersionCodeTag, hasApkMetadata, normalizeFiles } from "@dashboard/files/utils";
import { getBadgeContent, getColumnFilters } from "@dashboard/shared/components/SidePanel/shared/FileTab.utils";
import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { createRolloutTag, filterByTags } from "@dashboard/shared/tags";
import { selectIsWorkspaceFilesOverlimit, selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Group } from "@devices/types";
import { Box, Paper, TableBody, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondaryButton } from "@shared/CustomButton";
import {
    ColumnDef,
    ColumnFilter,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { ActionsCell } from "./components/ActionsCell";
import { FileUploadModal } from "./components/FileUploadModal";
import { FilterHeader } from "./FilterHeader";

const useStyles = makeStyles({
    headerBox: { display: "flex", justifyContent: "space-between", paddingBottom: 10 },
    btnBox: { display: "flex", gap: 5 },
    content: { maxHeight: "100%", marginBottom: 60 },
    tagsBox: {
        overflowY: "auto",
        maxWidth: 400,
        maxHeight: 70,
        display: "flex",
        flexWrap: "wrap",
        gap: 4,
        padding: 4,
    },
});

const useInitialFilters = () => {
    const filters: ColumnFilter[] = [];
    const tagsFilter = UserSettingsService.getGroupSidePanelFileTabTagsFilter();
    const typeFilter = UserSettingsService.getGroupSidePanelFileTabTypeFilter();

    if (tagsFilter.length) {
        filters.push({ id: "tags", value: tagsFilter });
    }
    if (typeFilter.length) {
        filters.push({ id: "groupId", value: typeFilter });
    }
    return filters;
};

const FileTab = (props: Group) => {
    const initialFilters = useInitialFilters();
    const [columnFilters, setColumnFilters] = React.useState<ColumnFilter[]>(initialFilters);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const classes = useStyles();

    const status = useSelector(selectFilesListStatus);
    const workspace = useSelector(selectWorkspace);

    const { groupAbility } = permissionService();
    const mayManageFile = groupAbility(props, workspace).can("manage", "File");

    const groupFiles = useSelector((state: RootState) => selectFilesByGroupId(state, props.id), shallowEqual);
    const normalizedGroupFiles = React.useMemo(() => normalizeFiles(groupFiles), [groupFiles]);
    const isOverlimit = useSelector(selectIsWorkspaceFilesOverlimit);
    const isExpired = useSelector(selectSubscriptionExpired);
    const sharedFiles = useSelector(selectFilesShared, shallowEqual);
    const data = React.useMemo(
        () => normalizeFiles([...sharedFiles, ...normalizedGroupFiles]),
        [sharedFiles, normalizedGroupFiles],
    );

    const tagsArray = _.compact(_.flatMap(data, (file) => file.metadata?.user?.tags));
    const columns: ColumnDef<API.File, unknown>[] = React.useMemo(
        () => [
            {
                header: "Package name",
                enableSorting: true,
                accessorKey: "filename",
                cell: (info) => {
                    const title = hasApkMetadata(info.row.original)
                        ? info.row.original.metadata?.file?.apk?.manifest?.package
                        : info.row.original.filename;
                    return (
                        <Box>
                            <Typography>{title}</Typography>
                        </Box>
                    );
                },
            },
            {
                header: "Source",
                accessorKey: "groupId",
                enableSorting: true,
                filterFn: (row, _columnId, filterValue: ("workspace" | "group")[]) => {
                    const isWorkspace = filterValue.includes("workspace");
                    const isGroup = filterValue.includes("group");

                    if (!filterValue.length) {
                        return true;
                    }

                    if (isWorkspace && isGroup) {
                        return true;
                    }

                    if (isGroup && row.original.groupId !== null) {
                        return true;
                    }

                    if (isWorkspace && row.original.groupId === null) {
                        return true;
                    }

                    return false;
                },
                cell: (info) => {
                    return (
                        <Box>
                            {info.row.original.groupId ? (
                                <Tooltip title="Group">
                                    <i className="fa-solid fa-folder" />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Workspace">
                                    <i className="fa-solid fa-folder-tree" />
                                </Tooltip>
                            )}
                        </Box>
                    );
                },
            },
            {
                header: "Tags",
                accessorKey: "tags",
                enableSorting: false,
                meta: { filterVariant: "filesTags" },
                filterFn: filterByTags,
                cell: (info) => {
                    const versionCodeTag = createVersionCodeTag(info.row.original);
                    const rolloutTag = createRolloutTag(info.row.original.rolloutPercentage);
                    const removableTags = info.row.original.metadata?.user?.tags;

                    return (
                        <div className={classes.tagsBox}>
                            {removableTags?.length
                                ? removableTags.map((item) => <Tag key={item.key} item={item} />)
                                : null}
                            {versionCodeTag ? (
                                <Tag key={versionCodeTag.key} item={versionCodeTag} theme="light" />
                            ) : null}
                            {rolloutTag ? <Tag key={rolloutTag.key} item={rolloutTag} theme="light" /> : null}
                        </div>
                    );
                },
            },
            {
                header: "Action",
                accessorKey: "action",
                enableSorting: false,
                cell: (info) => <ActionsCell file={info.row.original} groupId={props.id} />,
            },
        ],
        [props.id],
    );

    const table = useReactTable<API.File>({
        data,
        columns,
        state: { columnFilters },
        initialState: { sorting: [{ id: "filename", desc: true }] },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
    });

    const getAddBtnTitle = () => {
        const result = { title: "Upload file" };

        if (isExpired) {
            result.title = "Subscription expired";
        }
        if (!mayManageFile) {
            result.title = "You are not allowed to upload files in this group";
        }
        if (isOverlimit) {
            result.title = "You have reached maximum file limit";
        }
        return result;
    };
    const setTagFilters: React.Dispatch<React.SetStateAction<Record<string, boolean>>> = (values) => {
        setColumnFilters((prev) => {
            const prevFiltersWithoutTags = prev.filter((filter) => filter.id !== "tags");
            const value = Object.keys(_.pickBy(values, (item) => item));
            UserSettingsService.setWorkspaceValue("groupSidePanel.fileTab.tagsFilter", value);
            return [{ id: "tags", value }, ...prevFiltersWithoutTags];
        });
    };
    const setTypeFilters = (values: Record<string, boolean>) => {
        setColumnFilters((prev) => {
            const prevFiltersWithoutTags = prev.filter((filter) => filter.id !== "groupId");
            const value = Object.keys(_.pickBy(values, (item) => item));
            UserSettingsService.setWorkspaceValue("groupSidePanel.fileTab.typeFilter", value);
            return [{ id: "groupId", value }, ...prevFiltersWithoutTags];
        });
    };
    const getInitialFilters = () => {
        const filters = getColumnFilters("groupId", table);

        return {
            workspace: filters.includes("workspace"),
            group: filters.includes("group"),
        };
    };

    if (status === "pending") {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.headerBox}>
                <PaneElement.Header>Available files</PaneElement.Header>
                <SecondaryButton
                    onClick={() => setIsDialogOpen(true)}
                    tooltipProps={getAddBtnTitle()}
                    disabled={!mayManageFile || isOverlimit}
                >
                    <i className="fas fa-solid fa-square-plus" />
                </SecondaryButton>
            </Box>

            <TableContainer component={Paper}>
                <MinimalTable>
                    <MinimalTableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    if (header.id === "action") {
                                        return (
                                            <MinimalTableHeaderCell key={header.id} align="right">
                                                Actions
                                            </MinimalTableHeaderCell>
                                        );
                                    }
                                    if (header.id === "tags") {
                                        return (
                                            <TagFilterHeader
                                                key={header.id}
                                                header={header}
                                                tags={tagsArray}
                                                columnFilters={columnFilters}
                                                onSetFilters={setTagFilters}
                                            />
                                        );
                                    }
                                    if (header.id === "groupId") {
                                        return (
                                            <FilterHeader<API.File>
                                                key={header.id}
                                                header={header}
                                                badgeContent={getBadgeContent("groupId", columnFilters)}
                                                form={{
                                                    onSubmit: setTypeFilters,
                                                    initialValues: getInitialFilters(),
                                                }}
                                                options={[
                                                    { key: "workspace", label: "Workspace files" },
                                                    { key: "group", label: "Group files" },
                                                ]}
                                            />
                                        );
                                    }
                                    return <Header key={header.id} {...header} />;
                                })}
                            </TableRow>
                        ))}
                    </MinimalTableHeader>
                    <TableBody>
                        {table.getRowModel().rows.map((row) => (
                            <React.Fragment key={row.id}>
                                <HoverRow {...row}>
                                    {row.getVisibleCells().map((cell) => (
                                        <MinimalTableCell
                                            key={cell.id}
                                            onClick={() => null}
                                            style={{ overflow: "visible" }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </MinimalTableCell>
                                    ))}
                                </HoverRow>
                            </React.Fragment>
                        ))}
                        {!table.getRowModel().rows.length ? (
                            <TableRow>
                                <MinimalTableCell colSpan={columns.length + 1} align="center">
                                    No files
                                </MinimalTableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </MinimalTable>
            </TableContainer>

            <FileUploadModal open={isDialogOpen} handleClose={() => setIsDialogOpen(false)} groupId={props.id} />
        </PaneElement.Container>
    );
};

export default FileTab;
