import fileSize from "filesize";
import { useField } from "react-final-form";
import { theme as defaultTheme } from "src/providers/ThemeProvider";
import { useDispatch } from "src/store";
import { ProgressBar } from "src/ui/shared/ProgressBar";

import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast } from "@shared/toasts/Toasts";

import { useUploadContext } from "../context/UploadContext";
import { deleteFile } from "../store";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        border: "1px solid " + theme.palette.shadow[200],
        borderRadius: 10,
        padding: "10px 20px",
        marginBottom: 10,
        boxSizing: "border-box",
    },
    fileContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    fileName: {
        fontSize: 12,
        fontWeight: "bold",
    },
    fileSize: {
        fontSize: 12,
    },
    pointer: {
        cursor: "pointer",
    },
}));

interface ProgressBarProps {
    filesToUpload: File[] | null;
}

export const UploadProgressBar = (props: ProgressBarProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { uploadProgress, cancelUpload, uploadedFileIds } = useUploadContext();
    const fileInput = useField("file");
    const { setUploadedFileIds } = useUploadContext();
    const fileNameTitle = props.filesToUpload?.map((file) => file.name).join(", ");
    const fileSizeTitle = props.filesToUpload?.map((file) => (file.size ? fileSize(file.size) : "N/A")).join(", ");

    const handleCancelBtn = async () => {
        cancelUpload();
        fileInput.input.onChange(null);

        if (!props.filesToUpload?.length) {
            showErrorToast("No files to delete");
            return;
        }

        const remainingFileIds = [...uploadedFileIds];
        for (const id of uploadedFileIds) {
            try {
                await dispatch(deleteFile(id));
                const index = remainingFileIds.indexOf(id);
                if (index !== -1) {
                    remainingFileIds.splice(index, 1);
                    setUploadedFileIds(remainingFileIds);
                }
            } catch {
                showErrorToast("Error deleting file, please try again");
            }
        }
    };
    return (
        <div className={classes.container}>
            <div className={classes.fileContainer}>
                <div>
                    <div className={classes.fileName}>{fileNameTitle}</div>
                    <div className={classes.fileSize}>{fileSizeTitle}</div>
                </div>
                <div className={classes.pointer}>
                    <i className="fa fa-times" aria-hidden="true" onClick={handleCancelBtn}></i>
                </div>
            </div>
            <ProgressBar progress={uploadProgress} color={defaultTheme.palette.primary.main} />
        </div>
    );
};
