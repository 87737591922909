import { FormApi } from "final-form";
import React from "react";
import { Form, useField } from "react-final-form";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";
import { TextField } from "src/ui/shared/form/TextField";
import { Modal } from "src/ui/shared/Modal/Modal";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Tooltip } from "src/ui/shared/Tooltip";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { AndroidProject } from "@dashboard/products/types";
import AddIcon from "@mui/icons-material/Add";
import { Box, Chip, TableBody, TableContainer, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { actionOptions, projectValidationSchema } from "./ProjectSection.utils";

const useStyles = makeStyles({
    requiredBox: { marginBottom: 8, marginTop: 14, display: "flex", flexDirection: "column", gap: 8 },
    nonRequiredBox: { marginTop: 12, display: "flex", flexDirection: "column", gap: 8 },
    buttonBox: { marginBottom: 12, marginTop: 8, display: "flex", justifyContent: "flex-end" },
});

type FormValues = AndroidProject;

export const ProjectSection = () => {
    const [open, setOpen] = React.useState(false);

    const { input: projectsInput } = useField<AndroidProject[]>("customBuildConfig.projects");

    const classes = useStyles();
    const validate = useValidationSchema(projectValidationSchema);

    const onRemove = (project: AndroidProject) => {
        const newProjects = projectsInput.value.filter((item) => item.name !== project.name);
        projectsInput.onChange(newProjects);
    };

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        projectsInput.onChange([...projectsInput.value, values]);
        form.restart();
    };

    return (
        <React.Fragment>
            <Tooltip title="Manage git projects">
                <CustomBadge badgeContent={projectsInput.value.length}>
                    <Chip label="Projects" onClick={() => setOpen(true)} icon={<AddIcon />} />
                </CustomBadge>
            </Tooltip>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                title="Projects"
                description="Manage your Git projects by adding or removing repositories used in your custom
                        build configuration. You can also review the list of configured projects and remove
                        any unnecessary entries directly from the table below."
            >
                <Form<FormValues>
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{ action: "add" }}
                    render={({ handleSubmit, values, form }) => (
                        <form onSubmit={handleSubmit}>
                            <Box className={classes.requiredBox}>
                                <SelectField name="action" label="Action" options={actionOptions} fullWidth />
                                <TextField
                                    name="name"
                                    label="Name"
                                    placeholder="Git project name (e.g., platform_frameworks_base)"
                                    fullWidth
                                />
                            </Box>

                            {values.action === "add" ? (
                                <Box className={classes.nonRequiredBox}>
                                    <TextField
                                        name="path"
                                        label="Checkout path"
                                        placeholder="Destination path in repo checkout (e.g., frameworks/base)"
                                        fullWidth
                                    />
                                    <TextField
                                        name="remote"
                                        label="Remote"
                                        placeholder="Git remote (e.g., github)"
                                        fullWidth
                                    />
                                    <TextField
                                        name="revision"
                                        label="Revision"
                                        placeholder="Git revision or branch (e.g., android-12.0.0_r1)"
                                        fullWidth
                                    />
                                </Box>
                            ) : null}

                            <Box className={classes.buttonBox}>
                                <PrimaryOutlinedButton onClick={form.submit}>
                                    {values.action === "add" ? "Add" : "Remove"}
                                </PrimaryOutlinedButton>
                            </Box>

                            <TableContainer>
                                <MinimalTable>
                                    <MinimalTableHeader>
                                        <TableRow>
                                            <MinimalTableHeaderCell>Name</MinimalTableHeaderCell>
                                            <MinimalTableHeaderCell>Type</MinimalTableHeaderCell>
                                            <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                        </TableRow>
                                    </MinimalTableHeader>
                                    <TableBody>
                                        {!projectsInput.value.length ? (
                                            <TableRow>
                                                <MinimalTableCell colSpan={3} align="center">
                                                    No items available
                                                </MinimalTableCell>
                                            </TableRow>
                                        ) : null}

                                        {projectsInput.value.map((item) => (
                                            <TableRow key={item.name}>
                                                <MinimalTableCell align="left">{item.name}</MinimalTableCell>
                                                <MinimalTableCell align="left">
                                                    {item.action === "add" ? "Added" : "Removed"}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => onRemove(item)}
                                                        tooltipProps={{ title: "Delete" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </MinimalTable>
                            </TableContainer>
                        </form>
                    )}
                />
            </Modal>
        </React.Fragment>
    );
};
