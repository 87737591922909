import _ from "lodash";
import { RootState } from "src/store";

import { localState } from "@dashboard/devices/store";
import { Group, License } from "@dashboard/devices/types";
import { selectGroupById } from "@dashboard/groups/store/selectors";
import { selectLicenseById } from "@dashboard/licenses/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

type EntityTypes = "subscription" | "group" | "license" | "device";
export type BatchActionState = {
    isActive: boolean;
    type: "move" | null;
    entity: EntityTypes | null;
    selection: number[] | number[];
    targetId: number | null;
};
export const selectBatchState = (state: RootState): BatchActionState => localState(state).batchAction;
export const selectBatchActionIsActive = (state: RootState): boolean => selectBatchState(state).isActive;
export const selectBatchActionEntity = (state: RootState): EntityTypes | null => selectBatchState(state).entity;
export const selectBatchActionType = (state: RootState): BatchActionState["type"] => selectBatchState(state).type;
const selectBatchActionSelection = (state: RootState): number[] => selectBatchState(state)?.selection ?? [];
export const selectBatchActionSelectionTransformed = createSelector([selectBatchActionSelection], (selected) => {
    return _.mapValues(_.keyBy(selected), () => true);
});
export const selectBatchActionTargetId = (state: RootState): number | null => selectBatchState(state)?.targetId;

const selectBatchActionIsEntityLicense = createSelector(selectBatchActionEntity, (entity) => entity === "license");
const selectBatchActionIsEntityDevice = createSelector(selectBatchActionEntity, (entity) => entity === "device");
const selectBatchActionIsEntityGroup = createSelector(selectBatchActionEntity, (entity) => entity === "group");
export const selectBatchActionIsTypeMove = createSelector(selectBatchActionType, (type) => type === "move");
export const selectBatchActionIsActiveLicense = createSelector(
    selectBatchActionIsActive,
    selectBatchActionIsEntityLicense,
    (isActive, isLicense) => isActive && isLicense,
);
export const selectBatchActionIsActiveDevice = createSelector(
    selectBatchActionIsActive,
    selectBatchActionIsEntityDevice,
    (isActive, isDevice) => isActive && isDevice,
);
const selectBatchActionIsActiveGroup = createSelector(
    selectBatchActionIsActive,
    selectBatchActionIsEntityGroup,
    (isActive, isGroup) => isActive && isGroup,
);
export const selectBatchActionSelectionEntities = createSelector(
    selectBatchActionSelection,
    selectBatchActionIsActiveGroup,
    selectBatchActionIsActiveLicense,
    (state: RootState) => state,
    (selection, isGroup, isLicense, state) => {
        return selection
            .map((id) => {
                if (isGroup) {
                    return selectGroupById(state, id);
                } else if (isLicense) {
                    return selectLicenseById(state, id);
                }
                return null;
            })
            .filter((item) => !!item) as License[] | Group[];
    },
);
