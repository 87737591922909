import React from "react";
import { useDispatch, useSelector } from "src/store";

import { closeDetails, selectPaneTab, setPaneTab } from "@devices/store";
import { Group } from "@devices/types";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import { SecondaryButton } from "@shared/CustomButton";

import CommandTab from "../shared/CommandTab/CommandTab";
import { PaneElement } from "../shared/PaneElement";
import { useStyle } from "../shared/style";
import EnrollmentTab from "./EnrollmentTab/EnrollmentTab";
import FileTab from "./FileTab/FileTab";
import MemberTab from "./MemberTab/MemberTab";
import MenuGroup from "./MenuGroup/MenuGroup";
import PolicyTab from "./PolicyTab/PolicyTab";
import SummaryTab from "./SummaryTab/SummaryTab";

const groupTabs = ["SUMMARY", "MEMBERS", "FILES", "ENROLLMENT", "POLICY", "COMMANDS"];

export const GroupSidePanel = (props: Group) => {
    const dispatch = useDispatch();
    const classes = useStyle();

    const value = useSelector(selectPaneTab);
    const isGroupTab = groupTabs.includes(value);

    const onChange = (_event: React.ChangeEvent<object>, tabTitle: string) => {
        dispatch(setPaneTab(tabTitle));
    };

    React.useEffect(() => {
        if (!isGroupTab) {
            dispatch(setPaneTab("SUMMARY"));
        }
    }, [isGroupTab, dispatch]);

    if (!isGroupTab) {
        return null;
    }

    return (
        <TabContext value={value}>
            <PaneElement.HeaderBox>
                <Box>
                    <Typography variant="h6">Group</Typography>
                    <Typography variant="body2">{props.name}</Typography>
                </Box>

                <Box className={classes.buttonBox}>
                    <MenuGroup {...props} />
                    <SecondaryButton onClick={() => dispatch(closeDetails())}>
                        <i className="fas fa-times" />
                    </SecondaryButton>
                </Box>
            </PaneElement.HeaderBox>

            <TabList onChange={onChange} variant="scrollable" scrollButtons>
                <Tab
                    value="SUMMARY"
                    label="Summary"
                    iconPosition="start"
                    icon={<i className="fas fa-tachometer-alt" />}
                />
                <Tab value="MEMBERS" label="Members" iconPosition="start" icon={<i className="fas fa-users" />} />
                <Tab value="FILES" label="Files" iconPosition="start" icon={<i className="fas fa-file" />} />
                <Tab
                    value="ENROLLMENT"
                    label="Enrollment"
                    iconPosition="start"
                    icon={<i className="fas fa-qrcode" />}
                />
                <Tab value="POLICY" label="Policy" iconPosition="start" icon={<i className="fas fa-gears" />} />
                <Tab value="COMMANDS" label="Commands" iconPosition="start" icon={<i className="fas fa-terminal" />} />
            </TabList>

            <Box className={classes.contentBox}>
                <TabPanel value="SUMMARY">
                    <SummaryTab {...props} />
                </TabPanel>
                <TabPanel value="MEMBERS">
                    <MemberTab {...props} />
                </TabPanel>
                <TabPanel value="FILES">
                    <FileTab {...props} />
                </TabPanel>
                <TabPanel value="ENROLLMENT">
                    <EnrollmentTab {...props} />
                </TabPanel>
                <TabPanel value="POLICY">
                    <PolicyTab {...props} />
                </TabPanel>
                <TabPanel value="COMMANDS">
                    <CommandTab group={props} />
                </TabPanel>
            </Box>
        </TabContext>
    );
};
