import { FormApi } from "final-form";
import _ from "lodash";
import { Form } from "react-final-form";
import { useDispatch } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { Modal } from "src/ui/shared/Modal/Modal";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import * as yup from "yup";

import { updateProductAndroidVersion, updateProductCodename } from "@dashboard/products/api";
import { updateProduct } from "@dashboard/products/store";
import { ProductAdmin } from "@dashboard/products/types";
import { DialogProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TextField } from "@shared/form/TextField";

const validationSchema = yup.object({
    codename: yup.string().required().label("Codename"),
    version: yup.number().required().label("Android version"),
    name: yup.string().required().label("Product name"),
});

const useStyles = makeStyles({
    dialog: {
        minWidth: 400,
        gap: 6,
        display: "flex",
        flexDirection: "column",
    },
    errorMessage: {
        color: "red",
        fontSize: "12px",
        padding: 4,
    },
});

type FormValues = {
    codename: string;
    version: string;
    duplicate: boolean;
    name: string;
};

type Props = DialogProps & {
    onClose: () => void;
    product: ProductAdmin;
    products: API.Product[];
    setProducts: (products: API.Product[]) => void;
};

export const EditProductDialog = (props: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const codenameAndroidVersions = props.products
        .filter((item) => item.id !== props.product.id)
        .map((item) => item.codename + item.androidVersion);

    const onValidate = async (values: FormValues) => {
        const errors: Partial<Record<keyof FormValues, string>> = {};
        if (codenameAndroidVersions.includes(values.codename + values.version)) {
            const isDuplicate = props.products.some(
                (item) =>
                    item.id !== props.product.id &&
                    item.codename === values.codename &&
                    item.androidVersion === values.version,
            );

            if (isDuplicate) {
                errors.duplicate = "Product with this codename and android version already exists";
            }
        }
        try {
            await validationSchema.validate(values, { abortEarly: false });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.inner.forEach((validationError) => {
                    if (validationError.path) {
                        errors[validationError.path as keyof FormValues] = validationError.message;
                    }
                });
            }
        }

        return errors;
    };
    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        try {
            const product = { ...props.product };

            if (form.getFieldState("version")?.dirty) {
                await updateProductAndroidVersion(props.product.id, values.version);
                product.androidVersion = values.version;
            }
            if (form.getFieldState("codename")?.dirty) {
                await updateProductCodename(props.product?.id, values.codename);
                product.codename = values.codename;
            }
            if (form.getFieldState("name")?.dirty && product.customBuildConfig) {
                await dispatch(
                    updateProduct({
                        ...props.product,
                        customBuildConfig: {
                            ...product.customBuildConfig,
                            productName: values.name,
                        },
                    }),
                );
                product.customBuildConfig.productName = values.name;
            }
            showSuccessToast("Product updated successfully");
            const updatedProductsData = _.uniqBy([product, ...props.products], "id");
            props.setProducts(updatedProductsData);
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong during updating product's codename");
        } finally {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={onValidate}
            initialValues={{
                codename: props.product.codename,
                version: props.product.androidVersion,
                name: props.product.customBuildConfig?.productName,
            }}
            render={({ handleSubmit, submitting, form, errors }) => (
                <form onSubmit={handleSubmit} id="editProduct">
                    <Modal
                        open={props.open}
                        onClose={props.onClose}
                        title={props.title}
                        endButton={
                            <PrimaryButton
                                type="submit"
                                disabled={submitting || form.getState().pristine}
                                form="editProduct"
                            >
                                Save
                            </PrimaryButton>
                        }
                    >
                        <TextField fullWidth label="Product name" name="name" style={{ marginTop: 4 }} />
                        <TextField fullWidth label="Codename" name="codename" />
                        <TextField fullWidth label="Android version" name="version" />
                        {errors?.duplicate && <div className={classes.errorMessage}>{errors.duplicate}</div>}
                    </Modal>
                </form>
            )}
        />
    );
};
