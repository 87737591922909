import { FormApi } from "final-form";
import { Form } from "react-final-form";
import semver from "semver";
import { useDispatch, useSelector } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { TextareaField } from "src/ui/shared/form/TextareaField";
import { Modal } from "src/ui/shared/Modal/Modal";

import { BuildConfig } from "@dashboard/products/api/index";
import { createBuild } from "@dashboard/products/store/index";
import { selectBuilds } from "@dashboard/products/store/selectors/builds";
import { Build, Product } from "@dashboard/products/types/index";
import { Box, DialogProps, InputAdornment, SelectChangeEvent, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ControlledMultiSelectField, SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";

import { BuildVariantLabel } from "../../../Builds/shared";
import { buildVariantOptions } from "../Recipes";

const useStyles = makeStyles((_theme: Theme) => ({
    sectionBox: {
        display: "flex",
        gap: 6,
        alignItems: "center",
    },
    fromVersionContainer: {
        width: "100%",
    },
}));

type Props = Omit<DialogProps, "onClose"> & {
    onClose: () => void;
    product: Product;
};
type FormValues = BuildConfig;
type SelectHandler = (e: SelectChangeEvent<unknown>, form: FormApi<FormValues, Partial<FormValues>>) => void;
type TextAreaHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    form: FormApi<FormValues, Partial<FormValues>>,
) => void;

export const getNextVersion = (builds: Build[], androidVersion: string): string => {
    const relevantBuilds = builds.map((build) => build.version).sort(semver.compare);

    if (relevantBuilds.length === 0) {
        return `${androidVersion}.0.0`;
    }

    const latestVersion = relevantBuilds[relevantBuilds.length - 1];

    const nextVersion = semver.inc(latestVersion, "minor");

    return nextVersion || `${androidVersion}.0.0`;
};
const validate = (values: FormValues, androidVersion: string) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    const versionPattern = new RegExp(`^${androidVersion}\\.\\d+\\.\\d+$`);
    if (!versionPattern.test(values.version)) {
        errors.version = `Version must be in the format ${androidVersion}.X.Y, where X and Y are numbers.`;
    }

    if (values.rolloutPercentage < 0 || values.rolloutPercentage > 100) {
        errors.rolloutPercentage = "Rollout must be in the range from 0 to 100";
    }
    return errors;
};

export const ConfigurationModal = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const productAndroidVersion = props.product.androidVersion.slice(0, 2);
    const builds = useSelector(selectBuilds)
        ?.filter((build) => build.productId === props.product.id)
        .filter((build) => build.buildStatus === "succeeded");

    const fromVersionBuilds = builds.filter((build) => build.artifacts && build.artifacts.includes("Manifest"));
    const preselectedVersion = getNextVersion(builds, productAndroidVersion);
    const fromVersionOptions = [
        { label: "Create new build based on the latest state", value: "empty" },
        ...fromVersionBuilds.map((build) => ({
            label: build.version || "",
            value: build.version || "",
        })),
    ];
    const incrementalFromVersionsOptions = builds
        .filter((build) => build.artifacts && build.artifacts.includes("SignedFiles"))
        .map((build) => ({ label: build.version || "", value: build.version || "" }));

    const handleVersionChange: SelectHandler = (e, form) => {
        if (typeof e.target.value !== "string") {
            return;
        }
        if (e.target.value === "empty") {
            form.change("configSnapshot", undefined);
        } else {
            const selectedBuild = fromVersionBuilds.find((build) => build.version === e.target.value);
            if (selectedBuild?.configSnapshot) {
                form.change("configSnapshot", selectedBuild.configSnapshot);
            }
        }
    };
    const handleChangesChange: TextAreaHandler = (e, form) => {
        if (e.target.value.length) {
            form.change("changes", [e.target.value]);
        } else {
            form.change("changes", []);
        }
    };
    const onSubmit = (values: FormValues) => {
        const filteredValues = { ...values };

        if (filteredValues.configOverride?.manifest?.fromVersion === "empty") {
            delete filteredValues.configOverride.manifest;
        }

        if (filteredValues.configOverride.buildVariant === props.product.customBuildConfig?.buildVariant) {
            delete filteredValues.configOverride.buildVariant;
        }

        dispatch(createBuild({ productId: props.product.id, config: filteredValues }))
            .unwrap()
            .catch(({ message = "Something went wrong during creating new build" }) => showErrorToast(message))
            .finally(() => props.onClose());
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        const { submitting, active } = form.getState();

        if (e.key === "Enter" && !submitting && !active) {
            await form.submit();
        }
    };

    return (
        <Form<FormValues>
            initialValues={{
                version: preselectedVersion,
                rolloutPercentage: 100,
                configOverride: {
                    manifest: { fromVersion: "empty" },
                    ota: { incrementalFromVersions: [] },
                    buildVariant: props.product.customBuildConfig?.buildVariant,
                },
            }}
            validate={(values) => validate(values, productAndroidVersion)}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, form }) => (
                <Modal
                    {...props}
                    title="New OS build"
                    description='Initiate a new OS build by clicking "Start". Build configuration parameters below are all optional.'
                    onKeyDown={(e) => onKeyDown(e, form)}
                    endButton={
                        <PrimaryButton onClick={form.submit} disabled={submitting} loading={submitting}>
                            Start
                        </PrimaryButton>
                    }
                >
                    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                        <div className={classes.sectionBox}>
                            <TextField name="version" label="OS version" fullWidth />
                            <Tooltip title="Semantic version number in form &lt;Android Version&gt;.&lt;number&gt;.&lt;number&gt;">
                                <i className="fas fa-info-circle" />
                            </Tooltip>
                        </div>

                        <SelectField
                            fullWidth
                            name="configOverride.manifest.fromVersion"
                            label="Source config"
                            options={fromVersionOptions}
                            onChange={(e) => handleVersionChange(e, form)}
                        />

                        <div className={classes.sectionBox}>
                            <Tooltip
                                className={classes.fromVersionContainer}
                                placement="top"
                                hide={!!incrementalFromVersionsOptions.length}
                                title="There are no versions to choose from"
                            >
                                <ControlledMultiSelectField
                                    name="configOverride.ota.incrementalFromVersions"
                                    label="Delta updates"
                                    placeholder="Delta updates"
                                    options={incrementalFromVersionsOptions}
                                    disabled={!incrementalFromVersionsOptions.length}
                                />
                            </Tooltip>
                            <Tooltip title="A new build will always generate a full update that can be installed on any device. To generate incremental updates for specific versions, you must select those versions here">
                                <i className="fas fa-info-circle" />
                            </Tooltip>
                        </div>

                        <TextareaField
                            name="changes"
                            label="Changelog"
                            minRows={5}
                            onChange={(e) => handleChangesChange(e, form)}
                            placeholder={
                                "- Fixed bug in network module\n- Improved battery performance\n- Added support for new hardware"
                            }
                            fullWidth
                        />

                        <TextField
                            name="rolloutPercentage"
                            label="Rollout"
                            type="number"
                            fullWidth
                            required
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />

                        <Box className={classes.sectionBox}>
                            <SelectField
                                name="configOverride.buildVariant"
                                label="Build variant"
                                options={buildVariantOptions}
                                fullWidth
                            />
                            <BuildVariantLabel />
                        </Box>
                    </form>
                </Modal>
            )}
        />
    );
};
