import { AxiosResponse } from "axios";
import * as queryString from "query-string";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse, BasicApiResponse, ListResponse } from "src/types";

import { Group, GroupRoles } from "@dashboard/devices/types";

import { MdmCommand } from "../types/commands";
import { unpackError } from "./helpers";

const options = {
    // data needs to be attached because of an axios bug
    // https://github.com/axios/axios/issues/86#issuecomment-139638284
    data: "",
    headers: {
        "content-type": "application/json",
    },
};

type CreateGroupPayload = {
    name: string;
    description: string;
    subscriptionId: number;
    parentId: number | null;
};
export const createGroup = async (payload: CreateGroupPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const { data } = await httpService.post<CreateGroupPayload, AxiosResponse<ApiResponse<API.Group>>>(
            endpoint,
            payload,
        );
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export type UpdateGroupPayload = {
    id: number;
    name?: string;
    description?: string;
    subscriptionId?: number;
    addedLicenseIds?: number[];
    addedMembers?: Record<string, GroupRoles>;
    changedMembers?: Record<string, GroupRoles>;
    removedMembers?: string[];
};
export const updateGroup = async (payload: UpdateGroupPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const url = endpoint + String(payload.id);
        const { data } = await httpService.patch<UpdateGroupPayload, AxiosResponse<ApiResponse<[API.Group] | []>>>(
            url,
            payload,
        );
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

type UpdateGroupParentPayload = {
    id: number;
    parentId: number | null;
};
export const updateGroupParent = async (payload: UpdateGroupParentPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const { data } = await httpService.patch<
            UpdateGroupParentPayload["parentId"],
            AxiosResponse<ApiResponse<API.Group>>
        >(endpoint + String(payload.id) + "/parent", payload.parentId, {
            headers: { "content-type": "application/json" },
        });
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

type DeleteGroupPayload = {
    id: number;
};
export const deleteGroup = async (payload: DeleteGroupPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        await httpService.delete(endpoint + String(payload.id));
        return Promise.resolve();
    } catch (error) {
        throw unpackError(error);
    }
};

type UpdatePolicyPayload = {
    groupId: number;
    policy: string;
};
export const updatePolicy = async (payload: UpdatePolicyPayload): Promise<Group | undefined> => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const { data } = await httpService.put<string, AxiosResponse<ApiResponse<Group>>>(
            `${endpoint}${payload.groupId}/policy`,
            payload.policy,
            options,
        );

        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
type FetchGroupMdmCommandsPayload = { groupId: number; pageSize?: number; pageNumber?: number };
export const fetchGroupMdmCommands = async (payload: FetchGroupMdmCommandsPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const { data } = await httpService.get<string, AxiosResponse<ListResponse<MdmCommand>>>(
            endpoint + `${payload.groupId}/commands`,
            { ...options, params: { pageSize: payload.pageSize || 100, pageNumber: payload.pageNumber || 1 } },
        );

        return data.list;
    } catch (error) {
        throw unpackError(error);
    }
};

type CreateGroupMdmCommandPayload = {
    groupId: number;
    json: Record<string, unknown>;
    commandType?: string;
    commandParameter?: string;
};
export const createGroupMdmCommand = async (payload: CreateGroupMdmCommandPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const { data } = await httpService.post<string, AxiosResponse<ListResponse<MdmCommand>>>(
            endpoint + `${payload.groupId}/commands`,
            queryString.stringify({ id: payload.groupId, commands: JSON.stringify(payload.json) }),
            {
                headers: { "content-type": "application/x-www-form-urlencoded" },
            },
        );
        return data.list;
    } catch (error) {
        unpackError(error);
    }
};

interface DeleteGroupMdmCommandPayload {
    groupId: number;
    command: MdmCommand;
}
export const deleteGroupMdmCommand = async (payload: DeleteGroupMdmCommandPayload) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const response = await httpService.delete<string, AxiosResponse<BasicApiResponse>>(
            endpoint + `${payload.groupId}/commands/${payload.command.id}`,
        );
        return response;
    } catch (error) {
        unpackError(error);
    }
};

export const fetchGroups = async (ids: number[]) => {
    const query = queryString.stringify({ ids }, { arrayFormat: "none" });
    const endpoint = await RouteService.getGroupsRoute();
    const fullUrl = endpoint + `?${query}`;

    const { data } = await httpService.get<ApiResponse<API.Group[]>>(fullUrl);
    return data.model;
};
