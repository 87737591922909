import React from "react";
import { ConfirmDialog } from "src/ui/containers/dashboard/devices/components/shared";
import { invokeFileDownload } from "src/ui/containers/dashboard/files/api";
import { useDeleteFile } from "src/ui/containers/dashboard/files/hooks/entities";
import { DangerOutlinedButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { MinimalTableCell } from "src/ui/shared/table/MinimalTableComponents";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";

import { Box, DialogContentText, Typography } from "@mui/material";
import { flexRender, Row } from "@tanstack/react-table";

import { State } from "./AdminFilesPage";
import { useStyles } from "./AdminFilesPage.style";
import { CollapsibleDetails, CollapsibleRow, CollapsibleSummary } from "./CollapsibleRow";

type TableItemProps = {
    setState: (value: React.SetStateAction<State>) => void;
    row: Row<API.File>;
};

const TableItem: React.FC<TableItemProps> = ({ setState, row }) => {
    const classes = useStyles();

    const [deleteFileId, setDeleteFileId] = React.useState<string | null>(null);
    const [fetchingFileId, setFetchingFileId] = React.useState<string | null>(null);

    const fileItem = row.original;

    const { removeFile } = useDeleteFile();

    const onDelete = async () => {
        if (deleteFileId) {
            const wasDeleted = await removeFile(deleteFileId);
            if (wasDeleted) {
                setState((prev) => ({
                    ...prev,
                    count: prev.count - 1,
                    data: prev.data.filter((item) => item.id !== deleteFileId),
                }));
            }
        }
    };

    const onDownload = async (item: API.File) => {
        try {
            setFetchingFileId(item.id);
            await invokeFileDownload(item.id, item.filename);
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
        } finally {
            setFetchingFileId(null);
        }
    };

    return (
        <CollapsibleRow>
            <CollapsibleSummary>
                {row
                    .getVisibleCells()
                    .map((cell) => flexRender(cell.column.columnDef.cell, { ...cell.getContext(), key: cell.id }))}
                <MinimalTableCell align="right">
                    <div className={classes.actionCell}>
                        <SecondaryButton
                            onClick={() => onDownload(fileItem)}
                            loading={fileItem.id === fetchingFileId}
                            disabled={fileItem.id === fetchingFileId}
                        >
                            <i className="fa-solid fa-download" />
                        </SecondaryButton>
                        <DangerOutlinedButton onClick={() => setDeleteFileId(fileItem.id)}>
                            <i className="fa-solid fa-trash-alt" />
                        </DangerOutlinedButton>
                    </div>
                </MinimalTableCell>
            </CollapsibleSummary>
            <CollapsibleDetails>
                <Box className={classes.flex}>
                    <Typography className={classes.boldLabel}>File ID: </Typography>
                    <Typography>{fileItem.id}</Typography>
                </Box>
                {fileItem?.metadata?.file?.apk ? (
                    <>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Package: </Typography>
                            <Typography>{fileItem.metadata.file.apk.manifest?.package}</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Version code:</Typography>
                            <Typography>{fileItem.metadata.file.apk.manifest?.versioncode}</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Version name:</Typography>
                            <Typography>{fileItem.metadata.file.apk.manifest?.versionname}</Typography>
                        </Box>
                    </>
                ) : null}
                {fileItem?.metadata?.version ? (
                    <>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Version: </Typography>
                            <Typography>{fileItem.metadata.version}</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Codename: </Typography>
                            <Typography>{fileItem.metadata.codename}</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Product ID: </Typography>
                            <Typography>{fileItem.metadata.productId}</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography className={classes.boldLabel}>Product name: </Typography>
                            <Typography>{fileItem.metadata.productName}</Typography>
                        </Box>
                    </>
                ) : null}
                {fileItem?.metadata?.otaMetadata ? (
                    <>
                        <Box className={classes.flex}>
                            <Typography style={{ fontWeight: 600 }}>This is an OTA Update</Typography>
                        </Box>
                    </>
                ) : null}
            </CollapsibleDetails>
            <ConfirmDialog
                title="Delete file"
                dangerButton
                primaryActionText="Delete"
                open={!!deleteFileId}
                onConfirm={onDelete}
                onClose={() => setDeleteFileId(null)}
            >
                <DialogContentText>Are you sure you want to delete this file?</DialogContentText>
            </ConfirmDialog>
        </CollapsibleRow>
    );
};

export default TableItem;
