import React from "react";
import { theme } from "src/providers/ThemeProvider";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@shared/Tooltip";
import { CellContext } from "@tanstack/react-table";

import { GroupOverviewData } from "../../GroupsPage";

const useStyles = makeStyles(() => ({
    box: { display: "flex", alignItems: "center" },
    cellContent: {
        display: "inline-block",
        maxWidth: 240,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        position: "relative",
    },
    defaultBadge: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        fontSize: 12,
        padding: "2px 6px",
        borderRadius: 12,
        width: 52,
        marginLeft: 8,
    },
    highlightedText: {
        backgroundColor: theme.palette.yellow[100],
    },
}));

type Props = CellContext<GroupOverviewData, unknown> & { globalFilter: string; withIndent?: boolean };

const TableCell = (props: React.PropsWithChildren<Props>) => {
    const [isTruncated, setIsTruncated] = React.useState(false);
    const textRef = React.useRef<HTMLSpanElement>(null);

    const classes = useStyles();

    const value = props.getValue<string>();
    const shouldHighlight = props.globalFilter && value.toLowerCase().includes(props.globalFilter.toLowerCase());
    const regex = new RegExp(`(${props.globalFilter})`, "gi");
    const parts = value?.split(regex);

    React.useEffect(() => {
        const el = textRef.current;
        if (el) {
            setIsTruncated(el.scrollWidth > el.clientWidth);
        }
    }, [value]);

    return (
        <Tooltip title={isTruncated ? value : ""} placement="top" PopperProps={{ disablePortal: false }}>
            <Box
                className={classes.box}
                style={{ paddingLeft: props.withIndent ? `${props.row.depth * 2}rem` : "unset" }}
            >
                {props.children}
                <span className={classes.cellContent} ref={textRef}>
                    {shouldHighlight
                        ? parts.map((part, index) =>
                              part.toLowerCase() === props.globalFilter.toLowerCase() ? (
                                  <span key={index} className={classes.highlightedText}>
                                      {part}
                                  </span>
                              ) : (
                                  part
                              ),
                          )
                        : value}
                </span>
                {props.column.id === "name" && props.row.original.isDefault && (
                    <Box className={classes.defaultBadge}>
                        <span>Default</span>
                    </Box>
                )}
            </Box>
        </Tooltip>
    );
};

export default TableCell;
