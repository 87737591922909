type Extra = {
    key: string;
    type: "string" | "bool";
    value: string;
};

type Intent = {
    component: string;
    extras?: Extra[];
};

type InternalOsUpdateCommandPayload = {
    caption: string;
    command: string;
    action: string;
    intent: Intent;
    version: string;
};

type FinalOsUpdateCommandPayload = {
    caption: string;
    command: string;
    intent: Intent;
};

const transformOsUpdatePayload = (commandPayload: InternalOsUpdateCommandPayload): FinalOsUpdateCommandPayload => {
    const { version, action, intent, ...newPayload } = commandPayload;

    const extras: Extra[] = intent.extras ?? [];

    const updatedExtras: Extra[] = [
        ...extras,
        { key: "version", type: "string", value: version },
        { key: "action", type: "string", value: action },
    ];

    return {
        ...newPayload,
        intent: {
            ...intent,
            extras: updatedExtras,
        },
    };
};

type CommandPayload = Record<string, unknown>;

export const prepareCommandPayload = (commandType: string, payload: CommandPayload): Record<string, unknown> => {
    if (commandType === "startOsUpdate") {
        return transformOsUpdatePayload(payload as InternalOsUpdateCommandPayload);
    }

    return payload;
};
