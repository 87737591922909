import { useState } from "react";

import { SelectChangeEvent } from "@mui/material";

import { Group } from "../devices/types";

type ValidationErrors = {
    name?: string;
    description?: string;
    parentId?: string;
};

const getGroupDepth = (groupId: number | null, groups: Group[], depth = 0): number => {
    if (!groupId) {
        return depth;
    }
    const parentGroup = groups.find((group) => group.id === groupId);
    return parentGroup ? getGroupDepth(parentGroup.parentId, groups, depth + 1) : depth;
};

export const validateGroupForm = (
    values: { name: string; description: string; parentId: number | null },
    groups: Group[],
    groupNestingLimit: number,
    currentGroupId?: number,
): ValidationErrors => {
    const errors: ValidationErrors = {};

    const groupNameExists = groups.some(
        (group) =>
            group.id !== currentGroupId && group.name?.toLowerCase().trim() === values.name?.toLowerCase().trim(),
    );

    if (!values.name) {
        errors.name = "Name is required";
    } else if (groupNameExists) {
        errors.name = "A group with this name already exists in this workspace";
    } else if (!isNaN(Number(values.name))) {
        errors.name = "Group name cannot be a number";
    }

    if (values.parentId !== null && values.parentId !== undefined) {
        if (getGroupDepth(values.parentId, groups) >= groupNestingLimit) {
            errors.parentId = "Group nesting limit reached. Cannot select this parent.";
        }
    }

    return errors;
};

export const useParentGroupSelection = (groups: Group[], groupNestingLimit: number) => {
    const [isLimitReached, setIsLimitReached] = useState(false);

    const handleParentGroupChange = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;

        if (typeof value !== "number" && value !== null) {
            setIsLimitReached(false);
            return;
        }

        const depth = getGroupDepth(value, groups);
        setIsLimitReached(depth >= groupNestingLimit);
    };

    return { isLimitReached, handleParentGroupChange };
};

export const normalizeGroups = (groups: Group[]) => {
    const groupIds: (number | null)[] = groups.map((group) => group.id);

    return groups.map((group) => ({
        ...group,
        // if user dont have access to parent group, set parentId to null
        parentId: !groupIds.includes(group.parentId) ? null : group.parentId,
    }));
};
