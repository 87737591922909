import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useDispatch } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";
import { TextField } from "src/ui/shared/form/TextField";
import { Modal } from "src/ui/shared/Modal/Modal";

import { createGroup } from "@dashboard/devices/store";
import { Subscription } from "@dashboard/devices/types";
import { selectGroupNestingLimit, selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { selectGroups } from "../store/selectors";
import { useParentGroupSelection, validateGroupForm } from "../utils";

interface FormValues {
    name: string;
    description: string;
    parentId: number | null;
}

interface Props {
    open: boolean;
    onClose: () => void;
    subscription?: Subscription;
}

const CreateGroupDialog = ({ open = false, ...props }: Props) => {
    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace);
    const groups = useSelector(selectGroups);
    const groupNestingLimit = useSelector(selectGroupNestingLimit);
    const validate = (values: FormValues) => validateGroupForm(values, groups, groupNestingLimit);
    const { isLimitReached, handleParentGroupChange } = useParentGroupSelection(groups, groupNestingLimit);
    const options = [{ label: "-", value: undefined }, ...groups.map((item) => ({ label: item.name, value: item.id }))];

    const onSubmit = async (values: FormValues, form: FormApi<FormValues, Partial<FormValues>>) => {
        if (workspace) {
            await dispatch(createGroup({ subscriptionId: workspace.subscription, ...values }))
                .unwrap()
                .then((e) => {
                    if (e) {
                        const savedGroups = sessionStorage.getItem("newGroups") || "[]";
                        const groupsArray = [...JSON.parse(savedGroups), e.id];
                        sessionStorage.setItem("newGroups", JSON.stringify(groupsArray));
                    }
                    showSuccessToast("Created group");
                    form.restart();
                    props.onClose();
                })
                .catch(({ message = "Couldn't create group" }) => {
                    showErrorToast(message);
                });
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            e.preventDefault();
            await form.submit();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{ name: "", description: "", parentId: null }}
            render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        open={open}
                        onClose={props.onClose}
                        title="Add group"
                        onKeyDown={(e) => onKeyDown(e, form)}
                        endButton={
                            <PrimaryButton
                                onClick={() => form.submit()}
                                disabled={isLimitReached}
                                tooltipProps={{
                                    title: isLimitReached
                                        ? "Group nesting limit reached. Cannot create a subgroup at this level."
                                        : "",
                                }}
                            >
                                Create
                            </PrimaryButton>
                        }
                    >
                        <TextField name="name" label="Name" placeholder="Name" fullWidth required />
                        <TextField name="description" label="Description" placeholder="Description" fullWidth />
                        <SelectField
                            name="parentId"
                            label="Parent group"
                            placeholder="Parent group"
                            onChange={handleParentGroupChange}
                            options={options}
                            fullWidth
                        />
                    </Modal>
                </form>
            )}
        />
    );
};

export default CreateGroupDialog;
