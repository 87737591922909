import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    otpInputField: {
        width: "48px",
        height: "48px",
        border: `2px solid ${theme.palette.shadow[300]}`,
        borderRadius: "4px",
        backgroundColor: "transparent",
        outline: "none",
        textAlign: "center",
        fontWeight: "600",
        fontSize: "20px",
        transition: "border-color 0.3s, color 0.3s",
        "&:focus": {
            borderWidth: "3px",
            borderColor: theme.palette.blue[200],
        },
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            appearance: "none",
            margin: 0,
        },
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
    },

    otpInputContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 10,
    },
    actionButtonContainer: {
        marginTop: 6,
        gap: 10,
        display: "flex",
        justifyContent: "flex-end",
    },
    forumHintText: {
        marginTop: 6,
    },
    description: {
        margin: 0,
        marginBottom: 20,
        fontSize: 16,
        fontWeight: "normal",
    },
}));
