import _ from "lodash";
import React from "react";
import { useField, useForm } from "react-final-form";
import { androidVersionOptions, products } from "src/data/products";

import { InputBaseProps } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { Product } from "@products/types";
import { UncontrolledSelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";

const getAndroidVersionOptions = (value: string | undefined) => {
    const versionNames = Object.keys(androidVersionOptions);
    const versions =
        value && versionNames.includes(value) ? androidVersionOptions[value] : ["15", "14", "13", "12", "11"];
    return versions.map((version) => ({
        label: version,
        value: version,
    }));
};

export const HardwareInput = () => {
    const form = useForm<Product>();
    const { input: rootDeviceInput } = useField<string | undefined>("customBuildConfig.rootDevice");
    const { input: compatibilityDeviceInput } = useField<string | undefined>("customBuildConfig.compatibility");

    const platformOptions = _(products)
        .map(({ deviceName, rootDevice }) => ({ label: deviceName, value: rootDevice }))
        .uniqBy("label")
        .sortBy("label")
        .value();

    const isPredefinedOption = platformOptions.some((option) => option.value === rootDeviceInput.value);

    const onPlatformChange = (value: string) => {
        const androidVersions = getAndroidVersionOptions(value);
        const androidVersion = androidVersions[0].value;

        form.batch(() => {
            form.change("customBuildConfig.rootDevice" as keyof Product, value);
            form.change("androidVersion", androidVersion);
            compatibilityDeviceInput.onChange(`>=${androidVersion}`);
        });
    };
    const onChange: SelectInputProps["onChange"] = (e) => {
        const value = e.target.value;
        if (typeof value === "string") {
            onPlatformChange(value);
        }
    };
    const onBlur: InputBaseProps["onBlur"] = (e) => {
        const value = e.target.value;
        onPlatformChange(value);
    };

    return (
        <React.Fragment>
            <UncontrolledSelectField
                label="Hardware platform"
                value={isPredefinedOption ? rootDeviceInput.value : "custom"}
                options={[
                    { label: "Custom platform", value: "custom", disabled: !isPredefinedOption },
                    ...platformOptions,
                ]}
                defaultValue="custom"
                onChange={onChange}
                fullWidth
            />
            <TextField
                name="customBuildConfig.rootDevice"
                label="Hardware codename"
                placeholder="Hardware codename"
                onBlur={onBlur}
                fullWidth
                disabled={isPredefinedOption}
            />
        </React.Fragment>
    );
};
