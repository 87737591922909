import { diff } from "deep-diff";
import { Config, FormApi, FormState } from "final-form";
import _ from "lodash";
import React from "react";
import { Form, FormSpy } from "react-final-form";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import { Tooltip } from "src/ui/shared/Tooltip";
import * as yup from "yup";

import { SectionHeader } from "@dashboard/shared/styles";
import { Box, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createProduct, updateProduct } from "@products/store";
import { Product } from "@products/types";
import { selectWorkspace } from "@workspaces/store/selectors";

import { selectProducts } from "../../store/selectors/products";
import { ChangeSection } from "./components/ChangeSection";
import { ConfigImport } from "./components/ConfigImport";
import { ConfigurationModal } from "./components/ConfigurationDialog/ConfigurationDialog";
import { ExportSection } from "./components/ExportSection";
import { ProductBuilderImage } from "./components/ProductBuilderImage";
import { Recipes } from "./components/Recipes";
import { getInitialValues, productSchema } from "./ProductBuilder.utils";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        gap: 20,
        paddingTop: 20,

        "@media (max-width: 768px)": {
            flexDirection: "column",
        },
    },
    contentBox: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 20,
    },
    buttonSubBox: { display: "flex", gap: 8 },
    imageBox: {
        flex: 1,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
}));

const mutators: Config<Product>["mutators"] = {
    setTouched: ([fieldName]: string[], state) => {
        if (state.fields[fieldName]) {
            state.fields[fieldName].touched = true;
        }
    },
    setUnTouched: ([fieldName]: string[], state) => {
        if (state.fields[fieldName]) {
            state.fields[fieldName].touched = false;
        }
    },
};

type Props = {
    product: Product | undefined;
    setCurrentProductId: (value: number) => void;
    isAdvancedMode: boolean;
    setIsAdvancedMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProductBuilder = (props: Props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [configurationModal, setConfigurationModal] = React.useState(false);

    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const workspace = useSelector(selectWorkspace);
    const products = useSelector(selectProducts);
    const codenameAndroidVersion = products.map((item) => item.codename + item.androidVersion);
    const { productAbility } = permissionService();
    const cannotManageProducts = productAbility(workspace).cannot("manage", "Product");
    const isCreationProcess = !props.product?.id;

    const initialValues = React.useMemo(
        () => getInitialValues(props.product, workspace?.id),
        [props.product, workspace],
    );

    const onCreate = async (values: Product) => {
        try {
            setIsLoading(true);
            const fullCodename = workspace?.guid ? `${workspace.guid}_${values.codename}` : values.codename;
            const modifiedProduct = { ...values, codename: fullCodename };
            const data = await dispatch(createProduct(modifiedProduct)).unwrap();
            props.setCurrentProductId(data.id);
            showSuccessToast("Product created");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Error while creating product");
        } finally {
            setIsLoading(false);
        }
    };
    const onUpdate = async (values: Product) => {
        try {
            setIsLoading(true);
            await dispatch(updateProduct({ ...values, codename: props.product?.codename })).unwrap();
            showSuccessToast("Product saved");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Error while editing product");
        } finally {
            setIsLoading(false);
        }
    };
    const onBuild = (_values: Product) => {
        setConfigurationModal(true);
    };
    const onSubmit = async (values: Product) => {
        const difference = diff(props.product, values);

        if (isCreationProcess) {
            await onCreate(values);
        } else if (!isCreationProcess && difference) {
            await onUpdate(values);
        } else {
            onBuild(values);
        }
    };
    const onValidate = async (values: Product) => {
        const errors: Partial<Record<keyof Product, string>> = {};

        const isCodenameDirty = values.codename !== props.product?.codename;
        const isAndroidVersionDirty = values.androidVersion !== props.product?.androidVersion;
        const isCodenameAndAndroidVersionDirty = isCodenameDirty || isAndroidVersionDirty;

        const codenames = workspace?.guid
            ? codenameAndroidVersion.map((item) => item.replace(new RegExp(`^${workspace.guid}_`), ""))
            : codenameAndroidVersion;

        const isProductExists = codenames.includes(values.codename + values.androidVersion);

        if (isCodenameAndAndroidVersionDirty && isProductExists) {
            errors.codename = "Product with this codename and android version already exists";
            errors.androidVersion = "Product with this codename and android version already exists";
        }

        try {
            await productSchema.validate(values, { abortEarly: false });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.inner.forEach((validationError) => {
                    if (validationError.path) {
                        errors[validationError.path as keyof Product] = validationError.message;
                    }
                });
            }
        }

        return errors;
    };

    const onAdvancedMode = async (values: Product, form: FormApi<Product>) => {
        const difference = diff(props.product, values);
        if (props.isAdvancedMode && difference) {
            await form.submit();
        }
        props.setIsAdvancedMode((prev) => !prev);
    };
    const onChange = _.debounce(async (state: FormState<Product>, form: FormApi<Product>) => {
        if (state.dirty && !state.valid && state.errors) {
            Object.keys(state.errors).forEach((key) => {
                form.mutators.setTouched(key);
            });
        }
        if (state.pristine && !state.valid) {
            form.restart();
        }
        if (!isCreationProcess && !props.isAdvancedMode && state.valid && state.dirty) {
            await onUpdate(state.values);
        }
    }, 1000);

    return (
        <Form<Product>
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={onValidate}
            mutators={mutators}
            render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                    <FormSpy<Product>
                        subscription={{ values: true, valid: true, dirty: true, errors: true, pristine: true }}
                        onChange={(state) => onChange(state, form)}
                    />
                    <SectionHeader>Product details</SectionHeader>
                    <Box className={classes.container}>
                        <Box className={classes.contentBox}>
                            {props.isAdvancedMode ? (
                                <ConfigImport values={values} isEditing={!!props.product} />
                            ) : (
                                <Recipes product={props.product} />
                            )}

                            <Box className={classes.buttonBox}>
                                <Box className={classes.buttonSubBox}>
                                    <Tooltip
                                        title={props.product ? "Toggle advanced editor" : "No product selected"}
                                        placement="right-end"
                                    >
                                        <SecondaryButton
                                            size="small"
                                            disabled={!props.product}
                                            data-testid="advance-mode-switch"
                                            onClick={() => onAdvancedMode(values, form)}
                                        >
                                            <a
                                                className="fa-solid fa-code"
                                                style={{ fontSize: 18, color: theme.palette.shadow[350] }}
                                            />
                                        </SecondaryButton>
                                    </Tooltip>
                                    {props.product ? <ExportSection product={props.product} /> : null}
                                </Box>
                                <Box className={classes.buttonSubBox}>
                                    {props.product ? <ChangeSection product={props.product} /> : null}

                                    <PrimaryButton
                                        type="submit"
                                        size="large"
                                        disabled={isLoading || cannotManageProducts || props.isAdvancedMode}
                                        loading={isLoading}
                                        tooltipProps={{
                                            title: "Your role does not allow you to delete products",
                                            hide: !cannotManageProducts,
                                        }}
                                    >
                                        {isCreationProcess ? "Create" : "Build"}
                                    </PrimaryButton>
                                </Box>
                            </Box>
                        </Box>

                        <Box className={classes.imageBox}>
                            <ProductBuilderImage
                                nxpHwChecked={true}
                                qcomHwChecked={!!values.customBuildConfig?.rootDevice?.startsWith("rpi")}
                                customHwChecked={!!values.customBuildConfig?.rootDevice?.startsWith("caas")}
                                headlessChecked={values.customBuildConfig?.uiSettings?.headlessMode === "yes"}
                                kioskChecked={values.customBuildConfig?.uiSettings?.kioskMode === "yes"}
                                customUiChecked={false}
                                cloudChecked={false}
                                fleetChecked={false}
                                fleetAnalyticsChecked={false}
                            />
                        </Box>
                    </Box>
                    {props.product ? (
                        <ConfigurationModal
                            product={props.product}
                            open={configurationModal}
                            onClose={() => setConfigurationModal(false)}
                        />
                    ) : null}
                </form>
            )}
        />
    );
};
