import { TableRow, Theme, useTheme } from "@mui/material";
import { Row } from "@tanstack/react-table";

const darkenColor = (amount: number, theme: Theme): string => {
    const hex = theme.palette.white[50].replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const darken = (value: number): number => Math.max(0, value - Math.round((amount / 100) * 255));

    const newR = darken(r);
    const newG = darken(g);
    const newB = darken(b);

    const toHex = (value: number): string => value.toString(16).padStart(2, "0");

    return `#${toHex(newR)}${toHex(newG)}${toHex(newB)}`;
};

export const HoverRow = <T,>(props: React.PropsWithChildren<Row<T> & { selected?: boolean }>) => {
    const theme = useTheme();

    return (
        <TableRow
            style={{
                cursor: "pointer",
                backgroundColor: props.selected ? theme.palette.blue[50] : darkenColor(props.depth * 2, theme),
            }}
            onMouseEnter={(e) => {
                if (!props.selected) {
                    e.currentTarget.style.backgroundColor = theme.palette.blue[50];
                }
            }}
            onMouseLeave={(e) => {
                if (!props.selected) {
                    e.currentTarget.style.backgroundColor = darkenColor(props.depth * 2, theme);
                }
            }}
        >
            {props.children}
        </TableRow>
    );
};
