import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { theme } from "src/providers/ThemeProvider";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useDispatch } from "src/store";

import { ConfirmDialog } from "@dashboard/devices/components/shared";
import { cancelSubscription } from "@dashboard/devices/store";
import {
    selectSubscriptionExpired,
    selectSubscriptionExpiringWithinMonth,
} from "@dashboard/devices/store/selectors/subscriptions";
import { Subscription } from "@dashboard/devices/types";
import { selectFiles } from "@dashboard/files/store/selectors";
import { selectGroupsMembersByWorkspace } from "@dashboard/groups/store/selectors";
import { selectLicensesBySubscriptionId } from "@dashboard/licenses/store/selectors";
import { selectProducts } from "@dashboard/products/store/selectors/products";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { SectionContainer, SectionHeader } from "@dashboard/shared/styles";
import {
    selectGroupNestingLimit,
    selectWorkspace,
    selectWorkspaceFdroidRepoLimit,
    selectWorkspaceFileLimit,
    selectWorkspaceLicenseLimit,
    selectWorkspaceProductLimit,
    selectWorkspaceProvisioningLimit,
    selectWorkspaceStreamLimit,
} from "@dashboard/workspaces/store/selectors";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, DialogContentText, Typography } from "@mui/material";
import { DangerOutlinedButton, PrimaryButton } from "@shared/CustomButton";
import { CustomTileContainer, CustomTileTitle } from "@shared/CustomTile";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";

import { SubscriptionAlert } from "../SubscriptionAlert/SubscriptionAlert";
import { useStyles } from "./SubscriptionSection.style";

type TileProps = {
    badge: string;
    label: string | number;
    limit?: number;
};

const Tile = (props: TileProps) => {
    const classes = useStyles();
    const displayLabel = props.limit === Infinity ? `${props.label.toString().split("/")[0]}/unlimited` : props.label;

    const [currentValue, maxValue] = props.label.toString().split("/").map(Number);
    const isLimitReached =
        props.limit !== undefined && props.limit !== Infinity && maxValue !== 0 && currentValue >= props.limit;

    return (
        <CustomTileContainer style={{ minWidth: 250 }}>
            <CustomTileTitle>{props.badge}</CustomTileTitle>
            <Box className={classes.tileContent}>
                <Typography variant="subtitle2" align="center" className={classes.tileLabel}>
                    <div className={classes.limitIconContainer}>
                        {displayLabel}
                        {isLimitReached && (
                            <Tooltip title="Limit has been reached">
                                <WarningAmberIcon style={{ marginLeft: 5, color: theme.palette.orange[100] }} />
                            </Tooltip>
                        )}
                    </div>
                </Typography>
            </Box>
        </CustomTileContainer>
    );
};

type Props = {
    subscription: Subscription;
};
export const SubscriptionSection = (props: Props) => {
    const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const files = useSelector(selectFiles);
    const products = useSelector(selectProducts);
    const provisionings = useSelector(selectProvisionings);
    const currentWorkspace = useSelector(selectWorkspace);
    const currentWorkspaceMembers = useSelector(selectGroupsMembersByWorkspace, shallowEqual);
    const provisioningLimit = useSelector(selectWorkspaceProvisioningLimit);
    const productLimit = useSelector(selectWorkspaceProductLimit);
    const fileLimit = useSelector(selectWorkspaceFileLimit);
    const licenseLimit = useSelector(selectWorkspaceLicenseLimit);
    const fdroidRepoLimit = useSelector(selectWorkspaceFdroidRepoLimit);
    const groupNestingLimit = useSelector(selectGroupNestingLimit);
    const streamLimit = useSelector(selectWorkspaceStreamLimit);
    const licenses = useSelector((state: RootState) => selectLicensesBySubscriptionId(state, props.subscription.id));
    const deviceCount = licenses.filter((l) => l.device).length;
    const { workspaceAbility } = permissionService();
    const mayCancelSubscription = workspaceAbility(currentWorkspace).can("cancel", "Workspace");
    const isExpired = useSelector(selectSubscriptionExpired);
    const isExpiringWithinMonth = useSelector(selectSubscriptionExpiringWithinMonth);

    const confirmCancelSubscription = async (id: number) => {
        await dispatch(cancelSubscription(id))
            .unwrap()
            .then(() => {
                showSuccessToast("Subscription cancelled");
            })
            .catch(({ message = "Couldn't cancel subscription" }: { message: string }) => {
                showErrorToast(message);
            })
            .finally(() => {
                setCancelSubscriptionDialogOpen(false);
            });
    };

    const onRenewClick = () => {
        window.open("https://emteria.com/p/subscription", "_blank");
    };

    return (
        <React.Fragment>
            <SectionContainer className={classes.section}>
                <SectionHeader>Subscription status</SectionHeader>
                <SubscriptionAlert />
                <Box className={classes.tileBox}>
                    <Tile badge="Status" label={props.subscription.status} />
                    <Tile badge="Variant" label={props.subscription.variantName} />
                    <Tile badge="Charge ID" label={props.subscription.chargeId} />
                    <Tile badge="Expiration date" label={props.subscription.expirationDate || "-"} />
                    <Tile badge="Cancellation date" label={props.subscription.cancellationDate || "-"} />
                    <Tile badge="Revocation policy" label={licenseLimit > 200 ? "Self-governing" : "Upon request"} />
                </Box>
                <Box className={classes.cancelBtnBox}>
                    <DangerOutlinedButton
                        data-testid="subscriptionSection.btn.cancel"
                        startIcon={<i className="fas fa-ban" />}
                        onClick={() => setCancelSubscriptionDialogOpen(true)}
                        disabled={!mayCancelSubscription || props.subscription.isCancelled}
                        tooltipProps={{
                            hide: mayCancelSubscription && !props.subscription.isCancelled,
                            title: props.subscription.isCancelled
                                ? "Subscription is already canceled"
                                : "You have no permission to cancel the subscription",
                        }}
                    >
                        Cancel subscription
                    </DangerOutlinedButton>
                    {(isExpired || isExpiringWithinMonth) && (
                        <PrimaryButton data-testid="subscriptionSection.btn.renew" onClick={onRenewClick}>
                            Renew subscription
                        </PrimaryButton>
                    )}
                </Box>
            </SectionContainer>
            <SectionContainer className={classes.section}>
                <SectionHeader>Subscription quota</SectionHeader>
                <Box className={classes.tileBox}>
                    <Tile badge="Group limit" label={`${props.subscription.groupIds.length}/unlimited`} />
                    <Tile badge="User limit" label={`${currentWorkspaceMembers.length}/unlimited`} />

                    <Tile badge="Device limit" label={`${deviceCount}/${licenseLimit}`} limit={licenseLimit} />
                    <Tile
                        badge="Provisioning limit"
                        label={`${provisionings.length}/${provisioningLimit}`}
                        limit={provisioningLimit}
                    />
                    <Tile badge="Product limit" label={`${products.length}/${productLimit}`} limit={productLimit} />
                    <Tile badge="File limit" label={`${files.length}/${fileLimit}`} limit={fileLimit} />
                    <Tile badge="Stream limit" label={`${0}/${streamLimit}`} limit={streamLimit} />
                    <Tile
                        badge="FDroid repository limit"
                        label={`${props.subscription.fdroidRepos?.length || 0}/${fdroidRepoLimit}`}
                        limit={fdroidRepoLimit}
                    />
                    <Tile badge="Group nesting limit" label={groupNestingLimit} />
                </Box>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <PrimaryButton
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => window.open("https://emteria.com/p/subscription", "_blank")}
                    >
                        Purchase limit increase
                    </PrimaryButton>
                </Box>
            </SectionContainer>

            <ConfirmDialog
                title="Cancel subscription"
                open={cancelSubscriptionDialogOpen}
                onConfirm={() => confirmCancelSubscription(props.subscription.id)}
                onClose={() => setCancelSubscriptionDialogOpen(false)}
                dangerButton
            >
                <DialogContentText>
                    You are about to cancel this subscription. After cancellation, the subscription stays valid until
                    the next payment date and expires automatically. Once expired, all devices assigned to this
                    subscription via contained groups will lose their access to exclusive features like updates, MDM and
                    others. Please note that this action cannot be undone. Do you want to continue?
                </DialogContentText>
            </ConfirmDialog>
        </React.Fragment>
    );
};
