import React from "react";

import { Box } from "@mui/material";

type Props = {
    qcomHwChecked: boolean;
    nxpHwChecked: boolean;
    customHwChecked: boolean;
    headlessChecked: boolean;
    kioskChecked: boolean;
    customUiChecked: boolean;
    cloudChecked: boolean;
    fleetChecked: boolean;
    fleetAnalyticsChecked: boolean;
};

export const ProductBuilderImage = (props: Props) => {
    const [hardwareImage, setHardwareImage] = React.useState("");
    const [serviceImage, setServiceImage] = React.useState("");

    React.useEffect(() => {
        setHardwareImage("");
        setServiceImage("");

        if (props.nxpHwChecked) {
            setHardwareImage("/img/builder/nxp.svg");
            if (props.headlessChecked) {
                setHardwareImage("/img/builder/nxp-screen-off-kiosk-off.svg");
                if (props.kioskChecked) {
                    setHardwareImage("/img/builder/nxp-screen-off-kiosk-on.svg");
                } else if (props.customUiChecked) {
                    setHardwareImage("/img/builder/nxp-screen-off-customize-on.svg");
                }
            } else {
                setHardwareImage("/img/builder/nxp-screen-on.svg");
                if (props.kioskChecked) {
                    setHardwareImage("/img/builder/nxp-kiosk-on.svg");
                } else if (props.customUiChecked) {
                    setHardwareImage("/img/builder/nxp-screen-on-customize-on.svg");
                }
            }
        }

        if (props.qcomHwChecked) {
            setHardwareImage("/img/builder/qualcomm.svg");
            if (props.headlessChecked) {
                setHardwareImage("/img/builder/qualcomm-screen-off-kiosk-off.svg");
                if (props.kioskChecked) {
                    setHardwareImage("/img/builder/qualcomm-screen-off-kiosk-on.svg");
                } else if (props.customUiChecked) {
                    setHardwareImage("/img/builder/qualcomm-screen-off-customize-on.svg");
                }
            } else {
                setHardwareImage("/img/builder/qualcomm-screen-on.svg");
                if (props.kioskChecked) {
                    setHardwareImage("/img/builder/qualcomm-kiosk-on.svg");
                } else if (props.customUiChecked) {
                    setHardwareImage("/img/builder/qualcomm-screen-on-customize-on.svg");
                }
            }
        }

        if (props.customHwChecked) {
            setHardwareImage("/img/builder/customhw.svg");
            if (props.headlessChecked) {
                setHardwareImage("/img/builder/customhw-screen-off-kiosk-off.svg");
                if (props.kioskChecked) {
                    setHardwareImage("/img/builder/customhw-screen-off-kiosk-on.svg");
                } else if (props.customUiChecked) {
                    setHardwareImage("/img/builder/customhw-screen-off-customize-on.svg");
                }
            } else {
                setHardwareImage("/img/builder/customhw-screen-on.svg");
                if (props.kioskChecked) {
                    setHardwareImage("/img/builder/customhw-kiosk-on.svg");
                } else if (props.customUiChecked) {
                    setHardwareImage("/img/builder/customhw-screen-on-customize-on.svg");
                }
            }
        }

        if (props.cloudChecked) {
            setServiceImage("/img/builder/data-management.svg");
        }

        if (props.fleetChecked) {
            setServiceImage("/img/builder/fleet-management.svg");
        }

        if (props.fleetAnalyticsChecked) {
            setServiceImage("/img/builder/fleet-analytics.svg");
        }

        if (props.cloudChecked && props.fleetChecked) {
            setServiceImage("/img/builder/data-and-fleet-management.svg");
        }

        if (props.cloudChecked && props.fleetAnalyticsChecked) {
            setServiceImage("/img/builder/data-and-fleet-analytics.svg");
        }

        if (props.fleetChecked && props.fleetAnalyticsChecked) {
            setServiceImage("/img/builder/fleet-management-and-fleet-analytics.svg");
        }

        if (props.cloudChecked && props.fleetChecked && props.fleetAnalyticsChecked) {
            setServiceImage("/img/builder/all-services.svg");
        }
    }, [
        props.qcomHwChecked,
        props.nxpHwChecked,
        props.customHwChecked,
        props.headlessChecked,
        props.kioskChecked,
        props.customUiChecked,
        props.cloudChecked,
        props.fleetChecked,
        hardwareImage,
        serviceImage,
        props.fleetAnalyticsChecked,
    ]);

    return (
        <React.Fragment>
            <style
                dangerouslySetInnerHTML={{
                    __html: `#full-solution {
                                height: 90%;
                                width: 90%;
                                background-image: url(${hardwareImage}), url(${serviceImage});
                                background-size: contain, contain;
                                background-position: center, center;
                                background-repeat: no-repeat, no-repeat;
                            }`,
                }}
            />
            <Box id="full-solution" />
        </React.Fragment>
    );
};
