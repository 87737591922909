import semver from "semver";
import * as yup from "yup";

import { Product } from "@products/types";

import { defaultBuildVariantOption } from "./components/Recipes";

const generateCodeName = (string: string) => {
    if (!string) {
        return "";
    }
    return string
        .replaceAll(" ", "_")
        .replaceAll(/[^0-9a-zA-Z_]+/g, "")
        .toLowerCase();
};
export const getInitialValues = (product: Product | undefined, workspaceId: number | undefined): Product => {
    if (!product) {
        return {
            id: 0,
            workspaceId: workspaceId || 0,
            isPublic: false,
            groupId: null,
            codename: "",
            androidVersion: "14",
            nightlyPeriod: 0,
            changes: null,
            customBuildConfig: {
                productName: "",
                rootDevice: "caas",
                compatibility: ">=14",
                modules: [],
                projects: [],
                provisioningId: null,
                buildVariant: defaultBuildVariantOption,
                uiSettings: {
                    headlessMode: "no",
                    kioskMode: "no",
                },
            },
        };
    }
    return {
        ...product,
        workspaceId: product.workspaceId || workspaceId || 0,
        codename: product.codename || generateCodeName(product.customBuildConfig?.productName ?? ""),
        isPublic: product.isPublic,
        customBuildConfig: product.customBuildConfig
            ? {
                  ...product.customBuildConfig,
                  compatibility: product.customBuildConfig.compatibility || `>=${product.androidVersion}`,
                  buildVariant: product.customBuildConfig?.buildVariant || defaultBuildVariantOption,
                  modules: product.customBuildConfig?.modules || [],
                  projects: product.customBuildConfig?.projects || [],
                  uiSettings: {
                      ...product.customBuildConfig.uiSettings,
                      headlessMode: product.customBuildConfig.uiSettings?.headlessMode || "no",
                      kioskMode: product.customBuildConfig.uiSettings?.kioskMode || "no",
                  },
              }
            : null,
    };
};

const ProductApplicationSchema = yup.object().shape({
    privileged: yup.boolean().required(),
    source: yup
        .object()
        .shape({
            type: yup.string().oneOf(["storage"]).required(),
            fileId: yup.string().required(),
        })
        .required(),
});
const ProductBootAnimationSchema = yup.object().shape({
    source: yup
        .object()
        .shape({
            type: yup.string().oneOf(["storage"]).required(),
            fileId: yup.string().required(),
        })
        .required(),
});
const customBuildConfigSchema = yup.object().shape({
    productName: yup.string().required().label("Product name"),
    rootDevice: yup.string().required().label("Hardware platform"),
    buildVariant: yup.string().required().label("Build variant"),
    applications: yup.array().of(ProductApplicationSchema).optional().default(undefined),
    bootanimation: ProductBootAnimationSchema.optional().default(undefined),
    provisioningId: yup.number().nullable().default(null),
    compatibility: yup
        .string()
        .required()
        .test(
            "is-valid-version",
            "Compatibility must be a valid version string (e.g., >=1.0.0, >2.0, =1.2.3)",
            (value) => semver.validRange(value) !== null,
        )
        .label("Compatibility"),
});

export const productSchema = yup.object().shape({
    id: yup.number().required(),
    codename: yup.string().required().label("Codename"),
    groupId: yup.number().nullable(),
    nightlyPeriod: yup
        .number()
        .typeError("Nightly period must be a valid number")
        .min(0)
        .nullable()
        .label("Nightly period"),
    androidVersion: yup.string().required(),
    workspaceId: yup.number().nullable(),
    customBuildConfig: customBuildConfigSchema.required(),
});
