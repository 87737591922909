import { FormApi } from "final-form";
import { Form, useForm, useFormState } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "src/store";
import { selectLicenses, selectUniversalLicenses } from "src/ui/containers/dashboard/licenses/store/selectors";

import { License } from "@dashboard/devices/types";
import { Provisioning } from "@dashboard/provisioning/types/index";
import { alternatingBg, Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Alert, Link, Paper, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { selectProvisionings } from "@provisioning/store/selectors";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { MinimalTableCell, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { TableHeader } from "@shared/table/TableHeader";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

type FormValues = { activationCode: License["activationCode"] };

export const DeviceRegistration = () => {
    const { value, onChange } = useCollapsiblePersistence("provisioningPage.collapsedSections", "DeviceRegistration");

    const parentForm = useForm();
    const navigate = useNavigate();
    const sharedClasses = useStyles();
    const provisionings = useSelector(selectProvisionings, shallowEqual);

    const licenses = useSelector(selectLicenses);
    const allUniversalLicenses = useSelector(selectUniversalLicenses);

    const { values: parentFormValues } = useFormState<Partial<Provisioning>>();
    const universalCodes = parentFormValues.settings?.universalLicenses || [];
    const universalLicenses = allUniversalLicenses.filter((license) =>
        universalCodes?.includes(license.activationCode),
    );

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        if (universalCodes.length) {
            parentForm.change("settings.universalLicenses", [...universalCodes, values.activationCode]);
        }
        if (!universalCodes.length) {
            parentForm.change("settings.universalLicenses", [values.activationCode]);
        }

        form.restart({ activationCode: allUniversalLicenses[0]?.activationCode });
    };
    const validate = (values: FormValues) => {
        const licenseExists = licenses.some((l) => l.activationCode === values.activationCode);
        const licenseSelected = universalCodes.includes(values.activationCode);

        if (!values.activationCode) {
            return { activationCode: "Activation code is not allowed to be empty" };
        }
        if (licenseSelected) {
            return { activationCode: "This license is already selected" };
        }

        if (!licenseExists) {
            return { activationCode: "This license does not exist" };
        }
    };
    const handleDelete = (code: License["activationCode"]) => {
        parentForm.change(
            "settings.universalLicenses",
            universalCodes.filter((c) => c !== code),
        );
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{ activationCode: allUniversalLicenses[0]?.activationCode }}
            render={({ form }) => (
                <CollapsibleProvider initialValue={value} onChange={onChange}>
                    <CollapsibleSectionSummary>
                        <Typography className={sharedClasses.categoryTitle}>Device registration</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <SectionDescription>
                            A list of activation codes for universal activation of known and unknown devices.
                        </SectionDescription>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell align="left">Activation code</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">License group</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {universalLicenses.length ? (
                                        universalLicenses.map((item, index) => (
                                            <TableRow key={item.id} style={alternatingBg(index)}>
                                                <MinimalTableCell align="left">{item.activationCode}</MinimalTableCell>
                                                <MinimalTableCell align="left">
                                                    {item.universalGroupName}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDelete(item.activationCode)}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell align="center" colSpan={3}>
                                                No universal licenses were added yet
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {allUniversalLicenses.length ? (
                            <Row style={{ marginTop: 15 }}>
                                <SelectField
                                    name="activationCode"
                                    label="Activation Code"
                                    options={allUniversalLicenses.map((item) => ({
                                        value: item.activationCode,
                                        label: item.universalGroupName
                                            ? `${item.activationCode} - ${item.universalGroupName}`
                                            : item.activationCode,
                                    }))}
                                    fullWidth
                                />
                                <PrimaryOutlinedButton onClick={form.submit} disabled={!provisionings.length}>
                                    Add
                                </PrimaryOutlinedButton>
                            </Row>
                        ) : (
                            <Alert style={{ marginTop: 15 }} severity="info">
                                Please create a new universal license
                                <Link
                                    style={{ paddingBottom: 5, marginLeft: 5, marginRight: 5 }}
                                    color="inherit"
                                    underline="always"
                                    component="button"
                                    onClick={() => navigate("/dashboard/licenses")}
                                >
                                    in the universal licenses page
                                </Link>
                                before adding it to provisioning settings.
                            </Alert>
                        )}
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            )}
        />
    );
};
