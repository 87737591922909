import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        width: "100%",
        marginTop: 10,
        alignItems: "center",
    },
    outlineBox: {
        height: "10px",
        backgroundColor: theme.palette.shadow[200],
        borderRadius: 50,
        display: "flex",
        width: "100%",
        overflow: "hidden",
    },
    progress: {
        borderRadius: 50,
        height: "100%",
        transition: "width 0.3s ease",
    },
    progressPercentage: {
        paddingLeft: "5px",
        fontSize: "16px",
        color: theme.palette.black[100],
    },
}));

const getFormattedProgress = (progressNumber: number) => {
    if (progressNumber === 100) {
        return "100%";
    } else if (progressNumber > 0 && progressNumber < 1) {
        return "1%";
    } else {
        return `${Math.floor(progressNumber)}%`;
    }
};

interface Props {
    progress: number;
    color?: string;
}

export const ProgressBar = ({ progress, color }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.container} data-testid="progress-bar">
            <div className={classes.outlineBox}>
                <div className={classes.progress} style={{ width: `${progress}%`, backgroundColor: color }} />
            </div>
            <span className={classes.progressPercentage}>{getFormattedProgress(progress)}</span>
        </div>
    );
};
