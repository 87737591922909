import { DateTime } from "luxon";
import { RootState } from "src/store";

import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

import { Subscription } from "../../types";
import { subscriptionsAdapter } from "../helpers";

const defaultSelectors = subscriptionsAdapter.getSelectors<RootState>(
    (state) => state.devices.list.subscriptions.items,
);

const selectSubscriptions = defaultSelectors.selectAll;
const selectSubscriptionById = defaultSelectors.selectById;

const selectSubscription = createSelector(
    [selectSubscriptions, (state: RootState) => state],
    (subscriptions, state) => {
        const currentWorkspace = selectWorkspace(state);
        return subscriptions.find((subscription) => subscription.id === currentWorkspace?.subscription) || null;
    },
);
const selectSubscriptionLimitByLimitType = (state: RootState, subscriptionId: number, type: number) => {
    const subscriptions = selectSubscriptions(state);
    const subscription = subscriptions.find((sub) => sub.id === subscriptionId);
    const limit = subscription?.limits.find((lim) => lim.type === type)?.value;
    return limit ?? 0;
};
const selectSubscriptionExpired = (state: RootState) => {
    const subscription = selectSubscription(state);
    return Boolean(subscription?.isExpired);
};

const selectSubscriptionExpiringWithinMonth = (state: RootState) => {
    const subscription = selectSubscription(state);

    if (!subscription?.expirationDate || subscription.cancellationDate) {
        return false;
    }

    const now = DateTime.now();
    const expirationDate = DateTime.fromISO(subscription.expirationDate);
    const daysUntilExpiration = expirationDate.diff(now, "days").days;
    const thirtyDays = 30;

    return daysUntilExpiration <= thirtyDays;
};

const selectSubscriptionFdroidRepos = createSelector(selectSubscription, (subscription) => {
    return subscription?.fdroidRepos || [];
});
const selectSubscriptionsFdroidRepos = createSelector(selectSubscriptions, (subscriptions) => {
    return subscriptions.flatMap((subscription) => subscription.fdroidRepos ?? []);
});
const selectSubscriptionsFdroidReposById = (state: RootState, subscriptionId: Subscription["id"]) =>
    selectSubscriptionById(state, subscriptionId)?.fdroidRepos ?? [];

export {
    selectSubscription,
    selectSubscriptionById,
    selectSubscriptionExpired,
    selectSubscriptionExpiringWithinMonth,
    selectSubscriptionFdroidRepos,
    selectSubscriptionsFdroidRepos,
    selectSubscriptionsFdroidReposById,
    selectSubscriptionLimitByLimitType,
    selectSubscriptions,
};
