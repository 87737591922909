import React from "react";
import { useNavigate } from "react-router";
import { theme } from "src/providers/ThemeProvider";
import { RootState, useDispatch, useSelector } from "src/store";

import { IconSize } from "@dashboard/devices/components/shared/index";
import {
    clearAllFilters,
    defaultFilter,
    setViewGroupsCollapseAll,
    setViewGroupsExpandById,
    updateFilterProperties,
} from "@dashboard/devices/store";
import { filterLicensesByProperties } from "@dashboard/devices/store/helpers/index";
import { selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { Group, License } from "@dashboard/devices/types/index";
import { setColumnVisibility } from "@dashboard/devices/utils/columnVisibility";
import { selectGroupsByIds } from "@dashboard/groups/store/selectors";
import { selectLicensesByGroupIds } from "@dashboard/licenses/store/selectors";
import { CustomTileContainer, CustomTileLink, CustomTileText, CustomTileTitle } from "@shared/CustomTile";
import { LoadingIcon } from "@shared/Loading";
import { ProgressBar } from "@shared/ProgressBar";

import { calculateBarValue, useStyles } from "../../shared";

export const getNonCompliantDeviceCount = (allLicenses: License[], allGroups: Group[]) =>
    filterLicensesByProperties({
        licenses: allLicenses,
        filters: {
            ...defaultFilter.properties,
            policy_status: ["Non-compliant"] || null,
            license_activationStatus: ["Used"],
        },
        groups: allGroups,
    }).length;
export const getBarColor = (nonCompilant: number, total: number) => {
    if (nonCompilant === total && nonCompilant) {
        return theme.palette.red[100];
    }
    if (!nonCompilant) {
        return theme.palette.green[50];
    }

    return theme.palette.orange[100];
};

const PolicyComplianceCard = (props: { groupIds: number[] }) => {
    const isLoading = useSelector((state: RootState) => state.devices.list.licenses.status);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const groups = useSelector((state) => selectGroupsByIds(state, props.groupIds));
    const groupsNames = groups.map((group) => group.name);
    const licenses = useSelector((state) => selectLicensesByGroupIds(state, props.groupIds));
    const devices = useSelector((state) => selectDevicesByGroupIds(state, props.groupIds));

    const activeLicense = licenses.filter((license) => license.device).length;
    const nonCompliantDevicesCount = getNonCompliantDeviceCount(licenses, groups);

    const barValue = calculateBarValue(nonCompliantDevicesCount, devices.length);
    const barColor = getBarColor(nonCompliantDevicesCount, devices.length);

    const filterByPolicy = () => {
        dispatch(clearAllFilters());
        if (nonCompliantDevicesCount === 0 && devices.length > 0) {
            dispatch(
                updateFilterProperties({
                    policy_status: ["Compliant"],
                    license_activationStatus: ["Used"],
                    group_name: groupsNames,
                }),
            );
        } else {
            dispatch(
                updateFilterProperties({
                    policy_status: ["Non-compliant"],
                    license_activationStatus: ["Used"],
                    group_name: groupsNames,
                }),
            );
        }

        dispatch(setViewGroupsCollapseAll(true));
        dispatch(setViewGroupsExpandById(props.groupIds));
        navigate("/dashboard/devices");
    };
    const handleOnClick = () => {
        if (devices.length) {
            filterByPolicy();
            setColumnVisibility("policyStatus");
        }
    };

    return (
        <CustomTileContainer>
            <CustomTileTitle>Policy compliance</CustomTileTitle>

            {isLoading === "pending" ? (
                <div className={classes.loadingBox}>
                    <LoadingIcon size={IconSize.large} />
                </div>
            ) : (
                <React.Fragment>
                    {!nonCompliantDevicesCount && !devices.length ? <CustomTileText>No devices</CustomTileText> : null}
                    {!nonCompliantDevicesCount && devices.length ? (
                        <CustomTileText>Full compliance</CustomTileText>
                    ) : null}
                    {nonCompliantDevicesCount ? (
                        <CustomTileLink onClick={handleOnClick}>{`${nonCompliantDevicesCount} non-compliant device${
                            nonCompliantDevicesCount > 1 ? "s" : ""
                        }`}</CustomTileLink>
                    ) : null}

                    {activeLicense ? <ProgressBar progress={barValue} color={barColor} /> : null}
                </React.Fragment>
            )}
        </CustomTileContainer>
    );
};

export default PolicyComplianceCard;
