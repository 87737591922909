import _ from "lodash";
import React from "react";
import { Field } from "src/ui/shared/form/TextField";

import { InputAdornment, TextFieldProps } from "@mui/material";

interface Props extends Omit<TextFieldProps, "onChange" | "value"> {
    handleChange: (value?: string | number | readonly string[]) => void;
    handleClear?: () => void;
    debounceWait?: number;
    defaultValue?: string;
}

export const Search = ({ handleChange, handleClear, debounceWait = 700, ...props }: Props) => {
    const [value, setValue] = React.useState<string>(props.defaultValue || "");

    const onChangeDebounced = React.useMemo(() => _.debounce(handleChange, debounceWait), [handleChange, debounceWait]);

    const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setValue(e.target.value);
        onChangeDebounced(e.target.value);
    };
    const onClear = () => {
        setValue("");

        if (handleClear) {
            handleClear();
        }
    };

    React.useEffect(() => {
        handleChange(props.defaultValue);
        //We're silencing eslint here to avoid unwanted re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        return () => {
            onChangeDebounced.cancel();
        };
    }, [onChangeDebounced]);

    return (
        <Field
            {...props}
            value={value}
            onChange={onChange}
            variant="outlined"
            size="small"
            slotProps={{
                htmlInput: { style: { paddingLeft: 8 } },
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <i className="fas fa-search" />
                        </InputAdornment>
                    ),
                    endAdornment: value ? (
                        <InputAdornment position="end" style={{ cursor: "pointer" }}>
                            <i className="fa-solid fa-xmark" onClick={onClear} />
                        </InputAdornment>
                    ) : null,
                },
            }}
            fullWidth
        />
    );
};
