import { diff } from "deep-diff";
import exportFromJSON from "export-from-json";
import { Form } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router";
import UserSettingsService from "src/services/userSettingsService";
import { useDispatch, useSelector } from "src/store";
import { isValidZipUrl } from "src/ui/utils/validityChecker";

import { TutorialSection } from "@dashboard/overview/components/TutorialSection";
import * as Fragments from "@dashboard/provisioning/components";
import { useStyles } from "@dashboard/provisioning/ProvisioningPage.style";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Provisioning } from "@dashboard/provisioning/types";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer } from "@dashboard/shared/styles";
import { selectWorkspace, selectWorkspaceId } from "@dashboard/workspaces/store/selectors";
import { Box } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { ExpiredWrapper } from "@shared/ExpiredWrapper";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { CollapsibleGroupProvider } from "./components/CollapsibleSection";
import ExpandAllButton from "./components/ExpandAllButton";
import { updateProvisioning } from "./store";
import { defaultProvisioning } from "./utils";
import { provisioningSettingsConverter } from "./utils/provisioningSettingsConverter";

const getAllSectionExpandedState = () => {
    const sectionsCount = 14;
    const collapsedSections = UserSettingsService.getProvisioningPageCollapsedSections();
    const collapsedSectionsValues = Object.values(collapsedSections || {});

    if (collapsedSectionsValues.length !== sectionsCount) {
        // some sections are expanded, some are collapsed
        return null;
    }
    if (collapsedSectionsValues.every((item) => item)) {
        // all section expanded
        return true;
    }
    if (collapsedSectionsValues.every((item) => !item)) {
        // all section collapsed
        return false;
    }
    return null;
};

type FormValues = Provisioning;

export const ProvisioningPage = () => {
    const { provisioningId: preselectedProvisioningId } = useParams<{ provisioningId: string }>();

    const classes = useStyles();
    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace);
    const workspaceId = useSelector(selectWorkspaceId);

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const selectedProvisioning =
        provisionings.find((p) => p.id.toString() === preselectedProvisioningId) ||
        provisionings[0] ||
        defaultProvisioning;

    const onValidate = (values: FormValues) => {
        if (values.settings?.bootAnimationUrl && !isValidZipUrl(values.settings.bootAnimationUrl)) {
            return { settings: { bootAnimationUrl: "Invalid ZIP URL" } };
        }
    };
    const onSubmit = async (values: FormValues) => {
        if (workspaceId) {
            await dispatch(updateProvisioning({ ...values, workspaceId }))
                .unwrap()
                .then(() => showSuccessToast("Provisioning settings saved"))
                .catch(({ message = "Update failed" }) => showErrorToast(message));
        }
    };

    const exportProvisioningSettings = async (values: FormValues) => {
        if (values.settings) {
            const data = await provisioningSettingsConverter(values.settings);
            if (data) {
                exportFromJSON({
                    data,
                    fileName: `provisioning_${values.name}`,
                    exportType: exportFromJSON.types.json,
                });
            }
        }
    };

    return (
        <CollapsibleGroupProvider initialValue={getAllSectionExpandedState()}>
            <PageContainer>
                <PageTitleWithIcon title="Provisioning" iconName="fa-solid fa-sliders" />
                <Fragments.ProvisioningDescription />
                {workspace ? (
                    <ExpiredWrapper>
                        <Form<FormValues>
                            onSubmit={onSubmit}
                            validate={onValidate}
                            initialValues={selectedProvisioning}
                            render={({ handleSubmit, submitting, values, form }) => {
                                const formHasChanged = diff(form.getState().initialValues, values);
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Fragments.ProvisioningManagement submitting={submitting} form={form} />
                                        <Fragments.SetupAutomation />
                                        <Fragments.DeviceRegistration />
                                        <Fragments.Screen />
                                        <Fragments.ProductBranding />
                                        <Fragments.ProductAdministration />
                                        <Fragments.DisabledComponents />
                                        <Fragments.HostedApks />
                                        <Fragments.RuntimePermissions />
                                        <Fragments.PrivateRepositories />
                                        <Fragments.ApplicationPreferences />
                                        <Fragments.SshKeys />
                                        <Fragments.Connectivity />
                                        <Fragments.SystemSettings />
                                        <Fragments.SystemProperties />
                                        <Box className={classes.stickyFooter}>
                                            <ExpandAllButton />
                                            <SecondaryButton
                                                onClick={() => exportProvisioningSettings(values)}
                                                disabled={submitting || !workspaceId || !provisionings.length}
                                                startIcon={<i className="fas fa-solid fa-file-export" />}
                                            >
                                                Export
                                            </SecondaryButton>
                                            <Box className={classes.footerRightContainer}>
                                                <PrimaryButton
                                                    type="submit"
                                                    disabled={submitting || !provisionings.length || !formHasChanged}
                                                    loading={submitting}
                                                >
                                                    Save
                                                </PrimaryButton>
                                            </Box>
                                        </Box>
                                    </form>
                                );
                            }}
                        />
                    </ExpiredWrapper>
                ) : (
                    <TutorialSection />
                )}
            </PageContainer>
        </CollapsibleGroupProvider>
    );
};
