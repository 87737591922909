import { DateTime, Duration } from "luxon";

export const dateTimeFormat = "yyyy-MM-dd - HH:mm:ss";
const dateFormat = "yyyy-MM-dd";

export const msToDuration = (msTime = 0): Duration => {
    return Duration.fromObject({ milliseconds: msTime }, { locale: "en-US" }).shiftTo(
        "years",
        "months",
        "days",
        "hours",
        "minutes",
        "seconds",
        "milliseconds",
    );
};

export const formatDuration = (duration: Duration): string => {
    const format = Object.entries(duration.toObject())
        .map(([key, value]) => {
            if (!value) {
                return "";
            }
            if (key === "years") {
                return "yy'y'";
            }
            if (key === "months") {
                return "MM'm'";
            }
            if (key === "days") {
                return "dd'd'";
            }
            if (key === "hours") {
                return "hh'h'";
            }
            if (key === "minutes") {
                return "mm'min'";
            }
            if (key === "seconds") {
                return "ss'sec'";
            }
            return "";
        })
        .join(" ");
    return duration.toFormat(format);
};

export const timeElapsedFromNow = (givenDate: DateTime) => {
    const currentDate = DateTime.now();

    const diff = currentDate.diff(givenDate, ["years", "months", "days", "hours", "minutes", "seconds"]).toObject();

    if (diff.years && diff.years >= 1) {
        return `${Math.floor(diff.years)} year${diff.years !== 1 ? "s" : ""} ago`;
    } else if (diff.months && diff.months >= 1) {
        return `${Math.floor(diff.months)} month${diff.months !== 1 ? "s" : ""} ago`;
    } else if (diff.days && diff.days >= 1) {
        return `${Math.floor(diff.days)} day${diff.days !== 1 ? "s" : ""} ago`;
    } else if (diff.hours && diff.hours >= 1) {
        return `${Math.floor(diff.hours)} hour${diff.hours !== 1 ? "s" : ""} ago`;
    } else if (diff.minutes && diff.minutes >= 1) {
        return `${Math.floor(diff.minutes)} minute${diff.minutes !== 1 ? "s" : ""} ago`;
    } else {
        return `${Math.floor(diff.seconds ?? 0)} second${diff.seconds !== 1 ? "s" : ""} ago`;
    }
};

export const daysToDate = (date: string) => {
    const expirationDate = DateTime.fromISO(date);
    const now = DateTime.now();
    return Math.round(expirationDate.diff(now, "days").days);
};

export const isDateInThePast = (time: string | number) => {
    const date = new Date(time);
    const now = new Date();
    return date < now;
};

export const convertToLocalDate = (
    input: string | number,
    type: "ISO" | "MILLIS" | "SECONDS",
    format: "date" | "dateTime",
): string => {
    let date: DateTime;
    switch (type) {
        case "ISO":
            date = DateTime.fromISO(input.toString()).toLocal();
            break;
        case "MILLIS":
            date = DateTime.fromMillis(Number(input)).toLocal();
            break;
        case "SECONDS":
            date = DateTime.fromSeconds(Number(input)).toLocal();
            break;
    }

    return format === "dateTime" ? date.toFormat(dateTimeFormat) : date.toFormat(dateFormat);
};
