export * from "./filters";
export * from "./batchActions";
export * from "./details";

import { RootState } from "src/store";

import { localState } from "@dashboard/devices/store";

export type ViewState = {
    groups: {
        collapse: number[];
    };
    licenses: {
        pageSize: number;
    };
};

const selectSearchQuery = (state: RootState): string => localState(state)?.search?.query ?? "";
const selectPaneTab = (state: RootState) => localState(state).pane.tab;
const selectPaneEditEnabled = (state: RootState) => localState(state).pane.editEnabled;
const selectPaneStatus = (state: RootState) => localState(state).pane.status;

export { selectPaneEditEnabled, selectPaneStatus, selectPaneTab, selectSearchQuery };
