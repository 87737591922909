import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";
import { Modal } from "src/ui/shared/Modal/Modal";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { PrimaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { updateProvisioning } from "../store";
import { Provisioning } from "../types";

interface Props {
    open: boolean;
    onClose: () => void;
    provisioning: Provisioning;
}

const EditProvisioningNameDialog = ({ open, onClose, provisioning }: Props) => {
    const dispatch = useDispatch();

    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const provisioningNames = provisionings.map((item) => item.name).filter((name) => name !== provisioning.name);

    const onValidate = (values: Provisioning) => {
        const errors: Partial<Provisioning> = {};

        if (!values.name) {
            errors.name = "Name cannot be empty";
        }
        if (provisioningNames.includes(values.name)) {
            errors.name = "Name must be unique";
        }

        return errors;
    };

    const onSubmit = async (values: Provisioning) => {
        await dispatch(updateProvisioning({ ...values, name: values.name }))
            .unwrap()
            .then(() => showSuccessToast("Provisioning name changed"))
            .catch(({ message = "Update failed" }) => showErrorToast(message))
            .finally(() => {
                onClose();
            });
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<Provisioning>) => {
        if (e.key === "Enter" && !form.getState().submitting) {
            void form.submit();
        }
    };

    const handleOnClose = (form: FormApi<Provisioning>) => {
        form.reset();
        onClose();
    };

    return (
        <Form<Provisioning>
            onSubmit={onSubmit}
            validate={onValidate}
            initialValues={provisioning}
            render={({ handleSubmit, submitting, form }) => (
                <Modal
                    title="Edit provisioning name"
                    open={open}
                    onClose={() => handleOnClose(form)}
                    onKeyDown={(e) => onKeyDown(e, form)}
                    endButton={
                        <PrimaryButton onClick={form.submit} disabled={submitting} loading={submitting}>
                            Save
                        </PrimaryButton>
                    }
                >
                    <form onSubmit={handleSubmit}>
                        <TextField label="Settings name" fullWidth name="name" />
                    </form>
                </Modal>
            )}
        />
    );
};

export default EditProvisioningNameDialog;
