import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";
import { getBilling, updateBilling } from "src/services/accountService";
import CountryService from "src/services/countryService";
import { useValidationSchema } from "src/ui/utils/useValidationSchema";

import { ProfileBillingAddress } from "@dashboard/devices/types";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { ContentContainer, PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { SelectField, SimpleValue } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { validationSchema } from "./EditBillingPage.validation";

const useStyles = createUseStyles({
    titleContainer: {
        textAlign: "center",
    },
    formLabel: {
        fontSize: "16px",
    },
    saveContainer: {
        textAlign: "end",
    },
});

type FormValues = ProfileBillingAddress;

const EditBillingPage = () => {
    const classes = useStyles();
    const [profile, setProfile] = useState<ProfileBillingAddress | undefined>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState<SimpleValue[]>([]);
    const validate = useValidationSchema(validationSchema);
    const navigate = useNavigate();

    const onSubmit = async (values: FormValues) => {
        try {
            const { message } = await updateBilling(values);
            showSuccessToast(message);
            navigate("/account/profile");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        }
    };

    const onCancel = () => {
        navigate("/account/profile");
    };

    useEffect(() => {
        const fetchBillingAndCountries = async () => {
            setLoading(true);

            const countryListData = await CountryService.getSelectableCountryList();
            setCountryList(countryListData);

            try {
                const data = await getBilling();
                setProfile(data);
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            } finally {
                setLoading(false);
            }
        };

        void fetchBillingAndCountries();
    }, []);

    return (
        <PageContainer>
            <PageTitleWithIcon title="Billing data" iconName="fa-solid fa-gears" />
            <SectionDescription>Address inromation for billing purpouse</SectionDescription>
            <ContentContainer>
                <Form<FormValues>
                    onSubmit={onSubmit}
                    initialValues={profile}
                    validate={validate}
                    render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <label htmlFor="name" className={classes.formLabel}>
                                                Full name
                                            </label>
                                        </TableCell>
                                        <TableCell>
                                            <TextField name="name" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label htmlFor="address" className={classes.formLabel}>
                                                Address
                                            </label>
                                        </TableCell>
                                        <TableCell>
                                            <TextField name="address" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label htmlFor="zipCode" className={classes.formLabel}>
                                                Zip code
                                            </label>
                                        </TableCell>
                                        <TableCell>
                                            <TextField name="zipCode" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label htmlFor="city" className={classes.formLabel}>
                                                City
                                            </label>
                                        </TableCell>
                                        <TableCell>
                                            <TextField name="city" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label htmlFor="country" className={classes.formLabel}>
                                                Country
                                            </label>
                                        </TableCell>
                                        <TableCell>
                                            <SelectField
                                                options={countryList}
                                                name="country"
                                                fullWidth
                                                required
                                                disabled={isLoading}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ borderBottom: "none" }}>
                                            <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
                                        </TableCell>
                                        <TableCell style={{ borderBottom: "none" }}>
                                            <div className={classes.saveContainer}>
                                                <PrimaryButton type="submit" disabled={submitting || pristine}>
                                                    Save
                                                </PrimaryButton>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </form>
                    )}
                />
            </ContentContainer>
        </PageContainer>
    );
};

export default EditBillingPage;
