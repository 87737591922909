import { useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CustomTileContainer } from "src/ui/shared/CustomTile";
import { LoadingIcon } from "src/ui/shared/Loading";

import { selectFilesListStatus } from "@dashboard/files/store/selectors";
import Grid from "@mui/material/Grid2";
import makeStyles from "@mui/styles/makeStyles";

import EnrollmentProgressCard from "./EnrollmentProgressCard/EnrollmentProgressCard";
import { ManagedAppsCard } from "./ManagedAppsCard";
import OnlineStatusCard from "./OnlineStatusCard/OnlineStatusCard";
import { OperatingSystemsCard } from "./OperatingSystemsCard";
import PolicyComplianceCard from "./PolicyComplianceCard/PolicyComplianceCard";

const useStyles = makeStyles(() => ({
    appsLoadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "inherit",
    },
}));

export const StatisticSection = (props: { filter: number[] }) => {
    const classes = useStyles();

    const groups = useSelector(selectGroups);
    const status = useSelector(selectFilesListStatus);

    const groupsIds = groups.map((group) => group.id);
    const groupsForStatisctics = props.filter.length ? props.filter : groupsIds;

    return (
        <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: 4 }}>
                <EnrollmentProgressCard groupIds={groupsForStatisctics} />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <OnlineStatusCard groupIds={groupsForStatisctics} />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <PolicyComplianceCard groupIds={groupsForStatisctics} />
            </Grid>
            <Grid size={{ xs: 12, md: 8 }}>
                {status === "pending" ? (
                    <CustomTileContainer className={classes.appsLoadingBox}>
                        <LoadingIcon />
                    </CustomTileContainer>
                ) : (
                    <ManagedAppsCard filter={props.filter} />
                )}
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
                <OperatingSystemsCard filter={props.filter} />
            </Grid>
        </Grid>
    );
};
