import _ from "lodash";

import { Group } from "@dashboard/devices/types";
import { Table } from "@tanstack/react-table";

import { GroupWithChildren } from "./GroupFilterHeader";

export const buildGroupTree = (groups: Group[], parentId: number | null = null): GroupWithChildren[] => {
    return groups
        .filter((group) => group.parentId === parentId)
        .map((group) => ({
            ...group,
            children: buildGroupTree(groups, group.id),
        }));
};
export const getAllGroupIds = (groups: GroupWithChildren[]): number[] => {
    return groups.reduce((acc: number[], group) => {
        return [...acc, group.id, ...getAllGroupIds(group.children)];
    }, []);
};

const getColumnFilters = <T,>(table: Table<T>, headerId: string) => {
    const columnFilters = table.getState().columnFilters.find((item) => item.id === headerId)?.value;

    if (Array.isArray(columnFilters)) {
        return columnFilters;
    }
    return [];
};
export const getInitialFilters = <T,>(table: Table<T>, groups: Group[], headerId: string) => {
    const columnFilters = getColumnFilters(table, headerId);
    const allGroups = groups.reduce((acc, group) => ({ ...acc, [group.name]: false }), {});
    const filterGroups = columnFilters.reduce((acc, item) => ({ ...acc, [item]: true }), {});

    return _.merge({}, allGroups, filterGroups);
};
