export const palette = {
    //Blue
    colorBluePrimary: "#042c4c",
    colorBluePrimaryLight: "#0c416d",
    colorBluePrimaryLight2: "#eaf3ff",
    colorBlueAccent: "#1976d2",
    colorBlueNavy: "#027fe1",
    colorBlueAlice: "#edf2f7",
    colorBlueAliceDarker: "#718096",

    //Black
    colorBlackAlt1: "#00000014",
    colorBlack: "#000000",

    //White
    colorWhite: "#ffffff",

    //Gray
    colorWhiteSnow: "#fafafc",
    colorWhiteGhost: "#f2f2f5",
    colorGrayLightAlt2: "#cfcfcf",
    colorGrayCloud: "#c0c0c2",
    colorGrayLightAlt: "#aaa",
    colorGrayLight: "#888",
    colorGrayDark: "#666",

    //Red
    colorRedEmber: "#ff0000",
    colorRedEmberLight: "#dc3545",
    colorRedGrape: "#d93249",

    //Orange
    colorOrangeLight: "#fff4e5",
    colorOrangeDark: "#ffa500",

    //Green
    colorGreenEmerald: "#13c296",

    //Yellow
    colorYellowPastel: "#efd26d",
    colorYellowBright: "#ffff00",
    colorYellowTransparent: "#ffdf0033",
};
