import { DeviceStatus, LicenseActivationStatus, PolicyStatus } from "@dashboard/devices/types";

const licenseStatusFilterOptions: { value: LicenseActivationStatus; title: string }[] = [
    { value: "Used", title: "Used" },
    { value: "Unused", title: "Unused" },
];
export const licenseStatusFilterOptionsArray = licenseStatusFilterOptions.map((item) => item.value);

const deviceStatusFilterOptions: { value: DeviceStatus; title: string }[] = [
    { value: "Online", title: "Online" },
    { value: "Offline", title: "Offline" },
];
export const deviceStatusFilterOptionsArray = deviceStatusFilterOptions.map((item) => item.value);

const policyStatusFilterOptions: { value: PolicyStatus; title: string }[] = [
    { value: "Compliant", title: "Compliant" },
    { value: "Non-compliant", title: "Non-compliant" },
];
export const policyStatusFilterOptionsArray = policyStatusFilterOptions.map((item) => item.value);

const DeviceFilterTypes = [
    "license_activationStatus",
    "device_status",
    "policy_status",
    "group_name",
    "codename",
    "system_version",
] as const;
export type DeviceFilterType = (typeof DeviceFilterTypes)[number];
