import { useSelector } from "react-redux";
import { RootState, useDispatch } from "src/store";
import { DangerOutlinedButton, SecondaryButton } from "src/ui/shared/CustomButton";

import { useCommands } from "@devices/components/Commands/hooks";
import { setPaneTab } from "@devices/store";
import { Device } from "@devices/types";
import { ComplexCommandNames } from "@devices/types/commands";
import { getPresignedDownloadUrl } from "@files/api";
import { selectFilesByGroupIdWithShared } from "@files/store/selectors";
import { FileInfo } from "@files/types";
import { isApk } from "@files/utils";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CellContext } from "@tanstack/react-table";

import { Application } from "./AppTab";

const useStyles = makeStyles({
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        width: 140,
        height: 50,
    },
});

const getLatestApk = (avaibleApks: FileInfo[]) => {
    return avaibleApks.reduce((latest, current) => {
        const latestVersionCode = latest.metadata?.file?.apk?.manifest?.versioncode;
        const currentVersionCode = current.metadata?.file?.apk?.manifest?.versioncode;
        return latestVersionCode
            ? Number(currentVersionCode) > Number(latestVersionCode)
                ? current
                : latest
            : current;
    });
};
const getNewPackageVersion = (currentPackageVersion: number, avaibleApks: FileInfo[]) => {
    if (avaibleApks.length) {
        const latestApk = getLatestApk(avaibleApks);
        const latestPackageVersion = Number(latestApk.metadata?.file?.apk?.manifest?.versioncode);
        return latestPackageVersion > currentPackageVersion ? latestApk : null;
    }
    return null;
};

const ActionButtons = (props: CellContext<Application, unknown> & { device: Device }) => {
    const { createMdmCommand, loadingState } = useCommands();
    const dispatch = useDispatch();

    const handleTooltipText = (): string => {
        if (!props.row.original.enabled) {
            return "Application is disabled";
        } else if (!props.row.original.launchable) {
            return "Application is not launchable";
        } else {
            return "Start application";
        }
    };
    const handleStartButton = async () => {
        const startCommandObj = {
            command: ComplexCommandNames.StartPackage,
            caption: "Start package",
            package: props.row.original.package,
        };

        await createMdmCommand({ deviceId: props.device.id, json: startCommandObj }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };
    const handleStopButton = async () => {
        const stopCommandObj = {
            command: ComplexCommandNames.StopPackage,
            caption: "Stop package",
            package: props.row.original.package,
        };

        await createMdmCommand({ deviceId: props.device.id, json: stopCommandObj }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };

    if (props.row.original.enabled && props.row.original.launchable && !props.row.original.running) {
        return (
            <SecondaryButton
                tooltipProps={{ title: "Start application" }}
                onClick={handleStartButton}
                disabled={loadingState.createLoading}
                loading={loadingState.createLoading}
            >
                <i className="fas fa-play" />
            </SecondaryButton>
        );
    } else if (props.row.original.launchable && props.row.original.running) {
        return (
            <SecondaryButton
                tooltipProps={{ title: "Stop application" }}
                onClick={handleStopButton}
                disabled={loadingState.createLoading}
                loading={loadingState.createLoading}
            >
                <i className="fas fa-stop" />
            </SecondaryButton>
        );
    } else {
        return (
            <SecondaryButton disabled tooltipProps={{ title: handleTooltipText() }}>
                <i className="fas fa-play" />
            </SecondaryButton>
        );
    }
};
export const ActionCell = (props: CellContext<Application, unknown> & { groupId: number; device: Device }) => {
    const { createMdmCommand, loadingState } = useCommands();
    const classes = useStyles();
    const dispatch = useDispatch();

    const files = useSelector((state: RootState) => selectFilesByGroupIdWithShared(state, props.groupId));
    const apkFiles = files.filter((file) => isApk(file.filename));
    const avaibleApks = apkFiles.filter(
        (item) => item.metadata?.file?.apk?.manifest?.package === props.row.original.package,
    );
    const newPackageVersion = getNewPackageVersion(props.row.original.versionCode, avaibleApks);

    const installPackageForDevice = async (apk: FileInfo) => {
        const url = await getPresignedDownloadUrl(apk.id);
        if (url) {
            const commandObject = {
                url,
                package: apk.metadata?.file?.apk?.manifest?.package,
                version: apk.metadata?.file?.apk?.manifest?.versioncode,
            };

            await createMdmCommand({
                deviceId: props.device.id,
                json: { "command": "installPackage", "caption": "Install package", ...commandObject },
            }).then(() => {
                dispatch(setPaneTab("COMMANDS"));
            });
        }
    };
    const handleUninstallButton = async () => {
        const uninstallCommandObj = {
            command: ComplexCommandNames.UninstallPackage,
            caption: "Uninstall package",
            package: props.row.original.package,
            version: props.row.original.versionCode,
        };

        await createMdmCommand({ deviceId: props.device.id, json: uninstallCommandObj }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };

    return (
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className={classes.buttonBox}>
                {newPackageVersion && (
                    <SecondaryButton
                        tooltipProps={{ title: "Install update" }}
                        onClick={() => installPackageForDevice(newPackageVersion)}
                        disabled={loadingState.createLoading}
                        loading={loadingState.createLoading}
                    >
                        <i className="fa-solid fa-angles-up" />{" "}
                    </SecondaryButton>
                )}

                <ActionButtons {...props} />

                {props.row.original.system === "application" && (
                    <DangerOutlinedButton
                        tooltipProps={{ title: "Uninstall app" }}
                        onClick={handleUninstallButton}
                        disabled={loadingState.createLoading}
                        loading={loadingState.createLoading}
                    >
                        <i className="fa-solid fa-trash-alt" />
                    </DangerOutlinedButton>
                )}
            </div>
        </Box>
    );
};
