import { FormApi } from "final-form";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { Form } from "react-final-form";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { CheckboxField } from "src/ui/shared/form/CheckboxField";
import { Modal } from "src/ui/shared/Modal/Modal";
import { Header } from "src/ui/shared/table/TableHeader";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { Box, Checkbox, DialogProps, FormControlLabel, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CustomBadge } from "@shared/CustomBadge";
import { ColumnFilter, Header as TanstackHeader } from "@tanstack/react-table";

const useStyles = makeStyles((theme: Theme) => ({
    content: { minHeight: 300, background: theme.palette.shadow[50], borderRadius: "6px" },
    empty: { height: 150, display: "flex", justifyContent: "center", alignItems: "center" },
    informationText: {
        fontWeight: 600,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 16,
        padding: "14px 0px",
        maxHeight: 300,
        overflow: "auto",
        overflowX: "hidden",
    },
    selectManyContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    filterBadge: {
        cursor: "pointer",
        marginLeft: 5,
        paddingRight: 5,
        paddingBottom: 3,
        display: "inline",
    },
    tagIcon: {
        display: "inline",
        color: theme.palette.black[100],
    },
}));

const getFilterArray = (columnFilters: ColumnFilter[]) => {
    const filters = columnFilters.find((filter) => filter.id === "tags")?.value;

    if (Array.isArray(filters)) {
        return Object.fromEntries(filters.map((key) => [key, true]));
    }
    return [];
};
const changeAllValuesTo = (obj: Record<string, boolean>, value: boolean) => {
    const copy = { ...obj };
    for (const key of Object.keys(copy)) {
        copy[key] = value;
    }
    return copy;
};

interface FormValues {
    [x: string]: boolean;
}

interface Props<T> extends Omit<DialogProps, "open"> {
    tags: API.Tag[];
    columnFilters: ColumnFilter[];
    onSetFilters: Dispatch<SetStateAction<Record<string, boolean>>>;
    header: TanstackHeader<T, unknown>;
}

export const TagFilterHeader = <T,>({ tags, columnFilters, onSetFilters, header, ...props }: Props<T>) => {
    const [open, setIsOpen] = React.useState(false);
    const classes = useStyles();

    const allTagsObject = _.mapValues(_.keyBy(tags, "key"), () => false);
    const filters: Record<string, boolean> = { ...allTagsObject, ...getFilterArray(columnFilters) };
    const filtersArr = Object.keys(filters);
    const truthyFilters = changeAllValuesTo(filters, true);
    const falsyFilters = changeAllValuesTo(filters, false);

    const onSubmit = (values: FormValues) => {
        onSetFilters(values);
        setIsOpen(false);
    };

    const handleCloseButton = (form: FormApi<FormValues, Partial<FormValues>>) => {
        form.reset(filters);
        setIsOpen(false);
    };

    return (
        <Header {...header}>
            <CustomBadge
                className={classes.filterBadge}
                badgeContent={Object.values(filters).filter((item) => item).length}
                onClick={() => setIsOpen(true)}
                overlap="rectangular"
                data-testid="tag-filter-button"
            >
                <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
            </CustomBadge>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={filters}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <Modal
                            {...props}
                            open={open}
                            title="Tag filtering"
                            onClose={() => handleCloseButton(form)}
                            endButton={<PrimaryButton onClick={form.submit}>Save</PrimaryButton>}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className={classes.content}>
                                    {!filtersArr.length ? (
                                        <Box className={classes.empty}>
                                            <Typography>No tags</Typography>
                                        </Box>
                                    ) : null}
                                    <div className={classes.checkboxContainer}>
                                        {filtersArr.map((item) => (
                                            <CheckboxField key={item} name={item} label={item} />
                                        ))}
                                    </div>
                                </div>
                                <div className={classes.selectManyContainer}>
                                    {Object.values(values).length ? (
                                        <FormControlLabel
                                            label={<span className={classes.informationText}>Select all</span>}
                                            control={
                                                <Checkbox
                                                    checked={Object.values(values).every((item) => item)}
                                                    indeterminate={false}
                                                    onChange={(
                                                        _e: React.ChangeEvent<HTMLInputElement>,
                                                        checked: boolean,
                                                    ) => {
                                                        form.reset(checked ? truthyFilters : falsyFilters);
                                                    }}
                                                />
                                            }
                                        />
                                    ) : null}
                                </div>
                            </form>
                        </Modal>
                    );
                }}
            />
        </Header>
    );
};
