import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.blue[350],
        "& .MuiTableCell-root": {
            color: theme.palette.white[50],
            fontWeight: "bold",
        },
    },
    loadingContainer: {
        height: "50px",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    text: {
        textAlign: "center",
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        height: 50,
    },
    collapseBtn: {
        "&:focus": {
            outline: "none",
        },
    },
    collapsingRowContent: {
        padding: 10,
        width: "100%",
        maxWidth: "100%",
        minHeight: 36,
        textAlign: "left",
        fontSize: "0.9rem",
        wordWrap: "break-word",
    },
    collapsePropertyTitle: {
        fontWeight: "bold",
    },
    borderBottomVisible: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
    overflow: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}));
