import _ from "lodash";
import { RootState } from "src/store";
import { selectLicensesByGroupId } from "src/ui/containers/dashboard/licenses/store/selectors";

import { groupsAdapter } from "@dashboard/devices/store/helpers";
import { selectSubscription, selectSubscriptionById } from "@dashboard/devices/store/selectors/subscriptions";
import { Device, Group, GroupRoles } from "@dashboard/devices/types";
import { selectFiles } from "@dashboard/files/store/selectors";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

import { GroupOverviewData } from "../GroupsPage";

const defaultSelectors = groupsAdapter.getSelectors<RootState>((state) => state.devices.list.groups.items);

const selectGroupById = defaultSelectors.selectById;
const selectGroupCommands = createSelector(
    (state: RootState) => state,
    (_state: RootState, groupId: number) => groupId,
    (state, groupId) => {
        const group = defaultSelectors.selectById(state, groupId);
        return group?.commands ?? [];
    },
);
const selectGroupMemberRoleByEmail = (state: RootState, groupId: number, email: string | null): GroupRoles | null => {
    const group = defaultSelectors.selectById(state, groupId);
    const groupMember = group?.members?.find((member) => member.email === email);
    if (!groupMember) {
        return null;
    }

    return groupMember.role;
};

const selectGroups = createSelector([(state: RootState) => state, defaultSelectors.selectAll], (state, groups) => {
    const subscription = selectSubscription(state);
    return subscription ? groups.filter((item) => subscription.groupIds.includes(item.id)) : [];
});
const selectGroupsByIds = createSelector(
    selectGroups,
    (_state: RootState, groupIds: number[]) => groupIds,
    (groups, groupIds) => {
        return groups.filter((group) => groupIds.includes(group.id));
    },
);
const selectGroupsEntities = defaultSelectors.selectEntities;
const selectGroupsIdsBySubscriptionId = createSelector(
    (state: RootState) => state,
    (_state: RootState, subscriptionId: number) => subscriptionId,
    (state, subscriptionId) => {
        const subscription = selectSubscriptionById(state, subscriptionId);
        return subscription ? subscription.groupIds : [];
    },
);
const selectGroupsMembersByWorkspace = createSelector(
    (state: RootState) => state,
    selectWorkspace,
    selectGroups,
    (state, workspace, groups) => {
        const groupsMembers = groups.map((item) => item.members).flat();
        return workspace?.members
            ? _.uniqBy([...workspace.members, ...groupsMembers], "email")
            : _.uniqBy(groupsMembers, "email");
    },
);
const selectGroupsNames = createSelector(selectGroups, (groups) => groups.map((group) => group.name));

function getCompliantDevicesPercentage(group: Group, groupDevices: (Device | null)[]) {
    const policy = group.policy && group.policy.length > 0;
    const count = groupDevices.filter((device) => device?.lastState?.policy?.successful).length;
    const percentage = groupDevices.length > 0 ? ((count / groupDevices.length) * 100).toFixed(1) : 0;
    return policy && groupDevices.length ? `${percentage.toString()}%` : "";
}
function getOnlineDevicesPercentage(groupDevices: (Device | null)[]) {
    const count = groupDevices.filter((device) => device?.isOnline).length;
    const percentage = groupDevices.length > 0 ? Math.round((count / groupDevices.length) * 100).toFixed(1) : 0;
    return groupDevices.length ? `${percentage.toString()}%` : "";
}
function getAggregateDevices(items: { id: number; parentId: number | null; devices: (Device | null)[] }[]) {
    const groupedByParent = _.groupBy(items, "parentId");

    const calculateAggregate = (id: number): (Device | null)[] => {
        const children = groupedByParent[id] || [];
        return children.map((item) => [...item.devices, ...calculateAggregate(item.id)]).flat();
    };

    const result: Record<number, (Device | null)[]> = {};

    Object.keys(groupedByParent)
        .filter((item) => item !== null)
        .forEach((id) => {
            result[Number(id)] = calculateAggregate(Number(id));
        });

    return result;
}
function addChildrenProperty(data: GroupOverviewData[]) {
    const map = new Map<number, GroupOverviewData>();

    data.forEach((item) => {
        map.set(item.id, { ...item, children: [] });
    });

    data.forEach((item) => {
        if (item.parentId !== null) {
            const parent = map.get(item.parentId);
            const child = map.get(item.id);
            if (parent && child) {
                parent.children.push(child);
            }
        }
    });

    const groupIds = data.map((item) => item.id);
    return Array.from(map.values()).filter((item) => item.parentId == null || !groupIds.includes(item.parentId));
}

const selectGroupsOverviewData = createSelector(
    (state: RootState) => state,
    selectGroups,
    selectWorkspace,
    selectFiles,
    (state, groups, workspace, files) => {
        const groupArray: GroupOverviewData[] = [];

        const devicesByParentId = getAggregateDevices(
            groups.map((item) => {
                const groupLicenses = selectLicensesByGroupId(state, item.id);
                const groupDevices = groupLicenses.map((license) => license.device).filter((device) => device !== null);
                return { id: item.id, parentId: item.parentId, devices: groupDevices };
            }),
        );

        groups.map((group) => {
            const groupLicenses = selectLicensesByGroupId(state, group.id);
            const groupDevices = groupLicenses.map((license) => license.device).filter((device) => device !== null);
            const aggregateChildrenDevices = devicesByParentId[group.id] || [];
            const aggregateDevices = [...aggregateChildrenDevices, ...groupDevices];

            const newGroupData = {
                name: group.name,
                description: group.description || "-",
                onlineDevices: getOnlineDevicesPercentage(aggregateDevices),
                compliantDevices: getCompliantDevicesPercentage(group, aggregateDevices),
                numberOfFiles: files.filter((file) => file.groupId === group.id).length.toString(),
                numberOfDevices: aggregateDevices.length.toString(),
                id: group.id,
                isDefault: workspace?.defaultGroupId === group.id,
                parentId: group.parentId,
                children: [],
            };

            groupArray.push(newGroupData);
        });

        return addChildrenProperty(groupArray);
    },
);

const selectGroupChildrenIds = createSelector(
    defaultSelectors.selectAll,
    (_state: RootState, groupId: number) => groupId,
    (groups, groupId) => {
        const groupedByParent = _.groupBy(groups, "parentId");

        const calculateAggregate = (id: number): number[] => {
            const children = groupedByParent[id] || [];
            return children.map((item) => [item.id, ...calculateAggregate(item.id)]).flat();
        };

        return calculateAggregate(groupId);
    },
);

export {
    selectGroupById,
    selectGroupCommands,
    selectGroupMemberRoleByEmail,
    selectGroups,
    selectGroupsByIds,
    selectGroupsEntities,
    selectGroupChildrenIds,
    selectGroupsIdsBySubscriptionId,
    selectGroupsMembersByWorkspace,
    selectGroupsNames,
    selectGroupsOverviewData,
};
