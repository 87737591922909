import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    dialogContent: {
        width: 1100,
        paddingBottom: 0,
    },
    dialogTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
    },

    dialog: {
        maxWidth: "1200px",
    },
    qrContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    contentBox: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    modeContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: 12,
    },
    dataContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: 40,
        width: "100%",
    },
    advancedModeQrPosition: {
        margin: "0 auto",
    },
    input: {
        marginBottom: 20,
    },
});
