import _ from "lodash";

import { SortingState } from "@tanstack/react-table";

import { LocalStorage } from "./localStorageService";

type CommonSettings = {
    drawerOpen: boolean;
    fetchPublicProducts: boolean;
    includeTerminatedWorkspaces: boolean;
};
type WorkspaceSettings = {
    [workspaceId: string]: {
        licenseSidePanel: {
            appsTab: {
                systemFilter: string[];
            };
            fileTab: {
                typeFilter: string[];
                tagsFilter: string[];
            };
        };
        groupSidePanel: {
            fileTab: {
                typeFilter: string[];
                tagsFilter: string[];
            };
        };
        groupPage: {
            filter: {
                name: string[];
            };
        };
        devicePage: {
            filter: {
                groupName: string[];
            };
            rowsPerPage: number;
            visibleColumns: Record<string, boolean>;
        };
        filePage: {
            sorting: SortingState;
        };
        provisioningPage: {
            collapsedSections: Record<string, boolean>;
        };
        overviewPage: {
            groupFilter: number[];
        };
        productPage: {
            lastSelectedProduct: number;
        };
        workspacePage: {
            lastExpirationBannerDate: number;
        };
    };
};

type UserSettings = Partial<CommonSettings> & WorkspaceSettings;

class UserSettingsService {
    private static getUserSettings(): UserSettings {
        const settings = LocalStorage.getItem<UserSettings | null>("userSettings");
        return settings || {};
    }
    private static setUserSettings(value: Partial<UserSettings>) {
        LocalStorage.setItem("userSettings", value);
    }

    //common
    static setCommonValue<T>(key: string, value: T) {
        const settings = this.getUserSettings();
        _.set(settings, key, value);
        this.setUserSettings(settings);
    }
    static getDrawerOpen() {
        const settings = this.getUserSettings();
        return settings?.drawerOpen || false;
    }
    static getFetchPublicProducts() {
        const settings = this.getUserSettings();
        return settings?.fetchPublicProducts || false;
    }
    static getIncludeTerminatedWorkspaces() {
        const settings = this.getUserSettings();
        return settings?.includeTerminatedWorkspaces || false;
    }

    //workspace
    static setWorkspaceValue<T>(key: string, value: T) {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");

        if (workspaceId) {
            const settings = this.getUserSettings();
            _.set(settings, `${workspaceId}.${key}`, value);
            this.setUserSettings(settings);
        }
    }
    static getAppTabSystemFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.licenseSidePanel?.appsTab?.systemFilter || ["application"];
        }
        return ["application"];
    }
    static getGroupPageNameFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.groupPage?.filter?.name || [];
        }
        return [];
    }
    static getDevicePageNameFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.devicePage?.filter?.groupName || [];
        }
        return [];
    }
    static getFilePageSorting() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.filePage?.sorting || [];
        }
        return [];
    }
    static getDevicePageRowsPerPage() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.devicePage?.rowsPerPage || 10;
        }
        return 10;
    }
    static getDevicePageVisibleColumns() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        let result: Record<string, boolean> = {
            "batteryHealth": false,
            "batteryLevel": false,
            "batteryPlugged": false,
            "batteryStatus": false,
            "batteryVoltage": false,
            "chargeId": false,
            "comment": false,
            "connection": false,
            "displayDensity": false,
            "displayRotation": false,
            "displaySize": false,
            "imei": false,
            "ipv6": false,
            "licenseStatus": false,
            "memoryAvailable": false,
            "memoryLow": false,
            "memoryTotal": false,
            "moveLicenseCheckbox": false,
            "onlineSince": false,
            "policyStatus": false,
            "systemTime": false,
            "systemTimezone": false,
            "systemUptime": false,
            "systemVersion": false,
            "wifiNetwork": false,
        };

        if (workspaceId && settings?.[workspaceId]?.devicePage?.visibleColumns) {
            result = settings?.[workspaceId]?.devicePage?.visibleColumns;
        }
        return result;
    }
    static getProvisioningPageCollapsedSections() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.provisioningPage?.collapsedSections || {};
        }
        return {};
    }
    static getOverviewPageGroupFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.overviewPage?.groupFilter || [];
        }
        return [];
    }
    static getProductPageLastSelectedProduct() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.productPage?.lastSelectedProduct || null;
        }
        return null;
    }
    static getWorkspacePageLastExpirationBannerDate() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.workspacePage?.lastExpirationBannerDate || null;
        }
        return null;
    }
    static getFileTabTypeFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.licenseSidePanel?.fileTab?.typeFilter || [];
        }
        return [];
    }
    static getFileTabTagsFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.licenseSidePanel?.fileTab?.tagsFilter || [];
        }
        return [];
    }
    static getGroupSidePanelFileTabTypeFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.groupSidePanel?.fileTab?.typeFilter || [];
        }
        return [];
    }
    static getGroupSidePanelFileTabTagsFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.groupSidePanel?.fileTab?.tagsFilter || [];
        }
        return [];
    }
    static getLicenseSidePanelFileTabTypeFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.licenseSidePanel?.fileTab?.typeFilter || [];
        }
        return [];
    }
    static getLicenseSidePanelFileTabTagsFilter() {
        const workspaceId = LocalStorage.getItem<string | null>("currentWorkspace");
        const settings = this.getUserSettings();

        if (workspaceId) {
            return settings?.[workspaceId]?.licenseSidePanel?.fileTab?.tagsFilter || [];
        }
        return [];
    }
}
export default UserSettingsService;
