import React from "react";
import { createUseStyles } from "react-jss";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import CreateGroupDialog from "src/ui/containers/dashboard/groups/components/CreateGroupDialog";
import { SecondaryButton } from "src/ui/shared/CustomButton";

import { Dropdown, DropdownItem, DropdownList, Icon, IconSize } from "@dashboard/devices/components/shared";
import { BatchActionState, setBatchMode } from "@dashboard/devices/store";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Box } from "@mui/material";

import ExportCSV from "./ExportCSV";

const useStyles = createUseStyles({
    container: { display: "flex" },
    dropdown: { marginRight: 4 },
    btnIcon: {
        marginRight: 6,
    },
    menuItem: {
        height: "1.85rem",
    },
});

type SelectBatchActionMode = (entity: BatchActionState["entity"], type: BatchActionState["type"]) => void;

export const Menu = () => {
    const [isCreateGroupDialogOpen, setCreateGroupDialogOpen] = React.useState(false);
    const [isManageOpen, setIsManageOpen] = React.useState(false);

    const anchorRefManage = React.useRef(null);

    const classes = useStyles();
    const dispatch = useDispatch();
    const workspace = useSelector(selectWorkspace);
    const isExpired = useSelector(selectSubscriptionExpired);

    const { groupAbility } = permissionService();

    const selectBatchActionMode: SelectBatchActionMode = (entity, type) => {
        dispatch(setBatchMode({ type, entity }));
        setIsManageOpen(false);
    };

    const handleManageOpenToggle = () => {
        setIsManageOpen((prev) => !prev);
    };

    const getTooltipTitle = () => {
        if (isExpired) {
            return "Subscription expired";
        }
        if (groupAbility(null, workspace).cannot("create", "Group")) {
            return "Your role does not allow you to create groups";
        }
        return "";
    };

    return (
        <Box className={classes.container}>
            <Dropdown>
                <div ref={anchorRefManage}>
                    <SecondaryButton
                        onClick={handleManageOpenToggle}
                        size="small"
                        startIcon={<i className="fa-solid fa-gear" />}
                    >
                        Manage
                    </SecondaryButton>
                </div>
                <DropdownList
                    isOpen={isManageOpen}
                    onClickAway={() => setIsManageOpen(false)}
                    anchorRef={anchorRefManage}
                >
                    <DropdownItem
                        className={classes.menuItem}
                        onClick={() => setCreateGroupDialogOpen(true)}
                        disabled={groupAbility(null, workspace).cannot("create", "Group")}
                        tooltipProps={{
                            title: getTooltipTitle(),
                            hide: groupAbility(null, workspace).can("create", "Group"),
                        }}
                    >
                        <Icon name="fa-solid fa-plus" size={IconSize.small} className={classes.btnIcon} />
                        Add group
                    </DropdownItem>
                    <DropdownItem className={classes.menuItem} onClick={() => selectBatchActionMode("license", "move")}>
                        <Icon
                            name="fa-solid fa-arrow-right-arrow-left"
                            size={IconSize.small}
                            className={classes.btnIcon}
                        />
                        Move licenses
                    </DropdownItem>
                    <ExportCSV />
                </DropdownList>
            </Dropdown>
            <CreateGroupDialog open={isCreateGroupDialogOpen} onClose={() => setCreateGroupDialogOpen(false)} />
        </Box>
    );
};
