import React from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import UserSettingsService from "src/services/userSettingsService";
import { HoverRow } from "src/ui/shared/table/HoverRow";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { fuzzyFilter } from "src/ui/shared/table/Tanstack.utils";
import { Tooltip } from "src/ui/shared/Tooltip";

import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { Search } from "@devices/components/Header/Search";
import { Device, DeviceState } from "@devices/types";
import { selectFilesListStatus } from "@files/store/selectors";
import { Box, Paper, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Header } from "@shared/table/TableHeader";
import {
    CellContext,
    ColumnDef,
    ColumnFiltersState,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { ActionCell } from "./ActionCell";
import { FilterHeader } from "./FilterHeader";
import { NameCell } from "./NameCell";

const useStyles = makeStyles({
    content: { maxHeight: "100%", marginBottom: 60 },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
        width: 140,
        height: 50,
    },
    box: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        cursor: "pointer",
        "& i": {
            padding: "8px",
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
    expandedBox: { paddingLeft: 40, display: "flex", gap: 8 },
});

export type Application = {
    enabled: boolean;
    label: string;
    launchable: boolean;
    package: string;
    running: boolean;
    system: "system" | "application";
    versionCode: number;
    versionName: string;
};

const StatusCell = (props: CellContext<Application, unknown>) => {
    return (
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {props.row.original.running && (
                <Tooltip placement="top" title="Application is running">
                    <i className="fa-regular fa-circle-play" />
                </Tooltip>
            )}
            {!props.row.original.enabled && (
                <Tooltip placement="top" title="Application is hidden">
                    <i className="fa-sharp fa-solid fa-eye-slash" />
                </Tooltip>
            )}
        </Box>
    );
};
const TypeCell = (props: CellContext<Application, unknown>) => {
    return (
        <Box>
            {props.getValue<string>() === "system" ? (
                <Tooltip title="System app">
                    <i className="fa-solid fa-screwdriver-wrench" />
                </Tooltip>
            ) : (
                <Tooltip title="Installed app">
                    <i className="fa-solid fa-user" />
                </Tooltip>
            )}
        </Box>
    );
};
const transformData = (data?: DeviceState["packages"]): Application[] => {
    if (!data) {
        return [];
    }
    return data.map((item) => ({
        ...item,
        system: item.system ? "system" : "application",
    }));
};
const useInitialFilters = () => {
    const initialColumnFilters = UserSettingsService.getAppTabSystemFilter();

    if (initialColumnFilters.length) {
        return [{ id: "system", value: initialColumnFilters }];
    }

    return [];
};

interface Props {
    device: Device;
    groupId: number;
}
const AppTab = (props: Props) => {
    const initialColumnFilters = useInitialFilters();
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(initialColumnFilters);

    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const [searchQuery, setSearchQuery] = React.useState("");

    const classes = useStyles();
    const status = useSelector(selectFilesListStatus);

    const data = React.useMemo(
        () => transformData(props.device.lastState?.packages),
        [props.device.lastState?.packages],
    );
    const state = React.useMemo(
        () => ({ expanded, globalFilter: searchQuery, columnFilters }),
        [expanded, searchQuery, columnFilters],
    );
    const tableColumns: ColumnDef<Application, unknown>[] = React.useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "label",
                cell: (info) => <NameCell {...info} searchWords={[searchQuery]} />,
                enableSorting: true,
            },
            {
                header: "",
                accessorKey: "status",
                cell: (info) => <StatusCell {...info} />,
                enableSorting: false,
            },
            {
                header: "Type",
                accessorKey: "system",
                cell: (info) => <TypeCell {...info} />,
                enableSorting: true,
                filterFn: "arrIncludesSome",
            },
            {
                header: "Version",
                accessorKey: "versionCode",
                cell: (info) => (
                    <Highlighter
                        searchWords={[searchQuery]}
                        textToHighlight={String(info.getValue<number>())}
                        autoEscape
                    />
                ),
                enableSorting: true,
            },
            {
                header: "Action",
                accessorKey: "action",
                cell: (info) => <ActionCell groupId={props.groupId} device={props.device} {...info} />,
            },
        ],
        [searchQuery, props.device, props.groupId],
    );
    const table = useReactTable<Application>({
        data,
        state,
        initialState: { sorting: [{ id: "label", desc: false }] },
        columns: tableColumns,
        filterFns: { fuzzy: fuzzyFilter },
        getRowCanExpand: () => true,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
    });

    if (status === "pending") {
        return <PaneElement.Loader />;
    }

    return (
        <PaneElement.Container>
            <Box className={classes.content}>
                <PaneElement.Header>Available applications</PaneElement.Header>
                <Box style={{ display: "flex", flexDirection: "column", gap: 6, paddingTop: 8, paddingBottom: 16 }}>
                    <Search
                        defaultValue={searchQuery}
                        handleChange={(value) => setSearchQuery(String(value))}
                        handleClear={() => setSearchQuery("")}
                        placeholder="Enter a keyword to search..."
                    />
                </Box>

                <TableContainer component={Paper}>
                    <MinimalTable>
                        <MinimalTableHeader>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        if (header.id === "action") {
                                            return (
                                                <MinimalTableHeaderCell key={header.id} align="right">
                                                    Actions
                                                </MinimalTableHeaderCell>
                                            );
                                        }
                                        if (header.id === "system") {
                                            return (
                                                <FilterHeader<Application>
                                                    key={header.id}
                                                    header={header}
                                                    deviceId={props.device.id}
                                                    table={table}
                                                    options={[
                                                        { key: "system", label: "System applications" },
                                                        { key: "application", label: "User applications" },
                                                    ]}
                                                />
                                            );
                                        }

                                        return <Header key={header.id} {...header} />;
                                    })}
                                </TableRow>
                            ))}
                        </MinimalTableHeader>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <React.Fragment key={row.id}>
                                    <HoverRow {...row}>
                                        {row.getVisibleCells().map((cell) => (
                                            <MinimalTableCell key={cell.id} onClick={() => null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </MinimalTableCell>
                                        ))}
                                    </HoverRow>

                                    {row.getIsExpanded() ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={row.getAllCells().length}
                                                style={{ borderBottom: "none" }}
                                            >
                                                <Box className={classes.expandedBox}>
                                                    <Box style={{ display: "flex", flexDirection: "column" }}>
                                                        <Typography variant="body2" fontWeight={600}>
                                                            Package name:
                                                        </Typography>
                                                        <Typography variant="body2" fontWeight={600}>
                                                            Version name:
                                                        </Typography>
                                                        <Typography variant="body2" fontWeight={600}>
                                                            Version code:
                                                        </Typography>
                                                    </Box>
                                                    <Box style={{ display: "flex", flexDirection: "column" }}>
                                                        <Typography variant="body2">{row.original.package}</Typography>
                                                        <Typography variant="body2">
                                                            {row.original.versionName}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {row.original.versionCode}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </React.Fragment>
                            ))}
                            {!table.getRowModel().rows.length ? (
                                <TableRow>
                                    <MinimalTableCell colSpan={tableColumns.length + 1} align="center">
                                        No applications
                                    </MinimalTableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </MinimalTable>
                </TableContainer>
            </Box>
        </PaneElement.Container>
    );
};

export default AppTab;
