import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { RootState, useDispatch } from "src/store";
import { LimitType } from "src/types/limitType";
import { Modal } from "src/ui/shared/Modal/Modal";

import {
    deleteUniversalLicense,
    generateLicenses,
    revokeLicense,
    updateUniversalLicense,
} from "@dashboard/devices/store/index";
import {
    selectSubscription,
    selectSubscriptionLimitByLimitType,
} from "@dashboard/devices/store/selectors/subscriptions";
import { License } from "@dashboard/devices/types/index";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { selectGroups } from "@groups/store/selectors";
import { Alert, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { permissionService } from "@services/permissionService/permissionService";
import { DangerButton, DangerOutlinedButton } from "@shared/CustomButton";
import { UncontrolledSelectField } from "@shared/form/SelectField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { Tooltip } from "@shared/Tooltip";
import { PaneElement } from "@sidePanel/shared/PaneElement";

const useStyles = makeStyles({
    paragraph: { marginBottom: 12 },
    label: { marginBottom: 8, marginTop: 8 },
    form: { display: "flex", flexDirection: "column", gap: 4 },
    btnBox: { display: "flex", gap: 4, alignSelf: "flex-end" },
});

interface FormValues {
    groupId: number | null;
}
const LicenseTab = (license: License) => {
    const [openRevokeDialog, setOpenRevokeDialog] = React.useState(false);
    const [isRemoving, setIsRemoving] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [isRevoking, setIsRevoking] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace);
    const subscription = useSelector(selectSubscription);

    const permission = permissionService();
    const canManageLicense = permission.licenseAbility(license).can("manage", "License");

    const licensesLimit = useSelector((state: RootState) =>
        workspace?.subscription
            ? selectSubscriptionLimitByLimitType(state, workspace.subscription, LimitType.LicenseLimit)
            : 0,
    );
    const revocation = licensesLimit > 200 ? "self-governing" : "on request";
    const isUniversal = Boolean(license.universalGroupName);

    const groups = useSelector(selectGroups);
    const groupOptions = groups.map((item) => ({
        label: item.name,
        value: item.id,
        disabled: permission.groupAbility(item, workspace).cannot("manage", "UniversalLicense"),
    }));

    const avaibleGroups = groups.filter((item) =>
        permission.groupAbility(item, workspace).can("manage", "UniversalLicense"),
    );
    const isGroupIdInputDisabled = !avaibleGroups.length || !canManageLicense || isRemoving || isUpdating;

    const onRevoke = async () => {
        try {
            setIsRevoking(true);
            await dispatch(revokeLicense({ licenseId: license.id })).unwrap();
            await dispatch(generateLicenses({ groupId: license.groupId, grantCount: 1 })).unwrap();
            showSuccessToast("License has been revoked and a replacement license has been issued");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
            return;
        } finally {
            setIsRevoking(false);
            setOpenRevokeDialog(false);
        }
    };
    const onSubmit = async (values: FormValues) => {
        setIsUpdating(true);
        await dispatch(updateUniversalLicense({ id: license.id, groupId: Number(values.groupId) }))
            .unwrap()
            .then(() => showSuccessToast("Target group changed successful"))
            .catch(({ message = "Target group change failed" }) => showErrorToast(message))
            .finally(() => setIsUpdating(false));
    };
    const onRemove = async (form: FormApi<FormValues>) => {
        if (!isUniversal) {
            form.reset({ groupId: license.universalGroupId });
        } else {
            setIsRemoving(true);
            await dispatch(deleteUniversalLicense(license.id))
                .unwrap()
                .then(() => showSuccessToast("License is not universal anymore"))
                .catch(({ message = "Failed to remove the universal license. Please try again" }) =>
                    showErrorToast(message),
                )
                .finally(() => setIsRemoving(false));
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !isRevoking) {
            await onRevoke();
        }
    };
    const onGroupChange = async (value: unknown, form: FormApi<FormValues>) => {
        if (value === undefined) {
            await onRemove(form);
        } else {
            await onSubmit({ groupId: Number(value) });
        }
    };
    const getTooltipTitle = () => {
        if (!canManageLicense) {
            return "You have no permission to change target group";
        }
        if (!avaibleGroups.length) {
            return "There are no groups available";
        }
        return "";
    };

    return (
        <PaneElement.Container>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={{ groupId: license.universalGroupId || undefined }}
                render={({ handleSubmit, values, form }) => (
                    <React.Fragment>
                        {subscription?.variantName !== "Starter" ? (
                            <PaneElement.Section>
                                <PaneElement.Header>Revoke license</PaneElement.Header>
                                {revocation === "on request" ? (
                                    <Alert severity="info">
                                        Please reach out to <a href="mailto:support@emteria.com">support@emteria.com</a>{" "}
                                        with the license code and a short explanation for license revocation.
                                    </Alert>
                                ) : null}
                                <Typography className={classes.paragraph}>
                                    If your device is broken beyond repair, its associated license should be revoked.
                                    This will permanently hide the broken device from the list of all devices and allow
                                    a license replacement.
                                </Typography>
                                {revocation === "self-governing" ? (
                                    <div className={classes.btnBox}>
                                        <DangerOutlinedButton
                                            onClick={() => setOpenRevokeDialog(true)}
                                            disabled={!canManageLicense}
                                            tooltipProps={{
                                                hide: canManageLicense,
                                                title: !canManageLicense
                                                    ? "Your role does not allow you to revoke licenses"
                                                    : "This license has already been revoked",
                                            }}
                                        >
                                            Revoke
                                        </DangerOutlinedButton>
                                    </div>
                                ) : null}
                            </PaneElement.Section>
                        ) : null}

                        <PaneElement.Section withoutBorder>
                            <PaneElement.Header>Universal status</PaneElement.Header>
                            <Typography className={classes.paragraph}>
                                A{" "}
                                <a
                                    href="https://emteria.com/kb/create-universal-license#ul"
                                    rel="noreferrer nofollow"
                                    target="_blank"
                                >
                                    Universal License
                                </a>{" "}
                                enables automatic device enrollment into the selected group. Select a target group to
                                make this license universal:
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <Box className={classes.form}>
                                    <Tooltip
                                        title={getTooltipTitle()}
                                        hide={!!avaibleGroups.length && canManageLicense}
                                    >
                                        <UncontrolledSelectField
                                            value={values.groupId}
                                            onChange={(e) => onGroupChange(e.target.value, form)}
                                            options={groupOptions}
                                            disabled={isGroupIdInputDisabled}
                                            clearLabel="This license is not universal"
                                            fullWidth
                                        />
                                    </Tooltip>
                                </Box>
                            </form>
                        </PaneElement.Section>

                        <Modal
                            title="Revoke License"
                            open={openRevokeDialog}
                            onClose={() => setOpenRevokeDialog(false)}
                            onKeyDown={onKeyDown}
                            description="Revoking the license will remove this device from the list of your devices. Revoking
                            a license cannot be undone and should only be used for broken devices. Are you sure
                            you want to revoke this license?"
                            endButton={
                                <DangerButton onClick={onRevoke} loading={isRevoking} disabled={isRevoking}>
                                    Revoke
                                </DangerButton>
                            }
                        />
                    </React.Fragment>
                )}
            />
        </PaneElement.Container>
    );
};
export default LicenseTab;
