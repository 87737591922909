import React, { useState } from "react";
import { Form } from "react-final-form";
import { updateStripeCard } from "src/services/accountService";
import authService from "src/services/authService";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { Modal } from "src/ui/shared/Modal/Modal";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const useStyles = makeStyles({
    inputBox: {
        display: "flex",
        alignItems: "flex-start",
        gap: 10,
        padding: "10px 0",
    },
});

interface Props {
    open: boolean;
    onClose: () => void;
}

const EditStripeCard = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [consent, setConsent] = useState(false);

    const stripe = useStripe();
    const classes = useStyles();
    const elements = useElements();

    const toggleConsent: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConsent(e.currentTarget.checked);
    };

    const doSubmit = async (paymentMethodId: string | undefined) => {
        if (!paymentMethodId) {
            showErrorToast("Payment method ID is missing.");
            setLoading(false);
            return;
        }

        try {
            const { message } = await updateStripeCard(paymentMethodId);
            showSuccessToast(message);
            setSuccess(true);
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setLoading(false);
        }
    };
    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        const cardElement = elements?.getElement(CardElement);

        if (!stripe || !elements || !cardElement) {
            return;
        }

        e.preventDefault();
        setLoading(true);

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                email: authService.getCurrentUser()?.email,
            },
        });

        if (result.error) {
            setLoading(false);
            showErrorToast(result.error.message);
        } else {
            await doSubmit(result.paymentMethod?.id);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, form }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        title="Change default card"
                        open={props.open}
                        onClose={props.onClose}
                        endButton={
                            <PrimaryButton
                                onClick={form.submit}
                                disabled={loading || success || !consent}
                                loading={loading && !success}
                                fullWidth
                            >
                                {!loading && success ? <i className="far fa-check-circle" /> : "Update"}
                            </PrimaryButton>
                        }
                    >
                        <div className={classes.inputBox}>
                            <input
                                type="checkbox"
                                disabled={loading}
                                onChange={toggleConsent}
                                style={{ marginTop: "4px" }}
                            />

                            <Typography>
                                I hereby acknowledge that this card will be used for future off-session payments.
                            </Typography>
                        </div>
                        <CardElement />
                    </Modal>
                </form>
            )}
        />
    );
};

export default EditStripeCard;
