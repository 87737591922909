import { merge } from "lodash";
import defaultSettings from "src/settings/default.json";
import emteriaDevelopmentSettings from "src/settings/emteriaDevelopment.json";
import emteriaProductionSettings from "src/settings/emteriaProduction.json";
import emteriaStagingSettings from "src/settings/emteriaStaging.json";
import { Settings } from "src/types/settings";

export const getSettings = () => {
    const domain = window.location.hostname;
    const port = window.location.port;

    let settings = defaultSettings;

    if (domain === "localhost" && port === "3000") {
        settings = merge({}, defaultSettings, emteriaDevelopmentSettings);
    } else if (domain === "hub.emteria.com") {
        settings = merge({}, defaultSettings, emteriaProductionSettings);
    } else {
        settings = merge({}, defaultSettings, emteriaStagingSettings);
    }

    return settings as Settings;
};

export function getEnv() {
    return getSettings().envName;
}

export function getApiGatewayUrl(): string {
    return getSettings().api.apiGatewayUrl;
}

export function getHomepageUrl(): string {
    return getSettings().api.homepageUrl;
}

export function getMvcLegacyUrl(): string {
    return getSettings().api.apiLegacyUrl;
}

type AuthClient = {
    clientId: string;
    clientSecret: string;
};

export function getAuthClient(): AuthClient {
    return {
        clientId: getSettings().api.authClientId,
        clientSecret: getSettings().api.authClientSecret,
    };
}

export function getReduxVersion(): string {
    return "1.0";
}
