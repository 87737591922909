import clsx from "clsx";

import { Box, IconButton, Typography } from "@mui/material";
import { Tooltip } from "@shared/Tooltip";

import useStyles from "./Tag.style";

const getTitleWithTooltip = (title: string) => (title.length > 21 ? title : "");
export interface Props {
    item: API.Tag;
    onClick?: (tag: API.Tag) => void;
    theme?: "dark" | "light";
}
export const Tag = (props: Props) => {
    const classes = useStyles(props);

    const onClick = () => {
        if (props.onClick) {
            props.onClick(props.item);
        }
    };

    const isWrongTab = !props.item?.key || (props.item?.type === "scoped" && !props.item.value);

    if (isWrongTab) {
        return null;
    }

    if (props.item?.type === "simple") {
        return (
            <Tooltip placement="top" title={getTitleWithTooltip(props.item.key)}>
                <Box className={clsx(classes.container, classes.simpleContainer)}>
                    <div className={clsx(classes.label, classes.keyLabel, classes.simpleKeyLabel)}>
                        <Typography variant="caption" className={classes.key}>
                            {props.item.key}
                        </Typography>
                    </div>
                    {props.onClick ? (
                        <IconButton className={classes.button} onClick={onClick} size="large">
                            <i className={clsx("fa-solid fa-xmark", classes.iconLight)} />
                        </IconButton>
                    ) : null}
                </Box>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip placement="top" title={getTitleWithTooltip(props.item.value)}>
                <Box className={clsx(classes.container, classes.scopedContainer)}>
                    <Box className={classes.keyBox}>
                        <Typography variant="caption" className={clsx(classes.label, classes.keyLabel)}>
                            {props.item.key}
                        </Typography>
                    </Box>
                    <Box className={classes.valueBox}>
                        <Typography variant="caption" className={clsx(classes.label, classes.valueLabel)}>
                            {props.item.value}
                        </Typography>
                        {props.onClick ? (
                            <IconButton className={classes.button} onClick={onClick} size="large">
                                <i className={clsx("fa-solid fa-xmark", classes.iconDark)} />
                            </IconButton>
                        ) : null}
                    </Box>
                </Box>
            </Tooltip>
        );
    }
};
