import { FormApi } from "final-form";
import { Form, useForm, useFormState } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Provisioning } from "@dashboard/provisioning/types";
import { alternatingBg, Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Card, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { MinimalTableCell, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { TableHeader } from "@shared/table/TableHeader";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

const initialValues = { disableComponent: undefined };

type FormValues = { disableComponent: string };

export const DisabledComponents = () => {
    const { value, onChange } = useCollapsiblePersistence("provisioningPage.collapsedSections", "DisabledComponents");
    const parentForm = useForm();

    const sharedClasses = useStyles();
    const provisionings = useSelector(selectProvisionings, shallowEqual);

    const { values: parentFormValues } = useFormState<Partial<Provisioning>>();
    const components = parentFormValues.settings?.disabledComponents || [];

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        if (components.length) {
            parentForm.change("settings.disabledComponents", [...components, values.disableComponent]);
        }
        if (!components.length) {
            parentForm.change("settings.disabledComponents", [values.disableComponent]);
        }
        form.restart(initialValues);
    };
    const validate = (values: FormValues) => {
        if (components.includes(values.disableComponent)) {
            return { disableComponent: "This component has already been added" };
        }
        if (!values.disableComponent) {
            return { disableComponent: "Disabled component is not allowed to be empty" };
        }
    };
    const handleDelete = (index: number) => {
        parentForm.change(
            "settings.disabledComponents",
            components.filter((_, i) => i !== index),
        );
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ form }) => (
                <CollapsibleProvider initialValue={value} onChange={onChange}>
                    <CollapsibleSectionSummary>
                        <Typography className={sharedClasses.categoryTitle}>Disabled components</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <SectionDescription>
                            A list of not required applications and system components to be permanently disabled.
                        </SectionDescription>
                        <TableContainer component={Card}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell>Component name</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {components.length ? (
                                        components.map((component, index) => (
                                            <TableRow key={component} style={alternatingBg(index)}>
                                                <MinimalTableCell component="th" scope="row">
                                                    {component}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDelete(index)}
                                                        tooltipProps={{ title: "Remove" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell align="center" colSpan={2}>
                                                No disabled components to show
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row style={{ marginTop: 15 }}>
                            <TextField
                                name="disableComponent"
                                label="Fully Qualified Package Name"
                                disabled={!provisionings.length}
                                fullWidth
                            />
                            <PrimaryOutlinedButton onClick={form.submit} disabled={!provisionings.length}>
                                Add
                            </PrimaryOutlinedButton>
                        </Row>
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            )}
        />
    );
};
