import { useSelector } from "react-redux";
import { Alert } from "@mui/material";

import {
    selectSubscriptionExpired,
    selectSubscriptionExpiringWithinMonth,
} from "@dashboard/devices/store/selectors/subscriptions";

export const SubscriptionAlert = () => {
    const isExpired = useSelector(selectSubscriptionExpired);
    const isExpiringWithinMonth = useSelector(selectSubscriptionExpiringWithinMonth);

    if (isExpired) {
        return (
            <Alert title="Your subscription has expired" severity="error" style={{ marginTop: 12 }}>
                Your subscription has expired. To continue using our services and access all features, please renew your
                subscription.
                <a target="_blank" href="https://emteria.com/p/subscription" rel="noopener noreferrer">
                    {" "}
                    Contact Us{" "}
                </a>
                to renew your subscription or discuss your options.
            </Alert>
        );
    }

    if (isExpiringWithinMonth) {
        return (
            <Alert title="Your subscription is nearing expiration" severity="warning" style={{ marginTop: 12 }}>
                Your current subscription will expire in 30 days or less. To ensure uninterrupted service and access to
                all your features, we recommend taking action soon. If you wish to extend your subscription or explore
                other options, please contact our team at your earliest convenience. We&apos;re here to help with any
                questions or to guide you through the renewal process.
                <a target="_blank" href="https://emteria.com/p/subscription" rel="noopener noreferrer">
                    {" "}
                    Contact Us{" "}
                </a>
                to extend your subscription or discuss your options.
            </Alert>
        );
    }

    return null;
};
