import { FormApi } from "final-form";
import { Form, useForm, useFormState } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "src/store";

import { selectSubscription, selectSubscriptionsFdroidRepos } from "@dashboard/devices/store/selectors/subscriptions";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Provisioning } from "@dashboard/provisioning/types";
import { alternatingBg, Row, SectionDescription, useStyles } from "@dashboard/shared/styles";
import { Alert, Link, Paper, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { MinimalTableCell, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { TableHeader } from "@shared/table/TableHeader";

import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

type FormValues = { repositoryName: string };

export const PrivateRepositories = () => {
    const { value: initialValue, onChange } = useCollapsiblePersistence(
        "provisioningPage.collapsedSections",
        "PrivateRepositories",
    );
    const parentForm = useForm();
    const navigate = useNavigate();

    const sharedClasses = useStyles();
    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const subscription = useSelector(selectSubscription);
    const { values: parentFormValues } = useFormState<Partial<Provisioning>>();
    const repos = parentFormValues.settings?.fDroidRepos || [];
    const fdroidRepos = useSelector(selectSubscriptionsFdroidRepos);

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        const value = fdroidRepos.find((r) => r.name === values.repositoryName);
        if (repos.length) {
            parentForm.change("settings.fDroidRepos", [...repos, value]);
        }
        if (!repos.length) {
            parentForm.change("settings.fDroidRepos", [value]);
        }
        form.restart(values);
    };
    const validate = (values: FormValues) => {
        const repoAdded = repos.some((r) => r.name == values.repositoryName);

        if (!values.repositoryName) {
            return { repositoryName: "Please select a repository" };
        }
        if (repoAdded) {
            return { repositoryName: "This repository has already been added" };
        }
    };
    const handleDelete = (repoId: number) => {
        parentForm.change(
            "settings.fDroidRepos",
            repos.filter((repo) => repo.id !== repoId),
        );
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{ repositoryName: fdroidRepos[0]?.name }}
            render={({ form }) => (
                <CollapsibleProvider initialValue={initialValue} onChange={onChange}>
                    <CollapsibleSectionSummary>
                        <Typography className={sharedClasses.categoryTitle}>Private repositories</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <SectionDescription>
                            Additional sources for private applications to be included into the F-Droid store.
                        </SectionDescription>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell align="left">URL</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Title</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Description</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Subscription</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {repos.length ? (
                                        repos.map((item, index) => (
                                            <TableRow key={item.id} style={alternatingBg(index)}>
                                                <MinimalTableCell component="th" scope="row" align="left">
                                                    <a
                                                        href={`${"https://apps.emteria.com/fdroid/"}${item.name}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {`${"https://apps.emteria.com/fdroid/"}${item.name}`}
                                                    </a>
                                                </MinimalTableCell>
                                                <MinimalTableCell align="left">{item.title}</MinimalTableCell>
                                                <MinimalTableCell align="left">{item.description}</MinimalTableCell>
                                                <MinimalTableCell align="left">{subscription?.name}</MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDelete(item.id)}
                                                        tooltipProps={{ title: "Remove" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell align="center" colSpan={5}>
                                                No private repositories to show
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {fdroidRepos.length ? (
                            <Row style={{ marginTop: 15 }}>
                                <SelectField
                                    name="repositoryName"
                                    label="Repository name"
                                    disabled={!provisionings.length}
                                    options={fdroidRepos.map((item) => ({
                                        label: item.name,
                                        value: item.name,
                                    }))}
                                    fullWidth
                                />
                                <PrimaryOutlinedButton onClick={form.submit} disabled={!provisionings.length}>
                                    Add
                                </PrimaryOutlinedButton>
                            </Row>
                        ) : (
                            <Alert style={{ marginTop: 15 }} severity="info">
                                Please create a new private repository
                                <Link
                                    style={{ marginLeft: 5, marginRight: 5, paddingBottom: 5 }}
                                    color="inherit"
                                    underline="always"
                                    component="button"
                                    onClick={() => navigate("/dashboard/repositories")}
                                >
                                    in the app repositories page
                                </Link>
                                before adding it to provisioning settings.
                            </Alert>
                        )}
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            )}
        />
    );
};
