import React, { Component } from "react";
import { Link } from "react-router-dom";

import euporiaService from "../../services/euporiaService";

const NEWTAB = "_blank";
const MIDDLECLICK = 1;

export default class ApmLink extends Component {
    handleClick = (event) => {
        const { parent, target, title, children, href, onClick } = this.props;
        const normalClick = !(event.ctrlKey || event.shiftKey || event.metaKey || event.button === MIDDLECLICK);
        const sameTarget = target !== NEWTAB;

        let clickText = "";

        if (typeof children === "string") {
            clickText = children;
        } else if (React.isValidElement(children) && typeof children.props.children === "string") {
            clickText = children.props.children;
        } else if (Array.isArray(children)) {
            clickText = children.filter((child) => typeof child === "string").join(" ");
        }

        euporiaService.createTransaction("click", {
            click_element: "link",
            click_parent: parent,
            click_text: clickText,
            click_title: title,
            click_target: sameTarget ? "internal" : "external",
        });

        if (sameTarget && normalClick && href) {
            event.preventDefault();
            window.location.href = href;
        }

        if (onClick) {
            onClick(event);
        }
    };

    render() {
        const props = {
            ...this.props,
            onClick: this.handleClick,
        };

        if (props.to) {
            return (
                <Link className={props.className} to={props.to} onClick={props.onClick}>
                    {props.children}
                </Link>
            );
        }

        return React.createElement("a", props);
    }
}
