import { FormApi } from "final-form";
import _ from "lodash";
import { Form, useForm, useFormState } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { capitalizeFirstLetter } from "src/ui/utils/string";

import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { AppPreference, Provisioning } from "@dashboard/provisioning/types";
import { alternatingBg, Row, SectionDescription, useStyles as sharedUseStyles } from "@dashboard/shared/styles";
import { Paper, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { MinimalTableCell, MinimalTableHeaderCell } from "@shared/table/MinimalTableComponents";
import { TableHeader } from "@shared/table/TableHeader";

import { optionsType } from "../utils";
import {
    CollapsibleProvider,
    CollapsibleSectionDetails,
    CollapsibleSectionSummary,
    useCollapsiblePersistence,
} from "./CollapsibleSection";

const initialValues: Partial<AppPreference> = {
    package: undefined,
    category: undefined,
    comment: null,
    type: "Bool",
    key: undefined,
    value: undefined,
};

const useStyles = makeStyles({
    input: { flex: 1 },
    box: { display: "inline-flex", flex: 1, gap: 8 },
});

type FormValues = AppPreference;
type ValidationErros = Omit<FormValues, "type"> & { type: string };

export const ApplicationPreferences = () => {
    const { value: initialValue, onChange } = useCollapsiblePersistence(
        "provisioningPage.collapsedSections",
        "ApplicationPreferences",
    );
    const classes = useStyles();
    const parentForm = useForm();

    const sharedClasses = sharedUseStyles();
    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const { values: parentValues } = useFormState<Partial<Provisioning>>();
    const preferences = parentValues.settings?.appPreferences || [];

    const onSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        const newValue = { ...values, comment: values.comment || null };
        if (preferences.length) {
            parentForm.change("settings.appPreferences", [...preferences, newValue]);
        }
        if (!preferences.length) {
            parentForm.change("settings.appPreferences", [newValue]);
        }
        form.restart(initialValues);
    };
    const validate = (values: FormValues) => {
        return _.transform<FormValues, ValidationErros>(values, (result, value, key) => {
            if (!value && key !== "comment") {
                result[key] = `${capitalizeFirstLetter(key)} is not allowed to be empty`;
            }
        });
    };
    const handleDelete = (index: number) => {
        parentForm.change(
            "settings.appPreferences",
            preferences.filter((_item, i) => i !== index),
        );
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={({ form }) => (
                <CollapsibleProvider initialValue={initialValue} onChange={onChange}>
                    <CollapsibleSectionSummary>
                        <Typography className={sharedClasses.categoryTitle}>Application preferences</Typography>
                    </CollapsibleSectionSummary>
                    <CollapsibleSectionDetails>
                        <SectionDescription>Custom preferences for standard system applications</SectionDescription>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <MinimalTableHeaderCell align="left">Package</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Category</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Type</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Key</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Value</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="left">Comment</MinimalTableHeaderCell>
                                        <MinimalTableHeaderCell align="right">Action</MinimalTableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {preferences.length ? (
                                        preferences.map((item, index) => (
                                            <TableRow key={item.key + item.value} style={alternatingBg(index)}>
                                                <MinimalTableCell component="th" scope="row" align="left">
                                                    {item.package}
                                                </MinimalTableCell>
                                                <MinimalTableCell align="left">{item.category}</MinimalTableCell>
                                                <MinimalTableCell align="left">{item.type}</MinimalTableCell>
                                                <MinimalTableCell align="left">{item.key}</MinimalTableCell>
                                                <MinimalTableCell align="left">{item.value}</MinimalTableCell>
                                                <MinimalTableCell align="left">{item.comment}</MinimalTableCell>
                                                <MinimalTableCell align="right">
                                                    <DangerOutlinedButton
                                                        onClick={() => handleDelete(index)}
                                                        tooltipProps={{ title: "Remove" }}
                                                    >
                                                        <i className="fas fa-trash-alt" />
                                                    </DangerOutlinedButton>
                                                </MinimalTableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <MinimalTableCell align="center" colSpan={7}>
                                                No application preferences to show
                                            </MinimalTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row style={{ marginTop: 15, paddingBottom: 12 }}>
                            <TextField name="package" label="Package name" disabled={!provisionings.length} fullWidth />
                            <TextField
                                name="category"
                                label="Category name"
                                disabled={!provisionings.length}
                                fullWidth
                            />
                        </Row>
                        <Row style={{ paddingBottom: 12 }}>
                            <TextField name="key" label="Key name" disabled={!provisionings.length} fullWidth />
                            <SelectField
                                name="type"
                                label="Preference type"
                                options={optionsType.map((item) => ({
                                    label: item,
                                    value: item,
                                }))}
                                disabled={!provisionings.length}
                                fullWidth
                            />
                        </Row>
                        <Row>
                            <TextField
                                name="value"
                                label="Preference value"
                                disabled={!provisionings.length}
                                className={classes.input}
                            />
                            <div className={classes.box}>
                                <TextField
                                    name="comment"
                                    label="Comment"
                                    disabled={!provisionings.length}
                                    className={classes.input}
                                />
                                <PrimaryOutlinedButton onClick={form.submit} disabled={!provisionings.length}>
                                    Add
                                </PrimaryOutlinedButton>
                            </div>
                        </Row>
                    </CollapsibleSectionDetails>
                </CollapsibleProvider>
            )}
        />
    );
};
