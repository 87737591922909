import { compareVersions } from "compare-versions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "src/store";

import { getVisibleColumns, setVisibleColumns } from "@dashboard/devices/DevicesPage.utils";
import { clearAllFilters, updateFilterProperty } from "@dashboard/devices/store";
import { selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { selectGroups } from "@dashboard/groups/store/selectors";
import { Paper, TableBody, TableContainer, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CustomTileContainer, CustomTileTitle } from "@shared/CustomTile";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { Tooltip } from "@shared/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
    container: { height: "100%" },
    table: { marginTop: 12, boxShadow: "none" },
    link: { color: theme.palette.blue[150], cursor: "pointer", padding: 0, display: "inline-block" },
    tooltip: { display: "inline-block" },
}));

const compareOsVersions = (a: OperatingSystemWithCount, b: OperatingSystemWithCount): number => {
    if (a.count !== b.count) {
        return b.count - a.count;
    }

    return compareVersions(b.version, a.version);
};
type Props = {
    filter: number[];
};

type OperatingSystemWithCount = {
    version: string;
    count: number;
};

export const OperatingSystemsCard = ({ filter }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const groups = useSelector(selectGroups);
    const filteredGroups = filter.length > 0 ? groups.filter((group) => filter.includes(group.id)) : groups;
    const filteredGroupIds = filteredGroups.map((g) => g.id);
    const filteredDevices = useSelector((state) => selectDevicesByGroupIds(state, filteredGroupIds));

    const countOsArray: { [key: string]: number } = filteredDevices.reduce((acc, device) => {
        const version = device?.lastState?.version?.emteriaVersion || device?.lastState?.version?.androidVersion;
        if (version) {
            acc[version] = (acc[version] || 0) + 1;
        }
        return acc;
    }, {} as { [key: string]: number });

    const operatingSystemsWithCount: OperatingSystemWithCount[] = Object.entries(countOsArray).map(
        ([version, count]) => ({ version, count }),
    );

    const sortedOperatingSystemsWithCount = [...operatingSystemsWithCount].sort(compareOsVersions);

    const ensureSystemVersionColumnVisible = () => {
        const currentVisibleColumns = getVisibleColumns();

        const updatedVisibleColumns = {
            ...currentVisibleColumns,
            systemVersion: true,
        };
        setVisibleColumns(updatedVisibleColumns);
    };

    const handleVersionClick = (version: string) => {
        ensureSystemVersionColumnVisible();
        dispatch(clearAllFilters());
        dispatch(
            updateFilterProperty({
                property: "system_version",
                values: [version],
            }),
        );

        navigate("/dashboard/devices");
    };

    return (
        <CustomTileContainer className={classes.container}>
            <CustomTileTitle>OS versions</CustomTileTitle>
            <TableContainer component={Paper} className={classes.table}>
                <MinimalTable stickyHeader>
                    <MinimalTableHeader>
                        <TableRow>
                            <MinimalTableHeaderCell>Version</MinimalTableHeaderCell>
                            <MinimalTableHeaderCell align="right">Devices</MinimalTableHeaderCell>
                        </TableRow>
                    </MinimalTableHeader>
                    <TableBody>
                        {sortedOperatingSystemsWithCount.length === 0 && (
                            <TableRow>
                                <MinimalTableCell align="center" colSpan={2}>
                                    No devices
                                </MinimalTableCell>
                            </TableRow>
                        )}
                        {sortedOperatingSystemsWithCount.map((item, index) => (
                            <TableRow key={index}>
                                <MinimalTableCell border>{item.version}</MinimalTableCell>
                                <MinimalTableCell border align="right">
                                    <Tooltip
                                        title={item.count === 1 ? "Show this device" : "Show these devices"}
                                        className={classes.tooltip}
                                    >
                                        <span className={classes.link} onClick={() => handleVersionClick(item.version)}>
                                            {item.count}
                                        </span>
                                    </Tooltip>
                                </MinimalTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
        </CustomTileContainer>
    );
};
