import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    description: {
        margin: 0,
        marginBottom: 20,
        fontSize: 16,
        fontWeight: "normal",
    },
    card: {
        width: 400,
    },
    logo: {
        paddingBottom: 40,
    },
    linksContainer: {
        margin: 0,
        marginTop: 30,
        padding: "0 20px",
    },
});
