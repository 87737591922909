import { omit } from "lodash";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import authService from "src/services/authService";
import CountryService from "src/services/countryService";
import { useDispatch, useSelector } from "src/store";
import { selectProfile, updateProfile } from "src/store/account";

import { Profile } from "@dashboard/devices/types";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { ContentContainer, PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import ApmLink from "@shared/ApmLink";
import { PrimaryButton, PrimaryOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { CheckboxField } from "@shared/form/CheckboxField";
import { SelectField, SimpleValue } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { useStyles } from "./EditAccountPage.style";
import { validateProfile } from "./EditAccountPage.validation";

type FormValues = Profile;

const typeOfUsageOptions = [
    { value: "nb", label: "Non-business use" },
    { value: "b", label: "Business use" },
];

type ConfirmRegistrationPageState = {
    phoneCodes: SimpleValue[];
    countryList: SimpleValue[];
    mossCountries: string[];
};

const EditAccountPage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUserEmail = authService.getCurrentUser()?.email;
    const [isLoading, setLoading] = useState(false);
    const profileData = useSelector(selectProfile);
    const profile = omit(profileData, "password");
    const [data, setData] = useState<ConfirmRegistrationPageState>({
        phoneCodes: [],
        countryList: [],
        mossCountries: [],
    });

    const countryUsesMoss = data.mossCountries.includes(profile?.country || "");

    const onSubmit = async (values: FormValues) => {
        try {
            const response = await dispatch(updateProfile(values)).unwrap();
            const { success, message } = response ?? {};

            if (success) {
                showSuccessToast(message || "Profile updated successfully");
                navigate("/account/profile");
            } else if (Array.isArray(message)) {
                const result = message.flatMap((msgObj: { message: string }) => msgObj.message);
                if (result.length > 0) {
                    showErrorToast(result[0]);
                }
            } else {
                showErrorToast(message || "Something went wrong, please try again.");
            }
        } catch (error: unknown) {
            const message = (error as { message?: string }).message || "Something went wrong, please try again.";
            showErrorToast(message);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const phoneListData = await CountryService.getCountryPhonesList();
            const countryListData = await CountryService.getSelectableCountryList();
            const mossCountriesData = await CountryService.getMossCountries();

            try {
                setData({
                    phoneCodes: phoneListData,
                    countryList: countryListData,
                    mossCountries: mossCountriesData,
                });
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            } finally {
                setLoading(false);
            }
        };

        void fetchData();
    }, []);

    if (!data.phoneCodes.length || !data.countryList.length) {
        return;
    }

    return (
        <PageContainer>
            <PageTitleWithIcon title="Profile Information" iconName="fa-solid fa-gears" />
            <SectionDescription>Personal Data and Account Settings</SectionDescription>

            <ContentContainer>
                <Form<FormValues>
                    onSubmit={onSubmit}
                    validate={validateProfile}
                    initialValues={profile}
                    render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit} className={classes.formContainer}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Email address</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField value={currentUserEmail} name="email" disabled fullWidth />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Full name</label>
                                            <span className={classes.requiredLabel} />
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField name="name" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Phone Number</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <SelectField
                                                options={data.phoneCodes}
                                                style={{ marginBottom: 4, display: "block" }}
                                                name="phoneCode"
                                                fullWidth
                                                disabled={isLoading || submitting}
                                            />
                                            <TextField
                                                name="phoneNumber"
                                                fullWidth
                                                style={{ display: "block" }}
                                                disabled={isLoading}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Address</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField name="address" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Zip code</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField name="zipCode" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>City</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField name="city" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label htmlFor="country" className={classes.formLabel}>
                                                Country
                                            </label>
                                            <span className={classes.requiredLabel} />
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <SelectField
                                                options={data.countryList}
                                                name="country"
                                                fullWidth
                                                disabled={isLoading || submitting}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Type of usage</label>
                                            <span className={classes.requiredLabel} />
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <SelectField
                                                options={typeOfUsageOptions}
                                                name="typeOfUsage"
                                                fullWidth
                                                disabled={isLoading}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Company</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField name="companyName" fullWidth disabled={isLoading} />
                                        </TableCell>
                                    </TableRow>

                                    {countryUsesMoss ? (
                                        <TableRow>
                                            <TableCell>
                                                <label className={classes.formLabel}>VAT ID</label>
                                            </TableCell>
                                            <TableCell className={classes.tableValueCell}>
                                                <TextField name="companyVAT" fullWidth disabled={isLoading} />
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>
                                            <label className={classes.formLabel}>Industry</label>
                                        </TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <TextField
                                                name="fieldOfApplication"
                                                placeholder="Automotive, IoT, Construction, ..."
                                                fullWidth
                                                disabled={isLoading}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <h3>Security Settings</h3>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Account password</TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            <ApmLink
                                                parent="Edit account page"
                                                title="Change password"
                                                role="button"
                                                to="/account/password/edit"
                                            >
                                                <PrimaryOutlinedButton>Change</PrimaryOutlinedButton>
                                            </ApmLink>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Two-Factor authentication</TableCell>
                                        <TableCell className={classes.tableValueCell}>
                                            {profile.twoFactorEnabled ? (
                                                <ApmLink
                                                    parent="Edit account page"
                                                    title="Disable 2FA"
                                                    role="button"
                                                    to="/account/2fa/disable"
                                                >
                                                    <SecondaryButton>Disable</SecondaryButton>
                                                </ApmLink>
                                            ) : (
                                                <ApmLink
                                                    parent="Edit account page"
                                                    title="Enable 2FA"
                                                    role="button"
                                                    to="/account/2fa/enable"
                                                >
                                                    <PrimaryOutlinedButton>Enable</PrimaryOutlinedButton>
                                                </ApmLink>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <h3>E-Mail Notifications</h3>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography>
                                                We use the above information to communicate with you for account-related
                                                questions, fulfill your requests, improve our products and services, and
                                                comply with laws and regulations. Further information will be only sent
                                                if you expressly sign up to this kind of service.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2} className={classes.tableValueCell}>
                                            <CheckboxField
                                                name="newsletterChecked"
                                                disableRipple
                                                label="I agree to receive the monthly newsletter with product news and
                                                promotional material from emteria"
                                                disabled={isLoading}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <div className={classes.actionRow}>
                                <Link to="/account/profile">
                                    <SecondaryButton>Cancel</SecondaryButton>
                                </Link>
                                <PrimaryButton type="submit" disabled={submitting || pristine} loading={submitting}>
                                    Save
                                </PrimaryButton>
                            </div>
                        </form>
                    )}
                />
            </ContentContainer>
        </PageContainer>
    );
};

export default EditAccountPage;
