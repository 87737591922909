import clarity from "@microsoft/clarity";

import { getSettings } from "./services/configService";

export const initializeClarity = () => {
    const { envName } = getSettings();
    if (envName === "production") {
        clarity.init("jvfkx4k4ff");
    }
};
