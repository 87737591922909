import { FormApi } from "final-form";
import _ from "lodash";
import React from "react";
import { Form, FormProps } from "react-final-form";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { CheckboxField } from "src/ui/shared/form/CheckboxField";
import { Modal } from "src/ui/shared/Modal/Modal";

import { Checkbox, FormControlLabel, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Header } from "@shared/table/TableHeader";
import { Header as TanstackHeader } from "@tanstack/react-table";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        minHeight: 300,
        margin: "12px 0px",
        background: theme.palette.shadow[50],
        borderRadius: "6px",
    },
    informationText: {
        fontWeight: 600,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: 16,
        padding: "14px 0px",
        maxHeight: 300,
        overflow: "auto",
        overflowX: "hidden",
        position: "relative",
    },
    selectManyContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    sortIcon: {
        marginLeft: "8px",
        fontSize: "12px",
        cursor: "pointer",
    },
    sortButtonContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    sortText: {
        marginRight: "4px",
    },
    filterBadge: {
        cursor: "pointer",
        marginLeft: 5,
        paddingRight: 5,
        paddingBottom: 3,
        display: "inline",
    },
}));

const changeAllValuesTo = (arr: { key: string; label: string }[], value: boolean) => {
    return arr.reduce((acc, item) => ({ ...acc, [item.key]: value }), {});
};

type FormValues = Record<string, boolean>;

type Props<T> = {
    options: { key: string; label: string }[];
    header: TanstackHeader<T, unknown>;
    form: FormProps<FormValues>;
    badgeContent: number;
};

export const FilterHeader = <T,>(props: Props<T>) => {
    const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("asc");
    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    const truthyFilters = changeAllValuesTo(props.options, true);
    const falsyFilters = changeAllValuesTo(props.options, false);

    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        await props.form.onSubmit(values, form);
        setOpen(false);
    };

    const toggleSortOrder = () => {
        setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    };
    const getSortedFilters = (arr: { key: string; label: string }[]) => {
        return _.orderBy(arr, (item) => item.key, sortOrder);
    };

    return (
        <Header {...props.header}>
            <Form<FormValues>
                {...props.form}
                onSubmit={onSubmit}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <CustomBadge
                                badgeContent={props.badgeContent}
                                className={classes.filterBadge}
                                style={{ backgroundColor: "transparent" }}
                                onClick={() => setOpen(true)}
                                overlap="rectangular"
                            >
                                <i className="fa-solid fa-filter" />
                            </CustomBadge>
                            <Modal
                                title="Filter applications"
                                open={open}
                                onClose={() => setOpen(false)}
                                endButton={<PrimaryButton onClick={form.submit}>Save</PrimaryButton>}
                            >
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.sortButtonContainer} onClick={toggleSortOrder}>
                                        <Typography className={classes.sortText}>Sort</Typography>
                                        <i
                                            className={`fa-solid ${
                                                sortOrder === "asc" ? "fa-arrow-up" : "fa-arrow-down"
                                            } ${classes.sortIcon}`}
                                        />
                                    </div>

                                    <div className={classes.content}>
                                        <div className={classes.checkboxContainer}>
                                            {getSortedFilters(props.options).map((item) => (
                                                <CheckboxField
                                                    key={item.key}
                                                    name={`["${item.key}"]`}
                                                    label={item.label}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.selectManyContainer}>
                                        {Object.values(values).length ? (
                                            <FormControlLabel
                                                label={<span className={classes.informationText}>Select all</span>}
                                                control={
                                                    <Checkbox
                                                        checked={Object.values(values).every((item) => item)}
                                                        indeterminate={false}
                                                        onChange={(
                                                            _e: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean,
                                                        ) => {
                                                            form.reset(checked ? truthyFilters : falsyFilters);
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </form>
                            </Modal>
                        </form>
                    );
                }}
            />
        </Header>
    );
};
