import { useState } from "react";
import { useDispatch, useSelector } from "src/store";

import { deleteFdroidRepo } from "@dashboard/devices/store";
import {
    selectSubscriptionExpired,
    selectSubscriptionFdroidRepos,
} from "@dashboard/devices/store/selectors/subscriptions";
import { TutorialSection } from "@dashboard/overview/components/TutorialSection";
import { updateProvisioning } from "@dashboard/provisioning/store/index";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import {
    ContentContainer,
    ContentContainerSubTitle,
    ContentContainerTitle,
    PageContainer,
    SectionDescription,
} from "@dashboard/shared/styles";
import {
    selectIsWorkspaceFDroidReposOverlimit,
    selectIsWorkspaceLoading,
    selectWorkspace,
} from "@dashboard/workspaces/store/selectors";
import { DialogContentText, Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import { SecondaryButton } from "@shared/CustomButton";
import { ExpiredWrapper } from "@shared/ExpiredWrapper";
import { LoadingBox } from "@shared/Loading";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { ConfirmDialog } from "../devices/components/shared";
import { Provisioning } from "../provisioning/types";
import { CreateFdroidRepoDialog } from "./CreateFdroidRepoDialog/CreateFdroidRepoDialog";
import { useStyles } from "./RepositoriesPage.style";
import RepositoryTableItem from "./RepositoryTableItem";

export type DeleteProps = {
    title: string;
    subscriptionId: number;
    id: number;
};

export const RepositoriesPage = () => {
    const [selectedRepoForDeletion, setSelectedRepoForDeletion] = useState<DeleteProps | null>(null);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const workspace = useSelector(selectWorkspace);
    const fdroidRepos = useSelector(selectSubscriptionFdroidRepos);
    const overLimit = useSelector(selectIsWorkspaceFDroidReposOverlimit);
    const isExpired = useSelector(selectSubscriptionExpired);
    const provisioningSettings = useSelector(selectProvisionings);
    const isWorkspaceLoading = useSelector(selectIsWorkspaceLoading);

    const getCreateButtonTooltip = () => {
        if (overLimit) {
            return "You have reached the maximum limit of repositories allowed for your subscription.";
        }
        if (isExpired) {
            return "Subscription expired";
        }
        return "";
    };

    const handleDelete = () => {
        if (selectedRepoForDeletion) {
            const payload = {
                subscriptionId: selectedRepoForDeletion.subscriptionId,
                repoId: selectedRepoForDeletion.id,
            };

            const updatedProvisionings: Provisioning[] = [];

            provisioningSettings.forEach((item) => {
                if (item.settings?.fDroidRepos.some((repo) => repo.id === payload.repoId)) {
                    const updatedFDroidRepos = item.settings.fDroidRepos.filter((repo) => repo.id !== payload.repoId);

                    const updatedItem = {
                        ...item,
                        settings: {
                            ...item.settings,
                            fDroidRepos: updatedFDroidRepos,
                        },
                    };

                    updatedProvisionings.push(updatedItem);
                }
            });

            Promise.all(updatedProvisionings.map((provisioning) => dispatch(updateProvisioning(provisioning)).unwrap()))
                .then(() => dispatch(deleteFdroidRepo(payload)).unwrap())
                .then(() => showSuccessToast("Successfully deleted a repository"))
                .catch(({ message = "Failed to delete a repository. Please try again" }) => showErrorToast(message))
                .finally(() => setSelectedRepoForDeletion(null));
        }
    };

    if (isWorkspaceLoading) {
        return <LoadingBox />;
    }

    if (!workspace) {
        return (
            <PageContainer>
                <PageTitleWithIcon title="Application repositories" iconName="fa-solid fa-cubes" />
                <SectionDescription>
                    Create private app repositories to enrich the preinstalled F-Droid application store with your
                    custom Android apps. F-Droid repositories must be added to device provisioning options to be
                    enrolled during the initial device boot.
                </SectionDescription>
                <TutorialSection />
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <PageTitleWithIcon title="Application repositories" iconName="fa-solid fa-cubes" />
            <SectionDescription>
                Create private app repositories to enrich the preinstalled F-Droid application store with your custom
                Android apps. F-Droid repositories must be added to device provisioning options to be enrolled during
                the initial device boot.
            </SectionDescription>
            <ExpiredWrapper>
                <ContentContainer>
                    <div className={classes.titleContainer}>
                        <div>
                            <ContentContainerTitle>Existing F-Droid repositories</ContentContainerTitle>
                            <ContentContainerSubTitle>
                                Preinstalled F-Droid client can use these to present and manage custom Android apps.
                            </ContentContainerSubTitle>
                        </div>
                        <div>
                            <SecondaryButton
                                onClick={() => setCreateDialogOpen(true)}
                                disabled={overLimit || isExpired}
                                tooltipProps={{ title: getCreateButtonTooltip() }}
                                startIcon={<i className="fa-solid fa-plus" />}
                            >
                                Create new F-Droid repository
                            </SecondaryButton>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <MinimalTable>
                            <MinimalTableHeader>
                                <TableRow>
                                    <MinimalTableHeaderCell>URL</MinimalTableHeaderCell>
                                    <MinimalTableHeaderCell>Title</MinimalTableHeaderCell>
                                    <MinimalTableHeaderCell>Description</MinimalTableHeaderCell>
                                    <MinimalTableHeaderCell align="center">Used in provisioning</MinimalTableHeaderCell>
                                    <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                                </TableRow>
                            </MinimalTableHeader>

                            <TableBody>
                                {fdroidRepos.length ? (
                                    fdroidRepos.map((repo, index) => (
                                        <RepositoryTableItem
                                            key={index}
                                            index={index}
                                            repo={repo}
                                            handleOpenDeleteDialog={() => setSelectedRepoForDeletion(repo)}
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <MinimalTableCell align="center" colSpan={6}>
                                            No private repositories created
                                        </MinimalTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </MinimalTable>
                    </TableContainer>
                    <CreateFdroidRepoDialog isOpen={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
                    {selectedRepoForDeletion && (
                        <ConfirmDialog
                            title={`Delete "${selectedRepoForDeletion.title}" repository`}
                            dangerButton
                            primaryActionText="Delete"
                            open={!!selectedRepoForDeletion}
                            onConfirm={handleDelete}
                            onClose={() => setSelectedRepoForDeletion(null)}
                        >
                            <DialogContentText>
                                Are you sure you want to delete the{" "}
                                <span style={{ fontWeight: "bold" }}>{selectedRepoForDeletion.title}</span> repository.
                                This repository will be automatically removed from all provisioning settings, and all
                                files uploaded to this repository will be deleted. This action cannot be undone.
                            </DialogContentText>
                        </ConfirmDialog>
                    )}
                </ContentContainer>
            </ExpiredWrapper>
        </PageContainer>
    );
};
