import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CellContext, HeaderContext } from "@tanstack/react-table";

import { GroupOverviewData } from "../../GroupsPage";

const useStyles = makeStyles({
    box: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        cursor: "pointer",
        "& i": {
            padding: "8px",
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    },
});

type ExpanderButtonProps = {
    isExpanded: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    depth?: number;
};
const ExpanderButton = ({ depth = 0, ...props }: React.PropsWithChildren<ExpanderButtonProps>) => {
    const classes = useStyles();

    return (
        <div onClick={props.onClick} className={classes.box} style={{ paddingLeft: `${depth * 2}rem` }}>
            <div style={{ padding: "8px" }}>
                {props.isExpanded ? (
                    <i className="fa-solid fa-angle-down" />
                ) : (
                    <i className="fa-sm fa-solid fa-chevron-right" />
                )}
            </div>
            {props.children}
        </div>
    );
};
export const ExpandedCell = (props: React.PropsWithChildren<CellContext<GroupOverviewData, unknown>>) => {
    if (!props.row.getCanExpand()) {
        return <Box style={{ marginLeft: "1rem", paddingLeft: `${props.row.depth * 2}rem` }}>{props.children}</Box>;
    }

    return (
        <ExpanderButton
            isExpanded={props.row.getIsExpanded()}
            onClick={props.row.getToggleExpandedHandler()}
            depth={props.row.depth}
        >
            {props.children}
        </ExpanderButton>
    );
};

type ExpandedHeaderProps = HeaderContext<GroupOverviewData, unknown> & { title: string };
export const ExpandedHeader = (props: ExpandedHeaderProps) => {
    const classes = useStyles();

    const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        props.table.toggleAllRowsExpanded();
    };

    return (
        <div className={classes.box}>
            <ExpanderButton onClick={onClick} isExpanded={props.table.getIsAllRowsExpanded()} />
            <div style={{ fontWeight: 600 }} onClick={props.column.getToggleSortingHandler()}>
                {props.title}
            </div>
        </div>
    );
};
