import { FormApi } from "final-form";
import { useState } from "react";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";

import { ConfirmDialog } from "@dashboard/devices/components/shared/index";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Row } from "@dashboard/shared/styles";
import { selectIsWorkspaceProvisioningOverlimit } from "@dashboard/workspaces/store/selectors";
import { Box, DialogContentText, SelectChangeEvent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { SelectField } from "@shared/form/SelectField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { deleteProvisioning } from "../store";
import { selectProvisioningListStatus } from "../store/selectors";
import { Provisioning } from "../types";
import { defaultProvisioning } from "../utils";
import CreateProvisioningDialog from "./CreateProvisioningDialog";
import EditProvisioningNameDialog from "./EditProvisioningNameDialog";

const useStyles = makeStyles({
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 16,
    },
});

type Props = {
    submitting: boolean;
    form: FormApi<API.Provisioning, Partial<API.Provisioning>>;
};

export const ProvisioningManagement = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const status = useSelector(selectProvisioningListStatus);
    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const isOverLimit = useSelector(selectIsWorkspaceProvisioningOverlimit);
    const isExpired = useSelector(selectSubscriptionExpired);

    const selectedProvisioningId = props.form.getState().values.id;
    const selectedProvisioning = provisionings.find((p) => p.id === selectedProvisioningId) || null;

    const onChange = (e: SelectChangeEvent<unknown>) => {
        const provisioning = provisionings.filter((item) => item.id === e.target.value)[0];
        if (provisioning) {
            props.form.reset(provisioning);
        }
    };

    const getCreateButtonTooltip = () => {
        if (isOverLimit) {
            return "You have reached the maximum limit of provisioning settings allowed for your subscription.";
        }
        if (isExpired) {
            return "Subscription expired";
        }
        return "";
    };

    const onDelete = async (
        provisioningId: number | null,
        form: FormApi<API.Provisioning, Partial<API.Provisioning>>,
    ) => {
        if (provisioningId) {
            await dispatch(deleteProvisioning(provisioningId))
                .unwrap()
                .then(() => {
                    showSuccessToast("Provisioning settings deleted");
                    const provisioning = provisionings.filter((item) => item.id !== provisioningId)[0];
                    form.reset(provisioning || defaultProvisioning);
                })
                .catch(({ message = "Deletion failed" }) => showErrorToast(message || "Deletion failed"))
                .finally(() => setDeleteDialogOpen(false));
        }
    };

    return (
        <>
            <Box style={{ paddingBottom: 15 }}>
                <SelectField
                    name="id"
                    label={!provisionings.length ? "You have no provisioning settings" : "Choose settings"}
                    disabled={props.submitting || status === "pending" || !provisionings.length}
                    options={provisionings.map((item: Provisioning) => ({
                        label: item.name,
                        value: item.id,
                    }))}
                    fullWidth
                    onChange={onChange}
                />

                <Row className={classes.buttonContainer}>
                    <PrimaryOutlinedButton
                        disabled={props.submitting || isOverLimit || isExpired}
                        tooltipProps={{ title: "Edit name" }}
                        onClick={() => setEditDialogOpen(true)}
                    >
                        Edit
                    </PrimaryOutlinedButton>
                    <div>
                        <DangerOutlinedButton
                            style={{ marginRight: 6 }}
                            disabled={props.submitting || !provisionings.length}
                            onClick={() => setDeleteDialogOpen(true)}
                            data-testid="provisioningPage.btn.delete"
                        >
                            Delete
                        </DangerOutlinedButton>
                        <PrimaryOutlinedButton
                            disabled={props.submitting || isOverLimit || isExpired}
                            onClick={() => setCreateDialogOpen(true)}
                            data-testid="provisioningPage.btn.create"
                            tooltipProps={{ title: getCreateButtonTooltip() }}
                        >
                            Create
                        </PrimaryOutlinedButton>
                    </div>
                </Row>
            </Box>
            <CreateProvisioningDialog open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
            {selectedProvisioning ? (
                <EditProvisioningNameDialog
                    open={editDialogOpen}
                    onClose={() => setEditDialogOpen(false)}
                    provisioning={selectedProvisioning}
                />
            ) : null}
            <ConfirmDialog
                title="Delete provisioning settings"
                dangerButton
                primaryActionText="Delete"
                open={deleteDialogOpen}
                onConfirm={() => onDelete(props.form.getState().values.id, props.form)}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogContentText>
                    This will delete provisioning settings <b>{props.form.getState().values.name}</b>. Are you sure?
                </DialogContentText>
            </ConfirmDialog>
        </>
    );
};
