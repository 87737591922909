import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { Box } from "@mui/material";

import ApmLink from "../ApmLink";
import { PrimaryButton, SecondaryButton } from "../CustomButton";
import { showErrorToast } from "../toasts/Toasts";
import { useStyles } from "./OtpSection.style";

interface OtpSectionProps {
    setupOtp?: boolean;
    submitting: boolean;
    onSubmit: (otp: string) => void;
}

const OtpSection: React.FC<OtpSectionProps> = ({ onSubmit, setupOtp = false, submitting }) => {
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
    const navigate = useNavigate();
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array.from({ length: 6 }, () => null));
    const classes = useStyles();
    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        const { value } = e.target;
        const newOTP = [...otp];

        newOTP[index] = value.substring(value.length - 1);
        setOtp(newOTP);

        if (value && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }

        if (index === 5 && newOTP.every((digit) => digit !== "")) {
            onSubmit(newOTP.join(""));
        }
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && index > 0 && !otp[index]) {
            inputRefs.current[index - 1]?.focus();
        }
    };
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("text").trim();
        if (/^\d{6}$/.test(pasteData)) {
            setOtp(pasteData.split(""));
            onSubmit(pasteData);
        }
    };

    const handleOnSubmit = () => {
        const otpCode = otp.join("");

        if (otpCode.length < 6) {
            showErrorToast("Please enter the complete 6-digit authentication code");
            return;
        }

        onSubmit(otpCode);
    };

    const handleCancel = () => {
        if (setupOtp) {
            navigate("/account/edit");
        } else {
            navigate("/");
        }
    };

    return (
        <Box>
            <h1 className={classes.description}>Enter the 6-digit code from your authenticator app:</h1>
            <div className={classes.otpInputContainer}>
                {otp.map((_, index) => (
                    <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="number"
                        className={classes.otpInputField}
                        onChange={(e) => handleOnChange(e, index)}
                        onKeyDown={(e) => handleOnKeyDown(e, index)}
                        onPaste={handlePaste}
                        value={otp[index]}
                    />
                ))}
            </div>
            <p className={classes.forumHintText}>
                If you’re having trouble, visit our{" "}
                <ApmLink href="https://forum.emteria.com" target="_blank" rel="noopener noreferrer">
                    discussion forum
                </ApmLink>{" "}
                for assistance.
            </p>

            <div className={classes.actionButtonContainer}>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                <PrimaryButton onClick={handleOnSubmit} loading={submitting}>
                    {setupOtp ? "Confirm" : "Login"}
                </PrimaryButton>
            </div>
        </Box>
    );
};

export default OtpSection;
