import clsx from "clsx";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import UserSettingsService from "src/services/userSettingsService";
import { useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CustomBadge } from "src/ui/shared/CustomBadge";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";

import { Box, Checkbox, Divider, Drawer, FormControlLabel, MenuItem, Typography } from "@mui/material";

const setOverviewPageGroupFilter = (filters: number[]) => {
    UserSettingsService.setWorkspaceValue("overviewPage.groupFilter", filters);
};

const useStyles = createUseStyles({
    drawer: {
        width: "400px",
        flexShrink: 0,
    },
    drawerPaper: {
        width: "400px",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 15,
    },
    hideButton: {
        visibility: "hidden",
    },
    centeredBox: {
        marginTop: 16,
        display: "flex",
        justifyContent: "center",
    },
    listBox: { marginTop: 16 },
});

type Props = {
    filter: number[];
    setFilter: (filter: number[]) => void;
};

export const Filter = ({ filter, setFilter }: Props) => {
    const classes = useStyles();
    const groups = useSelector(selectGroups);

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleMenuItemClick = (id: number) => {
        let newFilter = [...filter];

        if (newFilter.includes(id)) {
            newFilter = newFilter.filter((i) => i !== id);
        } else {
            newFilter = [...newFilter, id];
        }

        setFilter(newFilter);
        setOverviewPageGroupFilter(newFilter);
    };

    const handleSelectAll = () => {
        if (groups.length === filter.length) {
            setFilter([]);
            setOverviewPageGroupFilter([]);
        } else {
            const newFilter = groups.map((g) => g.id);
            setFilter(newFilter);
            setOverviewPageGroupFilter(newFilter);
        }
    };

    return (
        <>
            <CustomBadge className={drawerOpen ? classes.hideButton : ""} badgeContent={filter.length}>
                <SecondaryButton startIcon={<i className="fas fa-filter" />} onClick={() => setDrawerOpen(true)}>
                    Filter
                </SecondaryButton>
            </CustomBadge>
            <Drawer
                className={drawerOpen ? classes.drawer : ""}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                anchor="right"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Box className={classes.drawerHeader}>
                    <PrimaryButton disabled={groups.length === 0} onClick={handleSelectAll}>
                        {groups.length === filter.length ? "Deselect all" : "Select all"}
                    </PrimaryButton>
                    <SecondaryButton onClick={() => setDrawerOpen(false)}>Close</SecondaryButton>
                </Box>
                <Divider />
                <Box className={clsx(classes.listBox, groups.length === 0 && classes.centeredBox)}>
                    {groups.length > 0 ? (
                        groups.map((g) => (
                            <MenuItem
                                key={g.id}
                                onClick={() => handleMenuItemClick(g.id)}
                                style={{ cursor: "pointer" }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.includes(g.id)}
                                            onChange={() => {
                                                handleMenuItemClick(g.id);
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label={g.name}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </MenuItem>
                        ))
                    ) : (
                        <Typography>No groups</Typography>
                    )}
                </Box>
            </Drawer>
        </>
    );
};
