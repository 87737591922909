import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";
import { PrimaryButton } from "src/ui/shared/CustomButton";
import { Modal } from "src/ui/shared/Modal/Modal";

import { updateGroup, updateGroupParent } from "@dashboard/devices/store/index";
import { SelectField } from "@shared/form/SelectField";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { selectGroupNestingLimit } from "../../workspaces/store/selectors";
import { GroupOverviewData } from "../GroupsPage";
import { selectGroupChildrenIds, selectGroups } from "../store/selectors";
import { useParentGroupSelection, validateGroupForm } from "../utils";

interface FormValues {
    name: string;
    description: string;
    parentId: number | null;
}

interface Props {
    group: GroupOverviewData;
    open: boolean;
    onClose: () => void;
}

const EditGroupDialog = (props: Props) => {
    const dispatch = useDispatch();

    const groups = useSelector(selectGroups);
    const childrenGroupIds = useSelector((state) => selectGroupChildrenIds(state, props.group.id));
    const avaibleGroups = groups.filter((group) => ![...childrenGroupIds, props.group.id].includes(group.id));
    const options = [
        { label: "No parent group", value: undefined },
        ...avaibleGroups.map((item) => ({ label: item.name, value: item.id })),
    ].sort();
    const groupNestingLimit = useSelector(selectGroupNestingLimit);

    const validate = (values: FormValues) => validateGroupForm(values, groups, groupNestingLimit, props.group.id);
    const { isLimitReached, handleParentGroupChange } = useParentGroupSelection(groups, groupNestingLimit);

    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        try {
            const initialValues = form.getState().initialValues;
            const parentIdChanged = values.parentId !== initialValues.parentId;

            const parentId = values.parentId === undefined ? null : values.parentId;
            if (parentIdChanged) {
                await dispatch(updateGroupParent({ id: props.group.id, parentId })).unwrap();
            }

            const nameChanged = values.name !== initialValues.name;
            const descriptionChanged = values.description !== initialValues.description;
            if (nameChanged || descriptionChanged) {
                await dispatch(
                    updateGroup({ id: props.group.id, name: values.name, description: values.description }),
                ).unwrap();
            }

            showSuccessToast("Successfully updated group");
            props.onClose();
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Failed to update group");
        }
    };

    return (
        <Form<FormValues>
            validate={validate}
            onSubmit={onSubmit}
            initialValues={{
                name: props.group.name,
                description: props.group.description,
                parentId: props.group.parentId || undefined,
            }}
            render={({ handleSubmit, pristine, form }) => (
                <form onSubmit={handleSubmit}>
                    <Modal
                        open={props.open}
                        onClose={props.onClose}
                        title="Edit group details"
                        endButton={
                            <PrimaryButton
                                onClick={form.submit}
                                disabled={pristine || isLimitReached}
                                tooltipProps={{
                                    title: isLimitReached
                                        ? "Group nesting limit reached. Cannot create a subgroup at this level."
                                        : "",
                                }}
                            >
                                Save
                            </PrimaryButton>
                        }
                    >
                        <TextField name="name" label="Name" required />
                        <TextField name="description" label="Description" multiline minRows={6} maxRows={6} />
                        <SelectField
                            name="parentId"
                            label="Parent group"
                            placeholder="Parent group"
                            options={options}
                            onChange={handleParentGroupChange}
                        />
                    </Modal>
                </form>
            )}
        />
    );
};

export default EditGroupDialog;
